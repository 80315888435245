import { tryParseDate } from '@shared/functions/try-parse-date';
import { ElectricMeterCabinet, IElectricMeterCabinet } from '@shared/models/electric-meter-cabinet';
import { Boat, IBoat } from '@shared/models/boat';
import { Reading } from '@shared/models/electric-meter-reading';
import { ElectricMeterAssignment, IElectricMeterAssignment } from '@shared/models/electric-meter-assignment';

export interface IElectricMeterRaw {
    id?: number;
    handle: string;
    manufacturerHandle: string;
    installationDate: string;
    meterReading: number;
    lastReadingOn: string;
    fuse: string;
    connector: string;
    keyHandle: string; // Hat jeder Zähler nochmal einen eigenen Schlüssel? Also Schrankschlüssel + Zählerschlüssel?
    note?: string;

    cabinet: IElectricMeterCabinet;
    boats: IBoat[];
    readings: any[];
    boatAssignments: IElectricMeterAssignment[];
}

export interface IElectricMeter {
    id?: number;
    handle: string;
    manufacturerHandle: string;
    installationDate: Date;
    meterReading: number;
    lastReadingOn: Date;
    fuse: string;
    connector: string;
    keyHandle: string; // Hat jeder Zähler nochmal einen eigenen Schlüssel? Also Schrankschlüssel + Zählerschlüssel?
    note?: string;

    cabinet: IElectricMeterCabinet;
    boats: IBoat[];
    readings: any[];
    boatAssignments: IElectricMeterAssignment[];
}

export class ElectricMeter {
    cabinet: ElectricMeterCabinet;
    meterReading: number;
    lastReadingOn: Date;
    connector: string;
    fuse: string;
    handle: string;
    id: number;
    installationDate: Date;
    keyHandle: string;
    manufacturerHandle: string;
    fullName: string;
    note: string;
    boats: Boat[];
    readings: Reading[];
    boatAssignments: ElectricMeterAssignment[];

    constructor(electricMeter = {} as (IElectricMeter | IElectricMeterRaw)) {
        this.meterReading = electricMeter.meterReading;
        this.lastReadingOn = tryParseDate(electricMeter.lastReadingOn);
        this.connector = electricMeter.connector;
        this.fuse = electricMeter.fuse;
        this.handle = electricMeter.handle;
        this.fullName = (electricMeter.cabinet?.handle + ' ' + electricMeter.handle).trim();
        this.id = electricMeter.id;
        this.installationDate = tryParseDate(electricMeter.installationDate);
        this.keyHandle = electricMeter.keyHandle;
        this.manufacturerHandle = electricMeter.manufacturerHandle;
        this.note = electricMeter.note;
        this.cabinet = electricMeter.cabinet ? new ElectricMeterCabinet(electricMeter.cabinet) : null;
        this.boats = (electricMeter.boats || []).map(boat => new Boat(boat));
        this.readings = (electricMeter.readings || []).map(reading => new Reading(reading));
        this.boatAssignments = (electricMeter.boatAssignments || []).map(assignment => new ElectricMeterAssignment(assignment));
    }

    toString(): string {
        return this.fullName;
    }

    valueOf(): number {
        return this.id;
    }

}

