import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@shared/models/company';

@Component({
    selector: 'bcm-company-link',
    templateUrl: './company-link.component.html'
})
export class CompanyLinkComponent {

    @Input()
    public company: Company;

    @Input()
    public prefix: string;

    @Input()
    public suffix: string;

    @Input()
    public dataTest: string;

    @Input()
    public disableLink: boolean;

    @Input()
    public showTenantRelations: boolean;

    @Output()
    public clicked = new EventEmitter<void>();

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
