<div [formGroup]="boatFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex="100">

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container *ngIf="!slimmedView">
                <h2 [innerHTML]="headline"></h2>
            </ng-container>

            <ng-container *ngIf="!boat?.id && canCreate">
                <div>
                    <button mat-icon-button
                            type="button"
                            color="primary"
                            data-test="B-Optionale Felder"
                            matTooltip="Optionale Felder {{showOptionalFields ? 'ausblenden' : 'einblenden'}}"
                            (click)="onClickToggleOptionalFields()">
                        <ng-container *ngIf="!showOptionalFields">
                            <mat-icon>visibility_off</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="showOptionalFields">
                            <mat-icon>visibility</mat-icon>
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="loadingBoats; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!topList?.length && !bottomList?.length">
                <u2b-message>
                    <header>{{'missingBoats' | translate}}</header>
                    <p>{{'missingBoatsInSystem' | translate}}</p>
                </u2b-message>
            </ng-container>

            <ng-container *ngIf="topList?.length || bottomList?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-label>{{'boat' | translate}}</mat-label>
                        <input type="text" matInput data-test="FI-Nach Boot suchen..."
                               formControlName="boat"
                               [matAutocomplete]="boatSelector">
                        <button matSuffix
                                mat-icon-button
                                type="button"
                                color="warn"
                                [matTooltip]="'deleteBoatTooltip' | translate"
                                [disabled]="!boat?.id || boatFormGroup.disabled"
                                (click)="onClickRemoveBoat()">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <mat-error
                                [hidden]="slimmedView">{{boatFormGroup.get('boat').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete #boatSelector="matAutocomplete" [displayWith]="displayBoatWith">
                        <ng-container *ngIf="displayBoatOwnerName?.length > 0 || boatOwnerName?.length > 0">
                            <ng-container *ngIf="filteredTopList$ | async as topList">
                                <mat-optgroup
                                        [label]="'boatsFromOwner' | translate : {boatOwnerName: (boatOwnerName || displayBoatOwnerName)}">
                                    <ng-container *ngFor="let boat of topList">
                                        <mat-option [value]="boat">
                                            <bcm-boat-link [disableLink]="true" [boat]="boat"></bcm-boat-link>
                                            <ng-container
                                                    *ngIf="(boat.owner || boat.ownerCompany)?.id; else noOwner">
                                                <br>
                                                <small>
                                                    <bcm-boat-owner-link [disableLink]="true"
                                                                         [boat]="boat"></bcm-boat-owner-link>
                                                </small>
                                            </ng-container>
                                            <ng-template #noOwner>
                                                <br>
                                                <small class="color-warn">Kein Eigner</small>
                                            </ng-template>
                                        </mat-option>
                                        <ng-container *ngIf="!topList?.length">
                                            {{('ownerHasNoBoat' | translate : {boatOwnerName: (boatOwnerName || displayBoatOwnerName)})}}
                                        </ng-container>
                                    </ng-container>
                                </mat-optgroup>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                            <mat-optgroup
                                    [label]="(displayBoatOwnerName?.length > 0 || boatOwnerName?.length > 0) ?('moreBoats' | translate) :('allBoats' | translate)">
                                <ng-container *ngFor="let boat of bottomList">
                                    <mat-option [value]="boat">
                                        <bcm-boat-link [disableLink]="true" [boat]="boat"></bcm-boat-link>
                                        <ng-container *ngIf="(boat.owner || boat.ownerCompany)?.id; else noOwner">
                                            <br>
                                            <small>
                                                <bcm-boat-owner-link [disableLink]="true"
                                                                     [boat]="boat"></bcm-boat-owner-link>
                                            </small>
                                        </ng-container>
                                        <ng-template #noOwner>
                                            <br>
                                            <small class="color-warn">Kein Eigner</small>
                                        </ng-template>
                                    </mat-option>
                                    <ng-container *ngIf="!bottomList?.length">
                                        {{'missingBoats' | translate}}
                                    </ng-container>
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </ng-container>

        </ng-template>
    </div>

    <ng-container *ngIf="boat?.id && showMetaData">
        <div fxFlex="100">
            <table class="simple sm">
                <tbody>
                <tr>
                    <td>Länge&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{boat.length || 'nicht angegeben'}}</td>
                </tr>
                <tr>
                    <td>Breite&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{boat.width || 'nicht angegeben'}}</td>
                </tr>
                <tr>
                    <td>Länge über Alles&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{boat.lengthOverall || 'nicht angegeben'}}</td>
                </tr>
                <tr>
                    <td>Fläche (m²):</td>
                    <td fxLayoutAlign="end center">{{boat.sqm || 'nicht angegeben'}}</td>
                </tr>
                <ng-container *ngIf="givenBerth?.isBuoy">
                    <tr>
                        <td>Gewicht (kg):</td>
                        <td fxLayoutAlign="end center">{{boat.weight || 'nicht angegeben'}}</td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="!boat?.id && canCreate">

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>Kennzeichen</mat-label>
            <input matInput data-test="FI-Bootsname" type="text" formControlName="licensePlate"
                   autocomplete="new-password">
            <mat-error>{{boatFormGroup.get('licensePlate').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>{{'boatName' | translate}}</mat-label>
            <input matInput data-test="FI-Bootsname" type="text" formControlName="name"
                   autocomplete="new-password">
            <mat-error>{{boatFormGroup.get('name').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>Länge&nbsp;(m)</mat-label>
            <app-input-number
                    data-test="FI-Länge (m)"
                    formControlName="length"
                    [step]="0.1"></app-input-number>
            <mat-error>{{boatFormGroup.get('length').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>Breite&nbsp;(m)</mat-label>
            <app-input-number
                    data-test="FI-Breite (m)"
                    formControlName="width"
                    [step]="0.1"></app-input-number>
            <mat-error>{{boatFormGroup.get('width').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="optional && showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                <mat-label>Tiefgang&nbsp;(m)</mat-label>
                <app-input-number
                        data-test="FI-Breite (m)"
                        formControlName="depth"
                        [step]="0.1"></app-input-number>
                <mat-error>{{boatFormGroup.get('depth').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>Bemerkungen / Notizen (optional)</mat-label>
                <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                          autocomplete="new-password"></textarea>
                <mat-error>{{boatFormGroup.get('note').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(displayBoatOwnerName?.length > 0 || boatOwnerName?.length > 0) && !slimmedView">
        <div fxFlex="100">
            <mat-checkbox formControlName="isOwner">
                {{('nameIsOwnerOfThisBoat' | translate : {boatOwnerName: (boatOwnerName || displayBoatOwnerName)})}}
            </mat-checkbox>
        </div>
    </ng-container>

    <ng-container *ngIf="canSave && this.boatFormGroup.dirty && !this.boatFormGroup.get('boat')?.value?.id">

        <div style="width: 100%">
            <ng-template #savingBoat>
                <mat-icon>save</mat-icon>
                <span>Speichert...</span>
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-template>

            <ng-template #notSavingBoat>
                <mat-icon>save</mat-icon>
                <span>{{'createBoat' | translate}}</span>
            </ng-template>

            <button class="float-right" data-test="B-Speichern" mat-flat-button color="accent"
                    (click)="saveBoat()" [disabled]="isSaving">
                <ng-container *ngIf="isSaving; then savingBoat else notSavingBoat"></ng-container>
            </button>
        </div>

    </ng-container>
</div>
