import { IProduct, Product } from '@shared/models/product';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { InvoicePosition } from '@shared/models/invoice-position';

export interface BcmVoucherPosition {
    voucher: BcmVoucher;
    quantity: number;
    totalPrice: number;
}

export interface BcmVoucherDto {
    id?: number;

    title: string;

    quantityTotal: number;
    quantityLeft: number;
    totalPrice: string;

    validFrom?: Date;
    validUntil?: Date;

    product: IProduct;
    person?: IPerson;
    company?: ICompany;
    boat?: IBoat;

    positions?: InvoicePosition[];

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmVoucher {
    id: number;

    title: string;

    quantityTotal: number;
    quantityLeft: number;
    totalPrice: number;

    validFrom?: Date;
    validUntil?: Date;

    product: Product;
    person?: Person;
    company?: Company;
    boat?: Boat;

    positions: InvoicePosition[];

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    active: boolean;
    future: boolean;

    constructor(dto: BcmVoucherDto) {
        this.id = dto.id;
        this.title = dto.title;
        this.quantityTotal = parseFloat(String(dto.quantityTotal));
        this.quantityLeft = parseFloat(String(dto.quantityLeft));
        this.totalPrice = parseFloat(dto.totalPrice) || 0;
        this.validFrom = tryParseDate(dto.validFrom);
        this.validUntil = tryParseDate(dto.validUntil);
        this.product = dto.product ? new Product(dto.product) : null;
        this.person = dto.person ? new Person(dto.person) : null;
        this.company = dto.company ? new Company(dto.company) : null;
        this.boat = dto.boat ? new Boat(dto.boat) : null;

        this.positions = (dto.positions || [])
            .map(p => new InvoicePosition(p))
            .sort((a, b) => b.insertedOn.getTime() - a.insertedOn.getTime());

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;

        this.active = this.quantityLeft > 0 && this.validFrom <= new Date() && (
            this.validUntil == null || this.validUntil >= new Date()
        );
        this.future = this.quantityLeft > 0 && this.validFrom > new Date();
    }

}
