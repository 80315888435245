import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, timer } from 'rxjs';
import { TIMEOUT_FORM_SAVING_MIN } from '@shared/constants/timeouts';
import { map } from 'rxjs/operators';
import { PageRequest } from '@shared/models/pagination';

type LAST_INSERT_ID = number;
type EFFECTED_ROWS = number;

class NoApiEndpointError extends Error {
    name = 'NoApiEndpointError';

    constructor(className) {
        super(`No API endpoint set in "${className}"`);
    }
}

export abstract class BaseApiService {

    protected abstract className: string;

    protected abstract endpoint: string;

    protected _suppressErrorForNextRequest = false;

    protected constructor(private http: HttpClient) {
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams(), responseType = 'json'): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.get<T>(`${this.endpoint}${path}`, {params, headers: this._getHeaders()});
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.get(`${this.endpoint}${path}`, {params, responseType: 'blob', headers: this._getHeaders()});
    }

    protected getBlobWithProgress(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.get(`${this.endpoint}${path}`, {
            params,
            reportProgress: true,
            observe: 'events',
            responseType: 'blob',
            headers: this._getHeaders()
        });
    }

    protected post<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.post<T>(`${this.endpoint}${path}`, body, {params, headers: this._getHeaders()}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected postWithBlobResponse<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.post<T>(`${this.endpoint}${path}`, body, {params, responseType: 'blob' as 'json', headers: this._getHeaders()}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected put<T = EFFECTED_ROWS>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.put<T>(`${this.endpoint}${path}`, body, {params, headers: this._getHeaders()}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected delete<T = EFFECTED_ROWS>(path, params: HttpParams = new HttpParams(), body?: unknown): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.delete<T>(`${this.endpoint}${path}`, {params, body, headers: this._getHeaders()});
    }

    private _getHeaders(): HttpHeaders | undefined {
        let headers: HttpHeaders;

        if (this._suppressErrorForNextRequest) {
            headers = new HttpHeaders().append('suppress-errors', 'true');
            this._suppressErrorForNextRequest = false;
        }

        return headers;
    }
}
