<div [formGroup]="berthFeeFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline">
        <h2 fxFlex=100>{{headline}}</h2>
    </ng-container>

    <ng-container *ngIf="berthFeeFormGroup.errors">
        <div fxFlex=100>
            <u2b-message type="error">
                {{berthFeeFormGroup.errors | firstErrorMessage}}
            </u2b-message>
        </div>
    </ng-container>

    <div fxFlex="100">
        <mat-checkbox data-test="FCB-Liegeplatz gebühr hinzufügen"
                      (ngModelChange)="addBerthFeeChange($event)"
                      [(ngModel)]="addBerthFee"
                      [ngModelOptions]="{standalone: true}">
            Liegeplatzgebühr hinzufügen?
        </mat-checkbox>
    </div>

    <ng-container *ngIf="addBerthFee">

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
            <u2b-message>
                Es werden Produkte berücksichtigt, die der Warengruppe <strong>Liegeplatzgebühr</strong> zugeordnet
                sind. Ist Dein gewünschtes Produkt nicht in der folgenden Liste aufgeführt, gehe in die
                <a [bcmRouterLink]="'products'">Produkte</a>, wähle das Produkt für die Bearbeitung aus und ordne die
                Warengruppe <strong>Liegeplatzgebühr</strong> zu.
            </u2b-message>
        </div>

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">

            <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                <mat-label>Produkt wählen</mat-label>
                <input type="text" matInput data-test="FI-Produkte durchsuchen..."
                       autocomplete="new-password"
                       formControlName="product"
                       [matAutocomplete]="productSelector">
                <mat-hint *ngIf="berthFeeFormGroup.get('product').value">
                    <bcm-product-price-label textAlign="left"></bcm-product-price-label>
                    : {{ berthFeeFormGroup.get('product').value?.price | bcmDynamicCurrency: '1.2-2' }}
                    / {{berthFeeFormGroup.get('product').value?.unit?.name}}
                </mat-hint>
                <mat-error>{{berthFeeFormGroup.get('product').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-autocomplete #productSelector="matAutocomplete" [displayWith]="displayProductWith">
                <mat-option disabled class="loading" *ngIf="loadingProducts">
                    <mat-spinner diameter="35"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="products?.length">
                    <mat-option *ngFor="let product of (filteredProducts$ | async) | orderBy: 'name'" [value]="product">
                        <span>{{ product.name }}</span>
                        <small> | {{product.price | bcmDynamicCurrency: '1.2-2'}}</small>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </div>

        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
            <mat-label>Anzahl / Einheiten</mat-label>
            <app-input-number
                    matInput
                    data-test="FI-Anzahl / Einheiten"
                    formControlName="quantity"
                    [step]="1"
                    [min]="0"></app-input-number>
            <mat-hint *ngIf="berthFeeFormGroup.get('product').value">
                Gesamtpreis: {{ totalPrice | bcmDynamicCurrency: '1.2-2' }}
            </mat-hint>
            <mat-error>{{berthFeeFormGroup.get('quantity').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <mat-divider class="special-divider"></mat-divider>
    </ng-container>
</div>
