import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseFacade } from '../bcm-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { Company, ICompany } from '@shared/models/company';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { companiesTableData } from '@modules/bcm/@core/state-management/companies/companies-table-data';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';

@Injectable({providedIn: 'root'})
export class CompaniesFacade extends BcmBaseFacade<Company, ICompany> {

    readonly resourcePath = 'companies';

    readonly resourceNameSingular = 'Organisation';

    readonly resourceNamePlural = 'Organisationen';

    readonly resourceIconPlural = 'location_city';

    readonly resourceIconSingular = 'location_city';

    readonly deletePermission = BcmUserPermission.COMPANIES_DELETE;

    readonly readPermission = BcmUserPermission.COMPANIES_READ;

    readonly writePermission = BcmUserPermission.COMPANIES_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportCompanies(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'Excel Export - Beziehungen',
            onClick: () => this._bcmExportExcelApiService.exportCompanyTenantRelations(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (company: Company) => this._bcmNavigationService.navigate('companies/' + company.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (company: Company) => super.remove(company, company.name).subscribe(),
        }
    ];

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _bcmNavigationService: BcmNavigationService
    ) {
        super(
            Company,
            companiesTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );
    }
}
