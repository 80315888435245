import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { EMPTY, of, switchMap } from 'rxjs';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';

@Component({
    selector: 'app-check-pay-by-cash',
    templateUrl: './check-pay-by-cash.component.html',
    styleUrls: ['./check-pay-by-cash.component.scss']
})
export class CheckPayByCashComponent implements OnInit {

    @Input() checkCancel: boolean;
    @Input() parentFormGroup: UntypedFormGroup;

    @Output() cashRegisterEmitter: EventEmitter<BcmCashRegister> = new EventEmitter<BcmCashRegister>();

    checkAddonDone = false;
    addonStatus = false;

    checkCashRegisterDone = false;
    cashRegister: BcmCashRegister;

    constructor(private _bcmTenantService: BcmTenantService,
                private _cashRegisterService: CashRegisterService,
                private _changeRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        new Promise(() => this.checkTseConditions()).then();
    }

    checkTseConditions(): void {
        of(this._bcmTenantService.checkPermission(BcmTenantPermission.CASH))
            .pipe(
                switchMap((hasPermission) => {
                    this.checkAddonDone = true;
                    this.addonStatus = hasPermission;
                    if (hasPermission) {
                        this.parentFormGroup.get('checkPayByCash').setValue(true);
                        return this._cashRegisterService.getLoggedInCashRegisterForUser();
                    } else {
                        this.parentFormGroup.get('checkPayByCash').setErrors(['Barzahlung ist nur in Verbindung mit dem DockSite Add-on möglich.']);
                        this.parentFormGroup.get('checkPayByCash').setValue(false);
                        this.cashRegisterEmitter.next(null);
                        return EMPTY;
                    }
                })).subscribe(cashRegister => {
            this.checkCashRegisterDone = true;
            this.cashRegister = cashRegister;
            if (!this.cashRegister?.id) {
                this.parentFormGroup.get('checkPayByCash').setErrors([`Du bist aktuell an keiner Kasse angemeldet`]);
                this.parentFormGroup.get('checkPayByCash').setValue(false);
                this.cashRegisterEmitter.next(null);
            } else {
                this.parentFormGroup.get('checkPayByCash').setValue(true);
                this.cashRegisterEmitter.next(this.cashRegister);
            }

            if (this.checkCancel && !cashRegister.currentUser.canCancel) {
                this.parentFormGroup.get('checkPayByCash').setErrors([`Du besitzt keine Berechtigung zum Stornieren an der aktuellen Kasse.`]);
                this.parentFormGroup.get('checkPayByCash').setValue(false);
                this.cashRegisterEmitter.next(null);
            } else if (this.checkCancel && cashRegister.currentUser.canCancel) {
                this.parentFormGroup.get('checkPayByCash').setValue(true);
                this.cashRegisterEmitter.next(this.cashRegister);
            }
            this._changeRef.markForCheck();
        });
    }

}
