<div class="input-number-wrapper">
    <input type="text"
           #input
           matInput
           class="mat-input-element {{align === 'r' ? 'align-right' : ''}}"
           autocomplete="new-password"
           [errorStateMatcher]="errorStateMatcher"
           [placeholder]="placeholder"
           [disabled]="disabled"
           [value]="parsedValue"
           (input)="customOnInput($event)"
           (change)="customOnChange($event)"
           (blur)="input.value = parsedValue">

    <ng-container *ngIf="!disabled && showArrowButtons">
        <div class="inc" (click)="incrementValue($event)" (dblclick)="disableEvent($event)">
            <div class="input-number-inc"></div>
        </div>
        <div class="dec" (click)="decrementValue($event)" (dblclick)="disableEvent($event)">
            <div class="input-number-dec"></div>
        </div>
    </ng-container>
</div>
