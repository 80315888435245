import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TseApiService } from '@bcmApiServices/tse/tse-api.service';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';
import { TsePayment } from '@shared/models/tse/TsePayment';
import { TseDocument } from '@shared/models/tse/TseDocument';

@Injectable({
    providedIn: 'root'
})
export class TseClosingApiService extends TseApiService {

    private RESOURCE_ENDPOINT = 'closing';

    /**
     * Books the opening stock for a cash register closure. Only books a payment, does not reset the cash balance.
     * Alternatively, the cash register can also book a receipt: documentType [11] = PayIn.
     *
     * @param {string} clientId - The identifier of the client for which the opening stock is to be booked.
     * @param {string} userId - The identifier of the user who is booking the opening stock.
     * @param {string} userCaption - The caption of the user who is booking the opening stock.
     * @param {TsePayment[]} payments - The payment(s) to be booked for the opening stock.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    bookOpeningStock(clientId: string, userId: string, userCaption: string, payments: TsePayment[] = []): Observable<TseFiscalResponse> {
        const params = new HttpParams()
            .set('userId', userId)
            .set('userCaption', userCaption)
            .set('clientId', clientId);

        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/${clientId}/book/openingStock`, payments, params);
    }

    /**
     * Sends a cash difference booking to the middleware.
     * Alternatively, the cash register can also book a receipt with BusinessTransactionType [91] = CashDifference.
     *
     * @param {string} clientId - The identifier of the client for which the cash difference is to be booked.
     * @param {string} userId - The identifier of the user who is booking the cash difference.
     * @param {string} userCaption - The caption of the user who is booking the cash difference.
     * @param {string} reason - The reason for the cash difference.
     * @param {TsePayment[]} payments - The payment(s) to be booked for the cash difference.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    bookCashDifference(clientId: string, userId: string, userCaption: string, reason: string, payments: TsePayment[] = []): Observable<TseFiscalResponse> {
        const params = new HttpParams()
            .set('userId', userId)
            .set('userCaption', userCaption)
            .set('clientId', clientId);

        return super.put<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/${clientId}/book/cashDifference`, payments, params);
    }

    /**
     * Books a money transfer from the cash system to the bank (or other destination).
     *
     * @param {string} clientId - The identifier of the client for which the money transfer is to be booked.
     * @param {string} userId - The identifier of the user who is booking the money transfer.
     * @param {string} userCaption - The caption of the user who is booking the money transfer.
     * @param {boolean} isStockAmount - A flag indicating whether the payment amount is the stock amount (true) or not (false).
     * @param {TsePayment[]} payments - The payment(s) to be booked for the money transfer.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    bookCashLift(clientId: string, userId: string, userCaption: string, isStockAmount: boolean, payments: TsePayment[] = []): Observable<TseFiscalResponse> {
        const params = new HttpParams()
            .set('userId', userId)
            .set('userCaption', userCaption)
            .set('clientId', clientId);

        return super.put<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/${clientId}/book/cashLift`, payments, params);
    }

    /**
     * Returns the current payment stock. This is calculated based on the documents transmitted (in addition to sales and return receipts, also deposits and withdrawals, etc.).
     *
     * @param {string} clientId - The identifier of the client for which the payment stock is to be returned.
     * @returns {Observable<TsePayment[]>} An observable containing the payment(s) representing the payment stock.
     */
    getActualStock(clientId: string): Observable<TsePayment[]> {
        const params = new HttpParams()
            .set('clientId', clientId);

        return super.get<TsePayment[]>(`${this.RESOURCE_ENDPOINT}/${clientId}/actualStock`, params);
    }

    /**
     * Returns the end-of-day document for the current day.
     *
     * The returned document must be stored (storeDocument > documentType: 99).
     *
     * @param {string} clientId - The identifier of the client for which the end-of-day document is to be returned.
     * @param {boolean} closeMonth - A flag indicating whether to close the month after the end-of-day document has been returned.
     * @returns {Observable<TseDocument>} An observable containing the end-of-day document.
     */
    getEndOfDayDocument(clientId: string, closeMonth: boolean = false): Observable<TseDocument> {
        const params = new HttpParams()
            .set('closeMonth', closeMonth)
            .set('clientId', clientId);

        return super.get<TseDocument>(`${this.RESOURCE_ENDPOINT}/${clientId}/endofdayDocument`, params);
    }

    /**
     * Returns the date and time of the last closing for a specific client/terminal.
     *
     * @param {string} clientId - The identifier of the client/terminal for which the date and time of the last closing is to be returned.
     * @returns {Observable<Date>} An observable containing the date and time of the last closing.
     */
    getLastClosing(clientId: string): Observable<Date> {
        const params = new HttpParams()
            .set('clientId', clientId);

        return super.get<Date>(`${this.RESOURCE_ENDPOINT}/${clientId}/lastClosing`, params);
    }

    /**
     * Checks the actual payment stock for this cash system and returns a list of payments with differences (or an empty list if there is no difference).
     *
     * @param {string} clientId - The identifier of the client for which the payment stock is to be checked.
     * @param {TsePayment[]} [payments=[]] - The payment(s) to be checked for the payment stock (optional).
     * @returns {Observable<TsePayment[]>} An observable containing the payment(s) with differences (if any).
     */
    checkCashpoint(clientId: string, payments: TsePayment[] = []): Observable<TsePayment[]> {
        const params = new HttpParams()
            .set('clientId', clientId);

        return super.post<TsePayment[]>(`${this.RESOURCE_ENDPOINT}/${clientId}/cashpointCheck`, payments, params);
    }

    /**
     * Closes the current day with a day-end report.
     *
     * @param {string} clientId - The identifier of the client for which the cash system is to be closed.
     * @param {string} userId - The identifier of the user who is closing the cash system.
     * @param {string} userCaption - The caption of the user who is closing the cash system.
     * @param {boolean} [raiseCashDifferenceException=false] - A flag indicating whether to raise a cash difference exception (optional, default: false).
     * @param {boolean} [closeMonth=false] - A flag indicating whether to close the month after the cash system has been closed (optional, default: false).
     * @param {TsePayment[]} [payments=[]] - The payments to be used for closing the cash system (optional).
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    closeCashpoint(clientId: string, userId: string, userCaption: string, closeMonth: boolean = false,
                   raiseCashDifferenceException: boolean = false, payments: TsePayment[] = []): Observable<TseFiscalResponse> {
        const params = new HttpParams()
            .set('userId', userId)
            .set('userCaption', userCaption)
            .set('raiseCashDifferenceException', raiseCashDifferenceException)
            .set('closeMonth', closeMonth)
            .set('clientId', clientId);

        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/${clientId}/cashpointClose`, payments, params);
    }

}
