import { MatSort } from '@angular/material/sort';
import { isValidDate } from 'rxjs/internal/util/isDate';

const collator = new Intl.Collator(
    navigator.languages[0] || navigator.language,
    {
        numeric: true,
        ignorePunctuation: true,
        sensitivity: 'base'
    }
);

export function naturalCompare(a: string, b: string): number {
    // source: http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
    return String(a).localeCompare(String(b), navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true, sensitivity: 'base'});
}

export function naturalSort(items: string[]): string[] {
    // source: http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
    return items.sort((a, b) => naturalCompare(a, b));
}

export function naturalSortArrayOfObjects(items: any[], attribute: string): any[] {
    return items.sort((a, b) => {
        if (isValidDate(a[attribute])) {
            return collator.compare((+a[attribute]).toString(), (+b[attribute]).toString());
        }
        return collator.compare((a[attribute] || '').toString(), (b[attribute] || '').toString());
    });
}

export function naturalSortMatTable(data, sort: MatSort): any[] {
    switch (sort.direction) {
        case 'desc':
            return naturalSortArrayOfObjects(data, sort.active).reverse();
        case 'asc':
            return naturalSortArrayOfObjects(data, sort.active);
        case '':
            return data;
    }
}
