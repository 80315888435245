<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <form (ngSubmit)="onClickSaveAndClose()" [formGroup]="formGroup" appInvalidControlScroll
          [scrollContainer]="scrollContainer">

        <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title dialog-title">
                    {{ this.formGroup.get('orderTitle').value ? 'Auftrag: ' + this.formGroup.get('orderTitle').value :
                        "Auftrag erfassen" }}
                </div>
                <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                        aria-label="Dialog schließen" type="button">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content cdkScrollable #scrollContainer>

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Titel</mat-label>
                <input formControlName="orderTitle" type="text" matInput placeholder="Titel">
                <mat-error>{{formGroup.get('orderTitle').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-accordion class="example-headers-align">

                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Auftraggeber
                        </mat-panel-title>
                        <mat-panel-description [ngClass]="selectedPerson?.firstName ? 'bold' : ''">
                            <span fxHide.lt-sm>{{ this.personString || 'Für wen wird gearbeitet?' }}</span>
                            <mat-icon [color]="this.personString ? 'accent' : 'primary'">account_circle</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-container
                            *ngIf="formGroupPrepare.get('type').touched && formGroupPrepare.get('type').errors">
                        <u2b-message type="warning">
                            {{formGroupPrepare.get('type').errors | firstErrorMessage}}
                        </u2b-message>
                    </ng-container>

                    <div [formGroup]="formGroupPrepare">
                        <mat-radio-group class="radio-group-inline" formControlName="type">
                            <mat-radio-button [value]="'person'">Person</mat-radio-button>
                            <mat-radio-button [value]="'organisation'">Organisation</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <ng-container *ngIf="formGroupPrepare.get('type').value === 'person'">
                        <mat-divider class="special-divider"></mat-divider>
                        <form-widget-person [person$]="person$" [parentFormGroup]="formGroup"
                                            [optional]="true"></form-widget-person>
                    </ng-container>

                    <ng-container *ngIf="formGroupPrepare.get('type').value === 'organisation'">
                        <mat-divider class="special-divider"></mat-divider>
                        <form-widget-company [company$]="company$" [parentFormGroup]="formGroup"
                                             [optional]="true"></form-widget-company>
                    </ng-container>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Zeitraum
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm>
                                {{ this.selectedStartTime || 'Wann startet und endet der Auftrag?' }}
                                <br>
                                {{ this.selectedEndTime || '' }}
                           </span>
                            <mat-icon
                                    [color]="this.selectedStartTime || this.selectedEndTime ? 'accent' : 'primary'">
                                add_alarm
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form-widget-date-range [from]="this.data?.order?.startTime" [to]="this.data?.order?.endTime"
                                            [headline]="null"
                                            [parentFormGroup]="formGroup"></form-widget-date-range>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'boat' | translate}}
                        </mat-panel-title>
                        <mat-panel-description [ngClass]="selectedBoat?.name ? 'bold' : ''">
                            <span fxHide.lt-sm>{{ this.boatString || ('Welches ' + ('boat' | translate) + ' wird bearbeitet?') }}</span>
                            <mat-icon [color]="this.boatString ? 'accent' : 'primary'">directions_boat</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="24px">
                        <ng-container>
                            <form-widget-boat [parentFormGroup]="formGroup"
                                              [givenBoats]="this.givenBoats"></form-widget-boat>
                        </ng-container>
                        <ng-container *ngIf="!this.selectedBoat?.owner && !this.selectedBoat?.ownerCompany">
                            <mat-checkbox data-test="FCB-Boot dem Auftraggeber zuweisen"
                                          (change)="assignBoat()"
                                          fxFlex="100">
                                {{'assignBoatToContractOwner' | translate}}
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Status
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm>{{ this.formGroup.get('status').value || ' Wähle den Auftragsstatus aus' }}</span>
                            <mat-icon [color]="this.formGroup.get('status').value ? 'accent' : 'primary'">timelapse
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayoutAlign="start start" class="mb-24">
                        <mat-form-field fxFlex>
                            <mat-label>Status</mat-label>
                            <mat-select data-test="FS-Status wählen" placeholder="Kein Status ausgewählt"
                                        formControlName="status">
                                <mat-option *ngFor="let order of orderTypes" [value]="order">
                                    {{ order }}
                                </mat-option>
                            </mat-select>
                            <mat-error>{{formGroup.get('status').errors | firstErrorMessage}}</mat-error>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Arbeitspakete
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm> {{ this.selectedWorkPackages?.length ? (this.selectedWorkPackages?.length) +
                                    'Arbeitspaket(e)' : 'Ein Arbeitspaket hinzufügen'}}</span>
                            <mat-icon
                                    [color]="this.formGroup.get('orderWorkPackagesForm').dirty ? 'accent' : 'primary'">
                                archive
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="mb-12" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">

                        <mat-form-field fxFlex>
                            <mat-label>Arbeitspakete</mat-label>
                            <mat-select data-test="FS-Arbeitspaket wählen" formControlName="orderWorkPackagesForm">
                                <mat-option *ngFor="let orderWorkPackage of this.orderWorkPackages"
                                            [value]="orderWorkPackage">
                                    {{orderWorkPackage.shortDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error>{{formGroup.get('orderWorkPackagesForm').errors | firstErrorMessage}}</mat-error>
                        </mat-form-field>
                        <button data-test="B-Abbrechen" mat-button (click)="onClickAddWorkPackage()" type="button"
                                class="mat-accent" matTooltip="Arbeitspaket hinzufügen"
                                [matTooltipPosition]="'above'">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>

                    <div>
                        <ng-container *ngFor="let selectedPackage of this.selectedWorkPackages">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-icon class="mr-16">archive</mat-icon>
                                    {{selectedPackage.shortDescription}}
                                </div>
                                <button type="button" mat-button color="warn"
                                        (click)="removeOrderWorkPackage(selectedPackage)"
                                        matTooltip="Arbeitspaket entfernen" [matTooltipPosition]="'above'">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>

                </mat-expansion-panel>


                <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ToDos
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm>
                                {{ this.formGroup.get('todos')['controls']?.length ?
                                    (this.formGroup.get('todos')['controls']?.length) + ' ToDo(s)' : 'Ein ToDo hinzufügen'
                                }}
                            </span>
                            <mat-icon
                                    [color]="this.formGroup.get('todos')['controls']?.length  ? 'accent' : 'primary'">
                                assignment
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div>
                        <div>
                            <div formArrayName="todos"
                                 *ngIf="this.formGroup.get('todos')['controls']?.length; else noTodos">
                                <div cdkDropList (cdkDropListDropped)="drop($event, true)">
                                    <div *ngFor="let todo of this.formGroup.get('todos')['controls']; let l = index"
                                         [attr.data-test]="'C-ToDo ' + (l + 1)" [formGroupName]="l" fxLayout="row"
                                         fxLayoutAlign="space-between center" fxLayoutGap="24px" cdkDrag>
                                        <button type="button" class="drag-handle" mat-icon-button cdkDragHandle>
                                            <mat-icon>unfold_more</mat-icon>
                                        </button>
                                        <mat-form-field appearance="outline" fxFlex="90">
                                            <mat-label>ToDo</mat-label>
                                            <input matInput data-test="FI-ToDo" type="text" value="{{todo.todo}}"
                                                   formControlName="todo">
                                            <mat-error>{{todo.get('todo').errors | firstErrorMessage}}
                                            </mat-error>
                                        </mat-form-field>


                                        <div class="mb-8" fxFlex="10">
                                            <ng-container *hasPermission="BcmUserPermission.ORDERS_WRITE">
                                                <button hidden (click)="addTodoToOrderWorkPackage($event)"></button>

                                                <button data-test="B-ToDo entfernen" mat-button
                                                        matTooltip="ToDo entfernen" [matTooltipPosition]="'above'"
                                                        class="mat-error"
                                                        (click)="removeTodoFromOrderWorkPackage($event, l)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div fxLayoutAlign="end end">
                                <button data-test="B-Abbrechen" mat-button
                                        (click)="addTodoToOrderWorkPackage($event)"
                                        type="button" class="mat-accent pr-0" matTooltip="ToDo hinzufügen"
                                        [matTooltipPosition]="'above'">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <ng-template #noTodos>
                        <p>Keine Todos vorhanden!</p>
                    </ng-template>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Leistungen
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm>
                                {{ this.formGroup.get('invoicePositions')['controls']?.length ?
                                    (this.formGroup.get('invoicePositions')['controls']?.length) + ' Produkt(e)' : 'Eine Leistung hinzufügen' }}
                            </span>
                            <mat-icon
                                    [color]="this.formGroup.get('invoicePositions')['controls']?.length  ? 'accent' : 'primary'">
                                add_shopping_cart
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                        <div fxFlex="100">
                            <div formArrayName="invoicePositions"
                                 *ngIf="this.formGroup.get('invoicePositions')['controls']?.length; else noInvoicePositions">
                                <div cdkDropList (cdkDropListDropped)="drop($event, false)">
                                    <div *ngFor="let invoicePosition of this.formGroup.get('invoicePositions')['controls']; let k = index"
                                         [attr.data-test]="'C-InvoicePosition ' + (k + 1)" [formGroupName]="k">
                                        <div fxFlex="60" fxLayout="row" fxLayoutAlign="space-between center"
                                             cdkDrag>
                                            <button type="button" class="drag-handle" mat-icon-button
                                                    cdkDragHandle>
                                                <mat-icon>unfold_more</mat-icon>
                                            </button>
                                            <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                                                <mat-label>Produkte durchsuchen...</mat-label>
                                                <input type="text" matInput data-test="FI-Produkte durchsuchen..."
                                                       value="{{ invoicePosition.get('product').value?.name }}"
                                                       formControlName="product"
                                                       [matAutocomplete]="productSelector">
                                                <mat-error>{{invoicePosition.get('product').errors | firstErrorMessage}}
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-autocomplete #productSelector="matAutocomplete"
                                                              [displayWith]="displayProductWith">
                                                <mat-option *ngFor="let product of (filteredProducts$ | async)"
                                                            [value]="product">
                                                    <span>{{ product.name }}</span>
                                                    <small> | {{ product.price | bcmDynamicCurrency :
                                                            '1.2-4'}}</small>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>

                                        <mat-form-field appearance="outline" fxFlex="30">
                                            <mat-label>Anzahl</mat-label>
                                            <app-input-number matInput
                                                              [formControl]="invoicePosition.get('quantity')"
                                                              data-test="FI-Anzahl / Einheiten"
                                                              [step]="1"></app-input-number>
                                            <mat-error>{{invoicePosition.get('quantity').errors | firstErrorMessage}}
                                            </mat-error>
                                        </mat-form-field>


                                            <div fxFlex="10" fxLayoutAlign="center center">
                                                <ng-container *hasPermission="BcmUserPermission.ORDERS_WRITE">
                                                    <button hidden
                                                            (click)="addInvoicePositionToOrderWorkPackage($event)"></button>
                                                    <button data-test="B-ToDo entfernen" type="button" mat-button
                                                            matTooltip="Leistung entfernen"
                                                            [matTooltipPosition]="'above'"
                                                            class="mat-error"
                                                            (click)="removeInvoicePositionFromOrderWorkPackage($event, k)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                            </div>
                            <div fxLayoutAlign="end end">
                                <button data-test="B-Abbrechen" mat-button
                                        (click)="addInvoicePositionToOrderWorkPackage($event)" type="button"
                                        class="mat-accent pr-0" matTooltip="Leistung hinzufügen"
                                        [matTooltipPosition]="'above'">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <ng-template #noInvoicePositions>
                        <p>Keine Leistungen vorhanden!</p>
                    </ng-template>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Bearbeiter
                        </mat-panel-title>
                        <mat-panel-description>
                            <span fxHide.lt-sm>
                                {{ this.formGroup.get('user').value ? this.formGroup.get('user').value.user?.firstName + ' '
                                    + this.formGroup.get('user').value.user?.lastName : 'Ein Bearbeiter wählen' }}
                            </span>
                            <mat-icon [color]="this.formGroup.get('user').value  ? 'accent' : 'primary'">
                                person
                            </mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
                        <div fxFlex="100" fxLayoutGap="24px">
                            <mat-select data-test="FS-Bearbeiter wählen" placeholder="Bitte einen Bearbeiter wählen"
                                        formControlName="user" fxFlex="85">
                                <mat-option *ngFor="let user of this.users" [value]="user">
                                    {{user.user?.firstName}} {{ user.user?.lastName}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </mat-expansion-panel>

                <div class="mt-24">
                    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                        <mat-label>Bemerkungen / Notizen (optional)</mat-label>
                        <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                                  autocomplete="new-password"></textarea>
                        <mat-error>{{formGroup.get('note').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-divider class="special-divider"></mat-divider>

                <ng-container
                        *ngIf="(formGroup.dirty && formGroup.invalid) || (formGroupPrepare.dirty && formGroupPrepare.invalid)">
                    <u2b-message type="warning" customClass="mt-24">
                        Deine Angaben sind noch nicht vollständig oder enthalten Fehler, die
                        korrigiert werden müssen.
                    </u2b-message>
                </ng-container>

                <ng-container
                        *ngIf="(formGroup.dirty && formGroup.valid) && (formGroupPrepare.dirty && formGroupPrepare.valid)">
                    <u2b-message type="success" customClass="mt-24">
                        <header>Geschafft!</header>
                        Alle Daten wurden erfasst. Klicke auf "Speichern" um die Daten im System zu speichern.
                    </u2b-message>
                </ng-container>

            </mat-accordion>
        </div>

        <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

            <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
                Abbrechen
            </button>

            <div>
                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Speichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <span>Speichern</span>
                </ng-template>

                <button type="submit" data-test="B-Speichern" mat-flat-button color="accent" [disabled]="isSaving">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>
            </div>

        </div>
    </form>
</div>
