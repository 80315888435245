import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { U2bApiService } from './u2b-api.service';
import { HttpParams } from '@angular/common/http';
import { SalutationNew } from '@shared/models/salutation';
import { AppUser } from '../user.service';
import { U2bFile } from '@core/services';
import { Boat } from '@core/services';
import { map } from 'rxjs/operators';
import { parseISO } from '@core/date.facade';

export interface User {
    id: string;
    salutation: SalutationNew;
    title: string;
    firstName: string;
    lastName: string;
    emailHash?: string;
    email: string;
    email2: string;
    phone: string;
    mobilePhone: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    birthDate: Date;
    image: U2bFile;

    boats: Boat[];
}

@Injectable({providedIn: 'root'})
export class U2bUserApiService extends U2bApiService {

    getCurrent(params: HttpParams = new HttpParams()): Observable<AppUser> {
        return super.get<AppUser>('users/current', params)
            .pipe(map((user: AppUser) => {
                const {privacyAndConditions} = user;
                if (privacyAndConditions) {
                    if (privacyAndConditions.readPrivacyOn) {
                        privacyAndConditions.readPrivacyOn = parseISO(privacyAndConditions.readPrivacyOn.toString());
                    }
                    if (privacyAndConditions.readConditionsOn) {
                        privacyAndConditions.readConditionsOn = parseISO(privacyAndConditions.readConditionsOn.toString());
                    }
                }

                return user;
            }));
    }

    getOne(user: AppUser, params: HttpParams = new HttpParams()): Observable<User> {
        return super.get<User>(`users/${user.id}`, params);
    }

    getBoats(user: AppUser, params: HttpParams = new HttpParams()): Observable<Boat[]> {
        return super.get<Boat[]>(`users/${user.id}/boats`, params);
    }

    update(user: AppUser, body: object = {}): Observable<number> {
        return super.put(`users/${user.id}`, body);
    }

    updatePrivacyAndConditions(user: AppUser): Observable<any> {
        if (!user.privacyAndConditions?.id) {
            return super.post(`users/${user.id}/privacy-and-conditions/`);
        }

        return super.put(`users/${user.id}/privacy-and-conditions/${user.privacyAndConditions.id}`);
    }
}
