import {
    Directive,
    DoCheck,
    ElementRef, EventEmitter,
    forwardRef,
    HostBinding, Injector,
    Input,
    OnDestroy,
    OnInit, Output,
    Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: BaseInputComponent
        }
    ],
})
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseInputComponent<T> implements DoCheck, OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<T> {

    static nextId = 0;

    protected _unsubscribeAll = new Subject();

    private _value: T = undefined;

    @Input()
    get value(): T {
        return this._value;
    }

    set value(value: T) {
        if (value !== this._value) {
            this._value = value;
        }
        this.onChange(value);
        this.stateChanges.next(undefined);
        this.valueChange.emit(this.value);
    }

    private _required = false;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next(undefined);
    }

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(dis) {
        this._disabled = coerceBooleanProperty(dis);
        this.stateChanges.next(undefined);
    }

    get empty(): boolean {
        return this._value == null;
    }

    stateChanges = new Subject<void>();

    ngControl: NgControl;

    controlType = 'text';

    errorState = false;

    focused: boolean;

    @HostBinding() id = `chip-list-input-${BaseInputComponent.nextId++}`;

    @HostBinding('attr.aria-describedby') describedBy = '';

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input() placeholder = '';

    @Output() valueChange = new EventEmitter<T>();

    public constructor(protected _renderer: Renderer2,
                       public el: ElementRef,
                       public injector: Injector,
                       public focusMonitor: FocusMonitor) {
        focusMonitor.monitor(el.nativeElement, true)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((origin) => {
                this.focused = !!origin;
                this.stateChanges.next(undefined);
            });
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        // ...
        this.onTouch();
    }

    ngOnInit(): void {
        this.ngControl = this.injector.get(NgControl);
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next(undefined);
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.el.nativeElement);
    }

    onChange = (_: T) => null;
    onTouch = () => null;

    public registerOnChange(fn: (v: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    public writeValue(inputValue: T): void {
        this.value = inputValue;
    }

    public setDisabledState(isDisabled: boolean): void {
        const {nativeElement} = this.el;
        this._disabled = isDisabled;
        this._renderer.setProperty(nativeElement, 'disabled', isDisabled);
        this._renderer.setAttribute(nativeElement, 'contentEditable', isDisabled ? 'false' : 'true');
    }
}
