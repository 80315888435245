import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { BcmApiService } from '@modules/bcm/@shared/services';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { Company, ICompany } from '@shared/models/company';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { GetProductSubscriptionEndDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-end-dialog/get-product-subscription-end-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmService } from '../../../bcm.service';
import { switchMap } from 'rxjs/operators';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';

@Injectable({
    providedIn: 'root'
})
export class CompanyApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'companies';

    constructor(private _matDialog: MatDialog,
                _http: HttpClient,
                _bcmService: BcmService) {
        super(_http, _bcmService);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<Company[]> {
        return super.get<ICompany[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<ICompany, Company>(Company));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Company> {
        return super.get<ICompany>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<ICompany, Company>(Company));
    }

    add(body: object = {}): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    remove(id: number): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }

    newTenantRelationAssignment(company: Company, tenantRelationAssignment: TenantRelationAssignment): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/${company.id}/tenant-relation`, tenantRelationAssignment);
    }

    editTenantRelationAssignment(company: Company, tenantRelationAssignment: TenantRelationAssignment): Observable<any> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${company.id}/tenant-relation/${tenantRelationAssignment.id}`, tenantRelationAssignment);
    }

    endTenantRelationAssignment(company: Company, tenantRelationAssignment: TenantRelationAssignment): Observable<any> {
        return this
            ._getEndTimestamp(tenantRelationAssignment)
            .pipe(switchMap((date) => {
                if (date) {
                    return super.put(`${this.RESOURCE_ENDPOINT}/${company.id}/tenant-relation/${tenantRelationAssignment.id}/end`, {date});
                }
                return EMPTY;
            }));
    }

    deleteTenantRelationAssignment(company: Company, tenantRelationAssignment: TenantRelationAssignment): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${company.id}/tenant-relation/${tenantRelationAssignment.id}`);
    }

    protected getDocument(companyId: number, documentId: number): Observable<BcmDocument> {
        return super.get<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${companyId}/documents/${documentId}`)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected addDocument(companyId: number, document: BcmDocument): Observable<BcmDocument> {
        return super.post<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${companyId}/documents`, document)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected updateDocument(companyId: number, document: BcmDocument, documentData: Partial<BcmDocument>): Observable<BcmDocument> {
        return super.put<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${companyId}/documents/${document.id}`, documentData)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected deleteDocument(companyId: number, documentId: number): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${companyId}/documents/${documentId}`);
    }

    private _getEndTimestamp(tenantRelationAssignment: TenantRelationAssignment): Observable<Date> {
        return this._matDialog.open(GetProductSubscriptionEndDialogComponent, {
            data: {
                subscription: tenantRelationAssignment,
                modalTitle: 'Beziehung beenden'
            }
        }).afterClosed();
    }
}
