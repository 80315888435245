import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmDynamicPrice, BcmDynamicPriceContext } from '@shared/models/bcm-dynamic-price';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProductsApiService, ProductTaxRateApiService, ProductUnitApiService } from '@modules/bcm/@shared/services';
import { AppNotificationService } from '@core/services/app-notification.service';
import { U2bValidators } from '@shared/validators/validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Product } from '@shared/models/product';
import { forkJoin, merge, Observable, of } from 'rxjs';
import { IUnit, UnitUniqueName } from '@shared/models/unit';
import { ITaxRate } from '@shared/models/tax-rate';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { BcmVoucherPosition } from '@shared/models/bcm-voucher';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { isFunction } from '@shared/functions/is-function';
import { stopEvent } from '@shared/functions/stop-event';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';

@UntilDestroy()
@Component({
    selector: 'invoice-position-dialog',
    templateUrl: './invoice-position-dialog.component.html',
    styleUrls: ['./invoice-position-dialog.component.scss'],
})
export class InvoicePositionDialogComponent {

    @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger})
    matAutocompleteTrigger: MatAutocompleteTrigger;

    formGroup: UntypedFormGroup;
    formGroupValid: boolean;

    hasSeenDynPrice = false;
    hasSeenVouchers = false;

    currentTabIndex = 0;
    hasNextTab = false;

    dynamicPriceContext?: BcmDynamicPriceContext;

    products: Product[];
    filteredProducts$: Observable<Product[]>;

    units: IUnit[];
    taxRates: ITaxRate[];
    showTaxRateColumn = true;

    isSaving: boolean;
    noProductsMessage = 'Es konnten keine Produkte gefunden werden.';

    showQuantityButtons = false;

    // do not set to false initially, otherwise if condition would not work first time in:
    // - dynamicPriceChanged
    // - useDynamicPriceChanged
    // - setVoucherUsage
    // I added the if conditions to have something similar to distinctUntilChanged() pipe
    hasDynamicPrice: boolean;
    vouchersAvailable: boolean;
    voucherPositions: BcmVoucherPosition[] = [];
    voucherUsage: boolean;

    dynamicPrice: BcmDynamicPrice;
    dynamicPriceHasError: boolean;
    useDynamicPrice: boolean;

    price: number;
    totalPrice: number;
    totalDiscount: number;

    constructor(
        public dialogRef: MatDialogRef<InvoicePositionDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            productsFilterFn?: (product: Product) => boolean,
            quantityButtons?: ProduktQuantityButton[],
            position?: InvoicePosition,
            dynamicPriceContext?: BcmDynamicPriceContext,
            startDate?: Date,
            endDate?: Date,
        },
        private _formBuilder: UntypedFormBuilder,
        private _productApiService: ProductsApiService,
        private _productTaxRateApiService: ProductTaxRateApiService,
        private _productUnitApiService: ProductUnitApiService,
        private _appNotificationService: AppNotificationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.dynamicPriceContext = data?.dynamicPriceContext;
        this.hasDynamicPrice = data?.position?.product?.hasDynamicPrice;
        this.useDynamicPrice = data?.position?.dynamicPrice?.id > 0;

        this._createForm();

        if (this.data?.position?.voucher?.id >= 0) {
            this.voucherPositions.push({
                voucher: this.data?.position.voucher,
                quantity: this.data?.position.quantity,
                totalPrice: 0
            });
        }

        this._loadProducts();
        this._loadProductTaxRates();
        this._loadProductUnits();
    }

    dynamicPriceChanged(dynPrice: BcmDynamicPrice): void {
        if (this.dynamicPrice === dynPrice) {
            return;
        }

        this.dynamicPrice = dynPrice;

        if (this.dynamicPrice?.ruleIndex !== null && this.dynamicPrice?.ruleIndex >= 0) {
            this.formGroup.get('price').setValue(this.dynamicPrice.rulePrice, {emitEvent: false});
        } else {
            this.formGroup.get('price').setValue(this.formGroup.get('product').value?.price, {emitEvent: false});
        }
    }

    useDynamicPriceChanged(useDynamicPrice: boolean): void {
        if (this.useDynamicPrice === useDynamicPrice) {
            return;
        }

        this.hasSeenDynPrice = false;
        this.useDynamicPrice = useDynamicPrice;

        if (this.useDynamicPrice) {
            this.formGroup.get('price').disable({emitEvent: false});
        } else {
            this.formGroup.get('price').enable({emitEvent: false});
        }

        this.checkHasNextTab();
    }

    selectTab(index: any): void {
        this.currentTabIndex = index;
        this.checkHasNextTab();
    }

    setVoucherUsage(usage: boolean): void {
        if (this.voucherUsage === usage) {
            return;
        }

        this.hasSeenVouchers = (this.currentTabIndex === 2);
        this.voucherUsage = usage;

        if (this.voucherUsage) {
            this.formGroup.get('price').setValue(0, {emitEvent: false});
        } else {
            if (this.dynamicPrice?.ruleIndex !== null && this.dynamicPrice?.ruleIndex >= 0 || this.dynamicPrice?.ruleCalculations?.calculations?.length > 0) {
                this.formGroup.get('price').setValue(this.dynamicPrice.rulePrice, {emitEvent: false});
            } else {
                this.formGroup.get('price').setValue(this.formGroup.get('product').value?.price, {emitEvent: false});
            }
        }
        this.checkHasNextTab();
    }

    checkHasNextTab(): void {
        if (this.currentTabIndex === 0) {
            this.hasNextTab = this.vouchersAvailable || this.hasDynamicPrice;
        } else if (this.currentTabIndex === 1) {
            this.hasSeenVouchers = true;
            this.hasNextTab = this.hasDynamicPrice;
        } else {
            this.hasNextTab = false;
            this.hasSeenDynPrice = true;
        }
    }

    nextTab(): void {
        if (this.currentTabIndex === 0) {
            if (this.vouchersAvailable) {
                this.currentTabIndex = 1;
            } else if (this.hasDynamicPrice) {
                this.currentTabIndex = 2;
            }
        } else if (this.currentTabIndex === 1) {
            if (this.hasDynamicPrice) {
                this.currentTabIndex = 2;
            }
        }
        this.checkHasNextTab();
    }

    setVouchersAvailable(vouchersAvailable: boolean): void {
        this.vouchersAvailable = vouchersAvailable;
    }

    openPanel(event: MouseEvent): void {
        event.stopPropagation();
        this.matAutocompleteTrigger.openPanel();
    }

    displayProductWith(product: Product): string {
        return product ? product.name : '';
    }

    public onSelectProduct(event: MatAutocompleteSelectedEvent): void {

        const product = event.option.value as Product;

        this.formGroup
            .patchValue({
                account: product.account,
                itemNumber: product.itemNumber,
                product,
                title: product.name,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
            });
    }

    public emptyProduct(event: MouseEvent): void {
        stopEvent(event);

        this.hasDynamicPrice = false;
        this.vouchersAvailable = false;

        this.formGroup
            .reset({
                account: null,
                itemNumber: null,
                product: null,
                title: null,
                unit: null,
                quantity: 1,
                price: null,
                taxRate: null,
            });

        setTimeout(() => this.formGroup.get('title').setValue(null), 50);
    }

    compareUnits(unit1: IUnit, unit2: IUnit): boolean {
        return unit1 && unit2 && unit1.name === unit2.name && unit1.id === unit2.id;
    }

    compareTaxRates(taxRate1: ITaxRate, taxRate2: ITaxRate): boolean {
        return taxRate1 && taxRate2 && taxRate1.value === taxRate2.value && taxRate1.id === taxRate2.id;
    }

    saveAndContinue(): void {

        let invalid = false;

        for (const key in this.formGroup.controls) {
            if (key !== 'dynamicPriceFormGroup') {
                if (this.formGroup.controls[key].invalid) {
                    this.formGroup.controls[key].markAsTouched();
                    invalid = true;
                }
            }
        }

        if (invalid) {
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const {
            product,
            quantity,
            account,
            itemNumber,
            unit,
            taxRate,
            title,
            price,
            vestingPeriodFrom,
            vestingPeriodUntil,
            discountPercentage,
        } = this.formGroup.value;

        const useDynamicPrice = this.formGroup.get('dynamicPriceFormGroup')?.get('useDynamicPrice')?.value;
        const dynamicPrice = {...this.formGroup.get('dynamicPriceFormGroup')?.get('dynamicPrice')?.value} as BcmDynamicPrice | null;

        this.isSaving = true;

        if (this.voucherPositions?.length > 0) {
            this.dialogRef.close(this.voucherPositions.map((voucherPosition, index) => {
                if (voucherPosition.voucher) {
                    return new InvoicePosition({
                        id: index === 0 ? this.data?.position?.id : null,
                        product,
                        quantity: voucherPosition.quantity,
                        account,
                        itemNumber,
                        title,
                        unit,
                        price: 0,
                        taxRate,
                        vestingPeriodFrom,
                        vestingPeriodUntil,
                        dynamicPrice: null,
                        voucher: voucherPosition.voucher,
                        positionId: this.data?.position?.positionId,
                        isNew: index === 0 && !this.data?.position?.id,
                        voucherRemainingAmount: voucherPosition.voucher.quantityLeft - voucherPosition.quantity,
                        discountPercentage,
                        priceLock: true,
                        titleLock: (product?.name !== title),
                        accountLock: (product?.account !== account),
                        itemNumberLock: (product?.itemNumber !== itemNumber),
                        taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                        unitLock: (product?.unit?.id !== unit?.id)
                    });
                } else {
                    return new InvoicePosition({
                        id: index === 0 ? this.data?.position?.id : null,
                        product,
                        quantity: voucherPosition.quantity,
                        account,
                        itemNumber,
                        title: useDynamicPrice ? dynamicPrice?.ruleName : title,
                        unit,
                        price: useDynamicPrice ? dynamicPrice?.rulePrice : (price > 0 ? price : product?.price),
                        taxRate,
                        vestingPeriodFrom,
                        vestingPeriodUntil,
                        dynamicPrice: useDynamicPrice ? dynamicPrice : null,
                        positionId: this.data?.position?.positionId,
                        isNew: !this.data?.position?.id,
                        discountPercentage,
                        priceLock: (price !== product?.price || (!!dynamicPrice?.id && price !== dynamicPrice?.rulePrice)),
                        titleLock: (title !== product?.name || (!!dynamicPrice?.id && title !== dynamicPrice?.ruleName)),
                        accountLock: (product?.account !== account),
                        itemNumberLock: (product?.itemNumber !== itemNumber),
                        taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                        unitLock: (product?.unit?.id !== unit?.id)
                    });
                }
            }));
        } else {
            this.dialogRef.close([
                new InvoicePosition({
                    id: this.data?.position?.id,
                    product,
                    quantity,
                    account,
                    itemNumber,
                    title: useDynamicPrice ? dynamicPrice?.ruleName : title,
                    unit,
                    price: useDynamicPrice ? dynamicPrice?.rulePrice : price,
                    taxRate,
                    vestingPeriodFrom,
                    vestingPeriodUntil,
                    dynamicPrice: useDynamicPrice ? dynamicPrice : null,
                    positionId: this.data?.position?.positionId,
                    isNew: !this.data?.position?.id,
                    discountPercentage,
                    priceLock: (price !== product?.price || (!!dynamicPrice?.id && price !== dynamicPrice?.rulePrice)),
                    titleLock: (title !== product?.name || (!!dynamicPrice?.id && title !== dynamicPrice?.ruleName)),
                    accountLock: (product?.account !== account),
                    itemNumberLock: (product?.itemNumber !== itemNumber),
                    taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                    unitLock: (product?.unit?.id !== unit?.id)
                })
            ]);
        }
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            account: [this.data?.position?.account],
            itemNumber: [this.data?.position?.itemNumber],
            product: [this.data?.position?.product],
            title: [this.data?.position?.title || null, [U2bValidators.required('Bitte Positionstitel angeben')]],
            quantity: [this.data?.position?.quantity || 1, [
                U2bValidators.required('Bitte Menge angeben'),
                U2bNumericValidators.numberMin(0, 'Die Menge kann nicht negativ sein.')
            ]],
            unit: [this.data?.position?.unit || null, [U2bValidators.required('Bitte Einheit angeben')]],
            price: [this.data?.position?.price || null, [U2bValidators.required('Bitte Einzelpreis angeben')]],
            taxRate: [this.data?.position?.taxRate || null, [U2bValidators.required('Bitte MwSt. angeben')]],
            vestingPeriodFrom: [
                this.data?.startDate ||
                (this.data?.position?.vestingPeriodFrom ? tryParseDate(this.data?.position?.vestingPeriodFrom) : null),
            ],
            vestingPeriodUntil: [
                this.data?.endDate ||
                (this.data?.position?.vestingPeriodUntil ? tryParseDate(this.data?.position?.vestingPeriodUntil) : null),
            ],
            discountPercentage: [this.data?.position?.discountPercentage || null, [U2bNumericValidators.numberMin(0.1), U2bNumericValidators.numberMax(100)]]
        });

        const vestingPeriodFromField = this.formGroup.get('vestingPeriodFrom');
        const vestingPeriodUntilField = this.formGroup.get('vestingPeriodUntil');

        this.formGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {

                if (!value) {
                    return;
                }

                if (this.dynamicPriceContext?.fromDate !== value.vestingPeriodFrom || this.dynamicPriceContext?.toDate !== value.vestingPeriodUntil) {
                    this.dynamicPriceContext = {
                        ...this.dynamicPriceContext,
                        fromDate: value.vestingPeriodFrom,
                        toDate: value.vestingPeriodUntil,
                    };
                }

                if (this.useDynamicPrice) {
                    this.formGroupValid = this.formGroup.get('title').valid &&
                        this.formGroup.get('quantity').valid &&
                        this.formGroup.get('unit').valid &&
                        this.formGroup.get('taxRate').valid;
                } else {
                    this.formGroupValid = this.formGroup.get('title').valid &&
                        this.formGroup.get('quantity').valid &&
                        this.formGroup.get('unit').valid &&
                        this.formGroup.get('price').valid &&
                        this.formGroup.get('taxRate').valid;
                }

                const {
                    product,
                    vestingPeriodFrom,
                    vestingPeriodUntil,
                } = value;

                this.hasDynamicPrice = product?.hasDynamicPrice;

                if (vestingPeriodFrom && vestingPeriodUntil) {
                    if (vestingPeriodFrom > vestingPeriodUntil) {
                        vestingPeriodFromField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Leistungszeitraum Start" Datum darf nicht hinter dem "Leistungszeitraum Ende" Datum liegen'
                            }
                        });
                        vestingPeriodUntilField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Leistungszeitraum Ende" Datum darf nicht vor dem "Leistungszeitraum Start" Datum liegen'
                            }
                        });
                    } else {
                        removeErrorFromControls('wrongDateRange', [
                            vestingPeriodFromField,
                            vestingPeriodUntilField,
                        ]);
                    }
                }

                if (this.currentTabIndex === 0) {
                    this.hasNextTab = this.hasDynamicPrice || this.vouchersAvailable;
                }

            });

        forkJoin([
            this.formGroup.get('product').valueChanges,
            this.formGroup.get('unit').valueChanges
        ])
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(([product, unit]) => {

                if (unit?.uniqueName === UnitUniqueName.SQM || product?.unit?.uniqueName === UnitUniqueName.SQM) {
                    this.showQuantityButtons = true;
                }

                this.voucherPositions = [];

                this._changeDetectorRef.detectChanges();
            });

        merge(
            this.formGroup.get('quantity').valueChanges,
            this.formGroup.get('price').valueChanges,
            this.formGroup.get('discountPercentage').valueChanges,
        )
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(() => {

                const [quantity, price, discountPercentage] = [
                    this.formGroup.get('quantity').value,
                    this.formGroup.get('price').value,
                    this.formGroup.get('discountPercentage').value,
                ];

                this._calculateTotalPrice(price, quantity, discountPercentage);

                this._changeDetectorRef.detectChanges();
            });

        this.filteredProducts$ = this.formGroup.get('title').valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                startWith(this.products),
                switchMap(
                    (value: string | Product) => isString(value)
                        ? this._filterProducts(value as string)
                        : of(this.products)
                )
            );

    }

    private _calculateTotalPrice(price = 0, quantity = 0, discountPercentage = 0): void {
        if (price == null || quantity == null) {
            return;
        }

        const totalPrice = price * quantity;
        this.totalDiscount = (totalPrice / 100 * (discountPercentage || 0));
        this.totalPrice = totalPrice - this.totalDiscount;
    }

    private _filterProducts(filter: string): Observable<Product[]> {
        return of(this.products)
            .pipe(
                untilDestroyed(this),
                map((products: Product[]) => products
                    .filter((product: Product) => product.name.toLowerCase().includes(filter.toLowerCase())))
            );
    }

    private _loadProducts(): void {
        this._productApiService
            .getAll()
            .subscribe((products: Product[]) => {
                if (isFunction(this.data?.productsFilterFn)) {
                    // todo: move filter to backend
                    this.products = products.filter(this.data?.productsFilterFn);
                    return;
                }
                this.products = products;
            });
    }

    private _loadProductTaxRates(): void {
        this._productTaxRateApiService.getAll()
            .subscribe((taxRates: ITaxRate[]) => {
                this.taxRates = taxRates;
                this.showTaxRateColumn = this.taxRates?.length > 1 || (this.taxRates?.length === 0 && this.taxRates[0].value !== 0);
                if (!this.showTaxRateColumn) {
                    this.formGroup.get('taxRate').disable();
                }
            });
    }

    private _loadProductUnits(): void {
        this._productUnitApiService.getAll().subscribe((units: IUnit[]) => this.units = units);
    }

}
