import { InvoicePosition } from '@shared/models/invoice-position';
import { Product } from '@shared/models/product';

export function getTaxRateValue(position: InvoicePosition | { product: Product; quantity: number; }): number {
    if ('taxRateValue' in position && position?.taxRateValue != null) {
        return position.taxRateValue;
    } else if ('taxRate' in position && position?.taxRate?.value != null) {
        return position.taxRate.value;
    } else if (position?.product?.taxRate?.value != null) {
        return position.product.taxRate.value;
    } else {
        return 0;
    }
}
