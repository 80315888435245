<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <form (ngSubmit)="onClickSaveAndClose()" [formGroup]="formGroup" appInvalidControlScroll
          [scrollContainer]="scrollContainer">
        <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Liegeplatz vergeben</span>
                <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                        aria-label="Dialog schließen" type="button">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content cdkScrollable #scrollContainer>

            <form-widget-tenant-relation [parentFormGroup]="formGroup"></form-widget-tenant-relation>

            <mat-divider class="special-divider"></mat-divider>

            <form-widget-berth [parentFormGroup]="formGroup"></form-widget-berth>

            <form-widget-berth-fee [parentFormGroup]="formGroup"></form-widget-berth-fee>

            <mat-divider class="special-divider"></mat-divider>

            <premium-form-widget-electric-meter [parentFormGroup]="formGroup"
                                                [dateFrom]="formGroup?.get('berthForm')?.get('from')?.value"
                                                [dateTo]="formGroup?.get('berthForm')?.get('to')?.value"></premium-form-widget-electric-meter>

            <mat-divider class="special-divider"></mat-divider>

            <form-widget-boat [parentFormGroup]="formGroup"></form-widget-boat>

            <mat-divider class="special-divider"></mat-divider>

            <h2>Wer bekommt den Liegeplatz?</h2>

            <ng-container *ngIf="formGroupPrepare.get('type').touched && formGroupPrepare.get('type').errors">
                <u2b-message type="warning">
                    {{formGroupPrepare.get('type').errors | firstErrorMessage}}
                </u2b-message>
            </ng-container>

            <div [formGroup]="formGroupPrepare">
                <mat-radio-group class="radio-group-inline" formControlName="type">
                    <mat-radio-button [value]="'person'">Person</mat-radio-button>
                    <mat-radio-button [value]="'organisation'">Organisation</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="formGroupPrepare.get('type').value === 'person'">
                <mat-divider class="special-divider"></mat-divider>
                <form-widget-person [parentFormGroup]="formGroup" [person$]="person$"></form-widget-person>
            </ng-container>

            <ng-container *ngIf="formGroupPrepare.get('type').value === 'organisation'">
                <mat-divider class="special-divider"></mat-divider>
                <form-widget-company [parentFormGroup]="formGroup" [company$]="company$"></form-widget-company>
            </ng-container>

            <mat-divider class="special-divider"></mat-divider>

            <form-widget-contract-co-owner [parentFormGroup]="formGroup"></form-widget-contract-co-owner>

            <mat-divider class="special-divider"></mat-divider>

            <form-widget-contract [parentFormGroup]="formGroup" [givenContracts]="filteredContracts"></form-widget-contract>

            <ng-container
                *ngIf="(formGroup.dirty && formGroup.invalid) || (formGroupPrepare.dirty && formGroupPrepare.invalid)">
                <u2b-message type="warning" customClass="mt-24">
                    Deine Angaben sind noch nicht vollständig, oder enthalten Fehler, die
                    korrigiert werden müssen.
                </u2b-message>
            </ng-container>

            <ng-container
                *ngIf="(formGroup.dirty && formGroup.valid) && (formGroupPrepare.dirty && formGroupPrepare.valid)">
                <u2b-message type="success" customClass="mt-24">
                    <header>Geschafft!</header>
                    Alle Daten wurden erfasst. Klicke auf "Speichern" um die Daten im System zu
                    speichern.
                </u2b-message>
            </ng-container>
        </div>

        <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

            <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
                Abbrechen
            </button>

            <div>
                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Speichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <span>Speichern</span>
                </ng-template>

                <button type="submit" data-test="B-Speichern" mat-flat-button color="accent"
                        [disabled]="isSaving">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>
            </div>
        </div>
    </form>
</div>
