<div [formGroup]="berthFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex=100>
        <h2>{{headline}}</h2>
    </div>

    <app-input-date-time formControlName="from">
        <label>von</label>
    </app-input-date-time>

    <app-input-date-time formControlName="to">
        <label>bis</label>
        <div class="hint">
            <ng-container *ngIf="!berthFormGroup.get('to').value; else showSetNull">
                Frei lassen, wenn zeitlich unbegrenzt
            </ng-container>
            <ng-template #showSetNull>
                <a href="javascript:void(0)" (click)="setNullToField()">"bis" Felder leeren</a>
            </ng-template>
        </div>
    </app-input-date-time>

    <ng-container *ngIf="berthFormGroup.get('from').valid">

        <div fxFlex="100" class="pb-16">
            <mat-checkbox data-test="FCB-Nur freie Liegeplätze anzeigen" formControlName="onlyFree">
                Nur freie Liegeplätze anzeigen
            </mat-checkbox>
        </div>

        <ng-container *ngIf="!loadingBerths && !berths?.length">
            <div fxFlex="100">
                <u2b-message>
                    Es wurden keine Liegeplätze gefunden, die den angegebenen Anforderungen entsprechen. Neue
                    Liegeplätze können unter <strong>Hafenverwaltung</strong> > <strong>Liegeplan</strong> erfasst
                    werden.
                </u2b-message>
            </div>
        </ng-container>

        <ng-container *ngIf="berthFormGroup.errors">
            <div fxFlex="100">
                <u2b-message type="error">
                    {{berthFormGroup.errors | firstErrorMessage}}
                </u2b-message>
            </div>
        </ng-container>

        <div fxFlex="100">
            <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                <mat-label>Liegeplatz</mat-label>
                <input type="text" matInput data-test="FI-Liegeplatz" formControlName="berth"
                       autocomplete="new-password"
                       [matAutocomplete]="berthSelector">
                <mat-error>{{berthFormGroup.get('berth').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-autocomplete fxFlex="0" #berthSelector="matAutocomplete">
                <mat-option disabled class="loading" *ngIf="loadingBerths">
                    <mat-spinner diameter="35"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="berths?.length">
                    <mat-option *ngFor="let berth of (filteredBerths$ | async) | orderBy: 'handle'" [value]="berth">
                        <span>{{ berth.handle }}</span>
                        <ng-container *ngIf="berth.pier?.handle">
                            - {{ berth.pier?.handle }}
                        </ng-container>
                        <ng-container *ngIf="berth.hasActiveAssignments() && !berthFormGroup.get('onlyFree').value">
                            - Belegt von
                            <ng-container *ngFor="let assignment of berth.getActiveAssignments(); let isLast = last">
                                {{assignment?.boat?.name}}
                                <ng-container *ngIf="!isLast">,</ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </div>

        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
            <mat-label>Bemerkungen / Notizen (optional)</mat-label>
            <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                      autocomplete="new-password"></textarea>
            <mat-error>{{berthFormGroup.get('note').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>
    </ng-container>
</div>
