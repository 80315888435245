import {InsertedLastUpdate} from '@shared/interfaces/inserted-updated';
import {IWinterStorageBoatAssignment, WinterStorageBoatAssignment} from '@shared/models/winter-storage-boat-assignment';

export interface IWinterStorage extends InsertedLastUpdate {
    id: number;
    handle: string;
    length: number;
    width: number;
    maneuveringArea: number;
    note?: string;
    assignments: IWinterStorageBoatAssignment[];
}

export class WinterStorage {
    id: number;
    handle: string;
    length: number;
    width: number;
    maneuveringArea: number;
    note?: string;
    assignments: WinterStorageBoatAssignment[];

    area: number;
    assignedArea: number;
    freeArea: number;

    constructor(winterStorage?: IWinterStorage) {
        this.id = winterStorage.id || null;
        this.handle = winterStorage.handle || null;
        this.length = winterStorage.length || 0;
        this.width = winterStorage.width || 0;
        this.maneuveringArea = winterStorage.maneuveringArea || 0;
        this.note = winterStorage.note || null;
        this.assignments = (winterStorage.assignments || []).map(assignment => new WinterStorageBoatAssignment(assignment));

        this.area = this.length * this.width;

        const today = new Date(Date.now());
        this.calcFreeArea(today, today);
    }

    toString(): string {
        const handle = `${this.handle}`.trim();
        return handle.includes('Lagerplatz') ? handle : 'Lagerplatz ' + handle;
    }

    public getActiveAssignments(): WinterStorageBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.active);
    }

    public getFutureAssignments(): WinterStorageBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.future);
    }

    public getForetimeAssignments(): WinterStorageBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.foretime);
    }

    public hasActiveAssignments(): boolean {
        return !!this.getActiveAssignments().length;
    }

    public calcFreeArea(startDate: Date, endDate: Date): void {
        this.calcAssignedArea(startDate, endDate);
        this.freeArea = this.area - this.maneuveringArea - this.assignedArea;
    }

    private calcAssignedArea(startDate: Date, endDate: Date): void {
        this.assignedArea = this.assignments
            .filter(assignment => (!endDate || assignment.from <= endDate) && (!assignment.to || assignment.to >= startDate))
            .map(assignment => assignment.assignedArea)
            .reduce(((a, b) => a + b), 0);
    }
}
