import { BcmPaymentType } from '@shared/models/person';
import { tryParseDate } from '@shared/functions/try-parse-date';

export interface InvoicePaymentDto {
    id?: number;
    bcm_invoices_id?: number;
    bcm_receipts_id?: number;
    partialPayment: boolean;
    date: Date | string;
    paymentType: BcmPaymentType;
    amount: string; // decimal
    note?: string;

    insertedOn?: string;
    insertedBy?: string;
    lastUpdateOn?: string;
    lastUpdateBy?: string;
}

export class InvoicePayment {
    id: number;
    // tslint:disable-next-line:variable-name
    bcm_invoices_id: number;
    // tslint:disable-next-line:variable-name
    bcm_receipts_id: number;
    partialPayment: boolean;
    date: Date;
    paymentType: BcmPaymentType;
    amount: number;
    note: string;
    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(obj?: InvoicePaymentDto) {
        this.id = obj?.id;
        this.bcm_invoices_id = obj?.bcm_invoices_id;
        this.bcm_receipts_id = obj?.bcm_receipts_id;
        this.partialPayment = obj?.partialPayment;
        this.date = tryParseDate(obj?.date);
        this.paymentType = obj?.paymentType;
        this.amount = parseFloat(obj?.amount);
        this.note = obj?.note || '';
        this.insertedOn = tryParseDate(obj?.insertedOn);
        this.insertedBy = obj?.insertedBy;
        this.lastUpdateOn = tryParseDate(obj?.lastUpdateOn);
        this.lastUpdateBy = obj?.lastUpdateBy;
    }
}
