import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';

@Injectable({
    providedIn: 'root'
})
export class TseApiService extends BcmApi2Service {

    private path = 'tse/';

    constructor(_http: HttpClient,
                _bcmService: BcmService) {
        super(_http, _bcmService);
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return super.get<T>(`${this.path}${path}`, params);
    }

    protected post<T>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.post<T>(`${this.path}${path}`, body, params);
    }

    protected put<T>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.put<T>(`${this.path}${path}`, body, params);
    }

    protected delete<T>(path): Observable<T> {
        return super.delete<T>(`${this.path}${path}`);
    }

}
