import { InsertedLastUpdate } from '@shared/interfaces/inserted-updated';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { Pier, PierRaw } from '@shared/models/pier';
import { isWithinInterval } from 'date-fns';
import { IProduct, Product } from '@shared/models/product';

export enum BerthStatus {
    Free = 'free',
    Reserved = 'reserved',
    Occupied = 'occupied',
    Locked = 'locked'
}

export const berthStatusTranslationsDe = {
    [BerthStatus.Free]: 'frei',
    [BerthStatus.Reserved]: 'reserviert',
    [BerthStatus.Occupied]: 'belegt',
    [BerthStatus.Locked]: 'gesperrt'
};

export interface IBerth extends InsertedLastUpdate {
    id: number;
    handle: string;
    // pierHandle: string;
    length: number;
    width: number;
    maxDraft: number;
    boatType: string;
    status: string;
    noMatch: boolean;
    noMatchLength: boolean;
    noMatchWidth: boolean;
    noMatchMaxDraft: boolean;
    noMatchBoatType: boolean;
    note?: string;
    assignments: IBerthBoatAssignment[];
    pier: PierRaw;
    isDoubleBox: boolean;
    isForeign: boolean;
    isLiftStation: boolean;
    isJetSki: boolean;
    isPier: boolean;
    isBuoy: boolean;
    forGuest: boolean;
    lat: number;
    long: number;
    maxCarryingCapacity: number;
    draftMeasuringStationUuid: string;
    draftMeasurementDifference: number;
    interimLocked: string;
    interimLockedReason: string;
    isMostFree: boolean;
    matchFilter?: boolean;
    freeScore?: number;

    products: IProduct[];
}

export class Berth {
    id: number;
    handle: string;
    length: number;
    width: number;
    maxDraft: number;
    boatType: string;
    status: BerthStatus;
    statusIndex: number;
    statusText: string;
    noMatch: boolean;
    noMatchLength: boolean;
    noMatchWidth: boolean;
    noMatchMaxDraft: boolean;
    noMatchBoatType: boolean;
    note?: string;
    assignments: BerthBoatAssignment[] = [];
    activeAssignments: BerthBoatAssignment[] = [];
    futureAssignments: BerthBoatAssignment[] = [];
    pier: Pier;
    isDoubleBox: boolean;
    isForeign: boolean;
    isLiftStation: boolean;
    isJetSki: boolean;
    isPier: boolean;
    isBuoy: boolean;
    forGuest: boolean;
    lat: number;
    long: number;
    maxCarryingCapacity: number;
    _interimLocked: Date | null;
    get interimLocked(): Date | null {
        if (this._interimLocked && this._interimLocked > new Date()) {
            return this._interimLocked;
        }
        return null;
    }

    interimLockedReason: string;

    draftMeasuringStationUuid?: string;
    draftMeasurementDifference?: number;
    measuredRelativeDraft?: number;
    isMostFree: boolean;
    matchFilter?: boolean;
    freeScore?: number;

    // helpers
    pierHandle: string;
    boatName = '';
    boatLicensePlate = '';
    boatManufacturer = '';
    boatOwner = '';
    type = '';
    squareMeters: number;

    products: Product[];

    constructor(berth?: IBerth) {
        this.id = berth.id || null;
        this.handle = berth.handle || null;
        this.pierHandle = berth.pier?.handle || null;
        this.length = berth.length || null;
        this.width = berth.width || null;
        this.maxDraft = berth.maxDraft || null;
        this.boatType = berth.boatType || null;
        this.note = berth.note || null;
        this.assignments = (berth.assignments || []).map(assignment => new BerthBoatAssignment(assignment));
        this.activeAssignments = this.getActiveAssignments();
        this.futureAssignments = this.getFutureAssignments();
        this.pier = berth.pier ? new Pier(berth.pier) : null;
        this.isDoubleBox = !!berth.isDoubleBox;
        this.isForeign = !!berth.isForeign;
        this.isLiftStation = !!berth.isLiftStation;
        this.isJetSki = !!berth.isJetSki;
        this.isPier = !!berth.isPier;
        this.isBuoy = !!berth.isBuoy;
        this.forGuest = !!berth.forGuest;
        this.lat = berth.lat;
        this.long = berth.long;
        this.maxCarryingCapacity = berth.maxCarryingCapacity;
        this._interimLocked = berth.interimLocked ? tryParseDate(berth.interimLocked) : null;
        this.interimLockedReason = berth.interimLockedReason;
        this.maxCarryingCapacity = berth.maxCarryingCapacity;
        this.type = this.getType();
        this.draftMeasuringStationUuid = berth.draftMeasuringStationUuid;
        this.draftMeasurementDifference = berth.draftMeasurementDifference;

        this.status = BerthStatus.Free;

        this.squareMeters = this.length * this.width;

        const activeAndFutureAssignments = [...this.activeAssignments, ...this.futureAssignments];
        const activeAndFutureReservations = activeAndFutureAssignments
            .filter(assignment => assignment.type === BookingType.Reservation);

        if (this.interimLocked) {
            this.status = BerthStatus.Locked;
        } else if (activeAndFutureAssignments?.length) {
            this.status = activeAndFutureAssignments.length === activeAndFutureReservations.length
                ? BerthStatus.Reserved
                : BerthStatus.Occupied;
        }

        // helper for sorting and labeling
        switch (this.status) {
            case BerthStatus.Free:
                this.statusIndex = 0;
                this.statusText = 'Frei';
                break;
            case BerthStatus.Reserved:
                this.statusIndex = 1;
                this.statusText = 'Reserviert';
                break;
            case BerthStatus.Occupied:
                this.statusIndex = 2;
                this.statusText = 'Belegt';
                break;
            case BerthStatus.Locked:
                this.statusIndex = 3;
                this.statusText = 'Gesperrt';
                break;
        }

        this.noMatch = !!berth.noMatch;
        this.noMatchLength = !!berth.noMatchLength;
        this.noMatchWidth = !!berth.noMatchWidth;
        this.noMatchMaxDraft = !!berth.noMatchMaxDraft;
        this.noMatchBoatType = !!berth.noMatchBoatType;
        this.isMostFree = !!berth.isMostFree;
        this.matchFilter = !!berth.matchFilter;
        if (this.matchFilter) {
            this.freeScore = berth.freeScore;
        }

        /* table list search helper */
        this.activeAssignments.forEach(assignment => {
            this.boatName += assignment.boat?.name || '';
            this.boatLicensePlate += assignment.boat?.licensePlate || '';
            this.boatManufacturer += assignment.boat?.manufacturer || '';
            this.boatOwner += (assignment.boat?.owner?.fullName || assignment.boat?.ownerCompany?.fullName) || '';
        });

        this.boatName = this.boatName || 'ZZ';
        this.boatLicensePlate = this.boatLicensePlate || 'ZZ';
        this.boatManufacturer = this.boatManufacturer || 'ZZ';
        this.boatOwner = this.boatOwner || 'ZZ';
        this.products = (berth.products || [])
            .map(p => new Product(p))
            .sort((a, b) => a.sortIndex - b.sortIndex);
    }

    toString(): string {
        return [this.handle, this.pier?.handle].join(' - ');
    }

    public isLockedBetweenDates(start: Date, end: Date): boolean {
        return isWithinInterval(this._interimLocked, {start, end});
    }

    public isLockedUntil(date: Date): boolean {
        return this._interimLocked && this._interimLocked > date;
    }

    public updateStatus(onlyActiveAssignments = true): void {
        this.status = BerthStatus.Free;

        const allAssignments = this.assignments;
        const allReservations = allAssignments
            .filter(assignment => assignment.type === BookingType.Reservation);

        const activeAssignments = this.activeAssignments;
        const activeReservations = activeAssignments
            .filter(assignment => assignment.type === BookingType.Reservation);

        if (this.interimLocked) {
            this.status = BerthStatus.Locked;
        } else if (onlyActiveAssignments && activeAssignments?.length) {
            this.status = activeAssignments.length === activeReservations.length
                ? BerthStatus.Reserved
                : BerthStatus.Occupied;
        } else if (allAssignments?.length) {
            this.status = allAssignments.length === allReservations.length
                ? BerthStatus.Reserved
                : BerthStatus.Occupied;
        }
    }

    public getActiveAssignments(): BerthBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.active);
    }

    public getFutureAssignments(): BerthBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.future);
    }

    public hasActiveAssignments(): boolean {
        return !!this.activeAssignments?.length;
    }

    public get area(): number {
        return this.length * this.width;
    }

    private getType(): string {
        const type: string[] = [];
        if (this.isDoubleBox) {
            type.push('Doppelbox');
        }
        if (this.isForeign) {
            type.push('Fremd');
        }
        if (this.isLiftStation) {
            type.push('Hebeanlage');
        }
        if (this.isJetSki) {
            type.push('Jetski Ponton');
        }
        if (this.isPier) {
            type.push('Pier');
        }
        if (this.isBuoy) {
            type.push('Boje');
        }
        if (this.forGuest) {
            type.push('Gastliegeplatz');
        }
        return type.join(', ');
    }
}


export interface BerthSchedulerItem extends Berth {

    assets: Array<{
        id: number,
        containerID: number,
        name: string,
        start: Date,
        end: Date,
        tooltip: string,
        cssStyles: {
            background?: string,
            'box-shadow'?: string,
            color?: string;
        },
        metaData: BerthBoatAssignment
    }>;
}
