import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { IProduct, Product } from '@shared/models/product';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { U2bValidators } from '@shared/validators/validators';
import { U2bProductValidators } from '@shared/validators/product/product-validators';
import { ProductsApiService } from '@modules/bcm/@shared/services';
import { AppNotificationService } from '@core/services/app-notification.service';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';

@Component({
    selector: 'add-position-dialog',
    templateUrl: './get-product-dialog.component.html',
    styleUrls: ['./get-product-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GetProductDialogComponent {
    productForm: UntypedFormGroup;
    price: number;
    totalPrice: number;
    products: Product[];
    filteredProducts$: Observable<Product[]>;
    isSaving: boolean;
    noProductsMessage = 'Es konnten keine Produkte gefunden werden.';
    showQuantityField = true;
    categories: string[];
    showIgnoreFreeOfChargePositionCheckbox: boolean;
    product: Product;
    quantityZeroAllowed = true;

    constructor(
        public dialogRef: MatDialogRef<GetProductDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: {
            showQuantityField?: boolean,
            categories?: string[],
            product?: Product,
            showIgnoreFreeOfChargePositionCheckbox?: boolean,
            quantityZeroAllowed?: boolean,
        },
        private _formBuilder: UntypedFormBuilder,
        private _productApiService: ProductsApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        dialogRef.addPanelClass('get-product-dialog');

        this.product = data?.product;
        this.showIgnoreFreeOfChargePositionCheckbox = data?.showIgnoreFreeOfChargePositionCheckbox;
        this.showQuantityField = data?.showQuantityField == null ? true : data.showQuantityField;
        this.categories = Array.isArray(data?.categories) ? data.categories : [];
        this.productForm = this._createForm();

        if (data.quantityZeroAllowed === false) {
            this.quantityZeroAllowed = false;
        }

        const form = this.productForm;
        const productField = form.get('product');
        const quantityField = form.get('quantity');

        combineLatest([productField.valueChanges, quantityField.valueChanges])
            .subscribe(
                (values) => this._calculateTotalPrice((values[0] as Product || {price: 0}).price, values[1])
            );

        this._loadProducts();

        this.filteredProducts$ = this.productForm.get('product').valueChanges
            .pipe(
                startWith(this.products),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterProducts(value) : of(this.products))
            );
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    saveAndContinue(): void {
        if (this.productForm.invalid) {
            this.productForm.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const {product, quantity, ignoreFreeOfChargePosition} = this.productForm.value;

        this.isSaving = true;
        this.dialogRef.close({product, quantity, ignoreFreeOfChargePosition});
    }

    private _createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            product: [
                this.data?.product || '',
                [
                    U2bValidators.required('Bitte Produkt aus der Liste auswählen'),
                    U2bProductValidators.productExists(),
                ],
            ],
            quantity: [
                this.data?.product?.quantity ?? null,
                [
                    U2bValidators.required('Bitte Anzahl / Einheiten angeben.'),
                    this.quantityZeroAllowed
                        ? U2bNumericValidators.numberMin(0, `Bitte Menge größer oder gleich 0 erfassen.`)
                        : U2bNumericValidators.numberMin(0.0001, `Bitte Menge größer 0 erfassen.`)
                ]
            ],
            ignoreFreeOfChargePosition: [this.data?.product?.id ? this.data?.product?.ignoreFreeOfChargePosition : false]
        });
    }

    private _calculateTotalPrice(price = 0, quantity = 0): void {
        if (price == null || quantity == null) {
            return;
        }

        this.totalPrice = (price * quantity);
    }

    private _filterProducts(filter: string): Observable<Product[]> {
        return of(this.products)
            .pipe(
                map((products: Product[]) => products.filter((product: Product) => product.name.toLowerCase().includes(filter.toLowerCase())))
            );
    }

    private _loadProducts(): void {
        this._productApiService
            .getAll()
            .subscribe((products: Product[]) => {
                if (this.categories.length) {
                    products = products.filter(p => this.categories.includes(p.category.name));
                }

                this.products = products;
            });
    }
}
