import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Product } from '@shared/models/product';
import { BcmDynamicPriceContext } from '@shared/models/bcm-dynamic-price';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BcmVoucher, BcmVoucherPosition } from '@shared/models/bcm-voucher';
import { BcmVouchersFacade } from '@modules/bcm/@core/state-management/vouchers/bcm-vouchers-facade';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@UntilDestroy()
@Component({
    selector: 'product-voucher',
    templateUrl: './product-voucher.component.html',
    styleUrls: ['./product-voucher.component.scss']
})
export class ProductVoucherComponent implements OnInit {

    @Input() parentFormGroup: UntypedFormGroup;

    @Input() dynamicPriceContext: BcmDynamicPriceContext;

    @Input() product: Product;

    @Input() quantity: number;

    @Output() vouchersAvalaible: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() voucherPositionsChanged: EventEmitter<BcmVoucherPosition[]> = new EventEmitter<BcmVoucherPosition[]>();

    @Output() voucherUsageChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() set selectVoucher(voucher: BcmVoucher) {
        if (voucher?.id) {
            this._vouchersFacade?.loadById(voucher.id)
                .pipe(take(1))
                .subscribe(v => {
                    this._selectVoucher = v;
                    this._selectVoucher.quantityLeft += parseFloat(String(this.quantity));

                    if (this.dynamicPriceContext?.positions?.length > 0) {

                        const reservedQuantity = this.dynamicPriceContext?.positions
                            .filter(p => p.voucher?.id === v.id)
                            .map(p => p.quantity)
                            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                        this._selectVoucher.quantityLeft -= reservedQuantity;

                    }

                    this.addVoucher(this.selectVoucher);
                });
        }
    }

    get selectVoucher(): BcmVoucher {
        return this._selectVoucher;
    }

    private _selectVoucher: BcmVoucher;

    price: number = null;

    voucherPositions: BcmVoucherPosition[] = [];

    vouchersFormGroup: UntypedFormGroup;

    vouchers: BcmVoucher[] = [];

    givenVouchers: BcmVoucher[] = [];

    filteredVouchers: BcmVoucher[] = [];

    selectedVouchers: BcmVoucher[] = [];

    constructor(private _formBuilder: FormBuilder,
                private _vouchersFacade: BcmVouchersFacade) {

    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.vouchersFormGroup = this._formBuilder.group({});
        this._loadVouchers(this.product);


        combineLatest([
            this.parentFormGroup.get('product').valueChanges.pipe(startWith(this.product)),
            this.parentFormGroup.get('price').valueChanges,
            this.parentFormGroup.get('quantity').valueChanges.pipe(startWith(this.quantity ?? 1)),
            this.parentFormGroup.get('vestingPeriodFrom').valueChanges.pipe(startWith(new Date()))
        ]).pipe(
            untilDestroyed(this),
            map(value => ({product: value[0], price: value[1], quantity: value[2], validDate: value[3] || new Date()})),
            distinctUntilChanged((previous, current) => {
                return previous.product?.id === current.product?.id &&
                    previous.price === current.price &&
                    previous.quantity === current.quantity &&
                    previous.validDate?.getTime() === current.validDate?.getTime();
            })
        )
            .subscribe(({product, price, quantity, validDate}) => {
                this.product = product;
                this.price = price;
                this.quantity = quantity;

                this._loadVouchers(product, validDate);
            });
    }

    addVoucher(voucher: BcmVoucher): void {
        this.selectedVouchers.push(voucher);
        this._filterVouchers();
    }

    updateVoucher(updateVoucherEvent: { index: number, voucher: BcmVoucher }): void {
        this.selectedVouchers[updateVoucherEvent.index] = updateVoucherEvent.voucher;
        this._filterVouchers();
    }

    removeVoucherByIndex(index: number): void {
        this.selectedVouchers.splice(index, 1);
        this._filterVouchers();
    }

    private _loadVouchers(product: Product, validDate?: Date): void {
        if (!product) {
            this.selectedVouchers = [];
            this.vouchersAvalaible.next(false);
            return;
        }

        let params = new HttpParams()
            .set('withQuantityLeft', true);

        if (validDate) {
            params = params
                .append('validDate', validDate.toISOString());
        }

        this._vouchersFacade.loadList(params)
            .pipe(untilDestroyed(this))
            .subscribe(vouchers => {
                if (product?.id) {
                    this.vouchers = vouchers.filter(v => v.product?.id === product.id);
                } else {
                    this.vouchers = vouchers;
                }

                if (this.vouchers.length === 0) {
                    this.selectedVouchers = [];
                    this.vouchersAvalaible.next(false);
                    return;
                }

                this.vouchersAvalaible.next(true);
                this._filterVouchers();
            });
    }

    private _filterVouchers(): void {

        const person = this.dynamicPriceContext?.person;
        const company = this.dynamicPriceContext?.company;
        const boats = [];

        if (this.dynamicPriceContext?.boat?.id) {
            boats.push(this.dynamicPriceContext?.boat);
        }

        const givenVouchers: BcmVoucher[] = [];

        if (person?.id) {
            givenVouchers.push(...this.vouchers.filter(v => v.person?.id === person?.id));
            if (person.boats?.length > 0) {
                boats.push(...person.boats);
            }
        }

        if (company?.id) {
            givenVouchers.push(...this.vouchers.filter(v => v.company?.id === company?.id));
            if (company.boats?.length > 0) {
                boats.push(...company.boats);
            }
        }

        if (boats.length > 0) {
            givenVouchers.push(...this.vouchers.filter(v => boats.findIndex(b => b.id === v.boat?.id) !== -1));
        }

        givenVouchers.push(...this.vouchers.filter(v => v.person === null && v.company === null && v.boat === null));

        // const vouchers = [...new Set([...givenVouchers])];

        let vouchers = [];

        for (const givenVoucher of givenVouchers) {
            if (vouchers.findIndex(v => v.id === givenVoucher.id) === -1) {
                vouchers.push({...givenVoucher});
            }
        }

        if (this.dynamicPriceContext?.positions?.length > 0) {

            vouchers = vouchers.map(v => {

                const reservedQuantity = this.dynamicPriceContext?.positions
                    .filter(p => p.voucher?.id === v.id)
                    .map(p => p.quantity)
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                v.quantityLeft -= reservedQuantity;

                return v;
            }).filter(v => v.quantityLeft > 0);
        }

        this.givenVouchers = [...vouchers];

        this.filteredVouchers = [...vouchers.filter(voucher =>
            this.selectedVouchers.findIndex(v => v.id === voucher.id) === -1)];

        if (vouchers.length === 0) {
            this.vouchersAvalaible.next(false);
        } else {
            this.vouchersAvalaible.next(true);
        }

        this._calculateVoucherUsage();
    }

    private _calculateVoucherUsage(): void {

        this.voucherPositions = [];

        let positionQuantityLeft = parseFloat(String(this.quantity));

        for (let voucher of this.selectedVouchers) {

            if (voucher.id === this.selectVoucher?.id) {
                voucher = this.selectVoucher;
            }

            const voucherQuantityLeft = voucher.quantityLeft;

            const quantity = Math.min(voucherQuantityLeft, positionQuantityLeft);

            positionQuantityLeft -= quantity;

            this.voucherPositions.push({voucher, quantity, totalPrice: 0});

            if (positionQuantityLeft === 0) {
                break;
            }
        }

        this.voucherUsageChanged.next(this.voucherPositions.length > 0);

        if (positionQuantityLeft > 0) {
            this.voucherPositions.push({
                voucher: null,
                quantity: positionQuantityLeft,
                totalPrice: positionQuantityLeft * ((this.price > 0) ? this.price : this.product.price)
            });
        }

        this.voucherPositionsChanged.next(this.voucherPositions);

    }

}
