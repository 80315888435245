import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { BcmWorkflowApiService } from '@bcmApiServices/bcm-workflow-api.service';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { Boat } from '@shared/models/boat';
import { Berth } from '@shared/models/berth';
import { BerthsMapFilterService } from '@bcmServices/berths/berths-map-filter.service';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { endOfToday } from '@core/date.facade';
import { isSameDay, startOfToday } from 'date-fns';
import { InvoicePosition } from '@shared/models/invoice-position';
import { U2bInformationDialogComponent } from '@sharedComponents/dialogs/information-dialog/information-dialog.component';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { NewBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import print from 'print-js';
import { ContractFileType } from '@shared/models/contract';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { GetProductSubscriptionDatesDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-dates-dialog/get-product-subscription-dates-dialog.component';
import { Product } from '@shared/models/product';
import { DEFAULT_BERTH_RESERVATION_UNIT } from '@modules/bcm/settings/default-units/default-units.component';
import { UnitUniqueName } from '@shared/models/unit';
import { PayByCashDialogComponent } from '@sharedComponents/dialogs/pay-by-cash-dialog/pay-by-cash-dialog.component';
import { TimeSchedulerAsset } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-asset';
import { TimeSchedulerItem } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-item';

@Component({
    selector: 'berth-reservation',
    templateUrl: './berth-reservation.component.html',
    styleUrls: ['./berth-reservation.component.scss'],
    providers: [InvoicesService]
})
export class BerthReservationComponent implements OnDestroy {

    DEFAULT_BERTH_RESERVATION_UNIT = DEFAULT_BERTH_RESERVATION_UNIT;

    private _unsubscribeAll = new Subject<any>();

    isLoading = true;

    BerthAssignmentType = BookingType;

    berthAssignmentTitle: string;

    formGroupPrepare: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    givenBoats: Boat[];

    captureBoat = true;

    captureTenantRelation = false;

    captureContract = false;

    captureReservedUntil = false;

    sendMail = false;

    isSaving: boolean;

    givenBerth?: Berth;

    berthProducts: Product[] = [];

    givenBerthAssignmentType?: BookingType;

    givenFromDate?: Date;

    givenToDate?: Date;

    prefilledTenantRelationId: number;

    activeTenantRelations: TenantRelationAssignment[] = [];

    givenInvoicePositions: InvoicePosition[] = [];

    boatToLong: number;

    boatToWide: number;

    boatToHeavy: number;
    boatWeight: number;
    berthMaxWeight: number;

    canSave = true;

    durationOfStay: number;
    totalDays: number;
    sameDay: boolean;
    isAfterPeriodStarts: boolean;
    isAfterPeriodEnds: boolean;

    showAssignmentTypeSelect: boolean;

    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    constructor(
        private _dialogRef: MatDialogRef<BerthReservationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            asset?: TimeSchedulerAsset<any, any>,
            item?: TimeSchedulerItem<any, any>,
            preselect?: { [key: string]: any },
            berth?: Berth,
            berthAssignmentType?: BookingType
        },
        private _appNotificationService: AppNotificationService,
        private _confirmDialogService: ConfirmDialogService,
        private _bcmWorkflowApiService: BcmWorkflowApiService,
        private _berthsFilterService: BerthsMapFilterService,
        private _invoicesService: InvoicesService,
        private _matDialog: MatDialog,
        private bcmSettingsFacade: BcmSettingsFacade,
        private _formBuilder: UntypedFormBuilder,
        private _productService: ProductService) {

        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.givenBerth = this.data.berth || this.data.asset?.metaData || null;

        this.berthProducts = this.givenBerth?.products || [];

        this.showAssignmentTypeSelect = !!this.data.item?.id;

        this.givenBerthAssignmentType = this.data.berthAssignmentType || BookingType.Reservation;
        this.berthAssignmentTitle = this.givenBerthAssignmentType === BookingType.Reservation
            ? 'Reservierung'
            : 'Buchung';

        const lastFilterData = this._berthsFilterService.lastBerthFilters[0];
        this.givenFromDate = this.data.item?.startDate || lastFilterData?.start || startOfToday();
        this.givenToDate = this.data.item?.endDate || lastFilterData?.end || endOfToday();

        if (!lastFilterData?.boat?.id) {
            this.isLoading = false;
        }

        this.formGroupPrepare = this._formBuilder.group({
            type: [null, [U2bValidators.required('Bitte wähle eine Option aus.')]]
        });

        this.formGroup = this._formBuilder.group({
            assignmentType: [this.givenBerthAssignmentType],
            note: [null, [U2bValidators.maxLength(1024)]],
            reservedUntil: [null],
            name: [null],
        });

        this.formGroup.get('assignmentType').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: BookingType) => {
                this.givenBerthAssignmentType = value;
                this.berthAssignmentTitle = this.givenBerthAssignmentType === BookingType.Reservation
                    ? 'Reservierung'
                    : 'Buchung';
            });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME * 2),
                map(value => ({
                    tenantRelationForm: value?.tenantRelationForm,
                    boatForm: value?.boatForm,
                    berthForm: value?.berthForm
                })),
                pairwise(),
                filter(([prev, current]) => {
                    return prev.tenantRelationForm?.tenantRelation?.id !== current.tenantRelationForm?.tenantRelation?.id ||
                        prev.boatForm?.boat?.id !== current.boatForm?.boat?.id ||
                        prev.berthForm?.berth?.id !== current.berthForm?.berth?.id;
                    // return JSON.stringify(prev) !== JSON.stringify(current);
                }),
                map(([prev, current]) => current),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((value) => {
                const person = value.boatForm?.boat?.owner;
                const company = value.boatForm?.boat?.ownerCompany;
                const berth = value.berthForm?.berth || this.givenBerth;

                if (berth?.id) {
                    this.berthProducts = (berth?.products || []).map(p => {
                        p.fromBerth = true;
                        return p;
                    });
                } else {
                    this.berthProducts = [];
                }

                if (person?.id) {
                    this.formGroupPrepare.get('type').setValue('person');
                    setTimeout(() => {
                        this.formGroup.get('personForm').get('person').setValue(person);
                        this.isLoading = false;
                    }, 500);
                } else if (company?.id) {
                    this.formGroupPrepare.get('type').setValue('organisation');
                    setTimeout(() => {
                        this.formGroup.get('companyForm').get('company').setValue(company);
                        this.isLoading = false;
                    }, 500);
                }

                this.canSave = !this.captureBoat || (this.captureBoat && this.formGroup.get('boatForm')?.value?.boat?.id);
                this.updatePositions();
            });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(value => {
                const person: Person = value?.personForm?.person;
                const company: Company = value?.companyForm?.company;

                if (person?.id && person?.boats?.length) {
                    this.givenBoats = person.boats;
                } else if (company?.id && company?.boats?.length) {
                    this.givenBoats = company.boats;
                } else {
                    this.givenBoats = undefined;
                }

                if (person?.id) {
                    this.formGroup.patchValue({
                        mail: person.primaryMail,
                        name: person.fullName,
                        contractForm: {mail: person.primaryMail},
                        invoiceForm: {mail: person.primaryMail}
                    }, {emitEvent: false});
                } else if (company?.id) {
                    this.formGroup.patchValue({
                        mail: company.primaryMail,
                        name: company.fullName,
                        contractForm: {mail: company.primaryMail},
                        invoiceForm: {mail: company.primaryMail}
                    }, {emitEvent: false});
                } else {
                    this.formGroup.patchValue({
                        mail: value?.personForm?.mail || value?.companyForm?.mail,
                        name: value?.companyForm?.name
                            || ((value?.personForm?.firstName || '') + ' ' + (value?.personForm?.lastName || '')).trim(),
                    }, {emitEvent: false});
                }

                this.activeTenantRelations =
                    person?.activeTenantRelationAssignments || company?.activeTenantRelationAssignments || [];

                const boatLength = value?.boatForm?.boat?.length || value?.boatForm?.length;
                const boatWidth = value?.boatForm?.boat?.width || value?.boatForm?.width;
                const boatWeight = value?.boatForm?.boat?.weight || value?.boatForm?.weight;

                this.boatToLong = undefined;
                this.boatToWide = undefined;
                this.boatToHeavy = undefined;
                this.berthMaxWeight = undefined;
                this.boatWeight = undefined;

                if (this.givenBerth && (boatLength || boatWidth)) {
                    if (this.givenBerth.length < boatLength) {
                        this.boatToLong = boatLength - this.givenBerth.length;
                    }
                    if (this.givenBerth.width < boatWidth) {
                        this.boatToWide = boatWidth - this.givenBerth.width;
                    }
                    if (this.givenBerth.isBuoy && this.givenBerth.maxCarryingCapacity) {
                        this.berthMaxWeight = this.givenBerth.maxCarryingCapacity * 1000; // t > kg

                        if (this.berthMaxWeight < boatWeight) {
                            this.boatToHeavy = boatWeight - this.berthMaxWeight;
                            this.boatWeight = boatWeight;
                        }
                    }
                }

            });

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map(value => ({
                    dateRangeForm: value?.dateRangeForm
                })),
                pairwise(),
                filter(([prev, current]) => {
                    return prev.dateRangeForm?.from?.getTime() !== current.dateRangeForm?.from?.getTime() ||
                        prev.dateRangeForm?.to?.getTime() !== current.dateRangeForm?.to?.getTime();
                }),
                map(([prev, current]) => current)
            )
            .subscribe((value) => {
                const from: Date = value?.dateRangeForm?.from;
                const to: Date = value?.dateRangeForm?.to;

                this.calculateDurationOfStay(from, to);
            });

        setTimeout(() => {
            const boat = data?.preselect?.boat || lastFilterData?.boat;
            this.formGroup.get('boatForm')?.patchValue({boat});
            this.updatePositions();
            if (lastFilterData?.boat?.owner === null && lastFilterData?.boat?.ownerCompany === null) {
                this.isLoading = false;
            }
        }, 1000);

        this.bcmSettingsFacade.loadSettings().subscribe((settings) => {
            this.prefilledTenantRelationId = settings[BcmSettingsSectionName.DefaultUnits]?.dayGuestBoater;

            this.defaultBerthReservationTimeUnit = settings[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
            this.calculateDurationOfStay(this.givenFromDate, this.givenToDate);

        });
    }

    private calculateDurationOfStay(from: Date, to: Date) {
        if (from !== null && to !== null && !!this.defaultBerthReservationTimeUnit) {

            if (this.defaultBerthReservationTimeUnit?.fromHours !== null && this.defaultBerthReservationTimeUnit?.fromMinutes !== null) {
                this.isAfterPeriodStarts = this.defaultBerthReservationTimeUnit.fromHours < from.getHours() ||
                    (this.defaultBerthReservationTimeUnit.fromHours === from.getHours() && this.defaultBerthReservationTimeUnit.fromMinutes <= from.getMinutes());

            }
            if (this.defaultBerthReservationTimeUnit?.toHours !== null && this.defaultBerthReservationTimeUnit?.toMinutes !== null) {
                this.isAfterPeriodEnds = this.defaultBerthReservationTimeUnit.toHours < to.getHours() ||
                    (this.defaultBerthReservationTimeUnit.toHours === to.getHours() && this.defaultBerthReservationTimeUnit.toMinutes < from.getMinutes());

            }

            this.sameDay = isSameDay(from, to);

            const fromWithoutTime = new Date(from.getFullYear(), from.getMonth(), from.getDate());
            const toWithoutTime = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            this.totalDays = Math.max(1, Math.floor(Math.abs((toWithoutTime.getTime() - fromWithoutTime.getTime()) / (1000 * 60 * 60 * 24))));

            if (this.sameDay && this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY) {
                this.durationOfStay = 1;
            } else if (!this.sameDay && this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY) {
                this.durationOfStay = this.totalDays + 1;
            } else if (this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY) {
                this.durationOfStay = (this.totalDays) + (this.isAfterPeriodStarts ? 0 : 1) + ((!this.sameDay && this.isAfterPeriodEnds) ? 1 : 0);
            }

            this.updatePositions();

        } else {
            this.durationOfStay = -1;
        }
    }

    updatePositions(): void {
        this.givenInvoicePositions = [];

        const tenantRelation = this.formGroup.get('tenantRelationForm')?.value?.tenantRelation;
        const boat = this.formGroup.get('boatForm')?.value?.boat;

        if (tenantRelation?.products?.length || this.berthProducts?.length) {

            const berth = this.givenBerth;

            const parameters = {boat, berth, tenantRelation};

            const tenantRelationProducts = (tenantRelation?.products || [])
                .filter((p: Product) => !!p)
                .map((p: Product) => ({
                    ...p,
                    fromTenantRelation: true,
                    fromBerth: false
                }));

            const berthProducts = (this.berthProducts || [])
                .filter((p: Product) => !!p)
                .map((p: Product) => ({
                    ...p,
                    fromTenantRelation: false,
                    fromBerth: true
                }));

            const positionsWithDynamicPrice$ = [
                ...tenantRelationProducts,
                ...berthProducts
            ].map(product => {

                if (this.durationOfStay > 0 &&
                    (product.unit?.uniqueName === UnitUniqueName.OVERNIGHT_STAY ||
                        product.unit?.uniqueName === UnitUniqueName.DAY)) {
                    product.quantity = this.durationOfStay;
                }

                if (product.hasDynamicPrice) {
                    return this._productService.evaluatePriceRule(product, parameters, product.quantity)
                        .pipe(
                            take(1),
                            map(dynamicPrice => {
                                dynamicPrice = {
                                    ...dynamicPrice,
                                    boat: boat,
                                    berth: berth,
                                    tenantRelation: tenantRelation
                                };

                                return {
                                    product,
                                    quantity: product.quantity,
                                    account: product.account,
                                    title: dynamicPrice.ruleName,
                                    unit: product.unit,
                                    price: dynamicPrice.rulePrice,
                                    taxRate: product.taxRate,
                                    dynamicPrice,
                                    fromTenantRelation: product.fromTenantRelation,
                                    fromBerth: product.fromBerth,
                                    vestingPeriodFromDate: this.givenFromDate,
                                    vestingPeriodUntilDate: this.givenToDate,
                                } as InvoicePosition;
                            })
                        );
                } else {
                    return of({
                        product,
                        quantity: product.quantity,
                        account: product.account,
                        title: product.name,
                        unit: product.unit,
                        price: product.price,
                        taxRate: product.taxRate,
                        fromTenantRelation: product.fromTenantRelation,
                        fromBerth: product.fromBerth,
                        vestingPeriodFromDate: this.givenFromDate,
                        vestingPeriodUntilDate: this.givenToDate,
                    } as InvoicePosition);
                }
            });

            forkJoin(positionsWithDynamicPrice$)
                .pipe(take(1))
                .subscribe(positions => {
                    // Nach Absprache mit Sandra werden Positionen erst nach dem Absenden des Formulars gefiltert.
                    this.givenInvoicePositions = positions
                        .map(product => new InvoicePosition(product));
                });

        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickClose(): void {

        if (this.formGroupPrepare.dirty || this.formGroup.dirty) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    text: 'Ja, beenden'
                })
                .setNoButton({
                    text: 'Abbrechen'
                })
                .openWithCallback(() => this._dialogRef.close());
        }

        this._dialogRef.close();
    }

    async onClickSaveAndClose(): Promise<void> {

        if (this.formGroup.get('invoiceForm').get('payByCash')?.value && !this.formGroup.get('invoiceForm').get('checkPayByCash')?.value) {
            this._appNotificationService.showError('Barzahlung nicht möglich.');
        } else if (this.formGroup.invalid || this.formGroupPrepare.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroupPrepare.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const {
                dateRangeForm,
                berthForm,
                personForm,
                companyForm,
                tenantRelationForm,
                boatForm,
                contractForm,
                contractCoOwnerForm,
                invoiceForm,
                note,
                reservedUntil,
                mail,
                // reservationText,
            } = this.formGroup.value;

            const body: any = {
                note,
                // reservationText,
                reservedUntil,
                isReservation: this.givenBerthAssignmentType === BookingType.Reservation,
                fromDate: dateRangeForm.from,
                toDate: dateRangeForm.to,
                sendMail: this.sendMail,
                mail
            };

            const hasTenantRelationProducts = tenantRelationForm?.tenantRelation?.products?.length;
            const hasInvoicePositions = invoiceForm?.positions?.length;
            const payByCash = invoiceForm?.payByCash;

            if (!dateRangeForm.to && (hasTenantRelationProducts || hasInvoicePositions)) {
                const result = await this._confirmDialogService
                    .setTitle('Abo hinzufügen')
                    .setBody('Es wurde kein Enddatum erfasst. Möchtest Du für die ausgewählten Produkte ein Abo erzeugen?')
                    .useWarnTheme()
                    .openAndReturnResult()
                    .toPromise();

                if (result) {
                    const subscriptionForm = await new Promise(resolve => {
                        const aboDialogRef = this._matDialog.open(
                            GetProductSubscriptionDatesDialogComponent,
                            {
                                data: {
                                    initialFromDate: dateRangeForm.from,
                                    initialPayableOption: tenantRelationForm?.tenantRelation?.payableOption,
                                }
                            }
                        );

                        aboDialogRef.afterClosed().subscribe(data => {
                            resolve(data);
                        });
                    });

                    if (subscriptionForm) {
                        body['subscription'] = subscriptionForm;
                    }
                }
            }

            if (berthForm) {
                body['berth'] = {
                    id: berthForm.berth.id,
                    note: berthForm.note
                };
            } else if (this.givenBerth) {
                body['berth'] = {
                    id: this.givenBerth.id,
                };
            }

            if (personForm) {
                let person: any = {};

                if (personForm.person && personForm.person.id) {
                    person.id = personForm.person.id;
                } else {
                    delete personForm.person;
                    person = {...personForm};
                }

                body['person'] = person;
            }

            if (companyForm) {
                let company: any = {};

                if (companyForm.company && companyForm.company.id) {
                    company.id = companyForm.company.id;
                } else {
                    delete companyForm.company;
                    company = {...companyForm};
                }

                body['company'] = company;
            }

            if (tenantRelationForm?.tenantRelation?.id) {
                body['tenantRelation'] = tenantRelationForm?.tenantRelation;

                if (tenantRelationForm?.tenantRelation?.products) {
                    body['products'] = tenantRelationForm.tenantRelation.products;
                }
            }

            if (boatForm) {
                let boat: any = {};

                if (boatForm.boat && boatForm.boat.id) {
                    boat.id = boatForm.boat.id;
                    boat.isOwner = boatForm.isOwner;
                } else {
                    delete boatForm.boat;
                    boat = {...boatForm};
                }

                body['boat'] = boat;
            }

            if (contractForm) {
                body['contract'] = contractForm;
                body['contractFileType'] = ContractFileType[contractForm.contractFileType];

                if (contractForm.contract?.isWordTemplate && !contractForm.contract?.templateMailFile && !contractForm.contract?.templatePrintFile) {
                    this._appNotificationService.showError('Der Vertrag kann nicht erstellt werden, da kein Word Dokument hinterlegt wurde.');
                    this.isSaving = false;
                    return;
                }
            }

            if (contractCoOwnerForm) {
                body['contractCoOwner'] = contractCoOwnerForm;
            }

            if (invoiceForm) {
                body['invoice'] = invoiceForm;
            }

            // todo find reason for this bug
            body?.invoice?.positions?.map(position => {
                if (position.dynamicPrice?.boat?.owner?.boats?.length > 0) {
                    position.dynamicPrice.boat.owner.boats = position.dynamicPrice.boat.owner.boats.map(boat => {
                        return {...boat, owner: null, ownerCompany: null};
                    });
                }
                return position;
            });

            // Nach Absprache mit Sandra, filtern wir erst hier die Positionen, die über den Liegeplatz kommen.
            if (body?.invoice?.positions?.length) {
                body.invoice.positions = body.invoice.positions
                    .filter(position => {
                        return !(position.fromBerth
                            && !!position.product?.ignoreFreeOfChargePosition
                            && ((position.price ?? 0) * (position.quantity ?? 0)) === 0);
                    });
            }

            this._bcmWorkflowApiService
                .berthReservation(body)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(async (result: NewBerthBoatAssignment) => {
                    let showError = false;
                    if (invoiceForm?.sendToPrinter) {
                        if (result?.invoice) {
                            const pdfBlob = await this._invoicesService.getPdfFile(result.invoice).toPromise();

                            if (pdfBlob && pdfBlob.size) {
                                const documentTitle = `Rechnung ${result?.invoice.invoiceNumber} - ${(result?.invoice.person.fullName || result?.invoice.company.name)}.pdf`;

                                print({
                                    printable: URL.createObjectURL(pdfBlob),
                                    documentTitle,
                                    onError: console.error
                                });
                            } else {
                                showError = true;
                            }
                        } else {
                            showError = true;
                        }

                        if (showError) {
                            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

                            confirmDialogRef.componentInstance.dialogTitle = `Drucken der Rechnung nicht möglich`;
                            confirmDialogRef.componentInstance.dialogBody = `Für diese Rechnung wurde kein druckbares PDF gefunden.`;
                        }
                    }

                    if (payByCash) {
                        this._matDialog.open(PayByCashDialogComponent, {
                            data: {
                                positions: result.positions,
                                person: result.booking.person,
                                company: result.booking.company
                            },
                            disableClose: true
                        });
                    }

                    this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');
                    this._dialogRef.close(result);
                })
                .add(() => this.isSaving = false);
        }
    }

    captureTenantRelationChange(event: any): void {
        this.captureTenantRelation = event.checked;
        if (!this.captureTenantRelation) {
            this.givenInvoicePositions = [];
            this.formGroup.get('tenantRelationForm')?.reset();
        }
    }

    captureBoatChange(event: any): void {
        this.captureBoat = event.checked;
        if (!this.captureBoat) {
            this.formGroup.get('boatForm')?.reset();
        }
    }

}
