import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { startOfToday } from 'date-fns';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-date-range',
    templateUrl: './form-widget-date-range.component.html',
    styles: [`
        :host [matsuffix].mat-button-disabled {
            display: none;
        }
    `]
})
export class FormWidgetDateRangeComponent implements OnInit, OnChanges {

    @Input()
    headline = 'Zeitraum wählen';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    hideHourMinFields = false;

    @Input()
    from: Date;

    @Input()
    fromLabel = 'von';

    @Input()
    to: Date;

    @Input()
    toLabel = 'bis';

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    dateRageFormGroup: UntypedFormGroup;

    today = startOfToday();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _formGroupDirective: FormGroupDirective) {
    }

    ngOnInit(): void {
        this._createForm();
        if (this._formGroupDirective?.form && !this.parentFormGroup) {
            this.parentFormGroup = this._formGroupDirective.form;
        }
        setTimeout(() => {
            this.parentFormGroup.addControl('dateRangeForm', this.dateRageFormGroup);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.dateRageFormGroup && (this.from || this.to)) {
            this.dateRageFormGroup.patchValue({
                from: this.from,
                to: this.to
            });
        }
    }

    resetControl(control: AbstractControl): void {
        control?.reset();
    }

    private _createForm(): void {
        this.dateRageFormGroup = this._formBuilder.group({
                from: [this.from || this.today, [U2bValidators.required('Wann beginnt der Zeitraum?')]],
                to: [this.to || null],
            },
            {
                validators: [
                    (formGroup: UntypedFormGroup) => {

                        const fromDate = formGroup.get('from').value;
                        const toDate = formGroup.get('to').value;

                        if (!fromDate || !toDate) {
                            return null;
                        }

                        if (fromDate > toDate) {
                            formGroup.get('from').setErrors({
                                wrongDateRange: {
                                    message: `Das "${this.fromLabel}" Datum darf nicht hinter dem "${this.toLabel}" Datum liegen`
                                }
                            });
                            formGroup.get('to').setErrors({
                                wrongDateRange: {
                                    message: `Das "${this.toLabel}" Datum darf nicht vor dem "${this.fromLabel}" Datum liegen`
                                }
                            });
                        } else {
                            formGroup.get('from').setErrors(null);
                            formGroup.get('to').setErrors(null);
                        }
                    }
                ],
            });
    }
}
