import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from '@core/state-management/base-api.service';
import { environment } from '@env/environment';
import { BcmService } from '@modules/bcm/bcm.service';
import { Page } from '@shared/models/pagination';

export abstract class BcmBaseApiService<Entity, RawEntity> extends BaseApiService<Entity, RawEntity> {

    private _disableSyncForNextRequest = false;

    protected endpoint = environment.endpoints.bcm.api;

    protected constructor(
        protected http: HttpClient,
        protected bcmService: BcmService,
    ) {
        super(http);
    }

    /**
     * When sync is disabled, the tenant id will not be append to the api endpoint.
     */
    protected disableSyncForNextRequest(): void {
        this._disableSyncForNextRequest = true;
    }

    protected getList(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity[]> {
        this._syncEndpoint();
        return super.getList(path, params);
    }

    protected getPage(path: string,
                      // pageRequest?: PageRequest<RawEntity>,
                      params: HttpParams = new HttpParams()): Observable<Page<RawEntity>> {
        this._syncEndpoint();
        return super.getPage(path, params);
    }

    protected get(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        this._syncEndpoint();
        return super.get(path, params);
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
        this._syncEndpoint();
        return super.getBlob(path, params);
    }

    protected getBlobWithProgress(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {
        this._syncEndpoint();
        return super.getBlobWithProgress(path, params);
    }

    protected post(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        this._syncEndpoint();
        return super.post(path, body, params);
    }

    protected put(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        this._syncEndpoint();
        return super.put(path, body, params);
    }

    protected delete(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        this._syncEndpoint();
        return super.delete(path, params);
    }

    private _syncEndpoint(): void {
        if (!this._disableSyncForNextRequest && this.bcmService.tenant) {
            this.endpoint = `${environment.endpoints.bcm.api}${this.bcmService.tenant.id}/`;
        } else {
            this.endpoint = environment.endpoints.bcm.api;
        }

        this._disableSyncForNextRequest = false;
    }
}
