import { FilterCondition } from '@core/datafilter/filter-condition';
import { isArray } from '@shared/functions/is-array';

const notRegexp = /^(!|not )/i;
const operatorList = [];

/**
 * Class providing simple filtering for collection of objects
 * @constructor
 * @param {Array} [conditions] A collection of conditions (ie: [['age', 'less than', 30], ['age', 'greater than', 20]])
 */
export class DataFilter<Entity> {

    conditions: FilterCondition[] = [];

    operators = new Operators();

    WHITELIST = true;

    constructor(conditions: FilterCondition[] = []) {
        if (isArray(conditions)) {
            for (const condition of conditions) {
                this.add(condition);
            }
        }
    }

    /**
     * Add a condition to the filter
     * @param {FilterCondition} condition
     * @returns {DataFilter}
     */
    add(condition: FilterCondition): DataFilter<Entity> {
        this.conditions.push(condition);
        return this;
    }

    /**
     * Remove from the filter any conditions matching the arguments
     * @param {FilterCondition} conditionToRemove
     * @returns {DataFilter}
     */
    remove({id}: FilterCondition): DataFilter<Entity> {
        const index = this.conditions.findIndex(condition => condition.id === id);
        this.conditions.splice(index, 1);
        return this;
    }

    /**
     * Remove all the conditions from the filter
     * @returns {DataFilter}
     */
    clear(): DataFilter<Entity> {
        this.conditions = [];
        return this;
    }

    /**
     * Obtain the value of an element (object or other) for the given field name or path
     * @param {*} element Element (object or other)
     * @param {string} field Field name or path of the value (ie : 'id', 'user.screenname', ...)
     * @returns {*} Field value
     * @protected
     */
    evaluateFieldValue(element, field): unknown {
        const fieldPath = (field === null || field === '' || field === undefined) ? [] : String(field).split('.');
        let value = element;

        // console.log(fieldPath);

        for (let i = 0; i < fieldPath.length && value !== null && value !== undefined; i++) {
            value = value[fieldPath[i]];
        }

        return value;
    }

    /**
     * Check whether an atomic condition is true
     * @param {*} sourceValue Value to test
     * @param {string|function} operator Operator
     * @param {*} conditionValue Value to compare with / filter on
     * @param {*} compareAttribute Value to compare with / filter on
     * @returns {boolean}
     * @protected
     */
    evaluatePartialExpression(sourceValue, operator, conditionValue, compareAttribute): boolean {
        let result = false;

        if (typeof operator === 'function') {
            result = !!(operator(sourceValue, conditionValue, compareAttribute));

        } else if (operatorList.hasOwnProperty(operator)) {
            result = !!(operatorList[operator](sourceValue, conditionValue, compareAttribute));
        }

        return result;
    }

    /**
     * Check whether a conditions is true
     * @param {*} sourceValue Value to test
     * @param {string|function} operator Operator
     * @param {*} conditionValues Value to compare with / filter on (may be an array of values)
     * @returns {boolean}
     * @protected
     */
    evaluateExpression(sourceValue, operator, conditionValues, compareAttribute): boolean {
        let result = false;
        let operatorPolarity = true;

        if (typeof operator !== 'function') {
            operator = String(operator).trim();

            if (notRegexp.test(operator)) {
                operatorPolarity = false;
                operator = operator.replace(notRegexp, '');
            }
        }

        if (!isArray(conditionValues)) {
            conditionValues = [conditionValues];
        }

        for (const conditionValue of conditionValues) {
            result = this.evaluatePartialExpression(sourceValue, operator, conditionValue, compareAttribute);

            if (result) {
                break;
            }
        }

        return operatorPolarity ? result : !result;
    }

    /**
     * Apply the conditions of the filter on a single object and returns whether the element passes all the conditions
     * @param {Object} element Object to test
     * @returns {boolean} Whether the object passes all the conditions
     */
    test(element): boolean {
        let result = true;

        for (const condition of this.conditions) {
            const sourceValue = this.evaluateFieldValue(element, condition.field);

            result = this.evaluateExpression(sourceValue, condition.operator, condition.value, condition.compareAttribute);

            if (!result) {
                break;
            }
        }

        return result;
    }

    /**
     * Apply the conditions of the filter on a collection of objects and returns all the matching elements as an array
     * @param {Array} elements A collection of objects to filter
     * @param {boolean} [polarity=true] True for whitelisting, false for blacklisting
     * @returns {Array}
     */
    match(elements, polarity?): Entity[] {
        const filtered: Entity[] = [];
        const filterPolarity = !!(polarity === undefined ? this.WHITELIST : polarity);

        // todo: add possibility to user logical and/or like in the back end filtering?
        //  example: A or B and C or D => (A || B) && (C || D)
        if (isArray(elements)) {
            for (const element of elements) {
                if (this.test(element) === filterPolarity) {
                    filtered.push(element);
                }
            }
        }

        return filtered;
    }

    /**
     * Apply the conditions of the filter on a collection of objects and returns the first matching element, if any
     * @param {Array} elements A collection of object to filter
     * @param {boolean} [polarity=true] True for whitelisting, false for blacklisting
     * @returns {Object|null}
     */
    first(elements, polarity): Entity | null {
        let first = null;
        const filterPolarity = !!(polarity === undefined ? this.WHITELIST : polarity);

        if (isArray(elements)) {
            for (let i = 0; i < elements.length && first === null; i++) {
                const element = elements[i];
                if (this.test(element) === filterPolarity) {
                    first = element;
                }
            }
        }

        return first;
    }

    // /**
    //  * Shorthand syntax to filter a collection of objects
    //  * @param {Array} elements A collection of objects to filter
    //  * @param {Array} conditions A collection of conditions (ie: [['age', 'less than', 30], ['age', 'greater than', 20]])
    //  * @param {boolean} [polarity=true] True for whitelisting, false for blacklisting
    //  * @returns {Array} Matching elements
    //  */
    // filter(elements, conditions, polarity): any[] {
    //     const filter = new DataFilter(conditions);
    //
    //     return filter.match(elements, polarity);
    // }

}

export class Operators {

    /**
     * Add an operator to the global operator list
     * @param {string} name Name of the operator (must not match the negation pattern or already be in use)
     * @param {function} evaluationFunction Evaluation function comparing the field value (its first argument) and
     *        the filter value (its second argument)
     * @returns {boolean} Whether the operation succeed
     */
    add(name, evaluationFunction): boolean {
        let result = false;

        name = name.trim();

        if (typeof evaluationFunction === 'function' /*&& !notRegexp.test(name)*/ && !operatorList.hasOwnProperty(name)) {
            operatorList[name] = evaluationFunction;
            result = true;
        }

        return result;
    }

    /**
     * Create an alias for an existing operator (by effectively creating a copy of the operator).
     * @param {string} name Name of the existing operator
     * @param {string} alias Name of the alias (must not match the negation pattern or already be in use)
     * @returns {boolean} Whether the operation succeed
     */
    alias(name, alias): boolean {
        let result = false;

        name = name.trim();
        alias = alias.trim();

        if (operatorList.hasOwnProperty(name) && !notRegexp.test(alias) && !operatorList.hasOwnProperty(alias)) {
            operatorList[alias] = operatorList[name];
            result = true;
        }

        return result;
    }
}
