import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { ListItemType, U2bNavigationItem } from './navigation.shared';
import { BcmVersions } from '@modules/bcm/bcm-versions';
import { TranslationService } from '@core/translation/translation.service';

export interface BcmNavigationItem extends U2bNavigationItem {
    permission?: string;
    tenantPermission?: BcmTenantPermission;
    children?: BcmNavigationItem[];
    type: ListItemType;
    isText?: boolean;
    forceShowing?: boolean;
    notEditable?: boolean;
    availableFor?: BcmVersions[];
}

enum BcmNavigationElementKey {
    Dashboard = 'dashboard',
    Persons = 'persons',
    Companies = 'companies',
    Relations = 'relations',
    Products = 'products',
    ProductCategories = 'product_categories',
    Vouchers = 'vouchers',
    Boats = 'boats',
    Piers = 'piers',
    Berths = 'berths',
    BerthsTable = 'berths_table',
    BerthsScheduler = 'berths_scheduler',
    WinterStorages = 'winter_storages',
    DocksiteOrder = 'docksite_order',
    ElectricMeters = 'electric_meters',
    Keys = 'keys',
    ExcelReports = 'excel_reports',
    AssociationReports = 'association_reports',
    NewInvoice = 'new_invoice',
    Invoices = 'invoices',
    InvoiceCollections = 'invoice_collections',
    InvoiceSettings = 'invoice_settings',
    InvoiceDefaultTemplates = 'invoice_default_templates',
    InvoiceWdTemplates = 'invoice_wd_templates',
    DATEV = 'datev_export',
    SEPA = 'sepa',
    POS = 'pos',
    NewMessage = 'new_message',
    SentMessage = 'sent_message',
    Users = 'users',
    Settings = 'settings',
    UserCustomSettings = 'user_custom_settings',
    RolesAndPermissions = 'roles_and_permissions',
    Contracts = 'contracts',
    ContractAttachments = 'contract_attachments',
    ContractsWd = 'contracts_wd',
    RecipientLists = 'recipient_lists',
    EmailTemplates = 'email_templates',
    EmailMailings = 'email_mailings',
    Seasons = 'seasons',
    ImportData = 'import',
    Documents = 'documents_list',
    DocumentCategories = 'document_categories',
    News = 'news',
}

export const navigationElements: { [key in BcmNavigationElementKey]: BcmNavigationItem } = {
    [BcmNavigationElementKey.Dashboard]: {
        id: 'my_harbour',
        title: 'Mein Hafen',
        type: ListItemType.Item,
        icon: 'dashboard',
        url: 'dashboards',
    },
    [BcmNavigationElementKey.Persons]: {
        id: 'persons',
        title: 'Personen',
        type: ListItemType.Item,
        url: 'persons',
        permission: BcmUserPermission.PERSONS_READ,
        tenantPermission: BcmTenantPermission.PERSONS
    },
    [BcmNavigationElementKey.Companies]: {
        id: 'companies',
        title: 'Organisationen',
        type: ListItemType.Item,
        url: 'companies',
        permission: BcmUserPermission.COMPANIES_READ,
        tenantPermission: BcmTenantPermission.ORGANISATIONS
    },
    [BcmNavigationElementKey.Relations]: {
        id: 'relations',
        title: 'Beziehungen',
        type: ListItemType.Item,
        url: 'tenant-relations',
        permission: BcmUserPermission.TENANT_RELATIONS_READ,
        tenantPermission: BcmTenantPermission.TENANT_RELATIONS
    },
    [BcmNavigationElementKey.Products]: {
        id: 'products',
        title: 'Produkte',
        type: ListItemType.Item,
        url: 'products',
        permission: BcmUserPermission.PRODUCTS_READ,
        tenantPermission: BcmTenantPermission.PRODUCTS
    },
    [BcmNavigationElementKey.ProductCategories]: {
        id: 'product_categories',
        title: 'Warengruppen',
        type: ListItemType.Item,
        url: 'product-categories',
        permission: BcmUserPermission.PRODUCT_CATEGORIES_READ,
        tenantPermission: BcmTenantPermission.PRODUCTS
    },
    [BcmNavigationElementKey.Vouchers]: {
        id: 'vouchers',
        title: 'Gutscheine',
        type: ListItemType.Item,
        url: 'vouchers',
        permission: BcmUserPermission.PRODUCTS_READ,
        tenantPermission: BcmTenantPermission.PRODUCTS,
    },
    [BcmNavigationElementKey.News]: {
        id: 'news',
        title: 'News & Infos',
        type: ListItemType.Item,
        url: 'news',
        permission: BcmUserPermission.NEWS_READ,
        tenantPermission: BcmTenantPermission.NEWS
    },
    [BcmNavigationElementKey.Boats]: {
        id: 'boats',
        title: TranslationService.translate('boats', undefined, 'Boote'),
        type: ListItemType.Item,
        url: 'boats',
        permission: BcmUserPermission.BOATS_READ,
        tenantPermission: BcmTenantPermission.BOATS
    },
    [BcmNavigationElementKey.Piers]: {
        id: 'piers',
        title: 'Stege',
        type: ListItemType.Item,
        url: 'piers',
        permission: BcmUserPermission.BERTHS_READ,
        tenantPermission: BcmTenantPermission.BERTH
    },
    [BcmNavigationElementKey.Berths]: {
        id: 'berths',
        title: 'Liegeplan grafisch',
        type: ListItemType.Item,
        url: 'berths/map',
        permission: BcmUserPermission.BERTHS_READ,
        tenantPermission: BcmTenantPermission.BERTH
    },
    [BcmNavigationElementKey.BerthsTable]: {
        id: 'berths-table',
        title: 'Liegeplan tabellarisch',
        type: ListItemType.Item,
        url: 'berths/list',
        permission: BcmUserPermission.BERTHS_READ,
        tenantPermission: BcmTenantPermission.BERTH
    },
    [BcmNavigationElementKey.BerthsScheduler]: {
        id: 'berths-scheduler',
        title: 'Buchungskalender',
        type: ListItemType.Item,
        url: 'berths/scheduler',
        permission: BcmUserPermission.BERTHS_READ,
        tenantPermission: BcmTenantPermission.BERTH
    },
    [BcmNavigationElementKey.WinterStorages]: {
        id: 'winter_storages',
        title: 'Lagerplätze',
        type: ListItemType.Item,
        url: 'winter-storages',
        permission: BcmUserPermission.WINTER_STORAGES_READ,
        tenantPermission: BcmTenantPermission.WINTER_STORAGES
    },
    [BcmNavigationElementKey.DocksiteOrder]: {
        id: 'docksite_order',
        title: 'Arbeitsaufträge',
        type: ListItemType.Item,
        url: 'orders',
        permission: BcmUserPermission.ORDERS_READ,
        tenantPermission: BcmTenantPermission.DOCKSITE,
    },
    // {
    //     id: 'Liegeplatz Manager',
    //     title: 'Liegeplatz Manager',
    //     type: ListItemType.Item,
    //     url: 'berth-scheduler',
    //     permission: BcmUserPermission.BERTHS_READ,
    //     tenantPermission: BcmTenantPermission.BERTH_SCHEDULER
    // },
    // {
    //     id: 'Liegeplatzvergabe',
    //     title: 'Liegeplatzvergabe',
    //     type: ListItemType.Item,
    //     function: () => {}, // todo: fire event which calls berth allocation workflow...
    //     permission: BcmUserPermission.BERTHS_READ,
    //     tenantPermission: BcmTenantPermission.BERTH_SCHEDULER
    // },
    [BcmNavigationElementKey.ElectricMeters]: {
        id: 'electric_meters',
        title: 'Stromzähler',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'electric-meters',
        permission: BcmUserPermission.ELECTRIC_METERS_READ,
        tenantPermission: BcmTenantPermission.ELECTRIC_METERS
    },
    [BcmNavigationElementKey.Keys]: {
        id: 'keys',
        title: 'Schlüssel',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'keys',
        permission: BcmUserPermission.KEYS_READ,
        tenantPermission: BcmTenantPermission.KEYS
    },
    [BcmNavigationElementKey.ExcelReports]: {
        id: 'excel_reports',
        title: 'Excel',
        type: ListItemType.Item,
        url: 'reports/default',
        permission: BcmUserPermission.DOWNLOAD_REPORTS,
        tenantPermission: BcmTenantPermission.REPORTS,
    },
    [BcmNavigationElementKey.AssociationReports]: {
        id: 'association_reports',
        title: 'Verbandsmeldungen',
        type: ListItemType.Item,
        url: 'reports/association',
        permission: BcmUserPermission.ASSOCIATION_REPORTS,
        tenantPermission: BcmTenantPermission.ASSOCIATION_REPORTS,
    },
    [BcmNavigationElementKey.NewInvoice]: {
        id: 'new_invoice',
        title: 'Neuer Beleg',
        type: ListItemType.Item,
        url: 'accounting/invoices/new',
        permission: BcmUserPermission.INVOICES_WRITE,
        tenantPermission: BcmTenantPermission.INVOICES,
        exactMatch: true
    },
    [BcmNavigationElementKey.Invoices]: {
        id: 'invoices',
        title: 'Alle Belege',
        type: ListItemType.Item,
        url: 'accounting/invoices',
        permission: BcmUserPermission.INVOICES_READ,
        tenantPermission: BcmTenantPermission.INVOICES,
        exactMatch: true
    },
    [BcmNavigationElementKey.InvoiceCollections]: {
        id: 'invoice_collections',
        title: 'Sammelrechnung',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'accounting/invoice-collections',
        permission: BcmUserPermission.INVOICE_COLLECTIONS_READ,
        tenantPermission: BcmTenantPermission.INVOICE_COLLECTION
    },
    // {
    //     id: 'Gutschriften',
    //     title: 'Gutschriften',
    //     type: ListItemType.Item,
    //     url: '#'
    // },
    // {
    //     id: 'Mahnungen',
    //     title: 'Mahnungen',
    //     type: ListItemType.Item,
    //     url: '#'
    // },
    // {
    //     id: 'Preislisten',
    //     title: 'Preislisten',
    //     type: ListItemType.Item,
    //     url: 'accounting-price-lists'
    // }
    [BcmNavigationElementKey.InvoiceSettings]: {
        id: 'invoice_settings',
        title: 'Beleglayout',
        type: ListItemType.Item,
        url: 'accounting/invoice-settings',
        permission: BcmUserPermission.INVOICES_READ,
        tenantPermission: BcmTenantPermission.INVOICES
    },
    [BcmNavigationElementKey.InvoiceDefaultTemplates]: {
        id: 'invoice-default-templates',
        title: 'Standardvorlagen',
        type: ListItemType.Item,
        url: 'accounting/invoice-templates/default',
        permission: BcmUserPermission.INVOICES_READ,
        tenantPermission: BcmTenantPermission.INVOICES
    },
    [BcmNavigationElementKey.InvoiceWdTemplates]: {
        id: 'invoice-wd-templates',
        title: 'Belegvorlagen (Word)',
        type: ListItemType.Item,
        url: 'accounting/invoice-templates/word',
        permission: BcmUserPermission.INVOICES_READ,
        tenantPermission: BcmTenantPermission.INVOICES
    },
    [BcmNavigationElementKey.DATEV]: {
        id: 'datev_export',
        title: 'DATEV Export',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'accounting/invoices/datev-export',
        permission: BcmUserPermission.INVOICES_READ,
        tenantPermission: BcmTenantPermission.DATEV
    },
    [BcmNavigationElementKey.SEPA]: {
        id: 'sepa',
        title: 'SEPA-Lastschriften',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'accounting/sepa-runs',
        permission: BcmUserPermission.SEPA_RUN_READ,
        tenantPermission: BcmTenantPermission.SEPA
    },
    [BcmNavigationElementKey.POS]: {
        id: 'pos',
        title: 'Kassen',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'accounting/cash-registers',
        permission: BcmUserPermission.CASH_REGISTER_READ,
        tenantPermission: BcmTenantPermission.CASH
    },
    [BcmNavigationElementKey.NewMessage]: {
        id: 'new_message',
        title: 'Neue E-Mail',
        type: ListItemType.Item,
        url: 'messages/mails/new',
        permission: BcmUserPermission.MAIL_MESSAGES_WRITE,
        exactMatch: true
    },
    [BcmNavigationElementKey.SentMessage]: {
        id: 'sent_message',
        title: 'Versendete E-Mails',
        type: ListItemType.Item,
        url: 'messages/mails',
        permission: BcmUserPermission.MAIL_MESSAGES_READ,
        exactMatch: true
    },
    [BcmNavigationElementKey.Users]: {
        id: 'users',
        title: 'Benutzer',
        type: ListItemType.Item,
        icon: 'people',
        url: 'users',
        permission: BcmUserPermission.FULL_GRANT
    },
    [BcmNavigationElementKey.RolesAndPermissions]: {
        id: 'roles_and_permissions',
        title: 'Rollen & Rechte',
        type: ListItemType.Item,
        icon: 'security',
        url: 'roles-and-permissions',
        permission: BcmUserPermission.FULL_GRANT,
        tenantPermission: BcmTenantPermission.ROLES_PERMISSIONS
    },
    [BcmNavigationElementKey.ImportData]: {
        id: 'data-import',
        title: 'Datenimport',
        type: ListItemType.Item,
        icon: 'input',
        url: 'import',
        permission: BcmUserPermission.IMPORTDATA_READ,
    },
    [BcmNavigationElementKey.Contracts]: {
        id: 'contracts',
        title: 'Vertragsvorlagen',
        type: ListItemType.Item,
        url: 'contract-manager/wysiwyg',
        permission: BcmUserPermission.CONTRACTS_READ
    },
    [BcmNavigationElementKey.ContractsWd]: {
        id: 'contracts-wd',
        title: 'Vertragsvorlagen Word',
        type: ListItemType.Item,
        url: 'contract-manager/word',
        permission: BcmUserPermission.CONTRACTS_READ
    },
    [BcmNavigationElementKey.ContractAttachments]: {
        id: 'contract_attachments',
        title: 'Vertragsanhänge',
        type: ListItemType.Item,
        url: 'contract-manager/attachments',
        permission: BcmUserPermission.CONTRACTS_READ
    },
    [BcmNavigationElementKey.RecipientLists]: {
        id: 'recipient_lists',
        title: 'E-Mail Empfängerlisten',
        type: ListItemType.Item,
        url: 'messages/mails/recipient-lists',
        permission: BcmUserPermission.MAIL_MESSAGES_READ,
        tenantPermission: BcmTenantPermission.MESSAGES_EMAIL,
        exactMatch: true
    },
    [BcmNavigationElementKey.EmailTemplates]: {
        id: 'email_templates',
        title: 'E-Mail Vorlagen',
        type: ListItemType.Item,
        url: 'email-templates',
        permission: BcmUserPermission.MAIL_TEMPLATES_READ
    },
    [BcmNavigationElementKey.EmailMailings]: {
        id: 'email_mailings',
        title: 'E-Mail Mailings',
        type: ListItemType.Item,
        url: 'email-mailings',
        permission: BcmUserPermission.MAIL_MAILINGS_READ
    },
    [BcmNavigationElementKey.Seasons]: {
        id: 'seasons',
        title: 'Saisons',
        type: ListItemType.Item,
        classes: 'premium-nav-link',
        url: 'seasons',
        permission: BcmUserPermission.SEASONS_READ,
        tenantPermission: BcmTenantPermission.SEASONS
    },

    [BcmNavigationElementKey.Documents]: {
        id: 'documents_list',
        title: 'Dokumente',
        type: ListItemType.Item,
        url: 'documents/list',
        permission: BcmUserPermission.DOCUMENTS_READ,
        tenantPermission: BcmTenantPermission.DOCUMENTS_MANAGEMENT
    },
    [BcmNavigationElementKey.DocumentCategories]: {
        id: 'documentCategories',
        title: 'Dokumenten-Kategorien',
        type: ListItemType.Item,
        url: 'documents/categories',
        tenantPermission: BcmTenantPermission.DOCUMENTS_MANAGEMENT
    },
    [BcmNavigationElementKey.Settings]: {
        id: 'settings',
        title: 'Hafen Einstellungen',
        type: ListItemType.Item,
        url: 'settings',
        permission: BcmUserPermission.FULL_GRANT,
        notEditable: true,
    },
    [BcmNavigationElementKey.UserCustomSettings]: {
        id: 'user_custom_settings',
        title: 'Meine Anpassungen',
        type: ListItemType.Item,
        url: 'user-settings',
        notEditable: true,
    },
};

export const editableNavigationBcm: BcmNavigationItem[] = [
    navigationElements.dashboard,
    {
        id: 'core_management',
        title: 'Verwaltungsdaten',
        type: ListItemType.Collapsable,
        icon: 'folder_open',
        children: [
            navigationElements.persons,
            navigationElements.companies,
            navigationElements.relations,
            navigationElements.products,
            navigationElements.product_categories,
            navigationElements.vouchers
        ]
    },
    {
        id: 'harbour_management',
        title: 'Hafenverwaltung',
        type: ListItemType.Collapsable,
        icon: 'directions_boat',
        children: [
            navigationElements.boats,
            navigationElements.piers,
            navigationElements.berths,
            navigationElements.berths_table,
            navigationElements.winter_storages,
            navigationElements.docksite_order,
            navigationElements.electric_meters,
            navigationElements.keys,
            navigationElements.seasons,

            {
                id: 'reports',
                title: 'Berichte',
                type: ListItemType.Collapsable,
                children: [
                    navigationElements.excel_reports,
                    navigationElements.association_reports,
                ]
            }
        ]
    },

    {
        id: 'accounting',
        title: 'Finanzverwaltung',
        type: ListItemType.Collapsable,
        icon: 'show_chart',
        children: [
            navigationElements.new_invoice,
            navigationElements.invoices,
            navigationElements.invoice_collections,
            {
                id: 'invoice-templates',
                title: 'Belegvorlagen',
                type: ListItemType.Collapsable,
                tenantPermission: BcmTenantPermission.INVOICES,
                children: [
                    navigationElements.invoice_default_templates,
                    navigationElements.invoice_wd_templates,
                ]
            },
            navigationElements.invoice_settings,
            navigationElements.datev_export,
            navigationElements.sepa,
            navigationElements.pos,
        ]
    },

    {
        id: 'documents',
        title: 'Dokumentenverwaltung',
        type: ListItemType.Collapsable,
        icon: 'description',
        tenantPermission: BcmTenantPermission.DOCUMENTS_MANAGEMENT,
        children: [
            navigationElements.documents_list,
            navigationElements.document_categories,
        ]
    },

    {
        id: 'messages',
        title: 'E-Mailnachrichten',
        type: ListItemType.Collapsable,
        icon: 'messages',
        tenantPermission: BcmTenantPermission.MESSAGES_EMAIL,
        children: [
            navigationElements.new_message,
            navigationElements.sent_message,
        ]
    },
    {
        id: 'administration',
        title: 'Administration',
        type: ListItemType.Collapsable,
        icon: 'settings',
        children: [
            navigationElements.users,
            navigationElements.roles_and_permissions,
            navigationElements.import,
            {
                id: 'contract-manager',
                title: 'Vertragsmanager',
                type: ListItemType.Collapsable,
                icon: 'folder',
                tenantPermission: BcmTenantPermission.CONTRACT_MANAGER,
                children: [
                    navigationElements.contracts,
                    navigationElements.contracts_wd,
                    navigationElements.contract_attachments,
                ]
            },
            {
                id: 'email',
                title: 'E-Mail-Konfiguration',
                type: ListItemType.Collapsable,
                icon: 'email',
                children: [
                    navigationElements.email_templates,
                    navigationElements.email_mailings,
                    navigationElements.recipient_lists,
                ]
            },
            navigationElements.news
        ]
    }
];

export const adminOnlyNavigationItemsBcm: BcmNavigationItem[] = [
    navigationElements.settings,
];

export const userNavigationItemsBcm: BcmNavigationItem[] = [
    navigationElements.user_custom_settings,
];

export const editableNavigationBcmTemplate: () => BcmNavigationItem[] = () => JSON.parse(JSON.stringify(editableNavigationBcm));
