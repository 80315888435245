import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { U2bApiService } from './u2b-api.service';
import { SystemNotificationModel } from '../../components/system-notifications/system-notification.model';
import { BcmService } from '@modules/bcm/bcm.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class U2bSystemNotificationsApiService extends U2bApiService {

    private RESOURCE_ENDPOINT = 'system-notifications';

    constructor(http: HttpClient,
                bcmService: BcmService,
                private domSanitizer: DomSanitizer) {
        super(http, bcmService);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<SystemNotificationModel[]> {
        return of([
            // {id: 1, content: '<strong>Wichtige Information:</strong><br>Dies ist eine Nachricht....', type: 'error'} as SystemNotificationModel,
            // {id: 1, content: '<strong>Wichtige Information:</strong> <a href="https://www.google.de">Dies</a> ist eine ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz ganz lange Nachricht....', type: 'warning'} as SystemNotificationModel
        ]).pipe(map(data => {
            data.forEach(item => item.trustedContent = this.domSanitizer.bypassSecurityTrustHtml(item.content));
            return data;
        }));
        // return super.get<SystemNotificationModel[]>(this.RESOURCE_ENDPOINT, params);
    }

    markAsRead(notificationModel: SystemNotificationModel = {} as SystemNotificationModel): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT + `/${notificationModel.id}/mark-read`, notificationModel);
    }

}
