import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { BcmFile, BcmFileDto } from '@shared/models/bcm-file';
import { BcmDocumentCategory, BcmDocumentCategoryDto } from '@shared/models/bcm-document-category';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { now } from 'lodash';

export interface BcmDocumentDto {
    id?: number;

    bcm_persons_id?: number;
    bcm_companies_id?: number;
    bcm_boats_id?: number;
    bcm_contracts_id?: number;
    bcm_files_id: number;
    bcm_document_categories_id: number;

    person?: IPerson;
    company?: ICompany;
    boat?: IBoat;

    file: BcmFileDto;
    documentCategory: BcmDocumentCategoryDto;

    title?: string;
    documentIdent?: string;
    dateOfExpiry?: Date;
    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmDocument {
    id: number;

    person?: Person;
    company?: Company;
    boat?: Boat;

    file: BcmFile;
    documentCategory: BcmDocumentCategory;

    categoryName: string;

    title?: string;
    documentIdent?: string;
    dateOfExpiry?: Date;
    note?: string;

    expired: boolean;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(dto: BcmDocumentDto) {
        this.id = dto.id;
        this.person = dto.person ? new Person(dto.person) : null;
        this.company = dto.company ? new Company(dto.company) : null;
        this.boat = dto.boat ? new Boat(dto.boat) : null;
        this.file = dto.file ? new BcmFile(dto.file) : null;
        this.documentCategory = dto.documentCategory ? new BcmDocumentCategory(dto.documentCategory) : null;
        this.categoryName = this.documentCategory?.name || '';
        this.title = dto.title;
        this.documentIdent = dto.documentIdent;
        this.dateOfExpiry = tryParseDate(dto.dateOfExpiry);
        this.note = dto.note;

        this.expired = this.dateOfExpiry?.getTime() < now();

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;
    }
}
