import { PayableOption } from '@shared/models/payable-option';
import { IProduct, Product } from '@shared/models/product';
import { Contract, IContract } from '@shared/models/contract';

export interface TenantRelationRaw {
    id: number;
    name: string;
    note: string;
    hexColor?: string;
    hexBgColor?: string;
    payableOption: PayableOption;
    products?: IProduct[];
    contracts: IContract[];
}

export class TenantRelation {
    id: number;
    name: string;
    note: string;
    hexColor?: string;
    hexBgColor?: string;
    payableOption: PayableOption;
    products?: Product[];
    contracts: Contract[];

    constructor(tenantRelation: TenantRelationRaw) {
        if (!tenantRelation) {
            return;
        }

        this.id = tenantRelation.id;
        this.name = tenantRelation.name;
        this.note = tenantRelation.note;
        this.hexColor = tenantRelation.hexColor;
        this.hexBgColor = tenantRelation.hexBgColor;
        this.products = (tenantRelation.products || []).map(product => new Product(product));
        this.contracts = (tenantRelation.contracts || []).map(contract => new Contract(contract));
        this.payableOption = tenantRelation.payableOption;
    }
}
