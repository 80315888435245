import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TseApiService } from '@bcmApiServices/tse/tse-api.service';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TseInformationApiService extends TseApiService {

    private _vatIdentificationMap: Map<string, number> = new Map<string, number>();

    private RESOURCE_ENDPOINT = 'information';

    /**
     * Returns the corresponding VAT identification for the requested percentage.
     *
     * @param {string} clientId - The identifier of the client for which the VAT identification is requested.
     * @param {number} vatPercent - The percentage for which the VAT identification is requested.
     * @param {Date} [requestDate=new Date()] - The date for which the VAT identification is requested (optional, default: current date and time).
     * @returns {Observable<number>} An observable containing the corresponding VAT identification for the requested percentage.
     */
    getVatIdentification(clientId: string, vatPercent: number, requestDate: Date = new Date()): Observable<number> {

        const identifier = `${clientId}-${vatPercent}-${requestDate.getFullYear()}-${requestDate.getMonth()}-${requestDate.getDate()}`;

        const existingId = this._vatIdentificationMap.get(identifier);

        if (existingId !== undefined) {
            return of(existingId);
        }

        const params = new HttpParams()
            .set('requestDate', requestDate.toISOString())
            .set('vatPercent', vatPercent)
            .set('clientId', clientId);

        return super.get<number>(`${this.RESOURCE_ENDPOINT}/client/${clientId}/getVatIdentification`, params)
            .pipe(tap(response => this._vatIdentificationMap.set(identifier, response)));
    }

    clearVatIdentificationMap(): void {
        this._vatIdentificationMap = new Map<string, number>();
    }


}
