import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPerson, Person } from '@shared/models/person';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseFacade } from '../bcm-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { personsTableData } from '@modules/bcm/@core/state-management/persons/persons-table-data';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { ImageCropperDialogService } from '@sharedComponents/dialogs/image-cropper-dialog/image-cropper-dialog.service';
import { EMPTY, switchMap } from 'rxjs';
import { FilesApiService } from '@modules/bcm/@shared/services';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';

@Injectable({providedIn: 'root'})
export class PersonsFacade extends BcmBaseFacade<Person, IPerson> {

    readonly resourcePath = 'persons';

    readonly resourceNameSingular = 'Person';

    readonly resourceNamePlural = 'Personen';

    readonly resourceIconPlural = 'person';

    readonly resourceIconSingular = 'person';

    readonly deletePermission = BcmUserPermission.PERSONS_DELETE;

    readonly readPermission = BcmUserPermission.PERSONS_READ;

    readonly writePermission = BcmUserPermission.PERSONS_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportPersons(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'Excel Export - Beziehungen',
            onClick: () => this._bcmExportExcelApiService.exportPersonTenantRelations(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (person: Person) => this._bcmNavigationService.navigate('persons/' + person.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (person: Person) => super.remove(person, person.fullName).subscribe(),
        }
    ];

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                filesApiService: FilesApiService,
                bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _bcmNavigationService: BcmNavigationService,
                private _imageCropperDialogService: ImageCropperDialogService,
    ) {
        super(
            Person,
            personsTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService,
            filesApiService
        );
    }

    public changeImageFrom(person: Person) {
        this._imageCropperDialogService
            .getCroppedImage(person.image)
            .pipe(switchMap(newImage => {
                if (newImage) {
                    return this.addOrUpdateFile(newImage);
                } else {
                    return EMPTY;
                }
            }))
            .subscribe(fileFromBackEnd => {
                if (fileFromBackEnd) {
                    person.image = fileFromBackEnd;
                    this.updateStateFor(person);
                }
            });
    }

    public deleteImageFrom(person: Person) {
        super.deleteFile(person.image)
            .subscribe(deleted => {
                if (deleted) {
                    person.image = undefined;
                    this.updateStateFor(person);
                }
            });
    }
}
