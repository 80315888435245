import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { isPlainObject } from '../../functions/is-plain-object';

export class IsElectricMeterCabinetValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('isElectricMeterCabinet', message || 'Falsche Eingabe. Bitte Zählerschrank aus der Liste wählen.');
    }

    public validate({value}: AbstractControl): ValidationErrors {
        return !value || (isPlainObject(value) && value.id != null) ?
            this.IS_VALID : this.IS_INVALID;
    }
}
