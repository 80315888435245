<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                {{(data?.position?.id || data?.position?.positionId) ? ('editInvoicePosition' | translate) : ('createInvoicePosition' | translate)}}
            </span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <mat-tab-group animationDuration="0ms" (selectedIndexChange)="selectTab($event)"
                       [selectedIndex]="currentTabIndex">

            <mat-tab>

                <ng-template mat-tab-label>
                    <ng-container *ngIf="!formGroupValid; else valid">
                        <mat-icon class="icon-error mr-8">
                            error
                        </mat-icon>
                    </ng-container>
                    <ng-template #valid>
                        <ng-container *ngIf="formGroup.get('product')?.value && formGroupValid">
                            <mat-icon class="icon-check mr-8">
                                check_circle
                            </mat-icon>
                        </ng-container>
                    </ng-template>
                    Leistung
                </ng-template>

                <ng-container *ngIf="products?.length; else noProducts">
                    <form [formGroup]="formGroup" class="mt-16">
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field fxFlex (click)="openPanel($event)" appearance="outline">
                                <mat-label>Bezeichnung</mat-label>
                                <textarea matInput
                                          data-test="FI-Bezeichnung"
                                          type="text"
                                          style="min-height: 28px"
                                          formControlName="title"
                                          autocomplete="new-password"
                                          rows="1"
                                          autosize
                                          matAutocompleteOrigin
                                          #matAutocompleteTest="matAutocompleteOrigin"></textarea>
                                <button mat-icon-button
                                        matSuffix
                                        type="button"
                                        color="warn"
                                        *ngIf="formGroup.get('product').value"
                                        (click)="emptyProduct($event)"
                                        matTooltip="Ausgewähltes Produkt entfernen und Formular zurücksetzen">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-form-field>
                            <input type="hidden" formControlName="product"
                                   #matAutocompleteTrigger="matAutocompleteTrigger"
                                   [matAutocompleteConnectedTo]="matAutocompleteTest"
                                   [matAutocomplete]="productSelector">

                            <mat-autocomplete #productSelector="matAutocomplete"
                                              class="product-selector"
                                              panelWidth="auto"
                                              [displayWith]="displayProductWith"
                                              (optionSelected)="onSelectProduct($event);">
                                <mat-option
                                        *ngFor="let product of (filteredProducts$ | async)"
                                        [value]="product">
                                    <span>{{ product?.name }}</span>
                                    <small>
                                        | {{product?.price | bcmDynamicCurrency: '1.2-2'}}</small>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                            <div fxFlex="50">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>Konto (optional)</mat-label>
                                    <input matInput
                                           data-test="FI-Konto"
                                           autocomplete="new-password"
                                           formControlName="account">
                                    <mat-error>{{formGroup.get('account').errors | firstErrorMessage}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>Artikelnummer (optional)</mat-label>
                                    <input matInput
                                           data-test="FI-Artikelnummer"
                                           autocomplete="new-password"
                                           formControlName="itemNumber">
                                    <mat-error>{{formGroup.get('itemNumber').errors | firstErrorMessage}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <app-input-date-time formControlName="vestingPeriodFrom" appearance="outline">
                                <label>Leistungszeitraum Start (optional)</label>
                            </app-input-date-time>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <app-input-date-time formControlName="vestingPeriodUntil" appearance="outline">
                                <label>Leistungszeitraum Ende (optional)</label>
                            </app-input-date-time>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                            <div fxFlex="50">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>Menge</mat-label>
                                    <app-input-number
                                            matInput
                                            data-test="FI-Menge"
                                            formControlName="quantity"></app-input-number>
                                    <mat-error>{{formGroup.get('quantity').errors | firstErrorMessage}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex="50">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>Einheit</mat-label>
                                    <mat-select data-test="FS-Einheit" formControlName="unit"
                                                [compareWith]="compareUnits">
                                        <mat-option *ngFor="let unit of units" [value]="unit">
                                            {{unit.name}}
                                            <ng-container *ngIf="unit.note">
                                                <small>= {{unit.note}}</small>
                                            </ng-container>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>{{formGroup.get('unit').errors | firstErrorMessage}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <ng-container *ngIf="showQuantityButtons">
                            <ng-container *ngIf="data?.quantityButtons?.length">
                                Übernehme die Menge von:
                                <div class="button-group mb-16">
                                    <ng-container *ngFor="let button of data?.quantityButtons">
                                        <button mat-stroked-button
                                                type="button"
                                                (click)="formGroup.get('quantity').setValue(button.quantity)">
                                            {{button.label}}
                                        </button>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                            <ng-container *ngIf="showTaxRateColumn">
                                <div fxFlex="50">
                                    <mat-form-field fxFlex appearance="outline">
                                        <mat-label>MwSt.</mat-label>
                                        <mat-select data-test="FS-MwSt."
                                                    formControlName="taxRate"
                                                    [compareWith]="compareTaxRates">
                                            <mat-option *ngFor="let taxRate of taxRates" [value]="taxRate">
                                                {{taxRate.value | number : '1.0-2'}} %
                                                <ng-container *ngIf="taxRate.note">
                                                    - <small>{{taxRate.note}}</small>
                                                </ng-container>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{formGroup.get('taxRate').errors | firstErrorMessage}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <div [fxFlex]="showTaxRateColumn ? 50 : 100">
                                <mat-form-field fxFlex appearance="outline"
                                                matTooltipPosition="left"
                                                [matTooltip]="(useDynamicPrice) ? 'Zum Bearbeiten des Preis muss der dynamische Preis deaktiviert werden.' : ''">
                                    <mat-label>
                                        <bcm-product-price-label textAlign="left"></bcm-product-price-label>
                                    </mat-label>
                                    <app-input-number
                                            matInput
                                            data-test="FI-Einzelpreis"
                                            formControlName="price"
                                            [fc]="formGroup?.get('price')"
                                            [step]="1"></app-input-number>
                                    <mat-hint>
                                        <ng-container *ngIf="dynamicPrice?.rulePrice">
                                            Standard Preis: {{formGroup.get('product').value?.price | bcmDynamicCurrency}}
                                        </ng-container>
                                    </mat-hint>
                                    <mat-error>{{formGroup.get('price').errors | firstErrorMessage}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field fxFlex appearance="outline">
                                <mat-label>Rabatt in&nbsp;% (optional)</mat-label>
                                <app-input-number matInput
                                                  formControlName="discountPercentage"
                                                  [fc]="formGroup?.get('discountPercentage')"
                                                  appearance="outline"></app-input-number>
                            </mat-form-field>
                        </div>
                    </form>
                </ng-container>

                <ng-template #noProducts>
                    {{noProductsMessage}}
                </ng-template>
            </mat-tab>

            <mat-tab [disabled]="!vouchersAvailable">
                <ng-template mat-tab-label>
                    <ng-container *ngIf="!vouchersAvailable">
                        <mat-icon class="icon-disabled mr-8 ml-8"
                                  [matTooltip]="'Für dieses Produkt ist kein ' + ('voucher' | translate) + ' verfügbar.'">
                            error
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="voucherUsage">
                        <mat-icon class="icon-check mr-8 ml-8"
                                  [matTooltip]="'Du hast mindestens einen ' + ('voucher' | translate) + ' ausgewählt.'">
                            check_circle
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="vouchersAvailable && !voucherUsage">
                        <mat-icon class="icon-warning mr-8 ml-8"
                                  [matTooltip]="'Du hast noch keinen ' + ('voucher' | translate) + ' ausgewählt.'">
                            error
                        </mat-icon>
                    </ng-container>
                    {{'voucher' | translate}}
                </ng-template>
                <product-voucher (vouchersAvalaible)="setVouchersAvailable($event)"
                                 (voucherPositionsChanged)="voucherPositions = $event"
                                 (voucherUsageChanged)="setVoucherUsage($event)"
                                 [selectVoucher]="data?.position?.voucher"
                                 [parentFormGroup]="formGroup"
                                 [dynamicPriceContext]="dynamicPriceContext"
                                 [product]="data?.position?.product"
                                 [quantity]="data?.position?.quantity">
                </product-voucher>
            </mat-tab>

            <mat-tab [disabled]="!hasDynamicPrice">
                <ng-template mat-tab-label>
                    <ng-container
                            *ngIf="(voucherPositions?.length === 1 && voucherPositions[0].totalPrice === 0); else noVoucher">
                        <mat-icon class="icon-warning mr-8 ml-8"
                                  [matTooltip]="'Dynamischer Preis ist nicht verfügbar, da ein ' + ('voucher' | translate) + ' ausgewählt ist.'">
                            error
                        </mat-icon>
                    </ng-container>
                    <ng-template #noVoucher>
                        <ng-container *ngIf="!hasDynamicPrice; else dp">
                            <mat-icon class="icon-disabled mr-8 ml-8"
                                      matTooltip="Dieses Produkt hat keinen dynamischen Preis.">
                                error
                            </mat-icon>
                        </ng-container>
                        <ng-template #dp>
                            <ng-container *ngIf="!useDynamicPrice; else useDp">
                                <mat-icon class="icon-warning mr-8 ml-8"
                                          matTooltip="Der dynamische Preis ist deaktiviert.">
                                    error
                                </mat-icon>
                            </ng-container>

                            <ng-template #useDp>
                                <ng-container *ngIf="!dynamicPriceHasError; else warningIcon">
                                    <mat-icon class="icon-check mr-8 ml-8"
                                              matTooltip="Angaben zur Berechnung des dynamischen Preis vollständig.">
                                        check_circle
                                    </mat-icon>
                                </ng-container>
                                <ng-template #warningIcon>
                                    <ng-container
                                            *ngIf="(dynamicPrice?.ruleIndex !== null && dynamicPrice?.ruleIndex >= 0) || (dynamicPrice?.ruleCalculations?.calculations?.length > 0); else errorIcon">
                                        <mat-icon class="icon-warning mr-8 ml-8"
                                                  matTooltip="Ein dynamischer Preis konnte berechnet werden,
                                  allerdings wurden eine oder mehrere Regeln ignoriert, da nicht alle Informationen bekannt sind.">
                                            warning
                                        </mat-icon>
                                    </ng-container>
                                    <ng-template #errorIcon>
                                        <mat-icon class="icon-error mr-8 ml-8"
                                                  matTooltip="Ein dynamischer Preis konnte nicht berechnet werden. Bitte ergänze die fehlenden Informationen.">
                                            warning
                                        </mat-icon>
                                    </ng-template>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                    Dynamischer Preis
                </ng-template>
                <div class="mt-16">
                    <ng-container
                            *ngIf="hasDynamicPrice && (!(voucherPositions?.length === 1 && voucherPositions[0].totalPrice === 0)); else voucherSelected">
                        <product-dynamic-price (dynamicPriceChanged)="dynamicPriceChanged($event)"
                                               (dynamicPriceErrorChanged)="dynamicPriceHasError = $event"
                                               (useDynamicPriceChanged)="useDynamicPriceChanged($event)"
                                               [parentFormGroup]="formGroup"
                                               [dynamicPrice]="data?.position?.dynamicPrice"
                                               [dynamicPriceContext]="dynamicPriceContext"
                                               [useDynamicPrice]="(!!data?.position?.id && !!data?.position?.dynamicPrice?.id) || (!data?.position?.id && (!data?.position?.positionId || !!data?.position?.dynamicPrice))"
                                               [product]="formGroup.get('product').value">
                        </product-dynamic-price>
                    </ng-container>

                    <ng-template #voucherSelected>
                        <u2b-message type="info">
                            Dynamischer Preis ist nicht verfügbar, da ein {{'addVoucher' | translate}} ausgewählt ist.
                        </u2b-message>
                    </ng-template>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                class="save-button mat-primary"
                aria-label="Abbrechen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <div>
            <ng-container *ngIf="hasNextTab">
                <button data-test="B-Weiter" mat-flat-button
                        color="accent"
                        (click)="nextTab()"
                        class="next-tab-button"
                        aria-label="Weiter">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    Weiter
                </button>
            </ng-container>

            <button data-test="B-Speichern" mat-flat-button
                    color="accent"
                    (click)="saveAndContinue()"
                    class="save-button"
                    [disabled]="(((vouchersAvailable && !(hasSeenVouchers || voucherUsage)) || (!voucherUsage && useDynamicPrice && dynamicPriceHasError && !hasSeenDynPrice)) && !data?.position?.id) || isSaving || !formGroup.dirty || (formGroup?.get('dynamicPrice')?.invalid)"
                    aria-label="Speichern">
                <mat-icon>save</mat-icon>
                Speichern
            </button>

        </div>

    </div>

</div>
