import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Company } from '@shared/models/company';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { isFunction } from '@shared/functions/is-function';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { CompanyApiService } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AppNotificationService } from '@core/services/app-notification.service';
import { CompaniesFacade } from '@modules/bcm/@core/state-management/companies/companies.facade';

@Component({
    selector: 'form-widget-company',
    templateUrl: './form-widget-company.component.html'
})
export class FormWidgetCompanyComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = 'Organisation wählen <small>oder</small> neu erfassen';

    @Input()
    inputLabel = 'Nach Organisation suchen...';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    company$: Observable<Company>;

    @Input()
    optional: boolean;

    @Input()
    slimmedView = false;

    @Input()
    canCreate = true;

    optionalString: string;

    showOptionalFields = false;

    companyFormGroup: UntypedFormGroup;

    company: Company;

    loadingCompanies: boolean;

    companies: Company[];

    filteredCompanies$: Observable<Company[]>;

    isSaving = false;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _appNotificationService: AppNotificationService,
                private _companiesFacade: CompaniesFacade,
                private _companyApiService: CompanyApiService) {
    }

    ngOnInit(): void {

        this.optionalString = this.optional ? ' (optional)' : '';
        this.showOptionalFields = !this.optional;

        if (!this.canCreate) {
            this.headline = 'Organisation wählen';
        }

        this._createForm();
        this._loadCompanies();

        setTimeout(() => {
            this.parentFormGroup.addControl('companyForm', this.companyFormGroup);
        });

        if (isFunction(this.company$?.subscribe)) {
            this.company$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(company => {
                    this.companyFormGroup.patchValue({company, ...company}, {onlySelf: true});

                    if (company?.phones && company.phones.length) {
                        this.companyFormGroup.get('phone').patchValue(company.phones[0], {onlySelf: true});
                    }

                    this.companyFormGroup.markAsDirty();
                });
        }
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('companyForm');
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickRemoveCompany(): void {
        this.company = null;
        this.companyFormGroup.get('company').setValue(null);
        this.companyFormGroup.reset();
        this.companyFormGroup.enable();
    }

    onClickToggleOptionalFields(): void {
        this.showOptionalFields = !this.showOptionalFields;
    }

    public displayCompanyWith(company: Company): string {
        return company ? company.toDropdownString() : '';
    }

    private _createForm(): void {
        this.companyFormGroup = this._formBuilder.group({
            company: [null],
            name: [null, [U2bValidators.required('Bitte Namen der Organisation angeben')]],
            form: [null],
            street: [null, this.optional ? [] : [U2bValidators.required('Bitte Strasse angeben')]],
            postCode: [
                null,
                this.optional ? [] : [
                    U2bValidators.required('Bitte PLZ angeben'),
                    // todo: add country based validator!
                    // U2bValidators.postCode(this.bcmService.tenant)
                ]
            ],
            city: [null, this.optional ? [] : [U2bValidators.required('Bitte Stadt angeben')]],
            mail: [null, this.optional ? [] : [U2bValidators.email('Bitte überprüfe die eingegebene E-Mail-Adresse. Beachte das E-Mail Format', true)]],
            phone: this._formBuilder.group({
                type: [null],
                number: [null],
            }),
            note: [null, [U2bValidators.maxLength(1024)]]
        });

        this.companyFormGroup
            .get('company')
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.company = company;
                if (company && company.id) {
                    disableControlsByName(
                        this.companyFormGroup,
                        ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note']
                    );
                } else {
                    enableControlsByName(
                        this.companyFormGroup,
                        ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
                        true
                    );
                }
            });
    }

    private _loadCompanies(): void {
        this.companies = [];
        this.loadingCompanies = true;
        this._companyApiService
            .getAll(new HttpParams().set('includeBoats', 'true'))
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingCompanies = false)
            )
            .subscribe(companies => this.companies = companies);

        this.filteredCompanies$ = this.companyFormGroup.get('company').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterCompanies(value) : this.companies)
            );
    }

    private _filterCompanies(filter: string): Company[] {
        filter = filter.toLowerCase();
        return this.companies.filter(company => {
            return (company || '').toString().toLowerCase().includes(filter)
                || (company.identNumber || '').toLowerCase().includes(filter)
                || (company.primaryMail || '').toLowerCase().includes(filter)
                || (company.primaryPhone || '').toLowerCase().includes(filter);
        });
    }

    save(): void {

        if (this.companyFormGroup.invalid) {
            this.companyFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder.');
            return;
        }

        this.isSaving = true;

        const newCompany = this.companyFormGroup.getRawValue();

        this._companiesFacade.add(newCompany, new HttpParams(), true)
            .subscribe((company) => {
                this.isSaving = false;
                this._appNotificationService.showSuccess('Organisation erfolgreich gespeichert.');
                this._loadCompanies();
                this.companyFormGroup.patchValue({company});
            });

    }

}
