<div [formGroup]="electricMeterFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex=100>
        <h2>{{headline}}</h2>

        <ng-container *ngIf="electricMeterFormGroup.errors">
            <u2b-message type="error">
                {{electricMeterFormGroup.errors | firstErrorMessage}}
            </u2b-message>
        </ng-container>
    </div>

    <div fxFlex="100" class="pb-16">
        <mat-checkbox data-test="FCB-Nur freie Liegeplätze anzeigen" formControlName="addElectricMeter">
            Mit Stromzähler verbinden?
        </mat-checkbox>
    </div>

    <ng-container *ngIf="electricMeterFormGroup.get('addElectricMeter').value">

        <mat-form-field fxFlex="100" appearance="fill" floatLabel="always">
            <input type="text" matInput data-test="FI-Zählerschrank suchen/wählen"
                   formControlName="electricMeterCabinet"
                   autocomplete="new-password"
                   placeholder="Zählerschrank suchen/wählen"
                   [matAutocomplete]="electricMeterCabinetSelector">
            <mat-error>{{electricMeterFormGroup.get('electricMeterCabinet').errors | firstErrorMessage}}</mat-error>

            <mat-autocomplete #electricMeterCabinetSelector="matAutocomplete"
                              [displayWith]="displayElectricMeterCabinetWith">
                <mat-option disabled class="loading" *ngIf="loadingElectricMeterCabinets">
                    <mat-spinner diameter="35"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="!loadingElectricMeterCabinets">
                    <mat-option *ngFor="let electricMeterCabinet of (filteredElectricMeterCabinets$ | async)"
                                [value]="electricMeterCabinet">
                        <span>{{electricMeterCabinet.handle}}</span>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <ng-container *ngIf="selectedCabinet?.handle">

            <ng-container *ngIf="!loadingElectricMeters && !electricMeters?.length">
                <div fxFlex="100">
                    <u2b-message type="warning">
                        Der ausgewählte Zählerschrank hat keine freien Stromzähler.
                    </u2b-message>
                </div>
            </ng-container>

            <div fxFlex="100" class="pb-16">
                <mat-checkbox data-test="FCB-Nur freie Liegeplätze anzeigen" formControlName="onlyFree">
                    Nur freie Zähler anzeigen
                </mat-checkbox>
            </div>

            <mat-form-field fxFlex="100" appearance="fill" floatLabel="always">
                <input type="text" matInput data-test="FI-Stromzähler suchen/wählen" formControlName="electricMeter"
                       autocomplete="new-password"
                       placeholder="Stromzähler suchen/wählen"
                       [matAutocomplete]="electricMeterSelector">
                <mat-error>{{electricMeterFormGroup.get('electricMeter').errors | firstErrorMessage}}</mat-error>

                <mat-autocomplete #electricMeterSelector="matAutocomplete" [displayWith]="displayElectricMeterWith">
                    <mat-option disabled class="loading" *ngIf="loadingElectricMeters">
                        <mat-spinner diameter="35"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!loadingElectricMeters">
                        <mat-option *ngFor="let electricMeter of (filteredElectricMeters$ | async)"
                                    [value]="electricMeter">
                            <span>{{ electricMeter.handle }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <ng-container *ngIf="selectedElectricMeter?.handle">
                <mat-form-field fxFlex="50" appearance="fill" floatLabel="always">
                    <mat-label>
                        Anfangs-Zählerstand
                        <ng-container *ngIf="dateFrom">
                            ({{dateFrom | date : 'dd.MM.yyyy'}})
                        </ng-container>
                    </mat-label>
                    <app-input-number
                        matInput
                        data-test="FI-Anfangs-Zählerstand"
                        formControlName="meterReadingStart"
                        [step]="0.5"></app-input-number>
                    <mat-error>{{electricMeterFormGroup.get('meterReadingStart').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="50" appearance="fill" floatLabel="always">
                    <mat-label>
                        End-Zählerstand (optional)
                        <ng-container *ngIf="dateTo">
                            ({{dateTo | date : 'dd.MM.yyyy'}})
                        </ng-container>
                    </mat-label>
                    <app-input-number
                        matInput
                        data-test="FI-End-Zählerstand"
                        formControlName="meterReadingEnd"
                        [step]="0.5"></app-input-number>
                    <mat-error>{{electricMeterFormGroup.get('meterReadingEnd').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>
            </ng-container>

        </ng-container>

    </ng-container>
</div>