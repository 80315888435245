import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ProductsApiService, TenantRelationsApiService } from '@bcmServices/index';
import { TenantRelation } from '@shared/models/tenant-relation';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { Product } from '@shared/models/product';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BcmDefaultAsset, BcmDefaultAssetDto } from '@shared/models/bcm-default-asset';
import { DefaultAssetsApiService } from '@bcmApiServices/default-assets.api-service';
import { take } from 'rxjs/operators';
import { cloneDeep } from '@shared/functions/clone-deep';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { hours, minutes } from '@shared/constants/date';
import { parseInt } from 'lodash';

export enum DEFAULT_BERTH_RESERVATION_UNIT {
    DAY, OVERNIGHT_STAY
}

@Component({
    selector: 'default-units',
    templateUrl: './default-units.component.html',
    styleUrls: ['./default-units.component.scss'],
})
export class DefaultUnitsComponent implements OnInit {

    readonly DEFAULT_BERTH_RESERVATION_UNIT = DEFAULT_BERTH_RESERVATION_UNIT;

    readonly hours = hours;
    readonly minutes = minutes;

    tenantRelations: TenantRelation[];

    products: Product[];

    formsDirty; // created this as checking 

    formGroup = this.fb.group({
        dayGuestBoater: [],
    });

    electricMeterFormGroup = this.fb.group({
        productID: [],
    });

    timeFormGroup = this.fb.group({
        type: [],
        fromHours: [],
        fromMinutes: [],
        toHours: [],
        toMinutes: [],
    });

    defaultAssetsDataSource: MatTableDataSource<BcmDefaultAsset> = new MatTableDataSource<BcmDefaultAsset>();

    defaultAssetsDisplayedColumns = ['name', 'length', 'width', 'buttons'];

    isSaving = false;

    isDeleting = false;

    assetInEditMode: BcmDefaultAssetDto;

    assetInEditModeOriginal: BcmDefaultAssetDto;

    assetToDelete: BcmDefaultAssetDto;

    newAsset: BcmDefaultAssetDto = {
        name: '',
        width: 0,
        length: 0
    };

    protected readonly BcmUserPermission = BcmUserPermission;

    constructor(
        private fb: UntypedFormBuilder,
        private appNotificationService: AppNotificationService,
        private confirmDialogService: ConfirmDialogService,
        private tenantRelationsApiService: TenantRelationsApiService,
        private defaultAssetsApiService: DefaultAssetsApiService,
        private productsApiService: ProductsApiService,
        public bcmSettingsFacade: BcmSettingsFacade,
    ) {
    }

    ngOnInit(): void {
        this.tenantRelationsApiService.getAll().subscribe((tr) => this.tenantRelations = tr);
        this.productsApiService.getAll().subscribe((p) => this.products = p);
        this.bcmSettingsFacade.loadSettings().subscribe((settings) => {
            this.formGroup.patchValue(settings[BcmSettingsSectionName.DefaultUnits]);
            this.electricMeterFormGroup.patchValue(settings[BcmSettingsSectionName.ElectricMeter]);

            const {
                type,
                fromHours,
                fromMinutes,
                toHours,
                toMinutes
            } = settings[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
            this.timeFormGroup.patchValue({ // TODO find the reason why the numbers are not displayed
                type,
                fromHours: String(fromHours).padStart(2, '0'),
                fromMinutes: String(fromMinutes).padStart(2, '0'),
                toHours: String(toHours).padStart(2, '0'),
                toMinutes: String(toMinutes).padStart(2, '0'),
            }, {emitEvent: false});
        });

        this.formGroup.valueChanges.subscribe(data => {
            this.formsDirty = this.formGroup.dirty;
        });

        this.electricMeterFormGroup.valueChanges.subscribe((data) => {
            this.formsDirty = this.electricMeterFormGroup.dirty;
        });

        this.timeFormGroup.valueChanges.subscribe(data => {
            this.formsDirty = this.timeFormGroup.dirty;
        });

        this.timeFormGroup.get('type')?.valueChanges
            .subscribe((type: DEFAULT_BERTH_RESERVATION_UNIT) => {
                if (type === DEFAULT_BERTH_RESERVATION_UNIT.DAY) {
                    this.timeFormGroup.patchValue({
                        fromHours: '00',
                        fromMinutes: '00',
                        toHours: hours[hours.length - 1],
                        toMinutes: minutes[minutes.length - 1]
                    }, {emitEvent: false});
                    this.timeFormGroup.get('fromHours').disable();
                    this.timeFormGroup.get('fromMinutes').disable();
                    this.timeFormGroup.get('toHours').disable();
                    this.timeFormGroup.get('toMinutes').disable();
                } else if (type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY) {
                    this.timeFormGroup.patchValue({
                        fromHours: '13',
                        fromMinutes: '00',
                        toHours: '12',
                        toMinutes: minutes[minutes.length - 1]
                    }, {emitEvent: false});
                    this.timeFormGroup.enable({emitEvent: false});
                }
            });

        this.defaultAssetsApiService.getAll()
            .pipe(take(1))
            .subscribe(defaultAssets => this.defaultAssetsDataSource.data = defaultAssets);

    }

    save(event): void {
        event.stopPropagation();

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const defaultSettings = this.formGroup.value;
        const electricMeterSettings = this.electricMeterFormGroup.value;
        const timeSettings = this.timeFormGroup.value;

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.DefaultUnits, defaultSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.ElectricMeter, electricMeterSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.DefaultBerthReservationTimeUnit, timeSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });
    }

    onAssetAdd(newAsset: BcmDefaultAssetDto): void {
        this.isSaving = true;

        this.defaultAssetsApiService
            .add(newAsset)
            .subscribe((addedAsset: BcmDefaultAsset) => {

                const data = this.defaultAssetsDataSource.data;
                data.push(addedAsset);
                this.defaultAssetsDataSource.data = data;

                newAsset.name = '';
                newAsset.width = undefined;
                newAsset.length = undefined;

                this.appNotificationService.showSuccess(`Asset Vorlage "${addedAsset.name}" erfolgreich gespeichert`);
            })
            .add(() => this.isSaving = false);
    }

    onAssetEdit(asset: BcmDefaultAsset): void {
        asset.inEditMode = true;
        this.assetInEditMode = asset;
        this.assetInEditModeOriginal = cloneDeep(asset);
    }

    onAssetEditCancel(asset: BcmDefaultAsset): void {
        const {name, length, width} = this.assetInEditModeOriginal;
        asset.inEditMode = false;
        asset.name = name;
        asset.length = length;
        asset.width = width;
        this.assetInEditModeOriginal = null;
        this.assetInEditMode = null;
    }

    onAssetSave(asset: BcmDefaultAsset): void {
        this.isSaving = true;
        this.defaultAssetsApiService.update(asset)
            .subscribe(() => {
                asset.inEditMode = false;
                this.assetInEditModeOriginal = null;
                this.assetInEditMode = null;
                this.appNotificationService.showSuccess(`Änderungen an Asset Vorlage "${asset.name}" wurde gespeichert.`);
            })
            .add(() => this.isSaving = false);
    }

    onAssetDelete(asset: BcmDefaultAsset): void {
        this.isDeleting = true;
        this.assetToDelete = asset;
        this.confirmDialogService
            .setTitle('Asset Vorlage wirklich entfernen?')
            .setTheme('warn')
            .openWithCallback(() => {
                this.defaultAssetsApiService.remove(asset)
                    .subscribe(() => {

                        const data = this.defaultAssetsDataSource.data;
                        const index: number = data.findIndex(d => d === asset);
                        data.splice(index, 1);
                        this.defaultAssetsDataSource.data = data;

                        this.appNotificationService.showSuccess(`Asset Vorlage "${asset.name}" wurde entfernt.`);
                    })
                    .add(() => {
                        this.isDeleting = false;
                        this.assetToDelete = null;
                    });
            }, () => {
                this.isDeleting = false;
                this.assetToDelete = null;
            }, () => {
                this.isDeleting = false;
                this.assetToDelete = null;
            });
    }

}
