import { BehaviorSubject, EMPTY, Observable, Subscription, switchMap } from 'rxjs';
import { BaseEntity, U2bAdminBaseState } from '@modules/admin/state/u2b-admin-base.state';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { U2bAdminGenericApi } from '@modules/admin/state/u2b-admin-generic.api';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { SvgIconsNames } from '@core/modules/svg-icons/svg-icon.names';

export interface ResourceConfig<Entity, EntityDto> {
    viewNameSingular: string;
    viewNamePlural: string;
    iconName?: string;
    iconNameSvg?: SvgIconsNames;
    endpointPath: string;
    entityClass: new(_: EntityDto) => Entity;
}

export class U2bAdminBaseFacade<Entity extends BaseEntity, EntityDto> {

    protected readonly api = new U2bAdminGenericApi<Entity, EntityDto>(
        this.httpClient,
        this.confirmDialogService,
        this.resourceConfig,
    );

    protected readonly state = new U2bAdminBaseState<Entity>();

    public readonly _searchTermChanged$ = new BehaviorSubject<string | undefined>(undefined);

    public get searchTermChanged$(): Observable<string | undefined> {
        return this._searchTermChanged$.asObservable();
    }

    public get searchTerm(): string | undefined {
        return this._searchTermChanged$.getValue();
    }

    public set searchTerm(searchTerm: string | undefined) {
        this._searchTermChanged$.next(searchTerm);
    }

    get list$(): Observable<Entity[]> {
        return this.state.list$;
    }

    get selected$(): Observable<Entity> {
        return this.state.selected$;
    }

    get isUpdatingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get isUpdating$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly confirmDialogService: ConfirmDialogService,
        protected readonly resourceConfig: ResourceConfig<Entity, EntityDto>,
    ) {
    }

    loadAll(): Observable<Entity[]> {
        return this.api.getAll()
            .pipe(tap(releaseNotes => this.state.setList(releaseNotes)));
    }

    loadById(id: number): Subscription {
        return this.api.getOne(id)
            .pipe(tap(result => {
                // result.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(result.releaseNotes);
                this.state.setSelected(result);
            }))
            .subscribe();
    }

    add(createData: Partial<Entity>): Subscription {
        this.state.startUpdating();
        return this.api.add(createData)
            .subscribe(
                (createdReleaseNote) => {
                    this.state.addListItem(createdReleaseNote);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    update(entity: Entity, updateData: Partial<Entity>): Subscription {
        this.state.startUpdating();
        return this.api.update(entity, updateData)
            .subscribe(
                (updatedReleaseNote) => {
                    this.state.replaceListItem(entity, updatedReleaseNote);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    remove(entity: Entity): Subscription {
        return this.confirmDialogService
            .setTheme('warn')
            .setTitle(`${this.resourceConfig.viewNameSingular} entfernen`)
            .setBody(
                `${this.resourceConfig.viewNameSingular} wirkich entfernen?<br><br>` +
                'Dieser Schritt kann <strong>nicht(!)</strong> rückgängig gemacht werden!'
            )
            .openAndReturnResult()
            .pipe(
                switchMap(result => {
                    if (result) {
                        this.state.startUpdating();
                        return this.api.remove(entity);
                    }

                    return EMPTY;
                })
            )
            .subscribe({
                next: () => {
                    this.state.removeListItem(entity);
                },
                error: (error) => this.handleError(error),
                complete: () => this.state.stopUpdating()
            });
    }

    private handleError(error: Error): void {
        throw error;
    }
}
