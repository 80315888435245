<ng-template #placeholder50>
    <div fxFlex="50">&nbsp;</div>
</ng-template>

<div [formGroup]="personFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex="100">

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container *ngIf="!slimmedView">
                <h2 [innerHTML]="headline"></h2>
            </ng-container>

            <ng-container *ngIf="!person?.id && canCreate">
                <div>
                    <button mat-icon-button
                            type="button"
                            color="primary"
                            data-test="B-Optionale Felder"
                            matTooltip="Optionale Felder {{showOptionalFields ? 'ausblenden' : 'einblenden'}}"
                            (click)="onClickToggleOptionalFields()">
                        <ng-container *ngIf="!showOptionalFields">
                            <mat-icon>visibility_off</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="showOptionalFields">
                            <mat-icon>visibility</mat-icon>
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="loadingPersons; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!persons?.length">
                <u2b-message>
                    <header>Keine Personen gefunden</header>
                    <p>Es konnten keine Personen im System gefunden werden.</p>
                </u2b-message>
            </ng-container>

            <ng-container *ngIf="persons?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-label>{{ inputLabel }}</mat-label>
                        <input type="text" matInput data-test="FI-Nach Person suchen..."
                               formControlName="person"
                               [matAutocomplete]="personSelector">
                        <button matSuffix
                                mat-icon-button
                                type="button"
                                color="warn"
                                matTooltip="Ausgewählte Person entfernen"
                                [disabled]="!person?.id || personFormGroup.disabled"
                                (click)="onClickRemovePerson()">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <mat-error>{{ personFormGroup.get('person').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete #personSelector="matAutocomplete" [displayWith]="displayPersonWith">
                        <ng-container *ngFor="let person of filteredPersons$ | async">
                            <mat-option [value]="person">
                                <bcm-person-link [disableLink]="true"
                                                 [person]="person"
                                                 [suffix]="person.identNumber ? ('(' + person.identNumber + ')') : null"></bcm-person-link>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="person?.id">
            <ng-container *ngIf="person.activeTenantRelationAssignments?.length && !slimmedView">
                <strong>Aktive Beziehungen:</strong>
                <bcm-tenant-relation-colored-label
                        [tenantRelations]="person?.activeTenantRelationAssignments"></bcm-tenant-relation-colored-label>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="!person?.id && canCreate">
        <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
            <mat-label>Anrede</mat-label>
            <mat-select data-test="FS-Anrede" formControlName="salutation">
                <mat-option [value]="salutation.Male">Herr</mat-option>
                <mat-option [value]="salutation.Female">Frau</mat-option>
            </mat-select>
            <mat-error>{{ personFormGroup.get('salutation').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="showOptionalFields; else placeholder50">
            <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
                <mat-label>Titel (optional)</mat-label>
                <input matInput data-test="FI-Titel" type="text" formControlName="title" autocomplete="new-password">
            </mat-form-field>
        </ng-container>

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>Vorname</mat-label>
            <input matInput data-test="FI-Vorname" type="text" formControlName="firstName" autocomplete="new-password">
            <mat-error>{{ personFormGroup.get('firstName').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
            <mat-label>Nachname</mat-label>
            <input matInput data-test="FI-Nachname" type="text" formControlName="lastName" autocomplete="new-password">
            <mat-error>{{ personFormGroup.get('lastName').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="optional && showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>Straße + Hausnummer{{ optionalString }}</mat-label>
                <input matInput data-test="FI-Straße + Hausnummer" type="text" formControlName="street"
                       autocomplete="new-password">
                <mat-error>{{ personFormGroup.get('street').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="40" floatLabel="always">
                <mat-label>PLZ{{ optionalString }}</mat-label>
                <input matInput data-test="FI-PLZ" type="text" formControlName="postCode" autocomplete="new-password">
                <mat-error>{{ personFormGroup.get('postCode').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="60" floatLabel="always">
                <mat-label>Ort{{ optionalString }}</mat-label>
                <input matInput data-test="FI-Ort" type="text" formControlName="city" autocomplete="new-password">
                <mat-error>{{ personFormGroup.get('city').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>E-Mail (optional)</mat-label>
                <input matInput data-test="FI-E-Mail" type="text" formControlName="mail" autocomplete="new-password">
                <mat-error>{{ personFormGroup.get('mail').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <div formGroupName="phone" fxFlex="100">

                <div fxLayout="row wrap"
                     fxLayoutGap="12px grid">

                    <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="60" floatLabel="always">
                        <mat-label>Telefonnummer (optional)</mat-label>
                        <input matInput data-test="FI-Telefonnummer" type="text" formControlName="number"
                               autocomplete="new-password">
                        <mat-error>{{ personFormGroup.get('phone').get('number').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="40" floatLabel="always">
                        <mat-label>Typ (optional)</mat-label>
                        <mat-select data-test="FS-Typ" formControlName="type">
                            <mat-option [value]="'Privat'">Privat</mat-option>
                            <mat-option [value]="'Arbeit'">Arbeit</mat-option>
                            <mat-option [value]="'Mobil'">Mobil</mat-option>
                            <mat-option [value]="'Fax'">Fax</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

            </div>

            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>Bemerkungen / Notizen (optional)</mat-label>
                <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                          autocomplete="new-password"></textarea>
            </mat-form-field>
        </ng-container>

        <div>
            <button data-test="B-Speichern" mat-flat-button
                    color="accent"
                    (click)="save()"
                    style="margin-left: auto"
                    class="save-button mat-accent mb-4"
                    [disabled]="isSaving"
                    aria-label="Speichern">
                <mat-icon>save</mat-icon>
                Person anlegen
            </button>
        </div>

    </ng-container>

</div>
