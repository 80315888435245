import { DataFilterOperator } from './filter-operator';
import { FilterFieldType } from './available-filter-fields';

let filterConditionId = 0;

export class FilterCondition {
    readonly id: number;
    editMode?: boolean;

    constructor(
        public field: string,
        public operator: DataFilterOperator,
        public value: any,
        public fieldType: FilterFieldType = FilterFieldType.Text,
        public compareAttribute?: string,
    ) {
        this.id = ++filterConditionId;
    }
}
