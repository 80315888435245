<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Zahlart auswählen</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <u2b-message type="info">Betrag: {{ totalSum | bcmDynamicCurrency }}</u2b-message>

        <mat-button-toggle-group name="paymentType" [disabled]="!canStartPaymentProcess"
                                 [(ngModel)]="paymentType">
            <mat-button-toggle [value]="BcmPaymentType.Cash">Bar</mat-button-toggle>
            <mat-button-toggle [value]="BcmPaymentType.EcCard">EC-Karte</mat-button-toggle>
            <mat-button-toggle [value]="BcmPaymentType.CreditCard">Kreditkarte
            </mat-button-toggle>
        </mat-button-toggle-group>

        <ul>
            <li>
                <div fxLayout="row">
                    <span class="check-info">Prüfe Add-on "Barzahlung" ..</span>
                    <ng-container *ngIf="!checkAddonDone">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-container>
                </div>

                <ul>
                    <ng-container *ngIf="checkAddonDone">
                        <ng-container *ngIf="addonStatus">
                            <li>
                                <div fxLayout="row">
                                    <span class="check-info">OK</span>
                                    <mat-icon color="accent">checkmark</mat-icon>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!addonStatus">
                            <li>
                                <div fxLayout="row">
                                    <span class="check-info"><b>Add-on "Barzahlung" nicht freigeschaltet.<br>Bitte wende dich an den Up2Boat Support.</b></span>
                                    <mat-icon color="warn">error</mat-icon>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>

            </li>


            <ng-container *ngIf="addonStatus">
                <li>
                    <div fxLayout="row">
                        <span class="check-info">Prüfe Verbindung zur Kasse ..</span>
                        <ng-container *ngIf="!checkCashRegisterDone">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="checkCashRegisterDone">
                        <ul>
                            <ng-container *ngIf="cashRegister?.id">
                                <li>
                                    <div fxLayout="row">
                                        <span class="check-info">Kasse: {{ cashRegister.title }}</span>
                                        <mat-icon color="accent">checkmark</mat-icon>
                                    </div>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!cashRegister?.id">
                                <li>
                                    <div fxLayout="row">
                                        <span class="check-info"><b>Du bist aktuell an keiner Kasse angemeldet</b></span>
                                        <mat-icon color="warn">error</mat-icon>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </li>
            </ng-container>

            <ng-container *ngIf="cashRegister?.id && (isProcessing || requestReceiptDone)">
                <li>
                    <div fxLayout="row">
                        <span class="check-info">Erstelle Beleg ..</span>
                        <ng-container *ngIf="!requestReceiptDone">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="requestReceiptDone">
                        <ul>
                            <ng-container *ngIf="receipt?.id">
                                <li>
                                    <div fxLayout="row">
                                        <span class="check-info">Rechnungsnummer: {{ receipt.receiptNumber }}</span>
                                        <mat-icon color="accent">checkmark</mat-icon>
                                    </div>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!receipt?.id">
                                <ng-container *ngFor="let error of receiptErrorMessages">
                                    <li>
                                        <div fxLayout="row">
                                            <span class="check-info"><b>{{ error }}</b></span>
                                            <mat-icon color="warn">error</mat-icon>
                                        </div>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </ng-container>
                </li>
            </ng-container>

        </ul>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxLayout="column">
            <button data-test="B-Abbrechen" mat-button
                    class="mt-auto"
                    (click)="close()"
                    [disabled]="isProcessing && !canStartPaymentProcess"
                    aria-label="Dialog schließen">
                <mat-icon>cancel</mat-icon>
                Fenster schließen
            </button>
        </div>
        <ng-container *ngIf="canStartPaymentProcess">
            <div fxLayout="column">
                <button data-test="B-Bezahlen" mat-flat-button
                        color="accent"
                        class="mt-auto"
                        style="min-width: 200px"
                        (click)="startPaymentProcess()"
                        [disabled]="isProcessing && !canStartPaymentProcess"
                        aria-label="Bezahlen">
                    <mat-icon>euro</mat-icon>
                    Bezahlen
                </button>
            </div>
        </ng-container>
        <!--        <div fxLayout="column">-->
        <!--            <button data-test="B-Kassenbuch anzeigen" mat-button-->
        <!--                    (click)="navigateToCashRegister()"-->
        <!--                    [disabled]="!receipt?.id || isProcessing || !requestReceiptDone || (receiptErrorMessages?.length > 0)"-->
        <!--                    aria-label="Kassenbuch anzeigen">-->
        <!--                <mat-icon>point_of_sale</mat-icon>-->
        <!--                Kassenbuch anzeigen-->
        <!--            </button>-->
        <!--            <button data-test="B-Beleg anzeigen" mat-button-->
        <!--                    class="mt-16"-->
        <!--                    (click)="showReceiptPdf()"-->
        <!--                    [disabled]="!receipt?.id || isProcessing || !requestReceiptDone || (receiptErrorMessages?.length > 0)"-->
        <!--                    aria-label="Beleg anzeigen">-->
        <!--                <mat-icon>insert_drive_file</mat-icon>-->
        <!--                Beleg als PDF anzeigen-->
        <!--            </button>-->
        <!--        </div>-->
    </div>

</div>
