import { tryParseDate } from '@shared/functions/try-parse-date';
import { BcmUser } from '@modules/bcm/bcm-user';
import { BcmPaymentType, IPerson, paymentTypeTranslationDe, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';
import { financialRecordTypeTranslationsDe, InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { Tenant } from '@shared/models/tenant';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmFile } from '@shared/models/bcm-file';
import { BcmSettings } from '@shared/models/bcm-settings';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { toDateStringDE } from '@core/functions/to-date-string';

export enum FinancialRecordStatus {
    Open = 'open',
    Reopen = 'reopen',
    Payed = 'payed',
    PaidPartially = 'paid_partially',
    Canceled = 'canceled',
    Error = 'error',
    Done = 'done'
}

export const financialRecordStatusTranslationsGer: { [key in FinancialRecordStatus]: string } = {
    [FinancialRecordStatus.Open]: 'OFFEN',
    [FinancialRecordStatus.Payed]: 'BEZAHLT',
    [FinancialRecordStatus.PaidPartially]: 'TEILBEZAHLT',
    [FinancialRecordStatus.Canceled]: 'STORNIERT',
    [FinancialRecordStatus.Error]: 'FEHLER',
    [FinancialRecordStatus.Done]: 'ABGESCHLOSSEN',
    [FinancialRecordStatus.Reopen]: 'OFFEN|FEHLER'
};

export interface BcmFinancialRecordPdfData {
    testRun?: boolean;
    tenantRelation: string;
    debtorNumber: string;
    invoiceNumber: string;
    bcmSettings: BcmSettings;
    paymentType: BcmPaymentType;
    tenant: Tenant;
    address: string;
    date: Date;
    dueDate?: Date;
    note?: string;
    positions: InvoicePosition[];
    priceData: TotalPrices;
    invoiceLogo: BcmFile;
    iban: string;
    qrData?: string;
    wordTemplate?: BcmInvoiceWdTemplate;
}

export interface TotalPrices {
    totalNetPrice: number;
    totalGrossPrice: number;
    totalTaxes: { [key: number]: number };
    totalTax?: number;
    totalTaxFreeProductsPrice?: number;
}

export interface BcmFinancialRecordDto {
    number: string;

    invoice?: IInvoice;
    receipt?: BcmReceiptDto;

    person?: IPerson;
    company?: ICompany;

    customerName: string;
    address: string;

    type: InvoiceTypes | ReceiptTypes;
    status: FinancialRecordStatus;

    cancellationRecord?: BcmFinancialRecordDto;
    cancellationNumber?: string;
    cancellationReason?: string;

    reopenReason: string;
    reopenDescription: string;

    date: Date;
    dueDate: Date;

    insertedOn?: string;
    createdBy?: BcmUser;

    total: number;
    creatorName: string;
    isSEPA: number;
}

export class BcmFinancialRecord {

    static fakeId = 1;

    id: number;
    number: string;

    invoice?: Invoice;
    receipt?: BcmReceipt;

    person?: Person;
    company?: Company;

    customerName: string;
    address: string;

    type: InvoiceTypes | ReceiptTypes;
    status: FinancialRecordStatus;

    cancellationRecord?: BcmFinancialRecord;
    cancellationNumber?: string;
    cancellationReason?: string;

    reopenReason: string;
    reopenDescription: string;

    date: Date;
    dueDate: Date;

    insertedOn?: Date;
    createdBy: BcmUser;

    paymentTypeDe: string;

    total: number;
    creatorName: string;

    // helpers
    numberTypeAbbreviation?: 'G' | 'S' | 'SG';
    numberTooltip?: string;

    statusText?: string;
    statusTooltip?: string;
    typeText?: string;

    isSEPA: boolean;

    constructor(dto: BcmFinancialRecordDto) {
        this.id = BcmFinancialRecord.fakeId++;
        this.number = dto.number;

        this.invoice = dto.invoice ? new Invoice(dto.invoice) : null;
        this.receipt = dto.receipt ? new BcmReceipt(dto.receipt) : null;

        this.person = dto.person ? new Person(dto.person) : null;
        this.company = dto.company ? new Company(dto.company) : null;

        if (this.invoice?.id) {
            this.invoice.person = this.person;
            this.invoice.company = this.company;
        }

        this.customerName = dto.customerName;
        this.address = dto.address;

        this.type = dto.type;
        this.status = dto.status;

        this.cancellationRecord = dto.cancellationRecord ? new BcmFinancialRecord(dto.cancellationRecord) : null;
        this.cancellationNumber = dto.cancellationNumber || null;
        this.cancellationReason = this.invoice?.cancellationReason || null;

        this.reopenReason = dto.reopenReason;
        this.reopenDescription = dto.reopenDescription;

        this.date = tryParseDate(dto.date);
        this.dueDate = tryParseDate(dto.dueDate);

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.createdBy = dto.createdBy ? new BcmUser(dto.createdBy) : null;

        this.paymentTypeDe = paymentTypeTranslationDe[this.invoice?.paymentType || this.receipt?.paymentType];

        this.total = dto.total;
        this.creatorName = dto.creatorName;
        this.isSEPA = dto.isSEPA === 1;

        this.setupHelpers();
    }

    public get reopenDescriptionText(): string {
        return (this.reopenReason === 'return-debit' ? 'Rücklastschrift' : (this.reopenDescription?.length > 0 ? this.reopenDescription : 'Zahlung fehlgeschlagen'));
    }

    public setupHelpers() {
        this.setupNumberHelpers();
        this.setupStatusHelpers();
        this.setupTypeHelpers();
    }

    private setupNumberHelpers() {
        if (this.invoice != null) {
            switch (this.invoice.invoiceType) {
                case InvoiceTypes.Crediting:
                    this.numberTypeAbbreviation = 'G';
                    this.numberTooltip = 'Gutschrift';
                    break;
                case InvoiceTypes.CancellationInvoice:
                    this.numberTypeAbbreviation = 'S';
                    this.numberTooltip = 'Stornorechnung';
                    break;
                case InvoiceTypes.CancellationCrediting:
                    this.numberTypeAbbreviation = 'SG';
                    this.numberTooltip = 'Stornogutschrift';
                    break;
            }
        }
        if (this.receipt != null) {
            switch (this.receipt.receiptType) {
                case 'cancellationReceipt':
                    this.numberTypeAbbreviation = 'S';
                    this.numberTooltip = 'Stornorechnung';
                    break;
            }
        }
    }

    private setupStatusHelpers() {
        this.statusText = financialRecordStatusTranslationsGer[this.status] || undefined;

        switch (this.status) {
            case FinancialRecordStatus.Reopen:
                this.statusTooltip = this.reopenDescriptionText;
                break;
            case FinancialRecordStatus.Payed:
                if (this.invoice?.payedOn) {
                    this.statusTooltip = 'bezahlt am ' + toDateStringDE(this.invoice.payedOn);
                }
                break;
        }
    }

    private setupTypeHelpers() {
        this.typeText = financialRecordTypeTranslationsDe[this.type] || '';
    }
}


