import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { WinterStorage } from '@shared/models/winter-storage';
import { WinterStorageApiService } from '@bcmApiServices/winter-storage.api-service';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-winter-storage',
    templateUrl: './form-widget-winter-storage.component.html'
})
export class FormWidgetWinterStorageComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    headline = 'Lagerplatz';

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    topListLabel = `Lagerplätze von ${this.personOrCompanyName}`;

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    set selectWinterStorage(value: WinterStorage) {
        if (value?.id || value?.id !== this._selectWinterStorage?.id) {
            this._selectWinterStorage = value;
        }
    }

    get selectWinterStorage(): WinterStorage {
        return this._selectWinterStorage;
    }

    private _selectWinterStorage: WinterStorage;

    @Input()
    givenWinterStorages: WinterStorage[];

    @Input()
    personOrCompanyName?: string;

    @Input()
    winterStorageRequired = true;

    @Input()
    slimmedView = false;

    winterStorageFormGroup: UntypedFormGroup;

    winterStorages: WinterStorage[];

    winterStorage: WinterStorage;

    loading: boolean;

    topList: WinterStorage[] = [];

    bottomList: WinterStorage[] = [];

    filteredTopList$: Observable<WinterStorage[]>;

    filteredBottomList$: Observable<WinterStorage[]>;

    private _unsubscribeAll = new Subject();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _winterStorageApiService: WinterStorageApiService) {
    }

    ngOnInit(): void {
        this.winterStorage = this.selectWinterStorage;

        this._createForm();
        this._loadWinterStorages(this.givenWinterStorages);

        setTimeout(() => {
            this.parentFormGroup.addControl('winterStorageForm', this.winterStorageFormGroup);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.winterStorageFormGroup) {
            return;
        }

        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'givenWinterStorages': {
                        this._loadWinterStorages(this.givenWinterStorages);
                    }
                }
            }
        }

        if (this.selectWinterStorage?.id) {
            this.winterStorageFormGroup.patchValue({
                winterStorage: this.selectWinterStorage
            });
        }


    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _createForm(): void {
        this.winterStorageFormGroup = this._formBuilder.group({
            winterStorage: [this.selectWinterStorage, this.winterStorageRequired ? [U2bValidators.required('Bitte Lagerplatz angeben')] : []],
        });

        this.winterStorageFormGroup
            .get('winterStorage')
            .valueChanges
            .subscribe((value: WinterStorage) => {
                this.winterStorage = value;
            });
    }

    private _loadWinterStorages(givenWinterStorages: WinterStorage[] = []): void {

        this.loading = true;

        this._winterStorageApiService
            .getAll()
            .subscribe((winterStorages) => {
                for (const winterStorage of winterStorages) {
                    if (givenWinterStorages.find(givenWinterStorage => givenWinterStorage.id === winterStorage.id)) {
                        this.topList.push(winterStorage);
                    } else {
                        this.bottomList.push(winterStorage);
                    }
                }
            })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.winterStorageFormGroup.get('winterStorage').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterWinterStorages(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.winterStorageFormGroup.get('winterStorage').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterWinterStorages(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterWinterStorages(filter: string, winterStorages: WinterStorage[]): WinterStorage[] {
        return winterStorages.filter(winterStorage => winterStorage.handle.toLowerCase().includes(filter.toLowerCase()));
    }

    public displayWinterStorageWith(winterStorage: WinterStorage): string {
        if (!winterStorage?.id) {
            return '';
        } else {
            return winterStorage.handle;
        }
    }

    onClickRemoveWinterStorage(): void {
        this.winterStorageFormGroup.get('winterStorage').setValue(null);
        this.winterStorageFormGroup.markAsDirty();
    }
}
