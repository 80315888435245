import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { DateValidValidator } from './date-valid.validator';
import { PhoneGroupValidator } from './phone-group.validator';
import { MailGroupValidator } from './mail-group.validator';
import { U2BRequiredValidator } from '@shared/validators/core/required';
import { U2BPatternValidator } from '@shared/validators/core/pattern';
import { U2BEmailValidator } from '@shared/validators/core/email';
import { U2BMinlengthValidator } from '@shared/validators/core/min-length';
import { U2BMaxlengthValidator } from '@shared/validators/core/max-length';
import { U2BRequiredTrueValidator } from '@shared/validators/core/required-true';
import { Tenant } from '@shared/models/tenant';
import { U2BPostcodeValidator } from '@shared/validators/core/postcode';

export class U2bValidators {

    // Angular wrapped core validators
    public static required(message?: string): ValidatorFn {
        return (control: AbstractControl) => new U2BRequiredValidator(message).validate(control);
    }

    public static requiredTrue(message?: string): ValidatorFn {
        return (control: AbstractControl) => new U2BRequiredTrueValidator(message).validate(control);
    }

    public static pattern(pattern: RegExp, message?: string): ValidatorFn {
        return (control: AbstractControl) => new U2BPatternValidator(pattern, message).validate(control);
    }

    public static postCode(tenant: Tenant): ValidatorFn {
        return (control: AbstractControl) => new U2BPostcodeValidator(tenant).validate(control);
    }

    public static email(message?: string, optional = false): ValidatorFn {
        return new U2BEmailValidator(message, optional).asValidatorFn();
    }

    public static minLength(minLength: number, message?: string): ValidatorFn {
        return new U2BMinlengthValidator(minLength, message).asValidatorFn();
    }

    public static maxLength(maxLength: number, message?: string): ValidatorFn {
        return new U2BMaxlengthValidator(maxLength, message).asValidatorFn();
    }

    // custom validators
    public static requiredIf(callback: () => boolean, message?: string): ValidatorFn {
        return (control: AbstractControl) => new U2BRequiredValidator(message, callback).validate(control);
    }

    // mail and phone
    public static phoneGroup(message?: string): ValidatorFn {
        return (control: AbstractControl) => new PhoneGroupValidator(message).validate(control);
    }

    public static mailGroup(message?: string): ValidatorFn {
        return (control: AbstractControl) => new MailGroupValidator(message).validate(control);
    }

    // Dates
    public static dateValid(message?: string): AsyncValidatorFn {
        return (control) => new DateValidValidator(message).validate(control);
    }

    // Dates
    public static ip(version: 4 | 6, message = 'IP Adresse ungültig'): ValidatorFn {
        switch (version) {
            case 4:
                return this.pattern(
                    /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/,
                    message
                );
            case 6:
                return this.pattern(
                    /^((?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(:[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(:[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(:[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(:[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,6}|:)|(?::((?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$/,
                    message
                );
        }
    }
}
