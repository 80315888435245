import { IPhoneNumber } from './phone-number';
import { IMail } from './mail';
import { BcmPaymentType, SEPAMandatType } from './person';
import { IBankAccount } from '@shared/models/bank-account';
import { isArray } from '@shared/functions/is-array';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { IFile } from '@shared/interfaces/file';
import { Boat, IBoat } from '@shared/models/boat';
import { ProductSubscription } from '@shared/models/product-subscription';
import { InvoicePosition } from '@shared/models/invoice-position';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { CompanyContact, ICompanyContact } from '@shared/models/company-contact';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmVoucher, BcmVoucherDto } from '@shared/models/bcm-voucher';
import { Country } from '@shared/models/country';

export interface ICompany {
    bankAccount: IBankAccount;
    id: number;
    identNumber: number;
    name: string;
    fullName: string;
    form: string;
    fullAddress: string;
    street: string;
    postCode: string;
    primaryPhone: string;
    primaryMail: string;
    salutationMail: string;
    phone: string;
    mail: string;
    city: string;
    country: Country;
    country_old: string;
    country_code: string;
    note: string;
    phones: IPhoneNumber[];
    mails: IMail[];
    companyContactsOrigin: ICompanyContact[];
    companyContacts: ICompanyContact[];
    contacts: ICompanyContact[];
    boatsOrigin: IBoat[];
    boats: IBoat[];
    positions: InvoicePosition[];
    subscriptions: ProductSubscription[];
    invoices: IInvoice[];
    documentsOrigin: BcmDocumentDto[];
    documents: BcmDocumentDto[];
    website: string;
    tenantRelationAssignments: TenantRelationAssignmentDto[];
    image: null;

    // hack until I have a class / Interface for InvoiceRecipient
    totalGrossPrice: number;
    totalNetPrice: number;

    accountHolder: string;
    IBAN: string;
    BIC: string;
    BLZ: string;
    KtoNr: string;
    Kreditinstitut: boolean;

    SEPAMandat: boolean;
    SEPAMandatId: string;
    SEPAMandatFile: IFile;
    SEPAMandatSignDate: string;
    SEPAMandatType: SEPAMandatType;
    SEPAMandatLastDirectDebitOn: string | Date;

    invoicesByMail: boolean;
    invoicesByPost: boolean;
    invoicePaymentType: BcmPaymentType;
    sortIndex?: number;
    vatId: string;
    taxNumber: string;

    entryDate: string | null;
    exitDate: string | null;

    isOwnerSince: string | null;
    isOwnerUntil: string | null;

    berthAssignments?: IBerthBoatAssignment[];

    vouchers: BcmVoucherDto[];
}

export class Company {

    private tenantRelationAssignments: TenantRelationAssignment[];

    bankAccount: IBankAccount;
    id: any;
    identNumber: any;
    city: string;
    country: Country;
    countryOld: string;
    countryCode: string;
    form: string;
    fullAddress: string;
    name: string;
    fullName: string;
    postCode: string;
    primaryPhone: string;
    primaryMail: string;
    salutationMail: string;
    phone: string;
    mail: string;
    street: string;
    note: string;
    phones: IPhoneNumber[] = [];
    mails: IMail[] = [];
    companyContactsOrigin: CompanyContact[] = [];
    companyContacts: CompanyContact[] = [];
    boatsOrigin: Boat[];
    boats: Boat[];
    positions: InvoicePosition[];
    subscriptions: ProductSubscription[];
    invoices: Invoice[];
    documentsOrigin: BcmDocument[];
    documents: BcmDocument[];
    website: string;
    image: null;

    // hack until I have a class / Interface for InvoiceRecipient
    totalGrossPrice: number;
    totalNetPrice: number;

    accountHolder: string;
    IBAN: string;
    BIC: string;
    BLZ: string;
    KtoNr: string;
    Kreditinstitut: boolean;

    SEPAMandat: boolean;
    SEPAMandatId: string;
    SEPAMandatFile: IFile;
    SEPAMandatSignDate: Date;
    SEPAMandatType: SEPAMandatType;
    SEPAMandatLastDirectDebitOn: string | Date;

    invoicesByMail: boolean;
    invoicesByPost: boolean;
    invoicePaymentType: BcmPaymentType;
    sortIndex?: number;
    vatId: string;
    taxNumber: string;

    entryDate: Date | null;
    exitDate: Date | null;

    isOwnerSince: Date | null;
    isOwnerUntil: Date | null;

    // search and filter helpers
    searchActiveTenantRelations: string;

    berthAssignments?: BerthBoatAssignment[];

    vouchers: BcmVoucher[];

    public get allTenantRelationAssignments(): TenantRelationAssignment[] {
        return this.tenantRelationAssignments;
    }

    public get activeTenantRelationAssignments(): TenantRelationAssignment[] {
        const today = Date.now();

        return this.tenantRelationAssignments
            .filter(tenantRelationAssignment => (tenantRelationAssignment.toDate == null || +tenantRelationAssignment.toDate >= today)
                && +tenantRelationAssignment.fromDate <= today);
    }

    public get activeTenantRelationAssignmentNames(): string {
        return this.activeTenantRelationAssignments.map(tenantRelationAssignment => tenantRelationAssignment.tenantRelation.name).join(', ');
    }

    public get nextTenantRelationAssignments(): TenantRelationAssignment[] {
        const today = Date.now();

        return this.tenantRelationAssignments
            .filter(tenantRelationAssignment => +tenantRelationAssignment.fromDate > today);
    }

    public get activeAndNextTenantRelationAssignments(): TenantRelationAssignment[] {
        return [
            ...this.activeTenantRelationAssignments,
            ...this.nextTenantRelationAssignments
        ];
    }

    // public get previousTenantRelations(): TenantRelationAssignment[] {
    //     const today = Date.now();
    //
    //     return this.tenantRelations
    //         .filter(tr => +tr.toDate < today && +tr.fromDate < today);
    // }

    public get firstActiveTenantRelationAssignment(): TenantRelationAssignment {
        return this.activeTenantRelationAssignments[0];
    }

    constructor(company = {} as ICompany, skipBoats = false) {
        this.id = company.id;
        this.identNumber = company.identNumber;
        this.city = company.city;
        this.country = company.country;
        this.countryOld = company.country_old;
        this.countryCode = company.country_code;
        this.form = company.form;
        this.name = company.name;
        this.fullName = company.name;
        this.postCode = company.postCode;
        this.street = company.street;
        this.website = company.website;
        this.bankAccount = company.bankAccount;
        this.mails = company.mails || [];
        this.phones = company.phones || [];
        this.note = company.note;
        this.salutationMail = company.salutationMail;
        this.tenantRelationAssignments = (company.tenantRelationAssignments || []).map(tr => new TenantRelationAssignment(tr)) || [];

        this.fullAddress = this.getAddressString();

        this._setPrimaryMail(company.mails);
        this._setPrimaryPhone(company.phones);

        this.companyContacts = (company.companyContacts || []).map(contact => new CompanyContact(contact));

        this.companyContactsOrigin = this.companyContacts.slice();

        if (!skipBoats) {
            this.boats = (company.boats || []).map(boat => new Boat(boat));
            this.boatsOrigin = this.boats.slice();
        }

        this.positions = (company.positions || []).map(pos => new InvoicePosition(pos));
        this.subscriptions = (company.subscriptions || []).map(subscription => new ProductSubscription(subscription));
        this.invoices = (company.invoices || []).map(invoice => new Invoice(invoice));

        this.totalGrossPrice = company.totalGrossPrice;
        this.totalNetPrice = company.totalNetPrice;

        this.accountHolder = company.accountHolder;
        this.IBAN = company.IBAN;
        this.BIC = company.BIC;
        this.BLZ = company.BLZ;
        this.KtoNr = company.KtoNr;
        this.Kreditinstitut = company.Kreditinstitut;

        this.SEPAMandat = company.SEPAMandat;
        this.SEPAMandatId = company.SEPAMandatId;
        this.SEPAMandatFile = company.SEPAMandatFile;
        this.SEPAMandatSignDate = tryParseDate(company.SEPAMandatSignDate);
        this.SEPAMandatType = company.SEPAMandatType;
        this.SEPAMandatLastDirectDebitOn = company.SEPAMandatLastDirectDebitOn;

        this.invoicesByMail = company.invoicesByMail;
        this.invoicesByPost = company.invoicesByPost;
        this.invoicePaymentType = company.invoicePaymentType;
        this.sortIndex = company?.sortIndex;

        this.documents = company.documents?.map(document => new BcmDocument(document)) || [];
        this.vatId = company.vatId;
        this.taxNumber = company.taxNumber;

        this.entryDate = tryParseDate(company.entryDate);
        this.exitDate = tryParseDate(company.exitDate);

        this.isOwnerSince = tryParseDate(company.isOwnerSince);
        this.isOwnerUntil = tryParseDate(company.isOwnerUntil);

        // search and filter helpers
        this.searchActiveTenantRelations = this.activeTenantRelationAssignmentNames;

        this.berthAssignments = (company?.berthAssignments || []).map(berthAssignment => new BerthBoatAssignment(berthAssignment)).reverse();

        this.vouchers = (company?.vouchers || []).map(v => new BcmVoucher(v));

    }

    get streetWithoutNumber(): string {
        try {
            const streetParts = this.street.split(' ');
            return streetParts.splice(streetParts.length - 1, 1).join(' ');
        } catch (e) {
            return this.street;
        }
    }

    get streetNumber(): string {
        try {
            const streetParts = this.street.split(' ');
            return streetParts[streetParts.length - 1];
        } catch (e) {
            return '';
        }
    }

    toString(): string {
        return `${this.fullName}`.trim();
    }

    toDropdownString(): string {
        return `${this.fullName}`.trim() + (this.identNumber ? `(${this.identNumber})` : '');
    }

    addTenantRelation(tenantRelationAssignment: TenantRelationAssignment): void {
        this.tenantRelationAssignments.unshift(tenantRelationAssignment);
        this.tenantRelationAssignments = this.tenantRelationAssignments.slice();
    }

    removeTenantRelation(tenantRelationAssignment: TenantRelationAssignment): void {
        this.tenantRelationAssignments = this.tenantRelationAssignments.filter((t) => t.id !== tenantRelationAssignment.id);
        this.tenantRelationAssignments = this.tenantRelationAssignments.slice();
    }

    public getAddressString(separator = ', ', includeName = false, includeForm = false, includeCountry = false): string {
        const address = [];
        let helper = '';

        if (includeName && this.name) {
            const name = this.name.trim();
            let form = '';

            if (includeForm && this.form && !name.endsWith(this.form)) {
                form = this.form;
            }

            address.push(`${name} ${form}`.trim());
        }

        if (this.street) {
            address.push(this.street);
        }

        if (separator === '\n') {
            address[address.length - 1] += separator;
        }

        if (this.postCode) {
            helper = this.postCode;
        }

        if (this.city) {
            helper += ' ' + this.city;
        }

        if (helper) {
            address.push(helper);
        }

        if (includeCountry && this.country?.de) {
            address.push(this.country?.de);
        }

        return address.join(separator);
    }

    private _setPrimaryPhone(phones: IPhoneNumber[]): void {
        phones = isArray(phones) ? phones : [];
        if (!!phones && phones.length) {
            this.primaryPhone = this.phone = (phones.find((phone) => phone.isPrimary) || phones[0]).number;
            return;
        }

        this.primaryPhone = this.phone = '';
    }

    private _setPrimaryMail(mails: IMail[]): void {
        mails = isArray(mails) ? mails : [];
        if (!!mails && mails.length) {
            this.primaryMail = this.mail = (mails.find((mail) => mail.isPrimary) || mails[0]).mail;
            return;
        }

        this.primaryMail = this.mail = '';
    }
}
