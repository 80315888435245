import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import { Boat, IBoat } from '@shared/models/boat';
import { IReadingRaw, Reading } from './electric-meter-reading';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';

export interface IElectricMeterAssignment {
    id?: number;
    from: string;
    to?: string;
    meterReadingStart: number;
    meterReadingEnd?: number;
    electricMeter?: IElectricMeter;
    boat?: IBoat;
    reading?: IReadingRaw;
    readings?: IReadingRaw[];
    paymentType: ElectricMeterPaymentType;
}

export class ElectricMeterAssignment {
    id?: number;
    from: Date;
    to?: Date;
    meterReadingStart: number;
    meterReadingEnd?: number;
    electricMeter?: ElectricMeter;
    boat?: Boat;
    reading?: Reading;
    readings?: Reading[];
    paymentType: ElectricMeterPaymentType;

    constructor(data = {} as IElectricMeterAssignment) {
        this.id = data.id;
        this.from = tryParseDate(data.from);
        this.to = tryParseDate(data.to);
        this.meterReadingStart = data.meterReadingStart;
        this.meterReadingEnd = data.meterReadingEnd;
        this.electricMeter = data.electricMeter ? new ElectricMeter(data.electricMeter) : null;
        this.boat = data.boat ? new Boat(data.boat) : null;
        this.reading = data.reading ? new Reading(data.reading) : null;
        this.readings = (data.readings || []).map(reading => new Reading(reading));
    }
}
