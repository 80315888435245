import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { merge, Observable } from 'rxjs';
import { ProductsApiService } from '@modules/bcm/@shared/services';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { IProduct, Product } from '@shared/models/product';
import { U2bProductValidators } from '@shared/validators/product/product-validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';

@Component({
    selector: 'form-widget-berth-fee',
    templateUrl: './form-widget-berth-fee.component.html',
})
export class FormWidgetBerthFeeComponent implements OnInit {

    @Input()
    headline = 'Liegeplatzgebühr';

    @Input()
    parentFormGroup: UntypedFormGroup;

    berthFeeFormGroup: UntypedFormGroup;

    products: Product[];

    filteredProducts$: Observable<Product[]>;

    totalPrice: number;

    loadingProducts = false;

    addBerthFee = false;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _productsApiService: ProductsApiService) {
        this._createForm();
        this._loadProducts();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('berthFeeForm', this.berthFeeFormGroup);
        });
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    private _createForm(): void {

        this.berthFeeFormGroup = this._formBuilder.group({
            product: [null, [U2bValidators.required('Bitte Produkt aus der Liste auswählen'), U2bProductValidators.productExists()]],
            quantity: [null, [U2bValidators.required('Bitte Menge angeben'), U2bNumericValidators.numberMin(0, 'Die Menge kann nicht negativ sein.')]],
        });

        const form = this.berthFeeFormGroup;
        const productField = form.get('product');
        const quantityField = form.get('quantity');

        productField.disable();
        quantityField.disable();

        merge(productField.valueChanges, quantityField.valueChanges)
            .pipe(startWith([productField.valueChanges, quantityField.value]))
            .subscribe(() => this._calculateTotalPrice((productField.value || {price: 0}).price, quantityField.value));

        this.filteredProducts$ = productField.valueChanges
            .pipe(
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterProducts(value) : this.products.slice())
            );
    }

    addBerthFeeChange(value): void {
        const productField = this.berthFeeFormGroup.get('product');
        const quantityField = this.berthFeeFormGroup.get('quantity');

        if (value) {
            productField.enable();
            quantityField.enable();
        } else {
            productField.disable();
            quantityField.disable();
        }
    }

    private _filterProducts(filter = ''): Product[] {
        return this.products.filter((product: Product) => product.name.toLowerCase().includes(filter.toLowerCase()));
    }

    private _calculateTotalPrice(price = 0, quantity = 0): void {
        this.totalPrice = (price * quantity);
    }

    private _loadProducts(): void {
        this.products = [];
        this.loadingProducts = true;
        this._productsApiService
            .getAll()
            .subscribe(products => {
                this.products = products.filter(p => ['Liegeplatzgebühr'].includes(p.category.name) || ['Liegeplatzgebühr'].includes(p.category.parentCategory?.name));
            })
            .add(() => this.loadingProducts = false);
    }
}
