import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { BcmWorkflowApiService } from '@bcmApiServices/bcm-workflow-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contract, ContractFileType } from '@shared/models/contract';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';

@Component({
    selector: 'berth-allocation',
    templateUrl: './berth-allocation.component.html',
    styleUrls: ['./berth-allocation.component.scss']
})
export class BerthAllocationComponent implements OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    formGroupPrepare: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    persistFormData = false;

    filteredContracts: Contract[];

    person$ = new EventEmitter<Person>();

    company$ = new EventEmitter<Company>();

    isSaving: boolean;

    constructor(private _dialogRef: MatDialogRef<BerthAllocationComponent>,
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _bcmWorkflowApiService: BcmWorkflowApiService,
                private _formBuilder: UntypedFormBuilder) {
        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.formGroupPrepare = this._formBuilder.group({
            type: [null, [U2bValidators.required('Bitte wähle eine Option aus.')]]
        });

        this.formGroup = this._formBuilder.group({});

        this.formGroup.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                if (value?.boatForm?.boat?.owner) {
                    this.formGroupPrepare.get('type').setValue('person');
                    this.person$.emit(value?.boatForm?.boat?.owner);
                } else if (value?.boatForm?.boat?.ownerCompany) {
                    this.formGroupPrepare.get('type').setValue('organisation');
                    this.company$.emit(value?.boatForm?.boat?.ownerCompany);
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickClose(): void {

        if (!this.persistFormData && (this.formGroupPrepare.dirty || this.formGroup.dirty)) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    text: 'Ja, beenden'
                })
                .setNoButton({
                    text: 'Abbrechen'
                })
                .openWithCallback(() => this._dialogRef.close());
        }

        this._dialogRef.close();
    }

    onClickSaveAndClose(): void {
        if (this.formGroup.invalid || this.formGroupPrepare.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroupPrepare.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const {
                berthForm,
                berthFeeForm,
                electricMeterForm,
                boatForm,
                personForm,
                companyForm,
                tenantRelationForm,
                contractForm,
                contractCoOwnerForm,
            } = this.formGroup.value;

            const body: any = {
                berth: {
                    id: berthForm.berth.id,
                    from: berthForm.from,
                    to: berthForm.to,
                    note: berthForm.note
                },
                berthFee: {
                    product: berthFeeForm?.product,
                    quantity: berthFeeForm?.quantity,
                }
            };

            if (electricMeterForm.addElectricMeter === true && electricMeterForm?.electricMeter) {
                const {electricMeter, meterReadingStart, meterReadingEnd} = electricMeterForm;
                body['electricMeter'] = {
                    id: electricMeter.id,
                    meterReadingStart,
                    meterReadingEnd,
                };
            }

            if (boatForm) {
                let boat: any = {};

                if (boatForm.boat && boatForm.boat.id) {
                    boat.id = boatForm.boat.id;
                } else {
                    delete boatForm.boat;
                    boat = {...boatForm};
                }

                body['boat'] = boat;
            }

            if (personForm) {
                let person: any = {};

                if (personForm.person && personForm.person.id) {
                    person.id = personForm.person.id;
                } else {
                    delete personForm.person;
                    person = {...personForm};
                }

                body['person'] = person;
            }

            if (companyForm) {
                let company: any = {};

                if (companyForm.company && companyForm.company.id) {
                    company.id = companyForm.company.id;
                } else {
                    delete companyForm.company;
                    company = {...companyForm};
                }

                body['company'] = company;
            }

            if (tenantRelationForm) {
                body['tenantRelation'] = tenantRelationForm.tenantRelation;
                if (tenantRelationForm?.tenantRelation?.products) {
                    body['products'] = tenantRelationForm.tenantRelation.products;
                }
            }

            if (contractForm) {
                body['contract'] = contractForm;
                body['contractFileType'] = ContractFileType[contractForm.contractFileType];

                if (contractForm.contract?.isWordTemplate && !contractForm.contract?.templateMailFile && !contractForm.contract?.templatePrintFile) {
                    this._appNotificationService.showError('Der Vertrag kann nicht erstellt werden, da kein Word Dokument hinterlegt wurde.');
                    this.isSaving = false;
                    return;
                }
            }

            if (contractCoOwnerForm?.name) {
                body['contractCoOwner'] = contractCoOwnerForm?.name;
            }

            this._bcmWorkflowApiService
                .berthAllocation(body)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    () => {
                        this._dialogRef.close();
                        this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');
                        // this.deleteStoredFormData();
                    },
                    (error) => {
                        this._appNotificationService.showError(error);
                    }
                )
                .add(() => this.isSaving = false);
        }
    }
}
