import { ValidatorFn } from '@angular/forms';
import { IsElectricMeterValidator } from '@shared/validators/electric-meter/is-electric-meter';
import { IsElectricMeterCabinetValidator } from '@shared/validators/electric-meter/is-electric-meter-cabinet';

export class U2bElectricMeterValidators {
    public static isElectricMeter(message?: string): ValidatorFn {
        return new IsElectricMeterValidator(message).asValidatorFn();
    }

    public static isElectricMeterCabinet(message?: string): ValidatorFn {
        return new IsElectricMeterCabinetValidator(message).asValidatorFn();
    }
}
