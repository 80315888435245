<ul>
    <li>
        <div fxLayout="row">
            <span class="check-info">Prüfe Add-on "Barzahlung" ..</span>
            <ng-container *ngIf="!checkAddonDone">
                <mat-spinner [diameter]="20"></mat-spinner>
            </ng-container>
        </div>

        <ul>
            <ng-container *ngIf="checkAddonDone">
                <ng-container *ngIf="addonStatus">
                    <li>
                        <div fxLayout="row">
                            <span class="check-info">OK</span>
                            <mat-icon color="accent">checkmark</mat-icon>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="!addonStatus">
                    <li>
                        <div fxLayout="row">
                            <span class="check-info"><b>Add-on "Barzahlung" nicht freigeschaltet.<br>Bitte wende dich an den Up2Boat Support.</b></span>
                            <mat-icon color="warn">error</mat-icon>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>

    </li>


    <ng-container *ngIf="addonStatus">
        <li>
            <div fxLayout="row">
                <span class="check-info">Prüfe Verbindung zur Kasse ..</span>
                <ng-container *ngIf="!checkCashRegisterDone">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-container>
            </div>

            <ng-container *ngIf="checkCashRegisterDone">
                <ul>
                    <ng-container *ngIf="cashRegister?.id">
                        <li>
                            <div fxLayout="row">
                                <span class="check-info">Kasse: {{ cashRegister.title }}</span>
                                <mat-icon color="accent">checkmark</mat-icon>
                            </div>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!cashRegister?.id">
                        <li>
                            <div fxLayout="row">
                                <span class="check-info"><b>Du bist aktuell an keiner Kasse angemeldet</b></span>
                                <mat-icon color="warn">error</mat-icon>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>

            <ng-container *ngIf="cashRegister?.id && checkCancel">
                <ul>
                    <ng-container *ngIf="checkCancel && cashRegister.currentUser.canCancel; else cannotCancel">
                        <li>
                            <div fxLayout="row">
                                <span class="check-info">Benutzer kann stornieren</span>
                                <mat-icon color="accent">checkmark</mat-icon>
                            </div>
                        </li>
                    </ng-container>
                    <ng-template #cannotCancel>
                        <li>
                            <div fxLayout="row">
                                <span class="check-info"><b>Du kannst keine Belege stornieren.</b></span>
                                <mat-icon color="warn">error</mat-icon>
                            </div>
                        </li>
                    </ng-template>
                </ul>
            </ng-container>
        </li>
    </ng-container>

</ul>
