<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="{{confirmTitleTheme ? 'mat-' + confirmTitleTheme : 'mat-accent'}} m-0"
                 cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{confirmTitle || 'Auswahl bestätigen'}}</span>

            <ng-container *ngIf="!dialogRef.disableClose">
                <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close()"
                        aria-label="Dialog schließen">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-container>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <div [innerHTML]="confirmBody || 'Möchtest Du die Aktion wirklich ausführen?'"></div>

        <ng-container *ngIf="confirmDialogFormControls?.length">
            <div [formGroup]="formGroup" fxLayout="column" class="mt-24">
                <ng-container *ngFor="let controlCfg of confirmDialogFormControls">
                    <ng-container [ngSwitch]="controlCfg.type">
                        <ng-container *ngSwitchCase="'checkbox'">
                            <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                                <mat-checkbox [attr.data-test]="'FCB-' + controlCfg.label"
                                              [formControlName]="controlCfg.name">
                                    {{controlCfg.label}}
                                </mat-checkbox>
                                <textarea matInput hidden [formControlName]="controlCfg.name"></textarea>
                                <mat-error>{{formGroup.get(controlCfg.name).errors | firstErrorMessage}}</mat-error>
                                <mat-hint *ngIf="controlCfg.hint">{{controlCfg.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                                <mat-label>{{controlCfg.label}}</mat-label>
                                <input matInput
                                       [formControlName]="controlCfg.name"
                                       [attr.data-test]="'FI-' + controlCfg.label">
                                <mat-error>{{formGroup.get(controlCfg.name).errors | firstErrorMessage}}</mat-error>
                                <mat-hint *ngIf="controlCfg.hint">{{controlCfg.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                            <mat-form-field fxFlex="100" appearance="outline" floatLabel="always" class="inside-content">
                                <mat-label>{{controlCfg.label}}</mat-label>
                                <app-input-number matInput
                                                  [attr.data-test]="'FI-' + controlCfg.label"
                                                  [formControlName]="controlCfg.name"></app-input-number>
                                <mat-error>{{formGroup.get(controlCfg.name).errors | firstErrorMessage}}</mat-error>
                                <mat-hint *ngIf="controlCfg.hint">{{controlCfg.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            <app-input-date-time fxFlex="100" appearance="outline" [formControlName]="controlCfg.name">
                                <label>{{controlCfg.label}}</label>
                            </app-input-date-time>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select'">
                            <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                                <mat-label>{{controlCfg.label}}</mat-label>
                                <input type="text" matInput
                                       [attr.data-test]="'FI-' + controlCfg.label"
                                       [formControlName]="controlCfg.name"
                                       [matAutocomplete]="autocompleteSelector">
                                <mat-error>{{formGroup.get(controlCfg.name).errors | firstErrorMessage}}</mat-error>
                                <mat-hint *ngIf="controlCfg.hint">{{controlCfg.hint}}</mat-hint>

                                <mat-autocomplete #autocompleteSelector="matAutocomplete" [displayWith]="displayListItemWith(controlCfg)">
                                    <ng-container *ngFor="let item of (filteredSelectLists[controlCfg.name] | async) | orderBy : controlCfg.listAttribute : false">
                                        <mat-option [value]="item">
                                            <span>{{item[controlCfg.listAttributeTranslation] | translate : null : item[controlCfg.listAttribute]}}</span><br>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <ng-container *ngIf="noButton; else placeholder">
            <button data-test="B-Nein"
                    mat-flat-button
                    [color]="noButton.color"
                    (click)="dialogRef.close(false)">
                {{noButton.text}}
            </button>
        </ng-container>
        <ng-template #placeholder>
            <div>&nbsp;</div>
        </ng-template>
        <button data-test="B-Ja"
                mat-flat-button
                [color]="yesButton.color"
                (click)="yesButtonClick()">
            {{yesButton.text}}
        </button>

    </div>
</div>
