import { FuseNavigationItem } from '@fuse/types';
import { ListItemType } from './navigation.shared';

export const navigationAdmin: FuseNavigationItem[] = [
    {
        id: 'back-link',
        title: 'Zurück zu Up2Boat',
        type: ListItemType.Item,
        icon: 'arrow_back',
        url: '/',
        exactMatch: true
    },

    // {
    //     id: 'admin',
    //     title: 'U2B Administration',
    //     type: 'group',
    //     children: [
    //
    //         // {
    //         //     id: 'dashboard',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     icon: 'dashboard',
    //         //     url: 'admin/dashboard',
    //         // },
    //
    //         {
    //             id: 'accounting',
    //             title: 'Buchhaltung',
    //             type: 'collapsable',
    //             icon: 'euro_symbol',
    //             children: [
    //                 {
    //                     id: 'Rechnung',
    //                     title: 'Neuer Beleg',
    //                     type: 'item',
    //                     icon: 'note_add',
    //                     url: 'admin/accounting/invoices/new',
    //                     exactMatch: true
    //                 },
    //                 {
    //                     id: 'Rechnungen',
    //                     title: 'Alle Belege',
    //                     type: 'item',
    //                     icon: 'insert_drive_file',
    //                     url: 'admin/accounting/invoices',
    //                     exactMatch: true
    //                 },
    //                 // {
    //                 //     id: 'Sammelrechnung',
    //                 //     title: 'Sammelrechnung',
    //                 //     type: 'item',
    //                 //     icon: 'filter_none',
    //                 //     url: 'admin/accounting/collective-invoices',
    //                 // },
    //                 {
    //                     id: 'addresses',
    //                     title: 'Adressen',
    //                     type: 'item',
    //                     icon: 'contact_mail',
    //                     url: 'admin/accounting/addresses',
    //                 },
    //             ]
    //         },
    //
    //         {
    //             id: 'e-commerce',
    //             title: 'E-Commerce',
    //             type: 'collapsable',
    //             icon: 'shopping_cart',
    //             children: [
    //                 {
    //                     id: 'orders',
    //                     title: 'Bestellungen',
    //                     type: 'item',
    //                     // type: 'collapsable',
    //                     url: 'admin/e-commerce/orders',
    //                     icon: 'shopping_cart',
    //                 },
    //                 {
    //                     id: 'products',
    //                     title: 'Produkte',
    //                     type: 'item',
    //                     // type: 'collapsable',
    //                     url: 'admin/e-commerce/products',
    //                     icon: 'shopping_basket',
    //                 },
    //                 {
    //                     id: 'product_categories',
    //                     title: 'Produktkategorien',
    //                     type: 'item',
    //                     icon: 'category',
    //                     url: 'admin/e-commerce/product-categories',
    //                 },
    //             ]
    //         },
    //
    //         // {
    //         //     id: 'users',
    //         //     title: 'Benutzer',
    //         //     type: 'item',
    //         //     icon: 'people',
    //         //     url: 'admin/users',
    //         //     exactMatch: true
    //         // },
    //
    //     ]
    // },

    {
        id: 'ym-admin',
        title: 'Yachthafenmanagement',
        type: ListItemType.Group,
        children: [
            {
                id: 'customers',
                title: 'Kunden',
                type: ListItemType.Item,
                icon: 'directions_boat',
                url: 'admin/tenants',
                // exactMatch: true
            },
            {
                id: 'release-notes',
                title: 'Release Notes',
                type: ListItemType.Item,
                icon: 'new_releases',
                url: 'admin/release-notes',
                // exactMatch: true
            },
        ]
    },

    {
        id: 'u2b-others',
        title: 'Sonstiges',
        type: 'group',
        children: [
            {
                id: 'tips_and_tricks',
                title: 'Tipps & Tricks',
                type: ListItemType.Item,
                icon: 'lightbulb',
                url: 'admin/tips/up2boat'
            },
        ]
    },
];
