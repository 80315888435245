import { AppUser } from '@core/services/user.service';
import { Role } from '@bcmApiServices/roles.api-service';
import { Tenant } from '@shared/models/tenant';
import { SalutationNew } from '@shared/models/salutation';

export enum TenantUserInviteStatus {
    Invited = 'invited',
    Declined = 'declined',
    Done = 'done',
}

export class BcmUser {
    id: string;
    role: Role;
    user: AppUser;
    tenants: Tenant[];

    constructor(dto) {
        this.id = dto.id;
        this.role = dto.role;
        this.user = dto.user;
        this.tenants = (dto.tenants || []).map(tenant => new Tenant(tenant));
    }

    get fullName(): string {
        return `${this.user.lastName}, ${this.user.firstName}`;
    }
}

export class BcmUserInvite {
    id: string;
    role: Role;

    activationCode: string;
    activationStatus: TenantUserInviteStatus;

    salutation: SalutationNew;
    firstName: string;
    lastName: string;
    email: string;
    invitedOn: Date;
}
