import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Salutation } from '@shared/models/salutation';
import { Person } from '@shared/models/person';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { isFunction } from '@shared/functions/is-function';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { PersonApiService } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AppNotificationService } from '@core/services/app-notification.service';
import { PersonsFacade } from '@modules/bcm/@core/state-management/persons/persons.facade';

@Component({
    selector: 'form-widget-person',
    templateUrl: './form-widget-person.component.html'
})
export class FormWidgetPersonComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = 'Person wählen <small>oder</small> neu erfassen';

    @Input()
    inputLabel = 'Nach Person suchen...';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    person$: Observable<Person>;

    @Input()
    optional: boolean;

    @Input()
    slimmedView = false;

    @Input()
    canCreate = true;

    optionalString: string;

    showOptionalFields = false;

    personFormGroup: UntypedFormGroup;

    salutation = Salutation;

    person: Person;

    loadingPersons: boolean;

    persons: Person[];

    filteredPersons$: Observable<Person[]>;

    isSaving = false;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _appNotificationService: AppNotificationService,
                private _personsFacade: PersonsFacade,
                private _personApiService: PersonApiService) {
    }

    ngOnInit(): void {

        this.optionalString = this.optional ? ' (optional)' : '';
        this.showOptionalFields = !this.optional;

        if (!this.canCreate) {
            this.headline = 'Person wählen';
        }

        this._createForm();
        this._loadPersons();

        setTimeout(() => {
            this.parentFormGroup.addControl('personForm', this.personFormGroup);
        });

        if (isFunction(this.person$?.subscribe)) {

            this.person$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(person => {
                    this.personFormGroup.patchValue({person, ...person});

                    if (person?.phones && person.phones.length) {
                        this.personFormGroup.get('phone').patchValue(person.phones[0]);
                    }

                    this.personFormGroup.markAsDirty();
                });
        }
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('personForm');
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickRemovePerson(): void {
        this.person = null;
        this.personFormGroup.get('person').setValue(null);
        this.personFormGroup.reset();
        this.personFormGroup.enable();
        this.personFormGroup.markAsDirty();
    }

    onClickToggleOptionalFields(): void {
        this.showOptionalFields = !this.showOptionalFields;
    }

    public displayPersonWith(person: Person): string {
        return person ? person.toDropdownString() : '';
    }

    private _createForm(): void {
        this.personFormGroup = this._formBuilder.group({
            person: [null],
            salutation: [{value: null, disabled: !!this.person}, [U2bValidators.required('Bitte Anrede angeben')]],
            title: [{value: null, disabled: !!this.person}],
            firstName: [{value: null, disabled: !!this.person}, [U2bValidators.required('Bitte Vornamen angeben')]],
            lastName: [{value: null, disabled: !!this.person}, [U2bValidators.required('Bitte Nachnamen angeben')]],
            street: [{
                value: null,
                disabled: !!this.person
            }, this.optional ? [] : [U2bValidators.required('Bitte Strasse angeben')]],
            postCode: [
                {value: null, disabled: !!this.person},
                this.optional ? [] : [
                    U2bValidators.required('Bitte PLZ angeben'),
                    // todo: add country based validator!
                    // U2bValidators.postCode(this.bcmService.tenant)
                ]
            ],
            city: [{
                value: null,
                disabled: !!this.person
            }, this.optional ? [] : [U2bValidators.required('Bitte Stadt angeben')]],
            mail: [{
                value: null,
                disabled: !!this.person
            }, [U2bValidators.email('Bitte überprüfe die eingegebene E-Mail-Adresse. Beachte das E-Mail Format', true)]],
            phone: this._formBuilder.group({
                type: [{value: null, disabled: !!this.person}],
                number: [{
                    value: null,
                    disabled: !!this.person
                }],
            }),
            note: [{value: null, disabled: !!this.person}, [U2bValidators.maxLength(1024)]]
        });

        this.personFormGroup
            .get('person')
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((person) => {
                this.person = person;
                if (person && person.id) {
                    disableControlsByName(
                        this.personFormGroup,
                        ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
                    );
                } else {
                    enableControlsByName(
                        this.personFormGroup,
                        ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
                        true
                    );
                }
            });
    }

    private _loadPersons(): void {
        this.persons = [];
        this.loadingPersons = true;
        this._personApiService
            .getAll(new HttpParams().set('includeBoats', 'true'))
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingPersons = false)
            )
            .subscribe(persons => this.persons = persons);

        this.filteredPersons$ = this.personFormGroup.get('person').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterPersons(value) : this.persons)
            );
    }

    private _filterPersons(filter: string): Person[] {
        filter = filter.toLowerCase();
        return this.persons.filter(person => {
            return (person.firstName + person.lastName || '').toLowerCase().includes(filter)
                || (person.identNumber || '').toLowerCase().includes(filter)
                || (person.primaryMail || '').toLowerCase().includes(filter)
                || (person.primaryPhone || '').toLowerCase().includes(filter);
        });
    }

    save(): void {

        if (this.personFormGroup.invalid) {
            this.personFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder.');
            return;
        }

        this.isSaving = true;

        const newPerson = this.personFormGroup.getRawValue();

        this._personsFacade.add(newPerson, new HttpParams(), true)
            .subscribe((person) => {
                this.isSaving = false;
                this._appNotificationService.showSuccess('Person erfolgreich gespeichert.');
                this._loadPersons();
                this.personFormGroup.patchValue({person});
            });

    }

}
