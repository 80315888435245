import { FuseNavigation } from '@fuse/types';
import { ListItemType } from './navigation.shared';

export const navigationDmyv: FuseNavigation[] = [
    {
        id: 'back',
        title: 'Zurück zu Up2Boat',
        type: ListItemType.Item,
        url: '/',
        icon: 'arrow_left',
        exactMatch: true
    },
    {
        id: 'dmyv',
        title: 'Deutscher Motoryachtverband',
        type: ListItemType.Group,
        children: [
            {
                id: 'members',
                title: 'Mitglieder',
                type: ListItemType.Item,
                icon: 'people',
                url: 'dmyv/membership-program-members'
            },
            {
                id: 'tips_and_tricks',
                title: 'Tipps & Tricks',
                type: ListItemType.Item,
                icon: 'lightbulb',
                url: 'dmyv/tips/dmyv'
            },
            {
                id: 'inkasso-wasserflaechen',
                title: 'Inkasso Wasserflächen',
                type: ListItemType.Item,
                icon: 'water',
                url: 'dmyv/inkasso-wasserflaechen'
            },
            {
                id: 'ibs',
                title: 'IBS Anträge',
                type: ListItemType.Item,
                icon: 'description',
                url: 'dmyv/ibs'
            },
            {
                id: 'shop-products',
                title: 'Portal Shop',
                type: ListItemType.Item,
                icon: 'shopping_cart',
                url: 'dmyv/shop-products'
            }
        ]
    },
    {
        id: 'sbv',
        title: 'Sportboot Vereinigung',
        type: ListItemType.Group,
        children: [
            {
                id: 'members_sbv',
                title: 'Mitglieder',
                type: ListItemType.Item,
                url: 'dmyv/sbv/membership-program-members'
            },
        ]
    },
];
