import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';

export class NoZeroAllowedValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(private _message?: string) {
        super();

        this.validationError = new BaseValidationError('noZeroAllowed', _message || `Eine Null ist hier nicht erlaubt.`);
    }

    public validate({value}: AbstractControl): ValidationErrors {

        if (value === 0) {
            return this.IS_INVALID;
        }

        return this.IS_VALID;
    }
}
