import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { BoatApiService } from '@modules/bcm/@shared/services';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Boat } from '@shared/models/boat';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { TranslationService } from '@core/translation/translation.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';

@Component({
    selector: 'form-widget-boat',
    templateUrl: './form-widget-boat.component.html'
})
export class FormWidgetBoatComponent implements OnInit, OnDestroy, OnChanges {

    private _unsubscribeAll = new Subject<any>();

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = TranslationService.translate('boat') + ' wählen <small>oder</small> neu erfassen';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    givenBerth: Berth;

    @Input()
    givenBoats: Boat[];

    @Input()
    showMetaData: boolean;

    @Input()
    set selectBoat(value: Boat) {
        if (value?.id || value?.id !== this._selectBoat?.id) {
            this._selectBoat = value;
        }
    }

    get selectBoat(): Boat {
        return this._selectBoat;
    }

    private _selectBoat: Boat;

    @Input()
    boatRequired: boolean;

    @Input()
    optional: boolean;

    @Input()
    canCreate = true;

    @Input()
    slimmedView = false;

    @Input()
    boatOwnerName: string;

    @Input()
    canSave: boolean;

    displayBoatOwnerName: string;

    isSaving: boolean;

    optionalString: string;

    showOptionalFields = false;

    boatFormGroup: UntypedFormGroup;

    boat: Boat;

    loadingBoats: boolean;

    topList: Boat[] = [];

    bottomList: Boat[] = [];

    filteredTopList$: Observable<Boat[]>;

    filteredBottomList$: Observable<Boat[]>;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _boatApiService: BoatApiService,
                private _appNotificationService: AppNotificationService) {
    }

    ngOnInit(): void {

        this.boat = this.selectBoat;

        this.optionalString = this.optional ? ' (optional)' : '';
        this.showOptionalFields = !this.optional;

        if (!this.canCreate) {
            this.headline = TranslationService.translate('selectBoat');
        }

        this._createForm();
        this._loadBoats(this.givenBoats);

        setTimeout(() => {
            this.parentFormGroup.addControl('boatForm', this.boatFormGroup);
        });

    }

    ngOnChanges(changes: SimpleChanges): void {


        if (!this.boatFormGroup) {
            return;
        }

        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'givenBoats': {
                        this._loadBoats(this.givenBoats);
                    }
                }
            }
        }

        if (this.selectBoat) {
            this.boatFormGroup.patchValue({
                boat: this.selectBoat
            });
        }


    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
        this.parentFormGroup.removeControl('boatForm');
    }

    onClickRemoveBoat(): void {
        this.boat = null;
        this.boatFormGroup.get('boat').setValue(null);
        this.boatFormGroup.markAsDirty();
    }

    onClickToggleOptionalFields(): void {
        this.showOptionalFields = !this.showOptionalFields;
    }

    public displayBoatWith(boat: Boat): string {
        return boat ? [boat.licensePlate, boat.name, boat.manufacturer || boat.type].filter(_ => !!_).join(', ') : '';
    }

    private _createForm(): void {
        this.boatFormGroup = this._formBuilder.group(
            {
                boat: [this.selectBoat, this.boatRequired ? [U2bValidators.required(TranslationService.translate('pleaseSelectBoat'))] : []],
                name: [{value: null, disabled: !!this.boat}],
                length: [
                    {value: null, disabled: !!this.boat},
                    this.optional ? [] : [
                        U2bValidators.required('Bitte Länge angeben'),
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0.5),
                        U2bNumericValidators.numberMax(500)
                    ].filter(_ => _ != null)
                ],
                width: [
                    {value: null, disabled: !!this.boat},
                    this.optional ? [] : [
                        U2bValidators.required('Bitte Breite angeben'),
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0.5),
                        U2bNumericValidators.numberMax(100)
                    ].filter(_ => _ != null)
                ],
                depth: [
                    {value: null, disabled: !!this.boat},
                    this.optional ? [] : [
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0),
                        U2bNumericValidators.numberMax(20)
                    ]
                ],
                licensePlate: [{value: null, disabled: !!this.boat}],
                note: [
                    {value: null, disabled: !!this.boat},
                    this.optional ? [] : [U2bValidators.maxLength(1024)]
                ],
                isOwner: [false]
            },
            {
                validators: [
                    form => {
                        if (this.optional || !this.canCreate) {
                            return null;
                        }

                        if (!form || this.boat?.id) {
                            return null;
                        }

                        const nameControl: AbstractControl = form.get('name');
                        const licensePlateControl: AbstractControl = form.get('licensePlate');

                        if (nameControl.disabled || licensePlateControl.disabled) {
                            return null;
                        }

                        if (!nameControl.value && !licensePlateControl.value) {
                            const error: ValidationErrors = {
                                nameLicensePlate: {
                                    message: TranslationService.translate('missingBoatNameOrLicensePlate')
                                }
                            };
                            nameControl.setErrors(error);
                            licensePlateControl.setErrors(error);
                            return error;
                        }

                        form.get('boat').setErrors(null);
                        nameControl.setErrors(null);
                        licensePlateControl.setErrors(null);
                        return null;
                    }
                ]
            }
        );

        this.boatFormGroup
            .get('boat')
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((boat) => {
                if (boat && boat.id) {
                    this.boat = boat;

                    disableControlsByName(
                        this.boatFormGroup,
                        ['name', 'length', 'width', 'depth', 'note', 'licensePlate']
                    );
                } else {
                    enableControlsByName(
                        this.boatFormGroup,
                        ['name', 'length', 'width', 'depth', 'note', 'licensePlate'],
                        true
                    );
                }
            });

        setTimeout(() => {
            const boat = this.parentFormGroup.get('boatForm')?.value.boat;
            const person = this.parentFormGroup.get('personForm')?.value.person;
            const company = this.parentFormGroup.get('companyForm')?.value.company;

            if (boat?.ownerCompany?.id > 0 && boat?.ownerCompany?.id === company?.id) {
                this.boatFormGroup.patchValue({isOwner: true}, {
                    emitEvent: false
                });
            } else if (boat?.owner?.id > 0 && boat?.owner?.id === person?.id) {
                this.boatFormGroup.patchValue({isOwner: true}, {
                    emitEvent: false
                });
            }
        }, 500);

        this.parentFormGroup.valueChanges.subscribe(value => {

            const boat = value?.boatForm?.boat;
            const personForm = value?.personForm;
            const person = personForm?.person;
            const companyForm = value?.companyForm;
            const company = companyForm?.company;

            if (company || companyForm?.name) {
                this.displayBoatOwnerName = company.toString() || companyForm?.name;
            } else if (person || (personForm?.firstName && personForm?.lastName)) {
                this.displayBoatOwnerName = person.toString() || (personForm?.firstName + ' ' + personForm?.lastName);
            } else {
                this.displayBoatOwnerName = null;
            }

            if (boat?.ownerCompany?.id > 0 && boat?.ownerCompany?.id === company?.id) {
                this.boatFormGroup.patchValue({isOwner: true}, {
                    emitEvent: false
                });
            } else if (boat?.owner?.id > 0 && boat?.owner?.id === person?.id) {
                this.boatFormGroup.patchValue({isOwner: true}, {
                    emitEvent: false
                });
            } else {
                this.boatFormGroup.patchValue({isOwner: false}, {
                    emitEvent: false
                });
            }
        });
    }

    private _loadBoats(givenBoats: Boat[] = []): void {
        this.topList = [];
        this.bottomList = [];
        this.loadingBoats = true;
        this._boatApiService
            .getAll()
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingBoats = false)
            )
            .subscribe(boats => {
                for (const boat of boats) {
                    if (givenBoats.find(givenBoat => givenBoat.id === boat.id)) {
                        this.topList.push(boat);
                    } else {
                        this.bottomList.push(boat);
                    }
                }
            });

        this.filteredTopList$ = this.boatFormGroup.get('boat').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBoats(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.boatFormGroup.get('boat').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBoats(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterBoats(filter: string, boats: Boat[]): Boat[] {
        filter = filter.toLowerCase();
        return boats.filter(boat => {
            return (boat.name || '').toLowerCase().includes(filter)
                || (boat.licensePlate || '').toLowerCase().includes(filter);
        });
    }

    saveBoat(): void {
        if (this.boatFormGroup.invalid) {
            this.boatFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const boat = this.boatFormGroup.getRawValue();
            delete boat.boat;

            boat.sqm = roundNumber(boat.length * boat.width, RoundNumberFactor.TwoDecimals);

            boat.owner = this.parentFormGroup.get('personForm')?.value?.person;
            boat.ownerCompany = this.parentFormGroup.get('companyForm')?.value?.company;

            this._boatApiService.add(boat)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(addedBoat => {
                    this.boatFormGroup.get('boat').setValue(addedBoat);
                })
                .add(() => this.isSaving = false);

        }
    }
}
