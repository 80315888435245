import { DataFilterOperator } from './filter-operator';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';

export const DATA_FILTER_OPERATOR_TRANSLATION_DE: { [key in DataFilterOperator]: string } = {
  [DataFilterOperator.GreaterThan]: 'größer',
  [DataFilterOperator.GreaterThanOrEqual]: 'größer o. gleich',
  [DataFilterOperator.LessThan]: 'kleiner',
  [DataFilterOperator.LessThanOrEqual]: 'kleiner o. gleich',
  [DataFilterOperator.Equal]: 'gleich',
  [DataFilterOperator.NotEqual]: 'ungleich',
  [DataFilterOperator.Contains]: 'beinhaltet',
  [DataFilterOperator.NotContains]: 'beinhaltet nicht',
  [DataFilterOperator.StartsWith]: 'beginnt mit',
  [DataFilterOperator.EndsWith]: 'endet mit',
};

export const FILTER_OPERATORS_DEFAULT: DataFilterOperator[] = [
  DataFilterOperator.GreaterThan,
  DataFilterOperator.LessThan,
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual
];

export const FILTER_OPERATORS_DEFAULT_STRING: DataFilterOperator[] = [
  DataFilterOperator.Contains,
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual,
  DataFilterOperator.StartsWith,
  DataFilterOperator.EndsWith,
];

export const FILTER_OPERATORS_DEFAULT_STRING_ALT: DataFilterOperator[] = [
  DataFilterOperator.Contains,
  DataFilterOperator.StartsWith,
  DataFilterOperator.EndsWith,
];

export const FILTER_OPERATORS_DEFAULT_SELECT: DataFilterOperator[] = [
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual,
];

export const FILTER_OPERATORS_DEFAULT_BOOLEAN: DataFilterOperator[] = [
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual,
];

export const FILTER_OPERATORS_DEFAULT_NUMERIC: DataFilterOperator[] = [
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual,
  DataFilterOperator.LessThan,
  DataFilterOperator.LessThanOrEqual,
  DataFilterOperator.GreaterThan,
  DataFilterOperator.GreaterThanOrEqual,
];

export const FILTER_OPERATORS_DEFAULT_DATE: DataFilterOperator[] = [
  DataFilterOperator.Equal,
  DataFilterOperator.NotEqual,
  DataFilterOperator.LessThan,
  DataFilterOperator.LessThanOrEqual,
  DataFilterOperator.GreaterThan,
  DataFilterOperator.GreaterThanOrEqual,
];

export const FILTER_OPERATORS_DEFAULTS_BY_FIELD_TYPE: { [key in FilterFieldType]: DataFilterOperator[] } = {
  [FilterFieldType.Text]: FILTER_OPERATORS_DEFAULT_STRING,
  [FilterFieldType.Time]: FILTER_OPERATORS_DEFAULT_DATE,
  [FilterFieldType.Boolean]: FILTER_OPERATORS_DEFAULT_BOOLEAN,
  [FilterFieldType.Numeric]: FILTER_OPERATORS_DEFAULT_NUMERIC,
  [FilterFieldType.Date]: FILTER_OPERATORS_DEFAULT_DATE,
  [FilterFieldType.Select]: FILTER_OPERATORS_DEFAULT_SELECT,
  [FilterFieldType.AutoComplete]: FILTER_OPERATORS_DEFAULT_SELECT,
};
