import { AvailableFilterFields, FilterFieldType } from '@core/datafilter/available-filter-fields';
import { Filter } from '@core/datafilter/filter';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterCondition } from '@core/datafilter/filter-condition';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FormArray, FormGroup } from '@angular/forms';
import { U2bColumnDefinition, U2bColumnDefinitionFilterSettings } from '@core/components/layout/table/table.types';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { FilterRaw } from '@core/datafilter/filter-raw';
import { toSqlDate } from '@core/functions/to-date-string';
import { isPlainObject } from '@shared/functions/is-plain-object';

export class DataFilterService<Entity> {

    private _availableFilterFields: AvailableFilterFields;

    private get availableFilterFields(): AvailableFilterFields {
        return this._availableFilterFields;
    }

    private _currentFilter$ = new BehaviorSubject<Filter<Entity>>(new Filter());

    public get currentFilter$(): BehaviorSubject<Filter<Entity>> {
        return this._currentFilter$;
    }

    private _currentFilterParams$ = new BehaviorSubject<HttpParams>(new HttpParams());

    public get currentFilterParams$(): Observable<HttpParams> {
        return this._currentFilterParams$;
    }

    // todo:
    //  To save the filter, just save the current form state to db together with a name
    //  Maybe add a own table bcm_table_filters to easier share filters between users?
    protected _allFiltersFormGroup = new FormGroup({});

    public get allFiltersFormGroup(): FormGroup {
        return this._allFiltersFormGroup;
    }

    public get hasActiveFilters(): boolean {
        return Object
            .values(this._allFiltersFormGroup.value)
            .map((formArray: Partial<FilterRaw>[]) => {
                return (formArray || []).find(item => item.value != null);
            })
            .flat()
            .filter(item => item != null).length > 0;
    }

    private _forceNextFormValueChange = false;

    constructor(columnDefinitions: U2bColumnDefinition[],
                private useBackEndFiltering = false) {

        this.setupAvailableFilters(columnDefinitions);
        this.setupFilterChangeSubscription();
    }

    public resetActiveFilters() {
        this._allFiltersFormGroup.reset();
        Object
            .values(this._allFiltersFormGroup.controls)
            .forEach((myFormControl: FormArray) => myFormControl.clear());
    }

    public forceNextFormValueChange(): void {
        this._forceNextFormValueChange = true;
    }

    private setupAvailableFilters(columnDefinitions: U2bColumnDefinition[]): void {
        this._availableFilterFields = (columnDefinitions || [])
            .filter(columnDefinition => columnDefinition.filter != null)
            .map(columnDefinition => {
                const columnDefinitionFilter = columnDefinition.filter as U2bColumnDefinitionFilterSettings;

                this.addFormArray(columnDefinition);

                return {
                    field: columnDefinitionFilter.property,
                    fieldType: columnDefinitionFilter.fieldType,
                    operators: columnDefinitionFilter.operators,
                };
            });
    }

    private getFieldType(field: string): FilterFieldType {
        return this.availableFilterFields.find((x) => x.field === field)?.fieldType || FilterFieldType.Text;
    }

    private parseValueByFieldName<T = unknown>(value: T, field: string): Date | string | T {

        const fieldType = this.getFieldType(field);

        switch (fieldType) {
            case FilterFieldType.Date:
                return tryParseDate(value);
            case FilterFieldType.Text:
                return value;
            default:
                return value;
        }
    }

    private formatValueByFieldName<T = unknown>(value: Date | string | T,
                                                field: string,
                                                columnDefinition: U2bColumnDefinition): string | number | boolean {

        const fieldType = this.getFieldType(field);

        switch (fieldType) {
            case FilterFieldType.Date:
                return toSqlDate(value as Date);
            case FilterFieldType.Text:
                return String(value);
            case FilterFieldType.AutoComplete:
                return this.formatValueForSelect(value, columnDefinition);
            case FilterFieldType.Select:
                return this.formatValueForSelect(value, columnDefinition);
            default:
                return String(value);
        }
    }

    private formatValueForSelect<T = unknown>(value: Date | string | T,
                                              columnDefinition: U2bColumnDefinition): string | number | boolean {
        return isPlainObject(value)
            ? (value[columnDefinition.filter.compareAttribute] || value.valueOf())
            : value;
    }

    public getFormArray(columnDefinition: U2bColumnDefinition): undefined | FormArray {
        return this._allFiltersFormGroup.get(columnDefinition.property) as FormArray || undefined;
    }

    private addFormArray(columnDefinition: U2bColumnDefinition) {
        this._allFiltersFormGroup.addControl(columnDefinition.property, new FormArray([]));
    }

    private setupFilterChangeSubscription() {

        let lastFormValueState = {};

        this._allFiltersFormGroup
            .valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(),
                filter(formValue => {
                    if (this._forceNextFormValueChange) {
                        this._forceNextFormValueChange = false;
                        return true;
                    }

                    // only continue if at least on filter condition with value
                    const previousItems = Object
                        .values(lastFormValueState)
                        .map((formArray: Partial<FilterRaw>[]) => {
                            return formArray.find(item => item.value != null);
                        })
                        .flat()
                        .filter(item => item != null);

                    return this.hasActiveFilters || previousItems.length > 0;
                })
            )
            .subscribe(formValue => {
                if (this.useBackEndFiltering) {
                    this.prepareBackEndFilters(formValue);
                } else {
                    this.prepareFrontEndFilters(formValue);
                }

                lastFormValueState = formValue;
            });
    }

    private prepareBackEndFilters(formValue: any) {
        let httpParams = new HttpParams();

        // unreserved characters: -._~
        // produce a string for each property like:
        //      "property=endsWith~abc.and.includes~test.or.includes~test2"
        // while back end parses this to sql like this:
        //      "? like '%abc' and (? like '%test%' or ? like '%test2%')"
        Object
            .entries(formValue)
            .forEach(([property, formArray]: [string, Partial<FilterRaw>[]]) => {
                const propertyValue = formArray
                    .filter(filterFormGroup => ![undefined, null, ''].includes(filterFormGroup.value))
                    .map((filterFormGroup, index, array) => {
                        const parsedValue = this.formatValueByFieldName(
                            filterFormGroup.value,
                            filterFormGroup.columnDefinition.property,
                            filterFormGroup.columnDefinition
                        );
                        const operationType = index < array.length - 1
                            ? `.${filterFormGroup.operationType}.`
                            : '';
                        return `${filterFormGroup.operator}~${parsedValue}${operationType}`;
                    })
                    .join('');

                if (propertyValue) {
                    httpParams = httpParams.set(property, propertyValue);
                }
            });

        this._currentFilterParams$.next(httpParams);
    }

    private prepareFrontEndFilters(formValue: any) {

        const currentFilter = new Filter<Entity>();

        Object
            .entries(formValue)
            .forEach(([property, formArray]: [string, Partial<FilterRaw>[]]) => {
                formArray
                    .filter(filterFormGroup => ![undefined, null, ''].includes(filterFormGroup.value))
                    .forEach(filterFormGroup => {
                        currentFilter.addCondition(
                            new FilterCondition(
                                filterFormGroup.columnDefinition.filter.property,
                                filterFormGroup.operator,
                                this.parseValueByFieldName(filterFormGroup.value, filterFormGroup.property),
                                filterFormGroup.columnDefinition.filter.fieldType,
                                filterFormGroup.columnDefinition.filter.compareAttribute,
                            ),
                            true
                        );
                    });
            });

        this._currentFilter$.next(currentFilter);
    }
}
