import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Observable, Subject } from 'rxjs';
import { Berth } from '@shared/models/berth';
import { HttpParams } from '@angular/common/http';
import { BerthApiService } from '@modules/bcm/@shared/services';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { U2bBerthValidators } from '@shared/validators/berth/berth-validators';
import { isPlainObject } from '@shared/functions/is-plain-object';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { addHours, startOfHour, startOfToday } from 'date-fns';
import { hours, minutes } from '@shared/constants/date';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';
import { TranslationService } from '@core/translation/translation.service';

@Component({
    selector: 'form-widget-berth',
    templateUrl: './form-widget-berth.component.html',
})
export class FormWidgetBerthComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject();

    @Input()
    headline = 'Liegeplatz wählen';

    @Input()
    parentFormGroup: UntypedFormGroup;

    berthFormGroup: UntypedFormGroup;

    berths: Berth[] = [];
    filteredBerths$: Observable<Berth[]>;

    today = startOfToday();

    loadingBerths = false;

    hours = hours;
    minutes = minutes;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _berthApiService: BerthApiService,
                private _translationService: TranslationService) {
        this._createForm();
        this._loadBerths();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('berthForm', this.berthFormGroup);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    public displayBerthWith(berth: any): string {

        if (isString(berth)) {
            return berth;
        }

        if (!isPlainObject(berth)) {
            return '';
        }

        const displayWith = [berth.handle];

        if (berth.pier?.handle) {
            displayWith.push(berth.pier?.handle);
        }

        if (berth.hasActiveAssignments()) {
            displayWith.push(berth.getActiveAssignments()?.boat?.name);
        }

        return displayWith.join(' - ');
    }

    public setNullToField(): void {
        this.berthFormGroup.patchValue({to: null});
    }

    private _createForm(): void {

        const start = startOfHour(addHours(new Date(), 1));
        // Wunsch: bis Zeit default leer! (https://trello.com/c/YfQqptSe/409-bug-liegeplatzworkflow-datum-bis-default-leer)

        this.berthFormGroup = this._formBuilder.group({
                from: [start, [U2bValidators.required(TranslationService.translate('sinceWhenBoatAtPlace'))]],
                to: [null],
                onlyFree: [true],
                berth: [null, [
                    U2bValidators.required('Bitte Liegeplatz auswählen'),
                    U2bBerthValidators.berthExists()
                ]],
                note: [null, [U2bValidators.maxLength(1024)]]
            },
            {
                validators: [
                    (formGroup: UntypedFormGroup) => {

                        if (!formGroup) {
                            return;
                        }

                        const _fromControl = formGroup.get('from');
                        const _toControl = formGroup.get('to');

                        const fromDate = _fromControl.value;
                        const toDate = _toControl.value;

                        if (!fromDate || !toDate) {
                            return null;
                        }

                        if (fromDate > toDate) {
                            const fromError = {
                                dateRange: {
                                    message: 'Das "von" Datum darf nicht hinter dem "bis" Datum liegen'
                                }
                            };
                            const toError = {
                                dateRange: {
                                    message: 'Das "bis" Datum darf nicht vor dem "von" Datum liegen'
                                }
                            };

                            _fromControl.setErrors(fromError);
                            _toControl.setErrors(toError);
                        } else {
                            removeErrorFromControls('dateRange', [
                                _fromControl,
                                _toControl,
                            ]);
                        }
                    }
                ],
            });

        this.filteredBerths$ = this.berthFormGroup.get('berth').valueChanges
            .pipe(
                startWith(''),
                map(value => isString(value) ? this._filterBerths(value) : this.berths.slice()),
            );

        this.berthFormGroup
            .get('onlyFree')
            .valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
            .subscribe(() => this._loadBerths());

    }

    private _loadBerths(): void {

        this.loadingBerths = true;

        const formValue = this.berthFormGroup.getRawValue();

        const params = new HttpParams()
            .set('onlyFree', formValue.onlyFree || '')
            .set('from', formValue.from || '')
            .set('to', formValue.to || '');

        this.berths = [];
        this._berthApiService
            .getAll(params)
            .subscribe(berths => this.berths = berths)
            .add(() => this.loadingBerths = false);
    }

    private _filterBerths(filter = ''): Berth[] {
        return this.berths.filter(berth => berth.handle.toLowerCase().includes(filter.toLowerCase()));
    }
}
