<div fxLayout="column">
    <form name="settingsForm" novalidate class="settings w-100-p" fxLayout="column"
          (ngSubmit)="false"
          fxFlex
          data-test="C-Verbandsmeldung">

        <div class="fuse-card auto-width">

            <div class="p-16"
                 fxLayout="column" fxLayoutAlign="center stretch"
                 fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

                <div fxLayout="row" fxLayoutAlign="start center">

                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="h2">
                            Standard Einheiten
                        </div>
                        <div class="subtitle secondary-text">
                            Um die Arbeit mit Yachthafenmanagement zu vereinfachen, können hier Standard Einheiten
                            konfiguriert werden, die vom System an entsprechender Stelle vorausgewählt werden.
                        </div>
                    </div>

                </div>

                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Speichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <span>Speichern</span>
                </ng-template>

                <button data-test="B-Speichern" mat-flat-button
                        color="accent"
                        [disabled]="(bcmSettingsFacade.isUpdating$ | async) || !formsDirty"
                        (click)="$event.stopPropagation(); save($event)">
                    <ng-container
                            *ngIf="(bcmSettingsFacade.isUpdating$ | async); then saving else notSaving"></ng-container>
                </button>

            </div>

            <mat-divider class="my-24"></mat-divider>

            <div class="p-16">

                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px grid"
                     fxLayoutGap.lt-sm="0px"
                     [formGroup]="formGroup">

                    <div fxFlex="60">
                        <h4>
                            Beziehung, die vorausgewählt wird, wenn ein Neukunde im Liegeplan für eine Buchung oder
                            Reservierung erfasst wird. (z.B. Tagesgastlieger)
                        </h4>
                    </div>

                    <mat-form-field appearance="outline" fxFlex="40">
                        <mat-select formControlName="dayGuestBoater">
                            <ng-container *ngFor="let tenantRelation of tenantRelations">
                                <ng-container *ngIf="!tenantRelations?.length">
                                    <mat-option disabled class="loading">
                                        <mat-spinner diameter="35"></mat-spinner>
                                    </mat-option>
                                </ng-container>
                                <mat-option [value]="tenantRelation.id">
                                    {{tenantRelation.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                </div>

                <mat-divider class="my-24"></mat-divider>

                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px grid"
                     fxLayoutGap.lt-sm="0px"
                     [formGroup]="electricMeterFormGroup">

                    <div fxFlex="60">
                        <h4>
                            Standard Produkt für die Berechnung von Stromgebühren
                        </h4>
                    </div>

                    <mat-form-field appearance="outline" fxFlex="40">
                        <mat-select formControlName="productID">
                            <mat-option [value]="9999999">
                                <span>kein Produkt gewählt</span>
                            </mat-option>
                            <ng-container *ngFor="let product of products">
                                <ng-container *ngIf="!products?.length">
                                    <mat-option disabled class="loading">
                                        <mat-spinner diameter="35"></mat-spinner>
                                    </mat-option>
                                </ng-container>
                                <mat-option [value]="product.id">
                                    <span>{{ product.name }}</span>
                                    <small> | {{product.price | bcmDynamicCurrency :'1.2-4'}}</small>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                </div>

                <mat-divider class="my-24"></mat-divider>

                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px grid"
                     fxLayoutGap.lt-sm="0px"
                     [formGroup]="timeFormGroup">

                    <div fxFlex="60">
                        <h4>
                            Standard Einheit für die Liegeplatz Reservierung / Buchung
                        </h4>
                    </div>

                    <div fxFlex="40">
                        <mat-radio-group formControlName="type" fxLayout="column">
                            <mat-radio-button [value]="DEFAULT_BERTH_RESERVATION_UNIT.DAY">Tag</mat-radio-button>
                            <mat-radio-button class="mt-8" [value]="DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY">Übernachtung
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxFlex="60">
                    </div>

                    <div fxFlex="40" class="mt-8">
                        <div fxFlex="45">
                            <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                                <mat-label>Std</mat-label>
                                <mat-select data-test="FS-Std"
                                            formControlName="fromHours">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">
                                        {{hour}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{timeFormGroup.get('fromHours').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>
                            <div fxFlex="2" fxLayoutAlign="center" fxFlexAlign="center">:</div>
                            <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                                <mat-label>Min</mat-label>
                                <mat-select data-test="FS-Std"
                                            formControlName="fromMinutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">
                                        {{minute}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{timeFormGroup.get('fromMinutes').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxFlex="10" fxLayoutAlign="center" fxFlexAlign="center">bis</div>

                        <div fxFlex="45">
                            <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                                <mat-label>Std</mat-label>
                                <mat-select data-test="FS-Std"
                                            formControlName="toHours">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">
                                        {{hour}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{timeFormGroup.get('toHours').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>
                            <div fxFlex="2" fxLayoutAlign="center" fxFlexAlign="center">:</div>
                            <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                                <mat-label>Min</mat-label>
                                <mat-select data-test="FS-Std"
                                            formControlName="toMinutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">
                                        {{minute}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{timeFormGroup.get('toMinutes').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </form>

    <div class="fuse-card auto-width p-16 mt-16">

        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="h2">
                Asset Vorlagen
            </div>
            <div class="subtitle secondary-text">
                Asset Vorlagen können bei der Zuweisung eines Assets in ein Lagerplatz als Vorlage genutzt
                werden.<br>
                Dabei werden die hier angegebenen Maße bei der Zuweisung des Assets vorausgefüllt.
            </div>
        </div>

        <mat-divider class="my-24"></mat-divider>

        <mat-table [dataSource]="defaultAssetsDataSource"
                   fusePerfectScrollbar
                   matSort
                   matSortActive="name"
                   matSortDisableClear
                   matSortDirection="asc">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Asset
                </mat-header-cell>
                <mat-cell *matCellDef="let asset">
                    <ng-container *ngIf="asset.inEditMode; else assetName">
                        <mat-form-field>
                            <input class="table-row-input" matInput data-test="FI-Name"
                                   placeholder="Name" type="text"
                                   [(ngModel)]="asset.name"
                                   [disabled]="isSaving"
                                   (keydown.enter)="$event.preventDefault(); asset.name && onAssetSave(asset)">
                        </mat-form-field>
                    </ng-container>
                    <ng-template #assetName>
                        <p class="text-truncate">
                            {{asset.name}}
                        </p>
                    </ng-template>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <mat-form-field>
                        <input class="table-row-input" matInput data-test="FI-Neue Asset Vorlage"
                               placeholder="Neue Asset Vorlage" type="text"
                               [(ngModel)]="newAsset.name"
                               [disabled]="isSaving"
                               (keydown.enter)="$event.preventDefault(); newAsset.name && onAssetAdd(newAsset)">
                    </mat-form-field>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="length">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Länge in m
                </mat-header-cell>
                <mat-cell *matCellDef="let asset">
                    <ng-container *ngIf="asset.inEditMode; else assetLength">
                        <mat-form-field>
                            <input class="table-row-input" matInput data-test="FI-Name"
                                   placeholder="Name" type="text"
                                   [(ngModel)]="asset.length"
                                   [disabled]="isSaving"
                                   (keydown.enter)="$event.preventDefault(); asset.name && onAssetSave(asset)">
                        </mat-form-field>
                    </ng-container>
                    <ng-template #assetLength>
                        <p class="text-truncate">
                            {{asset.length}}
                        </p>
                    </ng-template>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <mat-form-field>
                        <input class="table-row-input" matInput data-test="FI-Länge in Meter"
                               placeholder="Länge in Meter" type="number"
                               [(ngModel)]="newAsset.length"
                               [disabled]="isSaving"
                               (keydown.enter)="$event.preventDefault(); newAsset.name && onAssetAdd(newAsset)">
                    </mat-form-field>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="width">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Breite in m
                </mat-header-cell>
                <mat-cell *matCellDef="let asset">
                    <ng-container *ngIf="asset.inEditMode; else assetWidth">
                        <mat-form-field>
                            <input class="table-row-input" matInput data-test="FI-Name"
                                   placeholder="Name" type="text"
                                   [(ngModel)]="asset.width"
                                   [disabled]="isSaving"
                                   (keydown.enter)="$event.preventDefault(); asset.name && onAssetSave(asset)">
                        </mat-form-field>
                    </ng-container>
                    <ng-template #assetWidth>
                        <p class="text-truncate">
                            {{asset.width}}
                        </p>
                    </ng-template>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <mat-form-field>
                        <input class="table-row-input" matInput data-test="FI-Breite in Meter"
                               placeholder="Breite in Meter" type="number"
                               [(ngModel)]="newAsset.width"
                               [disabled]="isSaving"
                               (keydown.enter)="$event.preventDefault(); newAsset.name && onAssetAdd(newAsset)">
                    </mat-form-field>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef fxFlex="120px" fxFlex.gt-sm="280px"></mat-header-cell>
                <mat-cell *matCellDef="let asset" fxFlex="120px" fxFlex.gt-sm="280px">
                    <div fxFlex="row" fxLayoutAlign="end center">
                        <ng-container *ngIf="assetInEditMode?.id === asset.id">
                            <button data-test="B-Speichern"
                                    mat-icon-button aria-label="save" color="primary"
                                    matTooltip="Änderungen an Asset Vorlage speichern"
                                    class="mat-success"
                                    [disabled]="isSaving || !asset.name"
                                    (click)="onAssetSave(asset)">
                                <ng-container *ngIf="isSaving">
                                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                                </ng-container>
                                <ng-container *ngIf="!isSaving">
                                    <mat-icon>check</mat-icon>
                                </ng-container>
                            </button>
                            <button data-test="B-Bearbeiten abbrechen"
                                    mat-icon-button aria-label="cancel" color="primary"
                                    matTooltip="Änderungen an Asset Vorlage abbrechen"
                                    (click)="onAssetEditCancel(asset)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </ng-container>

                        <button data-test="B-Bearbeiten abbrechen"
                                *ngIf="!assetInEditMode"
                                mat-icon-button aria-label="cancel" color="primary"
                                [matMenuTriggerFor]="moreMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #moreMenu="matMenu">
                            <ng-container *ngIf="!assetInEditMode">
                                <button data-test="B-Bearbeiten"
                                        mat-menu-item aria-label="edit" color="accent"
                                        (click)="onAssetEdit(asset)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Bearbeiten</span>
                                </button>
                            </ng-container>
                            <button mat-menu-item
                                    data-test="B-Entfernen"
                                    aria-label="remove" color="warn"
                                    (click)="onAssetDelete(asset)">
                                <ng-container
                                        *ngIf="isDeleting && assetToDelete.id === asset.id; else deleteIcon">
                                    <mat-spinner class="saving-spinner"
                                                 [diameter]="20"></mat-spinner>
                                </ng-container>
                                <ng-template #deleteIcon>
                                    <mat-icon>delete</mat-icon>
                                    <span>Löschen</span>
                                </ng-template>
                            </button>
                        </mat-menu>

                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef fxFlex="120px" fxFlex.gt-sm="280px">
                    <div fxFlex="row" fxLayoutAlign="end center">
                        <button data-test="B-Asset Vorlage speichern" mat-flat-button
                                color="accent"
                                [disabled]="isSaving || !newAsset.name"
                                (click)="onAssetAdd(newAsset)">
                            <mat-icon>add</mat-icon>
                            <span fxHide fxShow.gt-xs>
                                        <span fxHide fxShow.gt-sm>
                                            Asset Vorlage speichern
                                        </span>
                                    </span>
                        </button>
                    </div>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="defaultAssetsDisplayedColumns;  sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: defaultAssetsDisplayedColumns;"
                     [attr.data-test]="'TZ-Asset-Vorlage-' + row.name"></mat-row>
            <mat-footer-row *matFooterRowDef="defaultAssetsDisplayedColumns"></mat-footer-row>
        </mat-table>
    </div>
</div>
