import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Pier } from '@shared/models/pier';
import { PiersApiService } from '@bcmApiServices/piers.api-service';

@Component({
    selector: 'form-widget-pier',
    templateUrl: './form-widget-pier.component.html'
})
export class FormWidgetPierComponent implements OnInit, OnDestroy {

    @Input()
    headline = 'Steg';

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    prefilledPierId: number;

    @Input()
    set selectPier(value: Pier) {
        if (value?.id > 0 && value?.id !== this._selectPier?.id) {
            this._selectPier = value;
        }
    }

    get selectPier(): Pier {
        return this._selectPier;
    }

    private _selectPier: Pier;

    @Input()
    givenPiers: Pier[];

    @Input()
    personOrCompanyName: string;

    @Input()
    pierRequired = true;

    @Input()
    slimmedView = false;

    @Input()
    givenBerth: Berth;

    @Input()
    disableOtherRelations = false;

    pierFormGroup: UntypedFormGroup;

    piers: Pier[];

    pier: Pier;

    loading: boolean;

    topList: Pier[] = [];

    bottomList: Pier[] = [];

    filteredTopList$: Observable<Pier[]>;

    filteredBottomList$: Observable<Pier[]>;

    private _unsubscribeAll = new Subject();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _piersApiService: PiersApiService) {
    }

    ngOnInit(): void {
        this.pier = this.selectPier;

        this._createForm();
        this._loadPiers(this.givenPiers);

        setTimeout(() => {
            this.parentFormGroup.addControl('pierForm', this.pierFormGroup);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
        this.parentFormGroup.removeControl('pierForm');
    }

    private _createForm(): void {
        this.pierFormGroup = this._formBuilder.group({
            pier: [this.selectPier, this.pierRequired ? [U2bValidators.required('Bitte Steg angeben')] : []],
        });
    }

    private _loadPiers(givenPiers: Pier[] = []): void {
        this.topList = [];
        this.bottomList = [];

        this.loading = true;

        this._piersApiService
            .getAll()
            .subscribe((piers) => {
                for (const pier of piers) {
                    if (this.personOrCompanyName && givenPiers.find(givenPier => givenPier.id === pier.id)) {
                        this.topList.push(pier);
                    } else {
                        this.bottomList.push(pier);
                    }
                }

                if (this.prefilledPierId && piers?.length) {
                    this.pierFormGroup.patchValue({
                        pier: piers.find(tr => tr.id === this.prefilledPierId) || null
                    });
                }
            })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.pierFormGroup.get('pier').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterPiers(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.pierFormGroup.get('pier').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterPiers(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterPiers(search: string, piers: Pier[]): Pier[] {
        return piers.filter(pier => pier.handle.toLowerCase().includes(search.toLowerCase()));
    }

    comparePiers(pier1: Pier, pier2: Pier): boolean {
        return pier1 && pier2 && pier1.id === pier2.id;
    }

    public displayPierWith(pier: any): string {
        return pier?.handle || '';
    }

    onClickRemovePier(): void {
        this.pierFormGroup.get('pier').setValue(null);
        this.pierFormGroup.markAsDirty();
    }
}
