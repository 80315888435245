export enum InvoiceTypes {
    Invoice = 'invoice', // Rechnung
    CancellationInvoice = 'cancellationInvoice', // Stornorechnung
    Crediting = 'crediting', // Gutschrift
    CancellationCrediting = 'cancellationCrediting', // Stornogutschrift
}

export enum ReceiptTypes {
    PayIn = 'payIn',
    Receipt = 'receipt',
    ReceiptError = 'error',
    CancellationReceipt = 'cancellationReceipt'
}

export enum InvoiceOrReceiptType {
    Invoice = 'invoice', // Rechnung
    InvoiceCollection = 'invoice_collection', // Sammelrechnung
    CancellationInvoice = 'cancellationInvoice', // Stornorechnung
    Crediting = 'crediting', // Gutschrift
    CancellationCrediting = 'cancellationCrediting', // Stornogutschrift
    Receipt = 'receipt',
    CancellationReceipt = 'cancellationReceipt',
    ReceiptError = 'error'
}

export const financialRecordTypeTranslationsDe: { [key in InvoiceTypes | ReceiptTypes]: string } = {
    [InvoiceTypes.Invoice]: 'Rechnung',
    [InvoiceTypes.CancellationInvoice]: 'Storno-Rechnung',
    [InvoiceTypes.Crediting]: 'Gutschrift',
    [InvoiceTypes.CancellationCrediting]: 'Storno-Gutschrift',

    [ReceiptTypes.PayIn]: 'Bar-Einzahlung',
    [ReceiptTypes.Receipt]: 'Barbeleg',
    [ReceiptTypes.ReceiptError]: 'Fehler',
    [ReceiptTypes.CancellationReceipt]: 'Storno-Barbeleg',
};
