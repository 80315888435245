import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WinterStorage } from '@shared/models/winter-storage';
import { ElectricMeter } from '@shared/models/electric-meter';

@Component({
    selector: 'bcm-electric-meter-link',
    templateUrl: './electric-meter-link.component.html'
})
export class ElectricMeterLinkComponent implements OnChanges {

    @Input()
    public electricMeter!: ElectricMeter;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(): void {
        const electricMeter = this.electricMeter;
        this.viewData = [
            (electricMeter?.handle?.includes('Zähler') ? electricMeter?.handle : `Zähler: ${electricMeter?.handle}`) || null,
            this.additionalInfo,
        ].filter(item => item != null)
            .join(' - ');
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
