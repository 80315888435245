import { isFunction } from '@shared/functions/is-function';
import { isPlainObject } from '@shared/functions/is-plain-object';

export class FuseUtils {

    static nestingLevel: number;

    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @param consideredFilterColumns
     * @param consideredFilterColumnsNoNesting
     * @param compareMapper
     * @returns {any}
     */
    public static filterArrayByString<T = any>(mainArr: T[],
                                               searchText: string,
                                               consideredFilterColumns?: string[],
                                               consideredFilterColumnsNoNesting?: boolean,
                                               compareMapper?: { [key: string]: (_: T) => string }): T[] {
        if (searchText == null || searchText === '') {
            return mainArr;

        }

        searchText = searchText.toLowerCase();

        return mainArr.filter((itemObj: T) => {
            return this.searchInObj(itemObj, searchText, consideredFilterColumns, consideredFilterColumnsNoNesting, compareMapper);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @param consideredFilterColumns
     * @param consideredFilterColumnsNoNesting
     * @param compareMapper
     * @returns {boolean}
     */
    public static searchInObj(itemObj: any,
                              searchText: string,
                              consideredFilterColumns: string[],
                              consideredFilterColumnsNoNesting: boolean,
                              compareMapper: { [key: string]: (_: any) => string }): boolean {
        for (const prop in itemObj) {

            if (String(prop)[0] === '_') {
                continue;
            }

            if (!itemObj.hasOwnProperty(prop) || (consideredFilterColumns?.length && !consideredFilterColumns.includes(prop))) {
                continue;
            }

            const value = isPlainObject(compareMapper) && isFunction(compareMapper[prop]) ? compareMapper[prop](itemObj) : itemObj[prop];

            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'number') {
                if (this.searchInNumber(value, searchText)) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                if (this.searchInArray(
                    value,
                    searchText,
                    consideredFilterColumnsNoNesting ? null : consideredFilterColumns,
                    consideredFilterColumnsNoNesting,
                    compareMapper
                )) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(
                    value,
                    searchText,
                    consideredFilterColumnsNoNesting ? null : consideredFilterColumns,
                    consideredFilterColumnsNoNesting,
                    compareMapper
                )) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @param consideredFilterColumns
     * @param consideredFilterColumnsNoNesting
     * @param compareMapper
     * @returns {boolean}
     */
    public static searchInArray(arr,
                                searchText,
                                consideredFilterColumns: string[],
                                consideredFilterColumnsNoNesting: boolean,
                                compareMapper: { [key: string]: (_: any) => string }): boolean {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(
                    value,
                    searchText,
                    consideredFilterColumns,
                    consideredFilterColumnsNoNesting,
                    compareMapper
                )) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Search in number
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInNumber(value, searchText): any {
        // return value === parseInt(searchText, 10)
        //     || value === parseFloat((searchText || '').replace(',', '.'));
        return value === parseFloat((searchText || '').replace(',', '.'));
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void {
        if (array.indexOf(item) === -1) {
            array.push(item);
        } else {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - company start of text
            .replace(/-+$/, '');            // Trim - company end of text
    }
}
