import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ITenant, Tenant } from '@shared/models/tenant';
import { U2bAdminTenantsApi } from '@modules/admin/state/tenants/u2b-admin-tenants.api';
import { U2bAdminBaseFacade } from '@modules/admin/state/u2b-admin-base.facade';
import { AppUser } from '@core/services/user.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';

const tenantResourceConfig = {
    viewNameSingular: 'Kunde',
    viewNamePlural: 'Kunden',
    endpointPath: 'tenants',
    entityClass: Tenant
};

@Injectable({providedIn: 'root'})
export class U2bAdminTenantsFacade extends U2bAdminBaseFacade<Tenant, ITenant> {

    protected readonly api = new U2bAdminTenantsApi(
        this.httpClient,
        this.confirmDialogService,
        tenantResourceConfig
    );

    get tenants$(): Observable<Tenant[]> {
        return this.state.list$;
    }

    get selected$(): Observable<Tenant> {
        return this.state.selected$;
    }

    get isLoadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get isLoading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(httpClient: HttpClient,
                confirmDialogService: ConfirmDialogService) {
        super(httpClient, confirmDialogService, tenantResourceConfig);
    }

    setIsDemo(tenant: Tenant, isDemo: boolean, isDemoUntil?: Date): Subscription {
        if (!tenant?.id) {
            return;
        }

        return super.api.update(tenant, {isDemo, isDemoUntil})
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .subscribe((updatedTenant) => this.state.replaceListItem(tenant, updatedTenant));
    }

    setDisabled(tenant: Tenant, disabled: boolean, disableReason?: string): Subscription {
        if (!tenant?.id) {
            return;
        }

        return this.api.update(tenant, {disabled, disableReason})
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .subscribe((updatedTenant) => this.state.replaceListItem(tenant, updatedTenant));
    }

    // removeTenant(tenant: Tenant): Observable<any> {
    //
    //     if (tenant?.id) {
    //
    //         this.state.removeListItem(tenant);
    //
    //         return this.api.remove(tenant)
    //             .pipe(
    //                 catchError((error: HttpErrorResponse) => {
    //                     this.state.addListItem(tenant);
    //                     return throwError(error);
    //                 })
    //             );
    //     }
    //
    //     return throwError('Bitte überprüfe Deine Angaben.');
    // }


    /**
     * Benutzer Verwaltung
     */
    addUser(tenant: Tenant, user: Partial<AppUser>): Observable<AppUser> {
        return this.api.addUser(tenant, user);
    }

    removeUser(tenant: Tenant, user: AppUser): Observable<unknown> {
        return this.api.removeUser(tenant, user);
    }
}
