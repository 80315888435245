import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { Observable, of, Subject, switchMap, throwError } from 'rxjs';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmPaymentType, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { BcmReceipt } from '@shared/models/bcm-receipt';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmService } from '@modules/bcm/bcm.service';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TsePaymentType } from '@shared/models/tse/TsePaymentType';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';

@Component({
    selector: 'app-pay-by-cash-dialog',
    templateUrl: './pay-by-cash-dialog.component.html',
    styleUrls: ['./pay-by-cash-dialog.component.scss']
})
export class PayByCashDialogComponent implements OnDestroy {

    _unsubscribeAll = new Subject();

    parentFormGroup: UntypedFormGroup;

    positions: InvoicePosition[];
    person: Person;
    company: Company;
    receiptToCancel: BcmReceipt;
    addPositionsBackToCoaster: boolean;

    isProcessing = false;
    updatedPositions: InvoicePosition[] = [];

    checkAddonDone = false;
    addonStatus = false;

    checkCashRegisterDone = false;
    cashRegister: BcmCashRegister;

    requestReceiptDone = false;
    receipt: BcmReceipt;
    receiptErrorMessages: string[];

    canStartPaymentProcess = true;

    paymentType: TsePaymentType = TsePaymentType.CASH;

    BcmPaymentType = BcmPaymentType;

    wordTemplate: BcmInvoiceWdTemplate;

    address: string;
    note: string;

    totalSum: number;

    constructor(public dialogRef: MatDialogRef<PayByCashDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: {
                    positions: InvoicePosition[],
                    person: Person,
                    company: Company,
                    formGroup: FormGroup,
                    receiptToCancel: BcmReceipt
                    addPositionsBackToCoaster: boolean,
                    wordTemplate: BcmInvoiceWdTemplate
                },
                private _bcmTenantService: BcmTenantService,
                private _cashRegisterService: CashRegisterService,
                private _bcmService: BcmService,
                private _router: Router) {
        this.positions = data?.positions?.filter(p => p.taxRate?.id);
        this.totalSum = _cashRegisterService.getTotal(this.positions);
        this.person = data?.person;
        this.company = data?.company;
        this.parentFormGroup = data?.formGroup;
        this.receiptToCancel = data?.receiptToCancel;
        this.addPositionsBackToCoaster = data?.addPositionsBackToCoaster;
        this.wordTemplate = data?.wordTemplate;

        new Promise(() => this.checkTseConditions()).then();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    checkTseConditions(): void {
        of(this._bcmTenantService.checkPermission(BcmTenantPermission.CASH))
            .pipe(
                switchMap((hasPermission) => {
                    this.checkAddonDone = true;
                    this.addonStatus = hasPermission;
                    if (hasPermission) {
                        return this._cashRegisterService.getLoggedInCashRegisterForUser();
                    } else {
                        return throwError(() => new Error('Barzahlung ist nur in Verbindung mit dem DockSite Add-on möglich.'));
                    }
                })).subscribe(cashRegister => {
            this.checkCashRegisterDone = true;
            if (cashRegister?.id) {
                this.cashRegister = cashRegister;
                this.note = this.parentFormGroup?.get('note')?.value;
                this.address = this.parentFormGroup?.get('address')?.value;

                if (!this.address) {
                    if (this.person?.id) {
                        this.address = this.person.getAddressString('\n', true);
                    } else if (this.company?.id) {
                        this.address = this.company.getAddressString('\n', true);
                    }
                }

                if (this.receiptToCancel?.id) {
                    if (!cashRegister.currentUser.canCancel) {
                        return throwError(() => [`Du besitzt keine Berechtigung zum Stornieren an der aktuellen Kasse.`]);
                    }
                }
            } else {
                if (this.receiptToCancel?.id) {
                    return throwError(() => new Error('Stornieren nicht möglich. Du bist aktuell an keiner Kasse angemeldet.'));
                } else {
                    return throwError(() => new Error('Bezahlung nicht möglich. Du bist aktuell an keiner Kasse angemeldet.'));
                }
            }
        });
    }

    startPaymentProcess(): void {
        this.canStartPaymentProcess = false;
        this.isProcessing = true;

        let observable: Observable<BcmReceipt>;

        if (this.receiptToCancel?.id) {
            observable = this._cashRegisterService.addCancellationReceipt(
                this.cashRegister,
                this.receiptToCancel,
                this.paymentType,
                this.address,
                this.person,
                this.company,
                null,
                this.addPositionsBackToCoaster
            );
        } else {
            observable = this._cashRegisterService.addReceipt(
                this.cashRegister,
                this.positions,
                this.paymentType,
                this.address,
                this.person,
                this.company,
                this.wordTemplate,
                this.note
            );
        }

        observable.subscribe({
            next: receipt => {
                this.parentFormGroup?.markAsPristine();
                this.receipt = receipt;
                const paidPositionIds = this.positions.map(paidPosition => paidPosition.id);
                this.updatedPositions = [...(this.person?.positions || this.company?.positions || []).filter(p => !paidPositionIds.includes(p.id))];
                this.showReceiptPdf();
            },
            error: err => {
                this.receiptErrorMessages = err;
                this.updatedPositions = this.person?.positions || this.company?.positions || [];
            }
        })
            .add(() => {
                this.isProcessing = false;
                this.requestReceiptDone = true;
            });

    }

    close(): void {
        this.dialogRef.close(this.updatedPositions);
    }

    showReceiptPdf(): void {
        this._router.navigate([this._bcmService.baseUrl, 'accounting', 'invoices', 'view', this.receipt.receiptNumber])
            .then(() => this.dialogRef.close(this.updatedPositions));
    }


    // navigateToCashRegister(): void {
    //     this._router.navigate([this._bcmService.baseUrl, 'accounting', 'cash-registers', this.cashRegister.id])
    //         .then(() => this.dialogRef.close(this.updatedPositions));
    // }

}
