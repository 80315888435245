import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Observable } from 'rxjs';
import { ProductsApiService } from '../../../@shared/services';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IProduct, Product } from '@shared/models/product';
import { DEFAULT_DEBOUNCE_TIME } from '../../../@shared/constants';
import { isString } from '@shared/functions/is-string';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bProductValidators } from '@shared/validators/product/product-validators';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-product-list',
    templateUrl: './form-widget-product-list.component.html',
})
export class FormWidgetProductListComponent implements OnInit, OnDestroy {

    @Input()
    headline = 'Produkte';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    singleSelection = false;

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    productsForm: UntypedFormGroup;

    filteredProductsList$: Observable<Product[]>[] = [];

    products$: Observable<Product[]>;

    captureProducts: boolean;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _confirmDialogService: ConfirmDialogService,
                private _productsApiService: ProductsApiService) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('productsForm', this.productsForm);
        });

        this._createForm();
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('productsForm');
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    private _createForm(): void {
        this.productsForm = this._formBuilder.group({
            products: this._formBuilder.array([])
        });

        (this.productsForm.get('products') as UntypedFormArray).push(this.createForm());
        this._loadProducts();
    }

    addProduct(event): void {
        event.preventDefault();
        event.stopPropagation();
        (this.productsForm.get('products') as UntypedFormArray).push(this.createForm());
    }

    removeProduct(event, index: number): void {
        event.preventDefault();
        event.stopPropagation();

        this._confirmDialogService
            .useWarnTheme()
            .setTitle('Produkt entfernen')
            .setBody('Möchtest Du das Produkt wirklich entfernen?')
            .openWithCallback(() => {
                this.filteredProductsList$.splice(index);
                (this.productsForm.get('products') as UntypedFormArray).removeAt(index);
                (this.productsForm.get('products') as UntypedFormArray).markAsDirty();
            });
    }

    private createForm(): UntypedFormGroup {

        const fields: { [key: string]: UntypedFormControl } = {
            product: new UntypedFormControl(null, [U2bValidators.required('Bitte Produkt wählen'), U2bProductValidators.productExists()]),
            quantity: new UntypedFormControl(null, [U2bValidators.required('Bitte Einheit angeben')]),
        };

        const formGroup = new UntypedFormGroup(fields);
        const productField = formGroup.get('product');
        this.filteredProductsList$.push(
            productField.valueChanges
                .pipe(
                    startWith(this.products$),
                    debounceTime(DEFAULT_DEBOUNCE_TIME),
                    switchMap((value) => isString(value) ? this._filterProducts(value) : this.products$)
                )
        );

        return formGroup;
    }

    private _filterProducts(name = ''): Observable<Product[]> {
        return this.products$
            .pipe(
                map((products: Product[]) => products.filter((product: Product) => product.name.toLowerCase().includes(name.toLowerCase())))
            );
    }

    private _loadProducts(): void {
        this.products$ = this._productsApiService.getAll();
    }
}
