import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { HttpParams } from '@angular/common/http';
import { ElectricMeterApiService, ElectricMeterCabinetApiService } from '@modules/bcm/@shared/services';
import { map, startWith } from 'rxjs/operators';
import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import { HasBerthValidator } from '@shared/validators/boat/async/has-berth.validator';
import { U2bElectricMeterValidators } from '@shared/validators/electric-meter/electric-meter-validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { ElectricMeterCabinet } from '@shared/models/electric-meter-cabinet';
import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { Observable } from 'rxjs';
import { isString } from '@shared/functions/is-string';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'premium-form-widget-electric-meter',
    templateUrl: './premium-form-widget-electric-meter.component.html',
})
export class PremiumFormWidgetElectricMeterComponent implements OnInit {

    @Input()
    headline = 'Stromzähler wählen';

    @Input()
    dateFrom: Date;

    @Input()
    dateTo: Date;

    @Input()
    parentFormGroup: UntypedFormGroup;

    electricMeterFormGroup: UntypedFormGroup;

    loadingElectricMeterCabinets = false;
    selectedCabinet: ElectricMeterCabinet;
    electricMeterCabinets: ElectricMeterCabinet[];
    filteredElectricMeterCabinets$: Observable<ElectricMeterCabinet[]>;

    loadingElectricMeters = false;
    selectedElectricMeter: ElectricMeter;
    electricMeters: ElectricMeter[];
    filteredElectricMeters$: Observable<ElectricMeter[]>;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _dialogRef: MatDialog,
                private _bcmApiService: BcmApiService,
                private _bcmNavigationService: BcmNavigationService,
                private _hasBerthValidator: HasBerthValidator,
                private _electricMeterApiService: ElectricMeterApiService,
                private _informationDialogService: InformationDialogService,
                private _appNotificationService: AppNotificationService,
                private _electricMeterCabinetApiService: ElectricMeterCabinetApiService) {
        this._createForm();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('electricMeterForm', this.electricMeterFormGroup);
        });
    }

    public displayElectricMeterCabinetWith(electricMeterCabinet: ElectricMeterCabinet): string {
        return electricMeterCabinet ? electricMeterCabinet.handle : '';
    }

    public displayElectricMeterWith(electricMeter: IElectricMeter): string {
        return electricMeter ? electricMeter.handle : '';
    }

    private _createForm(): void {
        this.electricMeterFormGroup = this._formBuilder.group({
            addElectricMeter: [{value: false, disabled: false}],
            onlyFree: [{value: true, disabled: true}],

            electricMeterCabinet: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Zählerschrank auswählen'),
                    U2bElectricMeterValidators.isElectricMeterCabinet()
                ]
            ],
            electricMeter: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Stromzähler auswählen'),
                    U2bElectricMeterValidators.isElectricMeter(),
                ]
            ],
            meterReadingStart: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Zählerstand angeben'),
                    U2bNumericValidators.numberFormat(),
                ]
            ],
            meterReadingEnd: [
                {value: null, disabled: true},
                [
                    U2bNumericValidators.numberFormat(),
                ]
            ],
        });

        const addElectricMeterField = this.electricMeterFormGroup.get('addElectricMeter');
        const electricMeterCabinetField = this.electricMeterFormGroup.get('electricMeterCabinet');
        const onlyFreeField = this.electricMeterFormGroup.get('onlyFree');
        const electricMeterField = this.electricMeterFormGroup.get('electricMeter');
        const meterReadingStartField = this.electricMeterFormGroup.get('meterReadingStart');
        const meterReadingEndField = this.electricMeterFormGroup.get('meterReadingEnd');

        // When clicking on the initial checkbox
        addElectricMeterField.valueChanges
            .subscribe((addElectricMeter) => {
                if (addElectricMeter) {
                    this._loadElectricMeterCabinets();
                    enableControlsByName(this.electricMeterFormGroup, ['electricMeterCabinet', 'electricMeter', 'onlyFree', 'meterReadingStart']);
                    if (this.dateTo) {
                        meterReadingEndField.enable();
                    }
                } else {
                    disableControlsByName(this.electricMeterFormGroup, ['electricMeterCabinet', 'electricMeter', 'onlyFree', 'meterReadingStart', 'meterReadingEnd']);
                }
            });

        // When typing into cabinets field
        this.filteredElectricMeterCabinets$ = electricMeterCabinetField
            .valueChanges
            .pipe(
                startWith(''),
                map(value => isString(value) ? this._filterElectricMeterCabinets(value) : this.electricMeterCabinets.slice())
            );

        electricMeterCabinetField
            .valueChanges
            .subscribe((electricMeterCabinet) => {
                if (!electricMeterCabinet || isString(electricMeterCabinet)) {
                    disableControlsByName(this.electricMeterFormGroup, ['electricMeter', 'onlyFree']);
                    return;
                }

                this.selectedCabinet = electricMeterCabinet;
                this._loadElectricMeters(electricMeterCabinet);
                enableControlsByName(this.electricMeterFormGroup, ['electricMeter', 'onlyFree']);
                onlyFreeField.setValue(true);
            });

        this.filteredElectricMeters$ = electricMeterField
            .valueChanges
            .pipe(
                startWith(''),
                map(value => isString(value) ? this._filterElectricMeters(value) : this.electricMeters.slice())
            );

        electricMeterField
            .valueChanges
            .subscribe((electricMeter) => {
                if (!electricMeter || isString(electricMeter)) {
                    meterReadingStartField.reset();
                    meterReadingEndField.reset();
                    return;
                }

                this.selectedElectricMeter = electricMeter;
                const readings = (electricMeter?.readings || []);
                const hasOpenReadings = readings.find(reading => reading.startMeterReading != null && reading.endMeterReading == null);
                const maxValue = readings.length ? Math.max(...readings.map(reading => {
                    return Math.max(reading.startMeterReading, reading.endMeterReading);
                })) : 0;

                meterReadingStartField.setValue(maxValue);
                meterReadingStartField.setValidators([
                    U2bValidators.required('Bitte Zählerstand angeben'),
                    U2bNumericValidators.numberFormat(),
                    U2bNumericValidators.numberMin(maxValue),
                ]);

                if (hasOpenReadings) {
                    this._informationDialogService
                        .setTheme('warn')
                        .setTitle('Achtung')
                        .setLeftButton({
                            text: 'Ablesungen anzeigen',
                            onClick: () => {
                                this._bcmNavigationService
                                    .navigate(['electric-meters', electricMeter.id, 'readings'])
                                    .catch(() => this._appNotificationService.showError('Navigation ist aktuell nicht möglich.'));
                                this._dialogRef.closeAll();
                            }
                        })
                        .setBody(
                            'Es wurden offene Ablesungen gefunden.<br><br>' +
                            'Eine lückenlose Ablesehistorie ist sehr wichtig!<br><br>' +
                            'Bitte ergänze die offene(n) Ablesung(en) um den Endzählerstand bevor Du den ausgewählten Stromzähler verwendest'
                        )
                        .open();
                }
            });

        onlyFreeField.valueChanges.subscribe((value) => {
            this._loadElectricMeters(electricMeterCabinetField.value, value);
        });
    }

    private _filterElectricMeterCabinets(filter: string): ElectricMeterCabinet[] {
        return this.electricMeterCabinets.filter(
            electricMeterCabinet => electricMeterCabinet.handle.toLowerCase().includes(String(filter).toLowerCase())
        );
    }

    private _filterElectricMeters(filter: string): ElectricMeter[] {
        return this.electricMeters.filter(
            electricMeter => electricMeter.handle.toLowerCase().includes(String(filter).toLowerCase())
        );
    }

    private _loadElectricMeterCabinets(): void {
        this.loadingElectricMeterCabinets = true;
        this.electricMeterCabinets = [];
        this._electricMeterCabinetApiService
            .getAll()
            .subscribe((electricMeterCabinets) => this.electricMeterCabinets = electricMeterCabinets)
            .add(() => this.loadingElectricMeterCabinets = false);
    }

    private _loadElectricMeters(electricMeterCabinet?: ElectricMeterCabinet, onlyFree = true): void {

        let params = new HttpParams();

        if (electricMeterCabinet && electricMeterCabinet.id) {
            params = params
                .set('bcm_electric_meter_cabinets_id', electricMeterCabinet.id.toString())
                .set('onlyFree', `${onlyFree ? '1' : ''}`);
        }

        this.loadingElectricMeters = true;
        this.electricMeters = [];
        this._electricMeterApiService
            .getAll(params)
            .subscribe((electricMeters) => this.electricMeters = electricMeters)
            .add(() => this.loadingElectricMeters = false);
    }
}
