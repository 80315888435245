import { DataFilter } from '@core/datafilter/datafilter';
import { DataFilterOperator } from '@core/datafilter/filter-operator';
import { toDateStringDE, toDateTimeStringDE } from '@core/functions/to-date-string';
import { deepEqual } from '@shared/functions/deep-equal';
import { isValidDate } from '@core/functions/is-valid-date';

function equal(fieldValue, conditionValue) {
    if (!isValidDate(fieldValue) && !isValidDate(conditionValue) && deepEqual(fieldValue, conditionValue)) {
        return true;
    }

    const fieldValueDate = toDateStringDE(fieldValue);
    const conditionValueDate = toDateStringDE(conditionValue);

    if (fieldValueDate != null
        && conditionValueDate != null
        && fieldValueDate === conditionValueDate) {
        return true;
    }

    const fieldValueDateTime = toDateTimeStringDE(fieldValue);
    const conditionValueDateTime = toDateTimeStringDE(conditionValue);

    return fieldValueDateTime != null
        && conditionValueDateTime != null
        && fieldValueDateTime === conditionValue;
}

function contains(fieldValue, conditionValue, attributeToCompare: string) {

    const isArray = Array.isArray(fieldValue);

    if (isArray) {
        if (!attributeToCompare) {
            return String(fieldValue || '').indexOf(String(conditionValue || '')) >= 0;
        }
        return fieldValue.find(item => item[attributeToCompare] === conditionValue[attributeToCompare]) !== undefined;
    }

    return String(fieldValue || '').indexOf(conditionValue) >= 0;
}

export function defineDefaultOperators<Entity>(dataFilter: DataFilter<Entity>): void {

    dataFilter.operators.add(
        DataFilterOperator.GreaterThan,
        (fieldValue, conditionValue) => (fieldValue > conditionValue)
    );

    dataFilter.operators.add(
        DataFilterOperator.GreaterThanOrEqual,
        (fieldValue, conditionValue) => {
            return (fieldValue >= conditionValue);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.LessThan,
        (fieldValue, conditionValue) => {
            return (fieldValue < conditionValue);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.LessThanOrEqual,
        (fieldValue, conditionValue) => {
            return (fieldValue <= conditionValue);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.Equal,
        equal
    );

    dataFilter.operators.add(
        DataFilterOperator.NotEqual,
        (fieldValue, conditionValue) => {
            return !equal(fieldValue, conditionValue);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.Contains,
        (fieldValue, conditionValue, attributeToCompare: string) => {
            return contains(fieldValue, conditionValue, attributeToCompare);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.NotContains,
        (fieldValue, conditionValue, attributeToCompare: string) => {
            return !contains(fieldValue, conditionValue, attributeToCompare);
        }
    );

    // dataFilter.operators.add(
    //     DataFilterOperator.Has,
    //     (fieldValue, conditionValue) => {
    //         return (Array.isArray(fieldValue) && fieldValue.indexOf(conditionValue) >= 0);
    //     }
    // );
    //
    // dataFilter.operators.add(
    //     DataFilterOperator.Matches,
    //     (fieldValue, conditionValue) => {
    //         if (typeof conditionValue === 'string') {
    //             conditionValue = new RegExp(conditionValue);
    //         }
    //
    //         return (typeof conditionValue.test === 'function' && conditionValue.test(fieldValue));
    //     }
    // );

    dataFilter.operators.add(
        DataFilterOperator.StartsWith,
        (fieldValue, conditionValue) => {
            fieldValue = String(fieldValue || '');
            conditionValue = String(conditionValue || '');
            return fieldValue.startsWith(conditionValue);
        }
    );

    dataFilter.operators.add(
        DataFilterOperator.EndsWith,
        (fieldValue, conditionValue) => {
            fieldValue = String(fieldValue || '');
            conditionValue = String(conditionValue || '');
            return fieldValue.endsWith(conditionValue);
        }
    );
}
