import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { parseHttpResponseItem, parseHttpResponsePage } from '@shared/functions/parse-http-response';
import { Page, PageRequest } from '@shared/models/pagination';
import { BcmCashRegister, BcmCashRegisterDto } from '@shared/models/bcm-cash-register';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';
import { TseDocument } from '@shared/models/tse/TseDocument';
import {
    BcmCashRegisterEndOfDayDocument,
    BcmCashRegisterEndOfDayDocumentDto
} from '@shared/models/bcm-cash-register-end-of-day-document';
import { BcmCashRegisterUser, BcmCashRegisterUserDto } from '@shared/models/bcm-cash-register-user';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';

@Injectable({
    providedIn: 'root'
})
export class BcmCashRegistersApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'cash-registers';

    getAll(pageRequest: PageRequest<BcmCashRegister>): Observable<Page<BcmCashRegister>> {
        return super.get<Page<BcmCashRegisterDto>>(this.RESOURCE_ENDPOINT, pageRequest.getHttpParams())
            .pipe(parseHttpResponsePage<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmCashRegister> {
        return super.get<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    add(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<BcmCashRegister> {
        return super.post<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}`, cashRegister, params)
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    update(cashRegister: BcmCashRegister, cashRegisterData: Partial<BcmCashRegister>): Observable<BcmCashRegister> {
        return super.put<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}`, cashRegisterData)
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    remove(cashRegister: BcmCashRegister): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}`);
    }

    lock(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<void> {
        return super.put<void>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/lock`, params);
    }

    unlock(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<void> {
        return super.put<void>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/unlock`, params);
    }

    login(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<void> {
        return super.put<void>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/login`, params);
    }

    logout(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<void> {
        return super.put<void>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/logout`, params);
    }

    getReceiptNumber(cashRegister: BcmCashRegister): Observable<string> {
        return super.get<string>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts/identifier`);
    }

    cancelReceiptCreation(cashRegister: BcmCashRegister,
                          receiptNumber: string,
                          body: {error: Error, person?: Person, company?: Company}): Observable<number> {
        const params = new HttpParams()
            .set('receiptNumber', receiptNumber);

        return super.delete<number>(
            `${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts/identifier`,
            params,
            body
        );
    }

    getAllReceipts(cashRegister: BcmCashRegister, pageRequest: PageRequest<BcmReceipt>): Observable<Page<BcmReceipt>> {
        return super.get<Page<BcmReceiptDto>>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts`, pageRequest?.getHttpParams())
            .pipe(parseHttpResponsePage<BcmReceiptDto, BcmReceipt>(BcmReceipt));
    }

    getUserRights(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<BcmCashRegisterUser> {
        return super.get<BcmCashRegisterUserDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/rights`, params)
            .pipe(parseHttpResponseItem<BcmCashRegisterUserDto, BcmCashRegisterUser>(BcmCashRegisterUser));
    }

    getOneReceipt(cashRegister: BcmCashRegister, receiptId: number, params: HttpParams = new HttpParams()): Observable<BcmReceipt> {
        return super.get<BcmReceiptDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts/${receiptId}`, params)
            .pipe(parseHttpResponseItem<BcmReceiptDto, BcmReceipt>(BcmReceipt));
    }

    incrementReprintCounter(cashRegister: BcmCashRegister, receiptId: number, params: HttpParams = new HttpParams()): Observable<BcmReceipt> {
        return super.put<BcmReceiptDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts/${receiptId}/reprintCounter`, params)
            .pipe(parseHttpResponseItem<BcmReceiptDto, BcmReceipt>(BcmReceipt));
    }

    addReceipt(cashRegister: BcmCashRegister,
               receipt: BcmReceiptDto,
               person: Person,
               company: Company,
               pdfData: any,
               saldo: number,
               amount?: number,
               receiptToCancel?: BcmReceipt,
               addPositionsBackToCoaster?: boolean): Observable<BcmReceipt> {
        return super.post<BcmReceiptDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/receipts`, {
            ...receipt,
            person,
            company,
            pdfData,
            saldo,
            amount,
            receiptToCancel,
            addPositionsBackToCoaster
        })
            .pipe(parseHttpResponseItem<BcmReceiptDto, BcmReceipt>(BcmReceipt));
    }

    getAllEndOfDayDocuments(cashRegister: BcmCashRegister, pageRequest: PageRequest<BcmCashRegisterEndOfDayDocument>): Observable<Page<BcmCashRegisterEndOfDayDocument>> {
        return super.get<Page<BcmCashRegisterEndOfDayDocumentDto>>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/eod`, pageRequest?.getHttpParams())
            .pipe(parseHttpResponsePage<BcmCashRegisterEndOfDayDocumentDto, BcmCashRegisterEndOfDayDocument>(BcmCashRegisterEndOfDayDocument));
    }

    getOneEndOfDayReceipt(cashRegister: BcmCashRegister, endOfDayDocumentId: number, params: HttpParams = new HttpParams()): Observable<BcmCashRegisterEndOfDayDocument> {
        return super.get<BcmCashRegisterEndOfDayDocumentDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/eod/${endOfDayDocumentId}`, params)
            .pipe(parseHttpResponseItem<BcmCashRegisterEndOfDayDocumentDto, BcmCashRegisterEndOfDayDocument>(BcmCashRegisterEndOfDayDocument));
    }

    getLatestEndOfDayDocument(cashRegister: BcmCashRegister, params: HttpParams = new HttpParams()): Observable<BcmCashRegisterEndOfDayDocument> {
        return super.get<BcmCashRegisterEndOfDayDocumentDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/eod/latest`, params)
            .pipe(parseHttpResponseItem<BcmCashRegisterEndOfDayDocumentDto, BcmCashRegisterEndOfDayDocument>(BcmCashRegisterEndOfDayDocument));
    }

    getOneEndOfDayExelExport(cashRegister: BcmCashRegister, endOfDayDocumentId: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/eod/${endOfDayDocumentId}/excel-export`, params);
    }

    openCashRegister(cashRegister: BcmCashRegister): Observable<BcmCashRegister> {
        return super.put<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/openCashRegister`, {})
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    closeCashRegister(cashRegister: BcmCashRegister, closedCash: number, endOfDayDocument: TseDocument): Observable<BcmCashRegister> {
        return super.put<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}/${cashRegister.id}/closeCashRegister`, {
            closedCash,
            endOfDayDocument
        })
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

    getLoggedInCashRegisterForUser(): Observable<BcmCashRegister> {
        return super.get<BcmCashRegisterDto>(`${this.RESOURCE_ENDPOINT}/loggedIn`)
            .pipe(parseHttpResponseItem<BcmCashRegisterDto, BcmCashRegister>(BcmCashRegister));
    }

}
