import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { Product } from '@shared/models/product';
import { cloneDeep } from '@shared/functions/clone-deep';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';
import { Berth } from '@shared/models/berth';
import { Boat } from '@shared/models/boat';
import { BcmPaymentType, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { TenantRelation } from '@shared/models/tenant-relation';
import { TranslationService } from '@core/translation/translation.service';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { GetProductSubscriptionDatesDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-dates-dialog/get-product-subscription-dates-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProductSubscription } from '@shared/models/product-subscription';

@Component({
    selector: 'form-widget-invoice',
    templateUrl: './form-widget-invoice.component.html',
    styleUrls: ['./form-widget-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetInvoiceComponent implements OnInit, OnDestroy, OnChanges {

    invoicePaymentType = BcmPaymentType;

    private _unsubscribeAll = new Subject<any>();

    private _prevGivenInvoicePositions: InvoicePosition[];

    @Input() appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = TranslationService.translate('invoicePositions');

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    givenInvoicePositions: InvoicePosition[];

    @Input()
    showAddBerthPositionButton = false;

    @Input()
    showPositionsTableOnly = false;

    @Input()
    givenBerth?: Berth;

    @Input()
    givenBoat?: Boat;

    @Input()
    givenTenantRelation?: TenantRelation;

    @Input()
    givenStartDate: Date;

    @Input()
    givenEndDate: Date;

    person: Person;
    company: Company;
    hasPersonOrCompanyData = false;

    invoiceFormGroup: UntypedFormGroup;

    name: string;

    mail: string;

    invoicePositions: InvoicePosition[] = [];

    positionsSum: number;

    colspan = 8;

    canPayByCash: boolean;
    canPayByCashToolTip: string;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _confirmDialogService: ConfirmDialogService,
                private _bcmInvoicePositionsService: BcmInvoicePositionsService,
                private _bcmTenantService: BcmTenantService,
                private _matDialog: MatDialog,
                private _bcmSettings: BcmSettingsFacade,
                private readonly cdRef: ChangeDetectorRef) {

        this.invoiceFormGroup = this._formBuilder.group({
            payByCash: [false],
            checkPayByCash: [false],
            createInvoice: [false],
            dueDateDays: [_bcmSettings.settings()[BcmSettingsSectionName.InvoiceTemplate].dueDateDefaultDays || 14],
            paymentType: [this.invoicePaymentType.Invoice],
            sendToPrinter: [false],
            sendToMail: [true],
            mail: [null, [U2bValidators.email()]],
            positions: [],
        });

        this.canPayByCash = this._bcmTenantService.checkPermission(BcmTenantPermission.CASH);

        if (!this.canPayByCash) {
            this.canPayByCashToolTip = 'Leider bist du nicht im Besitz des Barzahlen Add-on. Wende dich an den Up2Boat Support';
            this.invoiceFormGroup.get('payByCash').disable();
        }

        this.invoiceFormGroup.get('mail').disable();

        this.invoiceFormGroup.get('sendToMail').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(active => {
                if (active) {
                    this.invoiceFormGroup.get('mail').enable();
                } else {
                    this.invoiceFormGroup.get('mail').disable();
                }
            });

        this.invoiceFormGroup.get('payByCash').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(active => {
                if (active) {
                    this.invoiceFormGroup.get('createInvoice').setValue(false);
                    this.invoiceFormGroup.get('createInvoice').disable();
                } else {
                    this.invoiceFormGroup.get('createInvoice').enable({emitEvent: false});
                }
            });

        this.invoiceFormGroup.get('createInvoice').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(active => {
                if (active) {
                    this.invoiceFormGroup.get('mail').enable();
                    this.invoiceFormGroup.get('payByCash').setValue(false);
                    this.invoiceFormGroup.get('payByCash').disable();
                } else {
                    this.invoiceFormGroup.get('mail').disable();
                    if (this.canPayByCash) {
                        this.invoiceFormGroup.get('payByCash').enable({emitEvent: false});
                    }
                }
            });
    }

    ngOnInit(): void {

        const formGroupValues = this.parentFormGroup.getRawValue();

        if (formGroupValues.deviatingInvoiceRecipient) {
            this.person = (formGroupValues.personForm?.person || formGroupValues.personForm || null);
            this.company = (formGroupValues.companyForm?.company || formGroupValues.companyForm || null);
        } else {
            this.person = formGroupValues.ownerPerson || formGroupValues.personForm?.person || formGroupValues.personForm || null;
            this.company = formGroupValues.ownerCompany || formGroupValues.companyForm?.company || formGroupValues.companyForm || null;
        }

        this.hasPersonOrCompanyData = !!this.person || !!this.company;

        this.parentFormGroup.addControl('invoiceForm', this.invoiceFormGroup);

        this.parentFormGroup
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(value => {
                this.updatePrefillData(value);

                if (value.deviatingInvoiceRecipient) {
                    this.person = (value.personForm?.person || null);
                    this.company = (value.companyForm?.company || null);
                } else {
                    this.person = value.ownerPerson || value.personForm?.person || value.personForm || null;
                    this.company = value.ownerCompany || value.companyForm?.company || value.companyForm || null;
                }

                this.hasPersonOrCompanyData = !!this.person || !!this.company;

                this.cdRef.detectChanges();
            });

        this.updatePrefillData(this.parentFormGroup.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.invoiceFormGroup) {
            return;
        }
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'givenInvoicePositions': {

                        /**
                         * Nach Absprache mit Sandra am 22.03.2023:
                         * 1. Wir löschen an dieser Stelle alle prev Produkte. Diese können wir nur identifizieren,
                         *    indem wir eine uuid einführen, die wir nutzen können, wenn keine ID vorhanden ist.
                         * 2. Wir fügen die neuen Positionen hinzu.
                         * 3. Die manuell hinzugefügten Positionen bleiben so erhalten.
                         * 4. Sollte ein Produkt doppelt vorkommen, dann leben wir erst einmal damit.
                         *    Beispiel:
                         *     User fügt Produkt A mit Menge 10 hinzu. Über die Beziehung kommt nochmals das ProduktA
                         *     mit Menge 1 dazu. In dem Fall haben wir 2 Positionen, ein Mal mit 10 und ein Mal
                         *     mit Menge 1.
                         *
                         *     Möglich ist es, diese zusammenzufassen. Das machen wir aber - wenn überhaupt - erst wenn
                         *     es ein Kunde haben möchte.
                         */
                        this.invoicePositions = this.invoicePositions
                            .filter(invoicePosition => {
                                // Position entfernen, wenn sie zuvor über givenInvoicePositions hinzugekommen ist.
                                const foundPositionInPreviousPositions = (this._prevGivenInvoicePositions || [])
                                    .find(prevInvoicePosition => prevInvoicePosition.uuid === invoicePosition.uuid);

                                return foundPositionInPreviousPositions === undefined;
                            });

                        this.invoicePositions.push(...this.givenInvoicePositions);

                        this._prevGivenInvoicePositions = cloneDeep(this.givenInvoicePositions);
                        this.updateSum();

                        this.cdRef.detectChanges();
                    }
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('contractForm');
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    updatePrefillData(value): void {
        this.name = value?.name;
        this.mail = value?.mail;

        if (this.mail && this.invoiceFormGroup.get('mail').value == null) {
            this.invoiceFormGroup.patchValue({mail: this.mail}, {emitEvent: false, onlySelf: true});
        }
    }

    addBerthInvoicePosition(): void {

        const quantityButtons: ProduktQuantityButton[] = [];

        const berth = this.givenBerth;
        const boat = this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat;

        if (berth?.length && berth?.width) {
            const quantity = roundNumber(berth.length * berth.width, RoundNumberFactor.TwoDecimals);
            quantityButtons.push({
                label: `Fläche Liegeplatz: ${quantity} m²`,
                quantity
            });
        }

        if (boat) {
            let quantity = 0;

            if (boat?.sqm) {
                quantity = boat?.sqm;
            } else if (boat.length && boat.width) {
                quantity = boat.length * boat.width;
            }

            quantity = roundNumber(quantity, RoundNumberFactor.TwoDecimals);

            if (quantity) {
                quantityButtons.push({
                    label: `Fläche Boot: ${quantity} m²`,
                    quantity
                });
            }
        }

        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.parentFormGroup?.value?.boatForm?.boat,
                    preselection: {
                        'winterStorage': this.parentFormGroup?.value?.winterStorageAssignment?.winterStorage,
                        'boat': this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat,
                        'berth': this.givenBerth,
                        'tenantRelation': this.givenTenantRelation
                    },
                    fromDate: this.givenStartDate,
                    toDate: this.givenEndDate,
                },
                (product: Product) => {
                    return product.category?.uniqueId === 'Liegeplatzgebühr' || product.category?.parentCategory?.uniqueId === 'Liegeplatzgebühr';
                }, quantityButtons,
                this.givenStartDate,
                this.givenEndDate)
            .pipe(take(1))
            .subscribe(invoicePositions => {
                if (invoicePositions) {
                    this.invoicePositions.push(...invoicePositions);
                    this.updateSum();
                }
            });
    }

    addInvoicePosition(): void {
        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat,
                    preselection: {
                        'winterStorage': this.parentFormGroup?.value?.winterStorageAssignment?.winterStorage,
                        'boat': this.givenBoat || ((this.parentFormGroup?.value?.winterStorageAssignment?.boat)
                            ? this.parentFormGroup?.value?.winterStorageAssignment?.boat : this.parentFormGroup?.value?.boatForm?.boat),
                        'berth': this.givenBerth,
                        'tenantRelation': this.givenTenantRelation
                    },
                    fromDate: this.givenStartDate,
                    toDate: this.givenEndDate,
                },
                this.showAddBerthPositionButton
                    ? (product: Product) => {
                        return product.category?.uniqueId !== 'Liegeplatzgebühr' && product.category?.parentCategory?.uniqueId !== 'Liegeplatzgebühr';
                    }
                    : undefined,
                [],
                this.givenStartDate,
                this.givenEndDate
            )
            .subscribe(invoicePositions => {
                if (invoicePositions?.length > 0) {
                    this.invoicePositions.push(...invoicePositions);
                    this.updateSum();
                }
            });
    }

    editInvoicePosition(invoicePosition: InvoicePosition, index: number): void {
        this._bcmInvoicePositionsService
            .editInvoicePosition(invoicePosition)
            .subscribe(positions => {
                if (positions.length > 0) {
                    this.invoicePositions[index] = positions[positions.findIndex(p => p.positionId !== null)];
                    this.invoicePositions.push(...positions.filter(p => p.positionId === null));
                    this.updateSum();
                }
            });
    }

    removeInvoicePosition(index: number): void {
        this._confirmDialogService
            .useWarnTheme()
            .setBody('Möchtest Du die Position wirklich löschen?')
            .openWithCallback(
                () => {
                    this.invoicePositions.splice(index, 1);
                    this.updateSum();
                }
            );
    }

    onDrop(event: CdkDragDrop<InvoicePosition[]>): void {
        moveItemInArray(this.invoicePositions, event.previousIndex, event.currentIndex);
        // this.invoicePositions.forEach((invoicePosition, idx) => {
        //     invoicePosition.order = idx + 1; // todo: add order to DB for each invoice position
        // });
    }

    updateSum(): void {
        this.invoiceFormGroup.patchValue({
            positions: this.invoicePositions
        });
        this.positionsSum = (this.invoicePositions || [])
            .reduce((accumulator: number, invoicePosition) => {
                return accumulator + invoicePosition.totalPrice;
            }, 0);
        this.cdRef.detectChanges();
    }

    addOrEditSubscription(event: MouseEvent, index: number): void {
        event.stopPropagation();

        if (this.invoicePositions[index].subscription === null) {

            const aboDialogRef = this._matDialog.open(
                GetProductSubscriptionDatesDialogComponent,
                {
                    data: {
                        initialFromDate: this.givenStartDate,
                        initialPayableOption: this.invoicePositions[index].tenantRelation?.payableOption,
                    }
                }
            );

            aboDialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.invoicePositions[index].subscription = new ProductSubscription(data);
                }
            });

        } else {

            const aboDialogRef = this._matDialog.open(
                GetProductSubscriptionDatesDialogComponent,
                {
                    data: {
                        subscription: this.invoicePositions[index].subscription,
                    }
                }
            );

            aboDialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.invoicePositions[index].subscription = new ProductSubscription(data);
                } else if (data === false) {
                    this.invoicePositions[index].subscription = null;
                }
            });

        }
    }

}
