import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BcmCashRegistersApiService } from '@bcmApiServices/bcm-cash-registers-api.service';

@Component({
    selector: 'app-open-cash-register-dialog',
    templateUrl: './open-cash-register-dialog.component.html',
    styleUrls: ['./open-cash-register-dialog.component.scss']
})
export class OpenCashRegisterDialogComponent implements OnInit, OnDestroy {

    _unsubscribeAll = new Subject();

    formGroup: UntypedFormGroup;

    cashRegister: BcmCashRegister;

    previousDayCash: number;

    isLoading: boolean;

    constructor(public dialogRef: MatDialogRef<OpenCashRegisterDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: { cashRegister: BcmCashRegister },
                private _formBuilder: UntypedFormBuilder,
                private _cashRegisterService: CashRegisterService,
                private _cashRegisterApiService: BcmCashRegistersApiService) {
        this.cashRegister = data?.cashRegister;
    }

    ngOnInit(): void {
        this._createForm();

        if (this.cashRegister.closedOn) {
            this._cashRegisterApiService.getLatestEndOfDayDocument(this.cashRegister)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                    next: document => {
                        // const actualBalancePosition: TseDocumentPositionTotal = document.tseDocument.positions
                        //     .find(p => p['caption'] === 'Actual balance');

                        this.previousDayCash = document.actualClosingCash;
                        this.formGroup.get('cash').setValue(this.previousDayCash);
                    },
                    error: () => {
                        this.previousDayCash = null;
                        this.formGroup.get('cash').setValue(0);
                    }
                });
        } else {
            this.previousDayCash = null;
            this.formGroup.get('cash').setValue(0);
        }

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            cash: [0, [U2bValidators.required('Bitte gib den aktuellen Kassenbestand an.')]]
        });
    }

    openCashRegister(): void {
        this.isLoading = true;

        const cash = this.formGroup.get('cash').value;

        this._cashRegisterService.openCashRegister(this.cashRegister, cash)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: () => this.dialogRef.close(),
                error: () => this.isLoading = false
            });
    }

}
