import { ContractShortCodes } from '@modules/bcm/@shared/enums/contract-short-codes';
import { Boat } from '@shared/models/boat';
import { Tenant } from '@shared/models/tenant';
import { Company } from '@shared/models/company';
import { Berth } from '@shared/models/berth';

function uniq(a): [] {
    const prims = {'boolean': {}, 'number': {}, 'string': {}};
    const objs = [];

    return a.filter((item) => {
        const type = typeof item;
        if (type in prims) {
            return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        } else {
            return objs.indexOf(item) >= 0 ? false : objs.push(item);
        }
    });
}

export function missingContractFieldsCompany(knownShortCodeList: string[],
                                             tenant: Tenant = {} as Tenant,
                                             company: Company = {} as Company,
                                             boat: Boat = {} as Boat,
                                             berth: Berth = {} as Berth): {
    tenant: string[],
    company: string[],
    boat: string[],
    berth: string[],
} {
    const missingFields: {
        tenant: string[],
        company: string[],
        boat: string[],
        berth: string[],
    } = {
        tenant: [],
        company: [],
        boat: [],
        berth: [],
    };

    const {
        city,
        postCode,
        street,
        country,
        name,
        form,
        fullName,
        BIC,
        IBAN,
        Kreditinstitut,
        primaryMail,
        primaryPhone,
        mails,
        phones,
        identNumber,
    } = company;

    for (const code of knownShortCodeList) {
        switch (code) {
            case ContractShortCodes.VE_ADRESSE:
            case ContractShortCodes.VE_ADRESSE_UMBRUCH:
                if (!name && !fullName) {
                    missingFields.company.push('Vollständiger Name');
                }
                if (!street) {
                    missingFields.company.push('Straße');
                }
                if (!postCode) {
                    missingFields.company.push('PLZ');
                }
                if (!city) {
                    missingFields.company.push('Ort');
                }
                break;
            case ContractShortCodes.VE_NAME:
                if (!name && !fullName) {
                    missingFields.company.push('Vollständiger Name');
                }
                break;
            case ContractShortCodes.VE_NAME_PLUS_TITEL:
                if (!name && !fullName) {
                    missingFields.company.push('Vollständiger Name inkl. Titel');
                }
                break;
            case ContractShortCodes.VE_STR:
                if (!street) {
                    missingFields.company.push('Straße');
                }
                break;
            case ContractShortCodes.VE_PLZ:
                if (!postCode) {
                    missingFields.company.push('PLZ');
                }
                break;
            case ContractShortCodes.VE_STADT:
                if (!city) {
                    missingFields.company.push('Stadt');
                }
                break;
            case ContractShortCodes.VE_LAND:
                if (!country) {
                    missingFields.company.push('Land');
                }
                break;
            case ContractShortCodes.VE_MAIL:
                if (!primaryMail && !mails?.length) {
                    missingFields.company.push('E-Mail');
                }
                break;
            case ContractShortCodes.VE_TEL:
                if (!primaryPhone && !phones?.length) {
                    missingFields.company.push('Telefonnummer');
                }
                break;
            case ContractShortCodes.VE_MOBIL:
                if (!phones?.find(phone => phone.type === 'Mobil')) {
                    missingFields.company.push('Mobilnummer');
                }
                break;
            case ContractShortCodes.VE_FAX:
                if (!phones?.find(phone => phone.type === 'Fax')) {
                    missingFields.company.push('Faxnummer');
                }
                break;
            // case ContractShortCodes.VE_WEB:
            //     if (!web) {
            //         missingFields.company.push('Webseite');
            //     }
            //     break;
            case ContractShortCodes.VE_BANK_KONTOINHABER:
                if (!name || !fullName) {
                    missingFields.company.push('Kontoinhaber');
                }
                break;
            case ContractShortCodes.VE_BANK_NAME:
                if (!Kreditinstitut) {
                    missingFields.company.push('Bankname');
                }
                break;
            case ContractShortCodes.VE_BANK_IBAN:
                if (!IBAN) {
                    missingFields.company.push('IBAN');
                }
                break;
            case ContractShortCodes.VE_BANK_BIC:
                if (!BIC) {
                    missingFields.company.push('BIC');
                }
                break;
            case ContractShortCodes.VE_IDENTIFIKATIONSNUMMER:
                if (!identNumber) {
                    missingFields.company.push('Identifikationsnummer');
                }
                break;


            // TENANT
            case ContractShortCodes.VA_ADRESSE:
            case ContractShortCodes.VA_ADRESSE_UMBRUCH:
                if (!tenant.fullAddress) {
                    missingFields.tenant.push('Vollständige Adresse');
                }
                break;
            case ContractShortCodes.VA_NAME:
            case ContractShortCodes.VA_BANK_KONTOINHABER:
                if (!tenant.name) {
                    missingFields.tenant.push('Name');
                }
                break;
            case ContractShortCodes.VA_BANK_NAME:
                if (!tenant.primaryBankAccount?.bankName) {
                    missingFields.tenant.push('Bankname');
                }
                break;
            case ContractShortCodes.VA_BANK_IBAN:
                if (!tenant.primaryBankAccount?.iban) {
                    missingFields.tenant.push('IBAN');
                }
                break;
            case ContractShortCodes.VA_BANK_BIC:
                if (!tenant.primaryBankAccount?.bic) {
                    missingFields.tenant.push('BIC');
                }
                break;
            case ContractShortCodes.VA_FAX:
                if (!tenant.fax) {
                    missingFields.tenant.push('Fax');
                }
                break;
            case ContractShortCodes.VA_MAIL:
                if (!tenant.mail) {
                    missingFields.tenant.push('Mail');
                }
                break;
            case ContractShortCodes.VA_MOBIL:
                if (!tenant.mobile) {
                    missingFields.tenant.push('Mobil');
                }
                break;
            case ContractShortCodes.VA_TEL:
                if (!tenant.phone) {
                    missingFields.tenant.push('Telefon');
                }
                break;
            case ContractShortCodes.VA_WEB:
                if (!tenant.website) {
                    missingFields.tenant.push('Webseite');
                }
                break;

            // BOOT
            case ContractShortCodes.BOOT_NAME :
                if (!boat.name) {
                    missingFields.boat.push('Name');
                }
                break;
            case ContractShortCodes.BOOT_TYP:
                if (!boat.type) {
                    missingFields.boat.push('Typ');
                }
                break;
            case ContractShortCodes.BOOT_MODELL:
                if (!boat.model) {
                    missingFields.boat.push('Modell');
                }
                break;
            case ContractShortCodes.BOOT_HERSTELLER :
                if (!boat.manufacturer) {
                    missingFields.boat.push('Hersteller');
                }
                break;
            case ContractShortCodes.BOOT_KENNZEICHEN :
                if (!boat.licensePlate) {
                    missingFields.boat.push('Kennzeichen');
                }
                break;
            case ContractShortCodes.BOOT_HIN:
                if (!boat.HIN) {
                    missingFields.boat.push('HIN');
                }
                break;
            case ContractShortCodes.BOOT_LAENGE :
                if (!boat.length) {
                    missingFields.boat.push('Länge');
                }
                break;
            case ContractShortCodes.BOOT_BREITE:
                if (!boat.width) {
                    missingFields.boat.push('Breite');
                }
                break;


            // Liegeplatz (LP)
            case ContractShortCodes.LP_NAME:
                if (!berth?.handle) {
                    missingFields.berth.push('Name');
                }
                break;
            case ContractShortCodes.LP_BREITE:
                if (!berth?.width) {
                    missingFields.berth.push('Breite');
                }
                break;
            case ContractShortCodes.LP_LAENGE:
                if (!berth?.length) {
                    missingFields.berth.push('Länge');
                }
                break;
            case ContractShortCodes.LP_QM:
                if (!berth?.width) {
                    missingFields.berth.push('Breite');
                }
                if (!berth?.length) {
                    missingFields.berth.push('Länge');
                }
                break;
            case ContractShortCodes.LP_STEG_NAME:
                if (!berth?.pier?.handle) {
                    missingFields.berth.push('Stegname');
                }
                break;


            // Lagerplätze (LAPL)
            case ContractShortCodes.LAPL_NAME:
                if (!company) {
                    missingFields.berth.push('Name');
                }
                break;
            case ContractShortCodes.LAPL_QM:
                if (!company) {
                    missingFields.berth.push('Fläche');
                }
                break;
        }
    }

    missingFields.company = uniq(missingFields.company);
    missingFields.tenant = uniq(missingFields.tenant);
    missingFields.boat = uniq(missingFields.boat);
    missingFields.berth = uniq(missingFields.berth);

    return missingFields;
}
