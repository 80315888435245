import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { U2bApiService } from './u2b-api.service';

export interface U2bFile {
    id: number;
    name: string;
    file: File;
    note: string;
    mimeType: string;
    markForDelete?: boolean;
    hasChanges?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class U2bFilesApiService extends U2bApiService {

    private RESOURCE_ENDPOINT = 'files';

    getAll(params: HttpParams = new HttpParams()): Observable<U2bFile[]> {
        return super.get<U2bFile[]>(this.RESOURCE_ENDPOINT, params);
    }

    getOne(file: U2bFile, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${file.id}`, params);
    }

    add(file: U2bFile): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}`, this._getFileFormData(file));
    }

    update(file: U2bFile): Observable<any> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${file.id}`, this._getFileFormData(file));
    }

    delete(file: U2bFile): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${file.id}`);
    }

    private _getFileFormData(file: U2bFile): FormData {
        const theFile = new FormData();
        theFile.append('file', file.file, file.file.name);
        return theFile;
    }
}
