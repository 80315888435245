import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { ElectricMeter } from '@shared/models/electric-meter';
import { isPlainObject } from '@shared/functions/is-plain-object';

export class IsElectricMeterValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('electricMeterExists', message || 'Falsche Eingabe. Bitte Stromzähler aus der Liste wählen.');
    }

    public validate({value}: AbstractControl): ValidationErrors {
        return !value || (isPlainObject(value) && value instanceof ElectricMeter && value.id != null) ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
