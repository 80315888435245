<div [formGroup]="companyFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex="100">

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container *ngIf="!slimmedView">
                <h2 [innerHTML]="headline"></h2>
            </ng-container>

            <ng-container *ngIf="!company?.id && canCreate">
                <div>
                    <button mat-icon-button
                            type="button"
                            color="primary"
                            data-test="B-Optionale Felder"
                            matTooltip="Optionale Felder {{showOptionalFields ? 'ausblenden' : 'einblenden'}}"
                            (click)="onClickToggleOptionalFields()">
                        <ng-container *ngIf="!showOptionalFields">
                            <mat-icon>visibility_off</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="showOptionalFields">
                            <mat-icon>visibility</mat-icon>
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="loadingCompanies; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!companies?.length">
                <u2b-message>
                    <header>Keine Organisation gefunden</header>
                    <p>Es konnten keine Organisationen im System gefunden werden.</p>
                </u2b-message>
            </ng-container>

            <ng-container *ngIf="companies?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-label>{{inputLabel}}</mat-label>
                        <input type="text" matInput data-test="FI-Nach Organisation suchen..."
                               formControlName="company"
                               [matAutocomplete]="companySelector">
                        <button matSuffix
                                mat-icon-button
                                type="button"
                                color="warn"
                                matTooltip="Ausgewählte Organisation entfernen"
                                [disabled]="!company?.id || companyFormGroup.disabled"
                                (click)="onClickRemoveCompany()">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <mat-error>{{companyFormGroup.get('company').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete #companySelector="matAutocomplete" [displayWith]="displayCompanyWith">
                        <ng-container *ngFor="let company of filteredCompanies$ | async">
                            <mat-option [value]="company">
                                <bcm-company-link [disableLink]="true"
                                                  [company]="company"
                                                  [suffix]="company.identNumber ? (' (' + company.identNumber + ')') : null"></bcm-company-link>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="company?.id">
            <ng-container *ngIf="company.activeTenantRelationAssignments.length && !slimmedView">
                <strong>Aktive Beziehungen:</strong>
                <bcm-tenant-relation-colored-label
                        [tenantRelations]="company?.activeTenantRelationAssignments"></bcm-tenant-relation-colored-label>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="!company?.id && canCreate">
        <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="{{!showOptionalFields ? 100 : 50}}"
                        floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput data-test="FI-Name" type="text" formControlName="name" autocomplete="new-password">
            <mat-error>{{companyFormGroup.get('name').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="optional && showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                <mat-label>Gesellschaftsform{{optionalString}}</mat-label>
                <input matInput data-test="FI-Gesellschaftsform" type="text" formControlName="form"
                       autocomplete="new-password">
                <mat-error>{{companyFormGroup.get('form').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>Straße + Hausnummer{{optionalString}}</mat-label>
                <input matInput data-test="FI-Straße + Hausnummer" type="text" formControlName="street"
                       autocomplete="new-password">
                <mat-error>{{companyFormGroup.get('street').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="40" floatLabel="always">
                <mat-label>PLZ{{optionalString}}</mat-label>
                <input matInput data-test="FI-PLZ" type="text" formControlName="postCode" autocomplete="new-password">
                <mat-error>{{companyFormGroup.get('postCode').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="60" floatLabel="always">
                <mat-label>Ort{{optionalString}}</mat-label>
                <input matInput data-test="FI-Ort" type="text" formControlName="city" autocomplete="new-password">
                <mat-error>{{companyFormGroup.get('city').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="showOptionalFields">
            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>E-Mail (optional)</mat-label>
                <input matInput data-test="FI-E-Mail" type="text" formControlName="mail" autocomplete="new-password">
                <mat-error>{{companyFormGroup.get('mail').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>

            <div formGroupName="phone" fxFlex="100">

                <div fxLayout="row wrap"
                     fxLayoutGap="12px grid">

                    <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="60" floatLabel="always">
                        <mat-label>Telefonnummer (optional)</mat-label>
                        <input matInput data-test="FI-Telefonnummer" type="text" formControlName="number"
                               autocomplete="new-password">
                        <mat-error>{{companyFormGroup.get('phone').get('number').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-form-field [appearance]="appearance" fxFlex="100" fxFlex.gt-xs="40" floatLabel="always">
                        <mat-label>Typ (optional)</mat-label>
                        <mat-select data-test="FS-Typ" formControlName="type">
                            <mat-option [value]="'Festnetz'">Festnetz</mat-option>
                            <mat-option [value]="'Mobil'">Mobil</mat-option>
                            <mat-option [value]="'Fax'">Fax</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

            </div>

            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>Bemerkungen / Notizen (optional)</mat-label>
                <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                          autocomplete="new-password"></textarea>
            </mat-form-field>
        </ng-container>

        <div>
            <button data-test="B-Speichern" mat-flat-button
                    color="accent"
                    (click)="save()"
                    style="margin-left: auto"
                    class="save-button mat-accent mb-4"
                    [disabled]="isSaving"
                    aria-label="Speichern">
                <mat-icon>save</mat-icon>
                Organisation anlegen
            </button>
        </div>

    </ng-container>

</div>
