<ng-container *ngIf="formGroup">
    <div [ngStyle]="init ? {'visibility':'visible'} : {'visibility':'collapse'}">
        <form [formGroup]="formGroup" class="form p-16">
            <div fxLayout="row">
                <mat-slide-toggle formControlName="useDynamicPrice">Dynamischen Preis verwenden</mat-slide-toggle>
            </div>

            <ng-container
                    *ngIf="useDynamicPrice &&
                (requirements?.includes('boat') ||
                requirements?.includes('berth') ||
                requirements?.includes('tenantRelation')||
                requirements?.includes('season') ||
                requirements?.includes('winterStorage') ||
                requirements?.includes('pier'))">
                <div class="mt-24" fxLayout="column">

                    <ng-container *ngIf="requirements.includes('boat')">
                        <form-widget-boat [parentFormGroup]="formGroup"
                                          [appearance]="'outline'"
                                          [selectBoat]="boat"
                                          [givenBoats]="boats"
                                          [boatRequired]="true"
                                          [boatOwnerName]="dynamicPriceContext?.person?.toString() || dynamicPriceContext?.company?.toString()"
                                          [slimmedView]="true"
                                          [canCreate]="false"></form-widget-boat>
                    </ng-container>

                    <ng-container *ngIf="requirements.includes('berth')">
                        <form-widget-berth-only [parentFormGroup]="formGroup"
                                                [appearance]="'outline'"
                                                [selectBerth]="berth"
                                                [givenBerths]="berths"
                                                [berthRequired]="true"
                                                [personOrCompanyName]="dynamicPriceContext?.person?.toString() || dynamicPriceContext?.company?.toString()"
                                                [slimmedView]="true"></form-widget-berth-only>
                    </ng-container>

                    <ng-container *ngIf="requirements.includes('pier')">
                        <form-widget-pier [parentFormGroup]="formGroup"
                                          [appearance]="'outline'"
                                          [selectPier]="pier"
                                          [givenPiers]="piers"
                                          [pierRequired]="true"
                                          [slimmedView]="true"></form-widget-pier>
                    </ng-container>

                    <ng-container *ngIf="requirements.includes('tenantRelation')">
                        <form-widget-tenant-relation [parentFormGroup]="formGroup"
                                                     [appearance]="'outline'"
                                                     [selectTenantRelation]="tenantRelation"
                                                     [givenTenantRelations]="tenantRelations"
                                                     [tenantRelationRequired]="true"
                                                     [personOrCompanyName]="dynamicPriceContext?.person?.toString() || dynamicPriceContext?.company?.toString()"
                                                     [slimmedView]="true"></form-widget-tenant-relation>
                    </ng-container>

                    <ng-container *ngIf="requirements.includes('season')">
                        <form-widget-season (seasonChangedManually)="this.seasonChangedManually = true;"
                                            [parentFormGroup]="formGroup"
                                            [appearance]="'outline'"
                                            [selectSeason]="season"
                                            [seasonRequired]="true"
                                            [slimmedView]="true"></form-widget-season>
                    </ng-container>

                    <ng-container *ngIf="requirements.includes('winterStorage')">
                        <form-widget-winter-storage topListLabel="Aktueller Lagerplatz"
                                                    [parentFormGroup]="formGroup"
                                                    [appearance]="'outline'"
                                                    [selectWinterStorage]="winterStorage"
                                                    [givenWinterStorages]="winterStorages"
                                                    [winterStorageRequired]="true"
                                                    [slimmedView]="true"></form-widget-winter-storage>
                    </ng-container>

                </div>
            </ng-container>
        </form>

        <ng-container
                *ngIf="useDynamicPrice && dynamicPrice?.ruleEvaluations && dynamicPrice?.ruleEvaluations.evaluations?.length > 0">
            <div class="info-box p-16">

                <ng-container *ngIf="initIndexChanged || initTitleChanged || initPriceChanged">
                    <u2b-message type="warning">
                        <div class="mb-16">Der dynamische Preis dieser Position hat sich geändert.</div>
                        <ng-container *ngIf="initIndexChanged">
                            Alte Regel: <b>{{dynamicPriceOld.ruleIndex + 1}}. Regel</b>
                        </ng-container>
                        <ng-container *ngIf="initTitleChanged">
                            <div fxLayout="row">
                                <div style="width: 200px">Alte Bezeichnung der Regel:</div>
                                <div style="margin-left: auto; font-weight: bold; text-align: justify;">
                                    {{dynamicPriceOld.ruleName}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="initPriceChanged">
                            <div fxLayout="row">
                                <div style="width: 200px">Alter Preis der Regel:</div>
                                <div style="margin-left: auto; font-weight: bold;">
                                    {{dynamicPriceOld?.ruleEvaluations.price | bcmDynamicCurrency}}
                                </div>
                            </div>
                        </ng-container>
                    </u2b-message>
                </ng-container>

                <u2b-message type="info">
                    Der dynamische Preis wurde anhand der <b>{{dynamicPrice.ruleIndex + 1}}. Regel</b> gesetzt.
                    <div class="mt-16" fxLayout="row">
                        <div style="width: 200px">Bezeichnung der Regel:</div>
                        <div style="margin-left: auto; font-weight: bold; text-align: justify;">
                            {{dynamicPrice.ruleName}}
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width: 200px">Preis der Regel:</div>
                        <div style="margin-left: auto; font-weight: bold;">
                            {{dynamicPrice?.ruleEvaluations.price | bcmDynamicCurrency}}
                        </div>
                    </div>
                </u2b-message>
                <table class="mt-8">
                    <thead>
                    <td class="text-bold">Entität</td>
                    <td class="text-bold">Wert der Entität</td>
                    <td class="text-bold">Operator</td>
                    <td class="text-bold">Wert der Regel</td>
                    <td class="text-bold"></td>
                    </thead>
                    <tbody>
                    <tr *ngFor="let evaluation of dynamicPrice.ruleEvaluations.evaluations">
                        <td>{{evaluation.conditionEntity}}</td>
                        <td>{{evaluation.givenValue}}</td>
                        <td>{{evaluation.conditionOperatorText}}</td>
                        <td>{{evaluation.requiredValue}}</td>
                        <td>
                            <div style="display: flex">
                                <ng-container *ngIf="evaluation.isTrue; else notTrue">
                                    <mat-icon class="icon-check">
                                        check_circle
                                    </mat-icon>
                                </ng-container>
                                <ng-template #notTrue>
                                    <mat-icon class="icon-warning">
                                        cancel
                                    </mat-icon>
                                </ng-template>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </ng-container>

        <ng-container
                *ngIf="useDynamicPrice && dynamicPrice?.ruleCalculations && dynamicPrice?.ruleCalculations.calculations?.length > 0">
            <div class="info-box p-16">
                <u2b-message type="info">
                    Folgende Formel(n) wurden angewandt:<br>
                    <br>
                    <div *ngFor="let calculation of dynamicPrice.ruleCalculations.calculations">
                        <span>{{calculation.priceBeforeCalculation | bcmDynamicCurrency}} {{calculation.conditionOperatorSymbol}} {{calculation.givenValue | bcmDynamicCurrency: '1.2-2'}}
                            ({{calculation.conditionEntity}})</span>
                        <span style="float: right">= {{calculation.priceAferCalculation | bcmDynamicCurrency}}</span><br>
                    </div>
                    <br>
                    <div style="border-top: 1px solid black">Preis nach Berechnung: <span
                            style="float:right; font-weight: bold">{{dynamicPrice?.ruleCalculations.price | bcmDynamicCurrency}}</span>
                    </div>
                </u2b-message>
            </div>
        </ng-container>
    </div>
</ng-container>
