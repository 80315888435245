import { MapFavourite } from '@shared/models/map-interfaces';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { DEFAULT_BERTH_RESERVATION_UNIT } from '@modules/bcm/settings/default-units/default-units.component';

export enum FooterLayout {
    Row = 'row',
    Columns = 'columns',
    Editor = 'editor',
}

export enum TextAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}


export enum BcmSettingsSectionName {
    AssociationReports = 'association_reports',
    BerthsGraphically = 'berths-graphically',
    Dashboard = 'dashboard',
    Datev = 'datev',
    DefaultUnits = 'defaultUnits',
    DefaultBerthReservationTimeUnit = 'defaultBerthReservationTimeUnit',
    DockSite = 'scaleRegistration',
    ElectricMeter = 'electricMeter',
    Import = 'import',
    InvoiceTemplate = 'invoice_template',
    InvoiceDefaultTemplates = 'invoice_default_templates',
    MailServer = 'mail_server',
    MapEditor = 'mapEditor',
    PersonsOrganisations = 'personsOrganisations',
    Tenant = 'tenant',
    UISettings = 'uiSettings',
}

export interface BcmSettingsSection {
    [key: string]: any;
}

export interface BcmSettings {
    // Sort it a-z please
    [BcmSettingsSectionName.AssociationReports]: BcmAssociationReportsSettings;
    [BcmSettingsSectionName.BerthsGraphically]: BcmBerthGraphicallySettings;
    [BcmSettingsSectionName.Dashboard]: BcmSettingsSection;
    [BcmSettingsSectionName.Datev]: BcmDatevSettings;
    [BcmSettingsSectionName.DefaultUnits]: BcmDefaultUnitsSettings;
    [BcmSettingsSectionName.DefaultBerthReservationTimeUnit]: DefaultBerthReservationTimeUnit;
    [BcmSettingsSectionName.DockSite]: BcmDockSiteSettings;
    [BcmSettingsSectionName.ElectricMeter]: BcmElectricMeterSettings;
    [BcmSettingsSectionName.Import]: BcmImportSettings;
    [BcmSettingsSectionName.InvoiceTemplate]: BcmInvoiceTemplateSettings;
    [BcmSettingsSectionName.InvoiceDefaultTemplates]: BcmInvoiceDefaultTemplatesSettings;
    [BcmSettingsSectionName.MailServer]: BcmMailServerSettings;
    [BcmSettingsSectionName.MapEditor]: BcmMapEditorSettings;
    [BcmSettingsSectionName.PersonsOrganisations]: BcmPersonsOrganisationsSettings;
    [BcmSettingsSectionName.Tenant]: BcmTenantSettings;
}

export interface BcmDatevSettings extends BcmSettingsSection {
    consultantNumber: string; // Beraternummer
    clientNumber: string; // Mandantennummer
}

export interface BcmMailServerSettings extends BcmSettingsSection {
    smtpUrl: string;
    smtpSSLPort: number;
    imapUrl: string;
    imapSSLPort: number;
    name: string;
    mail: string;
    user?: string;
    password?: string;
}

export interface BcmElectricMeterSettings extends BcmSettingsSection {
    productID: number;
}

export interface BcmInvoiceTemplateSettingsFooter {
    headline?: string;
    text: string;
    align: TextAlign;
}

export interface BcmInvoiceTemplateSettings extends BcmSettingsSection {
    header?: string;
    showAdditionalDataUnderLogo?: boolean;
    additionalDataUnderLogo?: string;
    additionalDataUnderLogoAlign?: TextAlign;
    showBankDataInHeader?: boolean;
    showTenantRelationInHeader?: boolean;
    showAccount?: boolean;
    showItemNumber?: boolean;
    footerLayout?: FooterLayout;
    footer?: string;
    footerAlign?: TextAlign;
    footers?: BcmInvoiceTemplateSettingsFooter[];
    defaultNote?: string;
    defaultPaymentType?: string;
    dueDateDefaultEmpty?: boolean;
    dueDateDefaultDays?: number;
    dueDateNoteInvoice?: string;
    dueDateNoteDirectDebit?: string;
    certificateOfAchievementNote?: string;
    logoId?: number;
    showSinglePriceInNet?: boolean;
    roundPricesTo5SwissCentimes?: boolean;
    showSepaQrCode?: boolean;
}

export interface BcmInvoiceDefaultTemplatesSettings extends BcmSettingsSection {
    [type: string]: BcmInvoiceWdTemplate;

    // invoiceTemplate: BcmInvoiceWdTemplate,
    // cancellationInvoiceTemplate: BcmInvoiceWdTemplate,
    // creditingTemplate: BcmInvoiceWdTemplate,
    // cancellationCreditingTemplate: BcmInvoiceWdTemplate,
    // receiptTemplate: BcmInvoiceWdTemplate,
    // cancellationReceiptTemplate: BcmInvoiceWdTemplate
}

export interface BcmAssociationReportsSettings extends BcmSettingsSection {

    form: string;
    nonProfit: boolean;
    nonProfitDate: Date;

    chairman1_name?: string;
    chairman1_mail?: string;
    chairman1_phone?: string;
    chairman1_postcode?: string;
    chairman1_city?: string;

    chairman2_name?: string;
    chairman2_mail?: string;
    chairman2_phone?: string;
    chairman2_postcode?: string;
    chairman2_city?: string;

    treasurer_name?: string;
    treasurer_mail?: string;
    treasurer_phone?: string;
    treasurer_postcode?: string;
    treasurer_city?: string;

    secretary_name?: string;
    secretary_mail?: string;
    secretary_phone?: string;
    secretary_postcode?: string;
    secretary_city?: string;

    youth_leader_name?: string;
    youth_leader_mail?: string;
    youth_leader_phone?: string;
    youth_leader_postcode?: string;
    youth_leader_city?: string;

    tenantRelationsHonor: number[];
    tenantRelationsActive: number[];
    tenantRelationsInactive: number[];
    tenantRelationsPaying: number[];
    tenantRelationsGuest: number[];
}

export interface BcmPersonsOrganisationsSettings extends BcmSettingsSection {
    automaticIdentNumber: boolean;
    sharedIdentNumber: boolean;

    sharedIdentNumberCurrent: number;
    sharedIdentNumberTemplatePrefix: string;
    sharedIdentNumberTemplateSuffix: string;

    personsIdentNumberCurrent: number;
    personsIdentNumberTemplatePrefix: string;
    personsIdentNumberTemplateSuffix: string;

    companiesIdentNumberCurrent: number;
    companiesIdentNumberTemplatePrefix: string;
    companiesIdentNumberTemplateSuffix: string;
}

export interface BcmDockSiteSettings extends BcmSettingsSection {
    ipV4: string;
    port: number;
    isBlockedBy: string;
    productIdsWeighting: number[];
    goodwillWeightThreshold: number;
    productIdsSlipping: number[];
    ipV4Printer: string;
    portPrinter: number;
}

export interface BcmDefaultUnitsSettings extends BcmSettingsSection {
    // Tagesgastlieger
    dayGuestBoater: number;
}

export interface DefaultBerthReservationTimeUnit extends BcmSettingsSection {
    type: DEFAULT_BERTH_RESERVATION_UNIT;
    fromMinutes: number;
    fromHours: number;
    toMinutes: number;
    toHours: number;
}

export interface BcmMapEditorSettings extends BcmSettingsSection {
    isBlockedBy: string;
    isBlockedById: string;
    isBlockedSince: string | Date;
    favourites: MapFavourite[];
}

export interface BcmTenantSettings extends BcmSettingsSection {
    readonly startOfSubscriptionScheduler: string | Date;
}

export interface BcmBerthGraphicallySettings extends BcmSettingsSection {
    Items: any[]; // add type..
}

export interface BcmImportSettings extends BcmSettingsSection {
    berth: Date;
    winterStorage: Date;
    product: Date;
    productRelation: Date;
    person: Date;
    company: Date;
    electricMeter: Date;
    electricMeterCabinet: Date;
    boat: Date;
}
