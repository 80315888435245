import { Injectable } from '@angular/core';
import { TseClosingApiService } from '@bcmApiServices/tse/tse-closing.api-service';
import { TseManagementApiService } from '@bcmApiServices/tse/tse-management.api-service';
import { TseTransactionsApiService } from '@bcmApiServices/tse/tse-transactions.api-service';
import { BcmService } from '@modules/bcm/bcm.service';
import { Tenant } from '@shared/models/tenant';
import { TseFiscalClient } from '@shared/models/tse/TseFiscalClient';
import { concatMap, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TsePayment } from '@shared/models/tse/TsePayment';
import { UserService } from '@core/services/user.service';
import { TseDocumentType } from '@shared/models/tse/TseDocumentType';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { TsePaymentType } from '@shared/models/tse/TsePaymentType';
import { TseDocumentPositionType } from '@shared/models/tse/TseDocumentPositionType';
import { TseBusinessTransactionType } from '@shared/models/tse/TseBusinessTransactionType';
import { v4 as uuidv4 } from 'uuid';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { BcmCashRegistersApiService } from '@bcmApiServices/bcm-cash-registers-api.service';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';
import { StaticGlobals } from '@modules/bcm/@shared/static-globals';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmPaymentTypeFromString, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { TseInformationApiService } from '@bcmApiServices/tse/tse-information.api-service';
import { TseDocumentPositionTotal } from '@shared/models/tse/TseDocumentPositionTotal';
import { BcmFinancialRecordPdfData } from '@shared/models/bcm-financial-record';
import { TseReferenceType } from '@shared/models/tse/TseReferenceType';
import { ReceiptTypes } from '@shared/invoice-types';
import * as countries from 'i18n-iso-countries';
import { TsePartnerIdentificationType } from '@shared/models/tse/TsePartnerIdentificationType';
import { TsePartnerType } from '@shared/models/tse/TsePartnerType';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { getTaxRateValue } from '@core/functions/get-tax-rate-value';
import { roundCurrency, roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { Invoice } from '@shared/models/invoice';
import { TseAutomaticVatCalculation } from '@shared/models/tse/TseAutomaticVatCalculation';

@Injectable({
    providedIn: 'root'
})
export class CashRegisterService {

    private _tenant: Tenant;
    private readonly _userId: string;
    private readonly _userFullName: string;
    private readonly _userFirstName: string;
    private readonly _userLastName: string;

    constructor(
        private _bcmService: BcmService,
        private _userService: UserService,
        private _managementApi: TseManagementApiService,
        private _transactionsApi: TseTransactionsApiService,
        private _informationApi: TseInformationApiService,
        private _closingApi: TseClosingApiService,
        private _cashRegisterApi: BcmCashRegistersApiService
    ) {
        this._tenant = _bcmService.tenant;
        this._userId = this._userService.user.emailHash;
        this._userFullName = this._userService.userFullName;
        this._userFirstName = this._userService.userFirstName;
        this._userLastName = this._userService.userLastName;

        countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
    }

    getFiscalClient(clientId: string): Observable<TseFiscalClient> {
        return this._managementApi.getClient(clientId);
    }

    createClientByCloud(storeNumber: string, terminalNumber: string): Observable<string> {
        return this._managementApi.createClientByCloud(storeNumber, terminalNumber);
    }

    getClientIdByStoreAndTerminalNumber(storeNumber: string, terminalNumber: string): Observable<string> {
        return this._managementApi.getClientIdByStoreAndTerminalNumber(storeNumber, terminalNumber);
    }

    initializeCashRegister(clientId: string): Observable<TseFiscalResponse> {
        return this._managementApi.connectCloud(clientId)
            .pipe(
                switchMap(() => this._transactionsApi.getStartDocument(clientId)),
                switchMap((startDocument: TseDocument) => this._managementApi.initializeClient(startDocument))
            );
    }

    openCashRegister(cashRegister: BcmCashRegister, amount: number): Observable<TseFiscalResponse> {
        const payment: TsePayment = {
            caption: 'OPENING',
            amount,
            paymentType: TsePaymentType.CASH,
            currencyIsoCode: 'EUR',
            uniqueReadablePaymentIdentifier: `OPENING-${new Date().getTime()}-${cashRegister.clientId}`,
        };

        return this._cashRegisterApi.openCashRegister(cashRegister)
            .pipe(switchMap(() => this._closingApi.bookOpeningStock(cashRegister.clientId, this._userId, this._userFullName, [payment])));
    }

    closeCashRegister(cashRegister: BcmCashRegister, amount: number,
                      differenceSum: number = 0, differenceReason: string | null = null,
                      cashAdd: number, cashLift: number,
                      closeMonth: boolean = false): Observable<BcmCashRegister> {

        let observable$: Observable<any> = this._cashRegisterApi.getUserRights(cashRegister);

        differenceSum = Math.round(differenceSum * 100) / 100;
        cashAdd = Math.round(cashAdd * 100) / 100;
        cashLift = Math.round(cashLift * 100) / 100;

        if (differenceSum !== 0) {
            observable$ = observable$
                .pipe(
                    switchMap(() => this.bookCashDifference(cashRegister.clientId, differenceSum, differenceReason)),
                    // switchMap(() => this.bookCashLift(cashRegister.clientId, amount))
                );
        } /*else {
            observable = this._cashRegisterApi.getUserRights(cashRegister)
                .pipe(switchMap(() => this.bookCashLift(cashRegister.clientId, amount)));
        }*/

        if (cashAdd > 0) {

            const payment: TsePayment = {
                caption: 'ADD_MONEY',
                amount: cashAdd,
                paymentType: TsePaymentType.CASH,
                currencyIsoCode: 'EUR',
                uniqueReadablePaymentIdentifier: `ADD_MONEY-${new Date().getTime()}-${cashRegister.clientId}`,
            };

            observable$ = observable$
                .pipe(
                    switchMap(() => this._closingApi.bookOpeningStock(cashRegister.clientId, this._userId, this._userFullName, [payment])),
                );
        }

        if (cashLift > 0) {
            observable$ = observable$
                .pipe(
                    switchMap(() => this.bookCashLift(cashRegister.clientId, cashLift))
                );
        }

        return observable$.pipe(
            switchMap(() => this._closingApi.getEndOfDayDocument(cashRegister.clientId, closeMonth)
                .pipe(map((endOfDayDocument: TseDocument) => {
                    return {
                        ...endOfDayDocument,
                        documentId: endOfDayDocument.documentNumber,
                        user: {
                            id: this._userId,
                            caption: this._userFullName,
                            firstName: this._userFirstName,
                            lastName: this._userLastName
                        }
                    } as TseDocument;
                }))),
            switchMap((endOfDayDocument: TseDocument) => this._transactionsApi.storeDocument(endOfDayDocument)
                .pipe(map(() => endOfDayDocument))),
            switchMap((endOfDayDocument: TseDocument) => this._cashRegisterApi.closeCashRegister(cashRegister, amount, endOfDayDocument))
        );
    }

    bookCashDifference(clientId: string, amount: number, reason: string | null): Observable<TseFiscalResponse> {
        const payment: TsePayment = {
            caption: 'DIFFERENCE',
            amount: roundNumber(amount, RoundNumberFactor.TwoDecimals),
            paymentType: TsePaymentType.CASH,
            currencyIsoCode: 'EUR',
            uniqueReadablePaymentIdentifier: `DIFFERENCE-${new Date().getTime()}-${clientId}`,
        };

        return this._closingApi.bookCashDifference(clientId, this._userId, this._userFullName, reason, [payment]);
    }

    bookCashLift(clientId: string, amount: number): Observable<TseFiscalResponse> {
        const payment: TsePayment = {
            caption: 'LIFT',
            amount: roundNumber(amount * -1, RoundNumberFactor.TwoDecimals),
            paymentType: TsePaymentType.CASH,
            currencyIsoCode: 'EUR',
            uniqueReadablePaymentIdentifier: `LIFT-${new Date().getTime()}-${clientId}`,
        };

        return this._closingApi.bookCashLift(clientId, this._userId, this._userFullName, false, [payment]);
    }

    getActualStock(clientId: string): Observable<TsePayment[]> {
        return this._closingApi.getActualStock(clientId);
    }

    getLoggedInCashRegisterForUser(): Observable<BcmCashRegister> {
        return this._cashRegisterApi.getLoggedInCashRegisterForUser();
    }

    addCancellationReceipt(
        cashRegister: BcmCashRegister,
        receiptToCancel: BcmReceipt,
        paymentType: TsePaymentType,
        address: string,
        person?: Person,
        company?: Company,
        wordTemplate?: BcmInvoiceWdTemplate,
        addPositionsBackToCoaster?: boolean): Observable<BcmReceipt> {
        const positions = receiptToCancel.positions.map(p => ({...p, price: p.price * (-1)} as InvoicePosition));
        return this.addReceipt(
            cashRegister,
            positions,
            paymentType,
            address,
            person,
            company,
            wordTemplate,
            `STORNO für Beleg ${receiptToCancel.receiptNumber}`,
            receiptToCancel,
            addPositionsBackToCoaster);
    }


    addInvoicePayIn(cashRegister: BcmCashRegister,
                    invoice: Invoice,
                    paymentType: TsePaymentType,
                    amount: number): Observable<BcmReceipt> {

        const date = new Date();

        const person = invoice.person;
        const company = invoice.company;

        const _doc: TseDocument = {
            documentGuid: uuidv4(),
            documentId: 'receiptIdentifier',
            documentNumber: 'receiptIdentifier',
            automaticVatCalculation: TseAutomaticVatCalculation.NO_CALCULATION,
            fiscalDocumentNumber: 1,
            FiscalDocumentStartTime: date.getTime(),
            fiscalDocumentRevision: 1,
            isTraining: false,
            positionCount: 1,
            uniqueClientId: cashRegister.clientId,
            createDate: date.toUTCString(),
            bookDate: date.toUTCString(),
            documentType: TseDocumentType.PAY_IN,
            user: {
                id: this._userId,
                caption: this._userFullName,
                firstName: this._userFirstName,
                lastName: this._userLastName

            },
            partner: (Math.abs(amount) >= 250 && (person?.id || company?.id)) ?
                {
                    id: `${person?.id || company?.id}-${person?.fullName || company?.fullName}`,
                    caption: person?.fullName || company?.fullName,
                    street: person?.streetWithoutNumber || company?.streetWithoutNumber,
                    streetNumber: person?.streetNumber || company?.streetNumber,
                    city: person?.city || company?.city,
                    postalCode: person?.postCode || company?.postCode,
                    countryCode: countries.getAlpha3Code(person?.country?.de || company?.country?.de || 'Deutschland', 'de'),
                    identificationType: TsePartnerIdentificationType.OTHER,
                    partnerType: TsePartnerType.CUSTOMER,
                    partnerClassification: 'Customer'
                } :
                null,
            positions: [
                {
                    caption: `Einzahlung ${invoice.invoiceNumber}`,
                    itemCaption: `Einzahlung ${invoice.invoiceNumber}`,
                    quantity: 1,
                    grossValue: amount,
                    baseGrossValue: amount,
                    businessTransactionType: TseBusinessTransactionType.PAY_IN,
                    itemId: 'UUID_' + uuidv4(),
                    netValue: amount,
                    taxValue: 0,
                    vatIdentification: 5,
                    type: TseDocumentPositionType.BOOKING
                }
            ],
            payments: [
                {
                    amount: amount,
                    currencyIsoCode: 'EUR',
                    caption: 'PAY_IN',
                    uniqueReadablePaymentIdentifier: `PAY_IN-${invoice.invoiceNumber}-${new Date().getTime()}-${cashRegister.clientId}`,
                    foreignAmount: 0.0,
                    foreignAmountExchangeRate: 0.0,
                    paymentType
                }
            ]
        };

        return this._transactionsApi.createDocument(cashRegister.clientId, TseDocumentType.PAY_IN)
            .pipe(
                switchMap(createdDocument => {
                    return this._cashRegisterApi.getReceiptNumber(cashRegister)
                        .pipe(map(receiptNumber => {
                            return {
                                receiptNumber,
                                document: {
                                    ..._doc,
                                    fiscalDocumentNumber: createdDocument.fiscalisationDocumentNumber,
                                    FiscalDocumentStartTime: createdDocument.fiscalDocumentStartTime,
                                    documentId: String(receiptNumber),
                                    documentNumber: String(receiptNumber),
                                } as TseDocument
                            };
                        }));
                }),
                switchMap(({document, receiptNumber}) => {
                    return this._transactionsApi.validateDocument(document, false) // TODO only for testing .. remove in production
                        .pipe(
                            switchMap((validationErrors: { errorText: string }[]) => {
                                {
                                    if (validationErrors.length > 0) {
                                        return throwError(() => validationErrors.map(error => error.errorText));
                                    } else {
                                        return of({document, receiptNumber});
                                    }
                                }
                            })
                        );
                }),
                switchMap(({document, receiptNumber}) => {
                    return this._transactionsApi.storeDocument(document)
                        .pipe(
                            map(() => (receiptNumber)),
                            catchError((error) => {
                                return this._cashRegisterApi
                                    .cancelReceiptCreation(cashRegister, receiptNumber, {
                                        error,
                                        person,
                                        company
                                    })
                                    .pipe(switchMap(() => throwError(() => 'Beleg konnte nicht erstellt werden.')));
                            })
                        );
                }),
                switchMap(receiptNumber => {
                    return this._transactionsApi.getDocument(cashRegister.clientId, String(receiptNumber))
                        .pipe(map(document => ({document, receiptNumber})));
                }),
                switchMap(({document, receiptNumber}) => {
                    return this._closingApi.getEndOfDayDocument(cashRegister.clientId)
                        .pipe(map((eodDocument: TseDocument) => {
                            const position = eodDocument.positions.find((p: TseDocumentPositionTotal) => p.caption === 'Closing balance') as TseDocumentPositionTotal;
                            const saldo = roundCurrency(position?.value);
                            return {document, receiptNumber, saldo};
                        }));
                }),
                switchMap(({document, receiptNumber, saldo}) => {

                    const receiptDto: BcmReceiptDto = {
                        receiptNumber,
                        receiptType: ReceiptTypes.PayIn,
                        paymentType: BcmPaymentTypeFromString[paymentType],
                        documentContent: JSON.stringify(document),
                        positions: [],
                        bcm_persons_id: person?.id,
                        bcm_companies_id: company?.id
                    };

                    const address = (person || company)?.getAddressString('\n', true) || '';

                    const pdfData: BcmFinancialRecordPdfData = {
                        address: address,
                        bcmSettings: null,
                        date: null,
                        debtorNumber: person?.identNumber || company?.identNumber,
                        iban: person?.IBAN || company?.IBAN,
                        invoiceLogo: null,
                        invoiceNumber: receiptNumber,
                        paymentType: BcmPaymentTypeFromString[paymentType],
                        positions: [
                            // @ts-ignore
                            {
                                title:  `Einzahlung ${invoice.invoiceNumber}`,
                                price: amount,
                                invoice: invoice,
                                quantity: 1,
                            }
                        ],
                        priceData: {
                            totalTaxes: [],
                            totalTax: 0,
                            totalGrossPrice: amount,
                            totalNetPrice: amount,
                            totalTaxFreeProductsPrice: amount
                        },
                        tenant: null,
                        tenantRelation: null,
                    };

                    return this._cashRegisterApi.addReceipt(cashRegister, receiptDto, person, company, pdfData, saldo, amount);

                })
            );

    }


    addReceipt(cashRegister: BcmCashRegister,
               positions: InvoicePosition[],
               paymentType: TsePaymentType,
               address: string,
               person?: Person,
               company?: Company,
               wordTemplate?: BcmInvoiceWdTemplate,
               note?: string,
               receiptToCancel?: BcmReceipt,
               addPositionsBackToCoaster?: boolean): Observable<BcmReceipt> {

        if (!positions?.length || positions.length < 1) {
            return throwError(() => [`Es wurden keine Positionen angegeben.`]);
        }

        const taxValues = positions.map(position => getTaxRateValue(position));
        const uniqueTaxValues = Array.from(new Set(taxValues));

        const taxValues$: Observable<number>[] = uniqueTaxValues.map(taxValue => {
            return this._informationApi.getVatIdentification(cashRegister.clientId, taxValue);
        });

        return from(taxValues$)
            .pipe(
                concatMap(taxValue => taxValue),
                switchMap(() => {

                    return forkJoin(positions.map(position => {
                        const taxRateValue = getTaxRateValue(position);
                        return this._informationApi.getVatIdentification(cashRegister.clientId, taxRateValue)
                            .pipe(
                                switchMap(tseVatIdentificationId => {
                                    if (tseVatIdentificationId === null || !tseVatIdentificationId || tseVatIdentificationId === 0) {
                                        return throwError(() => [`Der Steuersatz ${taxRateValue} ist nicht gültig.`]);
                                    }
                                    return of(tseVatIdentificationId);
                                }),
                                map(tseVatIdentificationId => {
                                    return {
                                        ...position,
                                        tseVatIdentificationId
                                    } as InvoicePosition;
                                }));
                    })).pipe(
                        switchMap((positionsWithVatId: InvoicePosition[]) => {
                            const totalAmount = this.getTotal(positions);

                            if (Math.abs(totalAmount) >= 250) {
                                const errors: string[] = [];

                                errors.push('Bei Barzahlungen über 250 € wird die Anschrift des Empfängers benötigt.');

                                if (!person?.id && !company?.id) {
                                    errors.push('Der Zahlung ist kein gültiger Empfänger hinterlegt.');
                                } else {

                                    if (!person?.country && !company?.country) {
                                        errors.push(`Dem Empfänger ist kein Land hinterlegt`);
                                    }
                                    if (!person?.city && !company?.city) {
                                        errors.push(`Dem Empfänger ist kein Wohnort hinterlegt`);
                                    }
                                    if (!person?.postCode && !company?.postCode) {
                                        errors.push(`Dem Empfänger ist keine Postleitzahl hinterlegt`);
                                    }
                                    if (!person?.streetWithoutNumber && !company?.streetWithoutNumber) {
                                        errors.push(`Dem Empfänger ist keine Straße hinterlegt`);
                                    }
                                    if (!person?.streetNumber && !company?.streetNumber) {
                                        errors.push(`Dem Empfänger ist keine Hausnummer hinterlegt`);
                                    }

                                }

                                if (errors.length > 1) {
                                    errors.push(`Bitte ergänze diese Daten in den Stammdaten der Person / Organisation.`);
                                    return throwError(() => errors);
                                }
                            }


                            const date = new Date();
                            const document: TseDocument = {
                                cancellationDocument: !!(receiptToCancel?.id),
                                documentReference: (receiptToCancel?.id) ? {
                                    documentType: TseDocumentType.RECEIPT,
                                    documentBookDate: receiptToCancel.tseDocument.bookDate,
                                    documentId: receiptToCancel.tseDocument.documentId,
                                    documentGuid: receiptToCancel.tseDocument.documentGuid,
                                    referenceType: TseReferenceType.CANCELLATION,
                                    documentNumber: receiptToCancel.tseDocument.documentNumber,
                                    fiscalDocumentNumber: receiptToCancel.tseDocument.fiscalDocumentNumber
                                } : null,
                                documentGuid: uuidv4(),
                                documentId: 'receiptIdentifier',
                                documentNumber: 'receiptIdentifier',
                                fiscalDocumentNumber: 1,
                                FiscalDocumentStartTime: date.getTime(),
                                fiscalDocumentRevision: 1,
                                isTraining: false,
                                positionCount: positions.length,
                                uniqueClientId: cashRegister.clientId,
                                createDate: date.toUTCString(),
                                bookDate: date.toUTCString(),
                                documentType: TseDocumentType.RECEIPT,
                                user: {
                                    id: this._userId,
                                    caption: this._userFullName,
                                    firstName: this._userFirstName,
                                    lastName: this._userLastName

                                },
                                partner: (Math.abs(totalAmount) >= 250 && (person?.id || company?.id)) ?
                                    {
                                        id: `${person?.id || company?.id}-${person?.fullName || company?.fullName}`,
                                        caption: person?.fullName || company?.fullName,
                                        street: person?.streetWithoutNumber || company?.streetWithoutNumber,
                                        streetNumber: person?.streetNumber || company?.streetNumber,
                                        city: person?.city || company?.city,
                                        postalCode: person?.postCode || company?.postCode,
                                        countryCode: countries.getAlpha3Code(person?.country?.de || company?.country?.de || 'Deutschland', 'de'),
                                        identificationType: TsePartnerIdentificationType.OTHER,
                                        partnerType: TsePartnerType.CUSTOMER,
                                        partnerClassification: 'Customer'
                                    } :
                                    null,
                                positions: positionsWithVatId.map((position, index) => {
                                    const taxRateValue = getTaxRateValue(position);
                                    const additionalFields: Record<string, string> = {};

                                    // RF container throws 500 error, if some additionalFiled values are
                                    // null or undefined or anything else but string.
                                    if (position.account) {
                                        additionalFields.account = String(position.account);
                                    }

                                    if (position.unit?.name) {
                                        additionalFields.unit = String(position.unit.name);
                                    }

                                    if (position.product?.name || position.title) {
                                        additionalFields.productName = String(position.product?.name || position.title);
                                    }

                                    return {
                                        itemCaption: position.title,
                                        itemShortCaption: null,
                                        discounts: [],
                                        type: TseDocumentPositionType.ITEM,
                                        useSubItemVatCalculation: true,
                                        subItems: null,
                                        inHouse: true,
                                        quantity: position.quantity,
                                        quantityUnit: null,
                                        itemId: position.itemNumber ||
                                            (position.product?.id ? (position.product?.itemNumber || 'ID_' + String(position.product.id)) : 'UUID_' + uuidv4()),
                                        baseNetValue: this._getNetValue(position),
                                        baseGrossValue: this._getGrossValue(position),
                                        baseTaxValue: this._getTaxValue(position),
                                        businessTransactionType: TseBusinessTransactionType.REVENUE,
                                        vatIdentification: position.tseVatIdentificationId,
                                        vatPercent: taxRateValue,
                                        netValue: this._getNetValue(position),
                                        grossValue: this._getGrossValue(position),
                                        taxValue: this._getTaxValue(position),
                                        accountingIdentifier: null,
                                        positionNumber: index,
                                        positionReference: null,
                                        cancellationPosition: false,
                                        additionalFields
                                    };
                                }),
                                payments: [
                                    {
                                        amount: totalAmount,
                                        currencyIsoCode: 'EUR',
                                        caption: 'PAYMENT',
                                        uniqueReadablePaymentIdentifier: `RECEIPT-${new Date().getTime()}-${cashRegister.clientId}`,
                                        foreignAmount: 0.0,
                                        foreignAmountExchangeRate: 0.0,
                                        paymentType
                                    }
                                ]
                            };

                            return this._transactionsApi.validateDocument(document, false) // TODO only for testing .. remove in production
                                .pipe(
                                    switchMap((validationErrors: { errorText: string }[]) => {
                                        {
                                            if (validationErrors.length > 0) {
                                                return throwError(() => validationErrors.map(error => error.errorText));
                                            } else {
                                                return of(document);
                                            }
                                        }
                                    })
                                );
                        }),
                        switchMap(document => {
                            return this._transactionsApi.createDocument(cashRegister.clientId, TseDocumentType.RECEIPT)
                                .pipe(map(createdDocument => ({createdDocument, document})));
                        }),
                        switchMap(({createdDocument, document}) => {
                            return this._cashRegisterApi.getReceiptNumber(cashRegister)
                                .pipe(map(receiptNumber => {
                                    return {
                                        receiptNumber,
                                        document: {
                                            ...document,
                                            fiscalDocumentNumber: createdDocument.fiscalisationDocumentNumber,
                                            FiscalDocumentStartTime: createdDocument.fiscalDocumentStartTime,
                                            documentId: String(receiptNumber),
                                            documentNumber: String(receiptNumber),
                                        }
                                    };
                                }));
                        }),
                        switchMap(({document, receiptNumber}) => {
                            return this._transactionsApi.validateDocument(document, false) // TODO only for testing .. remove in production
                                .pipe(
                                    switchMap((validationErrors: { errorText: string }[]) => {
                                        {
                                            if (validationErrors.length > 0) {
                                                return throwError(() => validationErrors.map(error => error.errorText));
                                            } else {
                                                return of({document, receiptNumber});
                                            }
                                        }
                                    })
                                );
                        }),
                        switchMap(({document, receiptNumber}) => {
                            return this._transactionsApi.storeDocument(document)
                                .pipe(
                                    map(() => (receiptNumber)),
                                    catchError((error) => {
                                        return this._cashRegisterApi
                                            .cancelReceiptCreation(cashRegister, receiptNumber, {
                                                error,
                                                person,
                                                company
                                            })
                                            .pipe(switchMap(() => throwError(() => 'Beleg konnte nicht erstellt werden.')));
                                    })
                                );
                        }),
                        switchMap(receiptNumber => {
                            return this._transactionsApi.getDocument(cashRegister.clientId, String(receiptNumber))
                                .pipe(map(document => ({document, receiptNumber})));
                        }),
                        switchMap(({document, receiptNumber}) => {
                            return this._closingApi.getEndOfDayDocument(cashRegister.clientId)
                                .pipe(map((eodDocument: TseDocument) => {
                                    const position = eodDocument.positions.find((p: TseDocumentPositionTotal) => p.caption === 'Closing balance') as TseDocumentPositionTotal;
                                    const saldo = roundCurrency(position?.value);
                                    return {document, receiptNumber, saldo};
                                }));
                        }),
                        switchMap(({document, receiptNumber, saldo}) => {

                            const receiptDto: BcmReceiptDto = {
                                receiptNumber,
                                receiptType: (receiptToCancel?.id) ? ReceiptTypes.CancellationReceipt : ReceiptTypes.Receipt,
                                paymentType: BcmPaymentTypeFromString[paymentType],
                                documentContent: JSON.stringify(document),
                                positions: positions,
                                bcm_persons_id: person?.id,
                                bcm_companies_id: company?.id
                            };

                            const totalTaxes: { [key: number]: number } = {};
                            let totalTax = 0;

                            for (const position of positions.filter(p => p.taxRateValue > 0 || p.taxRate.value > 0)) {
                                const taxRateValue = getTaxRateValue(position);
                                const taxValue = roundCurrency(taxRateValue);
                                const totalTaxesForValue = totalTaxes[taxValue] || 0;
                                const positionTaxValue = this._getTaxValue(position);
                                totalTaxes[taxValue] = roundCurrency(totalTaxesForValue + positionTaxValue);
                                totalTax += positionTaxValue;
                            }

                            totalTax = roundCurrency(totalTax);

                            const pdfData: BcmFinancialRecordPdfData = {
                                wordTemplate,
                                address: address,
                                bcmSettings: null,
                                date: null,
                                debtorNumber: person?.identNumber || company?.identNumber,
                                iban: person?.IBAN || company?.IBAN,
                                invoiceLogo: null,
                                invoiceNumber: receiptNumber,
                                paymentType: BcmPaymentTypeFromString[paymentType],
                                positions: positions,
                                priceData: {
                                    totalTaxes,
                                    totalTax,
                                    totalGrossPrice: roundCurrency(
                                        positions
                                            .map(p => this._getGrossValue(p))
                                            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)),
                                    totalNetPrice: roundCurrency(positions
                                        .map(p => this._getNetValue(p))
                                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)),
                                    totalTaxFreeProductsPrice: roundCurrency(positions
                                        .filter(p => p.taxRateValue === 0 || p.taxRate.value === 0)
                                        .map(p => p.price)
                                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0))
                                },
                                tenant: null,
                                tenantRelation: null,
                                note
                            };

                            return this._cashRegisterApi.addReceipt(cashRegister, receiptDto, person, company, pdfData, saldo, null, receiptToCancel, addPositionsBackToCoaster);

                        })
                    );

                }));

    }

    incrementReceiptReprintCounter(cashRegister: BcmCashRegister, receipt: BcmReceipt): Observable<BcmReceipt> {
        const diff = Math.abs((receipt.insertedOn || new Date()).getTime() - new Date().getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        const daysBack = diffDays > 0 ? diffDays : -1;

        const user = {
            id: this._userId,
            caption: this._userFullName,
            firstName: this._userFirstName,
            lastName: this._userLastName
        };

        return this._cashRegisterApi.incrementReprintCounter(cashRegister, receipt.id);

        // tse api call not working at the moment
        // return this._transactionsApi.incrementReprintCount(cashRegister.clientId, receipt.tseDocument.documentGuid, user, daysBack)
        //     .pipe(switchMap(() => this._cashRegisterApi.incrementReprintCounter(cashRegister, receipt.id)));
    }

    private _getGrossValue(position: InvoicePosition): number {
        if (!StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity);
        } else {
            return this._getNetValue(position) + this._getTaxValue(position);
        }
    }

    private _getNetValue(position: InvoicePosition): number {
        const taxRateValue = getTaxRateValue(position);
        if (StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity);
        } else {
            return roundCurrency(position.price * position.quantity / ((taxRateValue / 100) + 1));
        }
    }

    private _getTaxValue(position: InvoicePosition): number {
        const taxRateValue = getTaxRateValue(position);
        if (StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity * (taxRateValue / 100));
        } else {
            return roundCurrency((position.price * position.quantity) - this._getNetValue(position));
        }
    }

    public getTotal(positions: InvoicePosition[]): number {
        const total = positions.map(p => this._getGrossValue(p)).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return roundCurrency(total);
    }

}
