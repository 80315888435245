import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TseApiService } from '@bcmApiServices/tse/tse-api.service';
import { TseDocumentType } from '@shared/models/tse/TseDocumentType';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';
import { HttpParams } from '@angular/common/http';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { TseDocumentValidationError } from '@shared/models/tse/TseDocumentValidationError';
import { TseUser } from '@shared/models/tse/TseUser';

@Injectable({
    providedIn: 'root'
})
export class TseTransactionsApiService extends TseApiService {

    private RESOURCE_ENDPOINT = 'transactions';

    /**
     * Returns a start document for the specified client.
     *
     * The start document is used to initialize the client and the fiscal unit (/api/v1/management/clients/initialize).
     *
     * @param {string} clientId - The identifier of the client for which the start document is to be returned.
     * @param {string} [bookDate] - The booking date for the start document (optional).
     * @returns {Observable<TseDocument>} An observable containing the start document.
     */
    getStartDocument(clientId: string, bookDate?: string): Observable<TseDocument> {
        const params = new HttpParams()
            .set('bookDate', bookDate)
            .set('clientId', clientId);

        return super.get<TseDocument>(`${this.RESOURCE_ENDPOINT}/document/${clientId}/start`, params);
    }

    /**
     * Creates a document in the fiscal unit.
     *
     * This method does not create the document itself, but only starts the process (e.g. on the TSE).
     *
     * @param {string} clientId - The identifier of the client for which the document is to be created.
     * @param {TseDocumentType} documentType - The type of document to be created.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    createDocument(clientId: string, documentType: TseDocumentType): Observable<TseFiscalResponse> {
        const params = new HttpParams()
            .set('clientId', clientId)
            .set('uniqueClientId', clientId)
            .set('documentType', documentType);

        return super.put<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/createDocument`, null, params);
    }

    /**
     * Stores a document in the fiscal unit.
     *
     * @param {TseDocument} document - The document to be stored in the fiscal unit.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    storeDocument(document: TseDocument): Observable<TseFiscalResponse> {
        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/storeDocument`, document);
    }

    /**
     * Cancels a document in the fiscal unit.
     *
     * @param {TseDocument} document - The document to be cancelled in the fiscal unit.
     * @returns {Observable<TseFiscalResponse>} An observable containing the response from the fiscal module.
     */
    cancelDocument(document: TseDocument): Observable<TseFiscalResponse> {
        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/cancelDocument`, document);
    }

    /**
     * Validates the document and returns all validation errors (or an empty list if there are no validation errors).
     *
     * @param {TseDocument} document - The document to be validated.
     * @param {boolean} [cancelledDocument=false] - A flag indicating whether the document to be validated is a cancelled document (optional, default: false).
     * @returns {Observable<TseDocumentValidationError[]>} An observable containing all validation errors (if any).
     */
    validateDocument(document: TseDocument, cancelledDocument: boolean = false): Observable<TseDocumentValidationError[]> {
        const params = new HttpParams()
            .set('cancelledDocument', cancelledDocument);

        return super.post<TseDocumentValidationError[]>(`${this.RESOURCE_ENDPOINT}/validateDocument`, document, params);
    }

    /**
     * Returns the requested document if it is stored in the internal memory.
     *
     * @param {string} clientId - The identifier of the client for which the document is requested.
     * @param {string} documentId - The identifier of the document that is requested.
     * @param {number} [daysBack=-1] - The number of days to look back for the requested document (optional, default: -1, only current closing).
     * @returns {Observable<TseDocument>} An observable containing the requested document if it is stored in the internal memory.
     */
    getDocument(clientId: string, documentId: string, daysBack: number = -1): Observable<TseDocument> {
        const params = new HttpParams()
            .set('clientId', clientId)
            .set('documentId', documentId)
            .set('daysBack', daysBack);

        return super.get<TseDocument>(`${this.RESOURCE_ENDPOINT}/document/${clientId}/id/${documentId}`, params);
    }

    /**
     * Increments documents reprintCount.
     *
     * @param {string} clientId - The identifier of the client for which the document is requested.
     * @param {string} documentGuid  - The document guid to reference the document which should be reprinted.
     * @param {TseUser} user  - The user which is requesting the reprint.
     * @param {number} [daysBack=-1] - The number of days to look back for the requested document (optional, default: -1, only current closing).
     * @returns {Observable<TseDocument>} An observable containing the requested document if it is stored in the internal memory.
     */
    incrementReprintCount(clientId: string, documentGuid: string, user: TseUser, daysBack: number = -1): Observable<TseDocument> {
        const params = new HttpParams()
            .set('clientId', clientId)
            .set('documentGuid', documentGuid)
            .set('daysBack', daysBack);
        return super.put<TseDocument>(`${this.RESOURCE_ENDPOINT}/reprintDocument/${clientId}/${documentGuid }`, params);
    }

}
