import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TseApiService } from '@bcmApiServices/tse/tse-api.service';
import { TseFiscalClient } from '@shared/models/tse/TseFiscalClient';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';

@Injectable({
    providedIn: 'root'
})
export class TseManagementApiService extends TseApiService {

    private RESOURCE_ENDPOINT = 'management';

    /**
     * Returns all clients listed in the configuration store.
     *
     * @returns {Observable<TseFiscalClient[]>} An observable containing all clients listed in the configuration store.
     */
    getClients(): Observable<TseFiscalClient[]> {
        return super.get<TseFiscalClient[]>(`${this.RESOURCE_ENDPOINT}/clients`);
    }

    /**
     * Returns client by the clientId.
     *
     * @param {string} clientId - The identifier of the client.
     * @returns {Observable<TseFiscalClient[]>} An observable containing all clients listed in the configuration store.
     */
    getClient(clientId: string): Observable<TseFiscalClient> {
        const params = new HttpParams()
            .set('clientId', clientId);

        return super.get<TseFiscalClient>(`${this.RESOURCE_ENDPOINT}/clients/${clientId}`, params);
    }

    /**
     * Creates a new client with a pre-configured cloud configuration or loads an existing client.
     * The configuration of the client is required for operation.
     *
     * @param {string} storeNumber - The number of the store to which the client should be connected.
     * @param {string} terminalNumber - The number of the terminal that the client represents.
     * @returns {Observable<string>} An observable containing the client identifier (client ID).
     */
    createClientByCloud(storeNumber: string, terminalNumber: string): Observable<string> {
        const params = new HttpParams()
            .set('Identification', 'TSE_IDENTIFICATION')
            .set('Type', 'TSE_IDENTIFICATION_TYPE')
            .set('storeNumber', storeNumber)
            .set('terminalNumber', terminalNumber)
            .set('cloudApiKey', 'API_KEY');

        return super.put<string>(`${this.RESOURCE_ENDPOINT}/clients/byCloud`, {}, params);
    }

    /**
     * Creates a new client with a pre-configured cloud configuration or loads an existing client.
     * The configuration of the client is required for operation.
     *
     * @param {string} storeNumber - The number of the store to which the client should be connected.
     * @param {string} terminalNumber - The number of the terminal that the client represents.
     * @returns {Observable<string>} An observable containing the client identifier (client ID).
     */
    getClientIdByStoreAndTerminalNumber(storeNumber: string, terminalNumber: string): Observable<string> {
        const params = new HttpParams()
            .set('licenseConsumerId', 'TSE_ORGANISATION_ID')
            .set('Identification', 'TSE_IDENTIFICATION')
            .set('Type', 'TSE_IDENTIFICATION_TYPE')
            .set('storeNumber', storeNumber)
            .set('terminalNumber', terminalNumber)
            .set('cloudApiKey', 'API_KEY');

        return super.get<string>(`${this.RESOURCE_ENDPOINT}/clients/id`, params);
    }

    /**
     * Establishes the cloud connection of the client in question and loads the fiscal module into the cache.
     * Required for systems that use cloud functions.
     * Required after every restart of the POS system.
     *
     * @param {string} clientId - The identifier of the client whose cloud connection is to be established.
     */
    connectCloud(clientId: string): Observable<void> {
        const params = new HttpParams()
            .set('clientId', clientId)
            .set('cloudApiKey', 'API_KEY');

        return super.post<void>(`${this.RESOURCE_ENDPOINT}/cloud/connect`, {}, params);
    }

    /**
     * Initializes the fiscal unit (and any hardware) and performs any necessary reporting to tax authorities.
     * Initialization is performed using the start document, which can be obtained either
     * by calling the GET /api/v1/transactions/document/{clientId}/start endpoint or by being created by the cash register system.
     *
     * @param {TseDocument} startDocument - The start document.
     * @returns {Observable<TseFiscalResponse>} - An observable that returns a TseFiscalResponse object containing information about the initialization status.
     */
    initializeClient(startDocument: TseDocument): Observable<TseFiscalResponse> {
        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/clients/initialize`, startDocument);
    }

}
