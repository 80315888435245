import {
    U2bColumnDefinitionExternalComponent,
    U2bFilterSelectOptionsRelations,
    U2bTableData
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';
import { Company } from '@shared/models/company';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { DataFilterOperator } from '@core/datafilter/filter-operator';

export const companiesTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Companies,
    columnDefinitions: [
        {
            property: 'name',
            name: 'Name der Organisation',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'form',
            name: 'Gesellschafsform',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'street',
            name: 'Strasse',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'postCode',
            name: 'Postleitzahl',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'city',
            name: 'Ort',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'country',
            name: 'Land',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Company) => item?.country?.de,
            filter: {
                property: 'country',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: 'country',
                operators: [
                    DataFilterOperator.Contains,
                    DataFilterOperator.NotContains,
                ],
                selectOptionsRelation: U2bFilterSelectOptionsRelations.Countries,
                selectOptionsFilterBy: ['de'],
                selectOptionsDisplayWith: 'de'
            }
        },
        {
            property: 'activeTenantRelations',
            name: 'Beziehung',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.TenantRelationColoredName,
            parseItem: (item: Company) => item.activeAndNextTenantRelationAssignments,
            filter: {
                property: 'activeTenantRelations',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: 'name',
                operators: [
                    DataFilterOperator.Contains,
                    DataFilterOperator.NotContains,
                ],
                selectOptionsRelation: U2bFilterSelectOptionsRelations.TenantRelations,
                selectOptionsFilterBy: ['name'],
                selectOptionsDisplayWith: 'name',
            }
        },
        {
            property: 'note',
            name: 'Notiz',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'phone',
            name: 'Rufnummer',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'mail',
            name: 'E-Mail',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
    ],
};
