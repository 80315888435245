import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemNotificationModel } from '@core/components/system-notifications/system-notification.model';
import { U2bSystemNotificationsApiService } from '@core/services/api/u2b-system-notifications.api-service';

@Component({
    selector: 'u2b-system-notifications',
    templateUrl: './system-notifications.component.html',
    styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {

    messages$: Observable<SystemNotificationModel[]>;

    constructor(private api: U2bSystemNotificationsApiService) {
    }

    ngOnInit(): void {
        this.messages$ = this.api.getAll();
    }

}
